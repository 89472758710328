import gql from 'graphql-tag'

export const GET_DOCUMENTS = gql`
  query repositories($sort: String, $order: String, $search: String, $offset: Int, $page: Int, $folderId: String) {
    repositories(sort: $sort, order: $order, search: $search, offset: $offset, page: $page, folderId: $folderId) {
      folders {
        _id
        name
        createdAt
        size
        numberOfDocs
      }
      data {
        _id
        name
        size
        extension
        fileURL
        mimeType
        createdAt
        clientName
        lawyerName
      }
      pagination {
        totalRecords
        pagesAmount
        order
        current
        previous
        next
      }
    }
  }
`

export const GET_DOCUMENT_NAME = gql`
  query repositoryInformation($id: String!) {
    repositoryInformation(id: $id) {
      _id
      name
      extension
    }
  }
`

export const GET_CONTRACTS = gql`
  query getDocuments($sort: String, $order: String, $offset: Int, $page: Int, $search: String) {
    getDocuments(sort: $sort, order: $order, offset: $offset, page: $page, search: $search) {
      data {
        _id
        contractName
        contractMembers
        contractPenalty
        categoryName
        contractDueDate
        contractValue
        contractFile {
          _id
          name
          size
          fileURL
          fileName
          extension
          mimeType
          createdAt
          clientName
          lawyerName
        }
      }
      pagination {
        totalRecords
        pagesAmount
        order
        current
        previous
        next
      }
    }
  }
`

export const GET_CONTRACTS_CLOSE_DUE_DATE = gql`
  query getDocumentsCloseToDueDate{
    getDocumentsCloseToDueDate {
        data {
            _id
            contractName
            contractMembers
            contractDueDate
        }
    }
  }
`

export const GET_CONTRACT = gql`
  query getDocument($id: String!){
    getDocument(id:$id) {
        _id
        contractName
        contractMembers
        categoryName
        contractDueDate
        contractFile {
          _id
          name
          size
          fileURL
          fileName
          extension
          mimeType
          createdAt
          clientName
          lawyerName
        }
        notificationAlertPeriod
        contractValue
        contractValueIncreaseType
        contractPenalty
        observation
        contractObject
        contractReviewRequest
        contractContactName
        contractContactTelephone
        contractContactEmail
        createdAt
        contractPartEmail
        alertId
      }
  }
`

export const GET_ALERTS = gql`
  query getAlerts($sort: String, $order: String, $offset: Int, $page: Int, $search: String) {
    getAlerts(sort: $sort, order: $order, offset: $offset, page: $page, search: $search) {
      data {
        _id
        alertName
        alertDescription
        alertPeriod
        alertReceivers
        alertRepeatPeriod
        isDefault
        createdAt
        updatedAt
      }
      pagination {
        totalRecords
        pagesAmount
        order
        current
        previous
        next
      }
    }
  }
`

export const GET_ALERT = gql`
  query getAlert($id: String) {
    getAlert(id: $id) {
        _id
        alertName
        alertDescription
        alertPeriod
        alertReceivers
        alertRepeatPeriod
        isDefault
        createdAt
        updatedAt
    }
  }
`

export const GET_S3_EXISTS = gql`
  query getS3Exists($file: String) {
    getS3Exists(file: $file) {
      message
    }
  }
`