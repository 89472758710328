import React, { useState } from 'react'
import { 
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import { FaCheck, FaTimes} from "react-icons/fa"
import { FormSelect } from "react-bootstrap"
import { useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../graphql/mutations'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { useHistory } from 'react-router-dom'


const View = ({view, setView}) => {

  const options = [
    'Alimentação/Bebidas',
    'Moda',
    'Construção/imobiliário',
    'Saúde',
    'Academia/Estética/marido de aluguel',
    'Advogado/Contador/RH',
    'Agronegócio',
    'Educação',
    'Informática/Tecnologia',
    'Venda/Marketing',
    'Entretenimento',
    'Industria',
    'Outros/Não sei informar',
  ];
  const [answers, setAnswser] = useState([]);
  const [anwser1,setAnwser1] = useState(0);
  const [anwser2,setAnwser2] = useState(0);
  const [anwser3,setAnwser3] = useState(0);
  const [anwser4,setAnwser4] = useState(0);
  const [anwser5,setAnwser5] = useState(0);
  const [typePlan,setTypePlan] = useState('');

  const { showMessage } = useAlertStack()
  const history = useHistory()

  const [sendCheckoutDataPrivacy] = useMutation(demandsMutations.DATAPRIVACY_CHECKOUT, {
    onCompleted(response) {
      history.push('/dashboard/para-empresas/data-privacy/pagamento');
    },
    onError(error) {
      const customError = ((((((error.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).message || {}).errors
      showMessage({
        title: 'Proposta não finalizada',
        message: customError || 'Ocorreu algum erro desconhecido',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  function handleAnwser(question,anwser){
    setAnswser([...answers,anwser]);
    switch (question) {
      case 1:
        if (anwser === 'nao') {
          setAnwser1(1200)
        } else if (anwser === 'sim1') {
          setAnwser1(400)
        } else if (anwser === 'sim2'){
          setAnwser1(800)
        }
          break;
      case 2:
        if (anwser === 'B2B') {
          setAnwser2(0)
        } else if (anwser === 'B2C') {
          setAnwser2(600)
        } else if (anwser === 'B2B2C'){
          setAnwser2(400)
        }else if(anwser === 'B2B2C'){
          setAnwser2(200)
        }
        break;
      case 3:
        if (anwser === 'Alimentação/Bebidas') {
          setAnwser3(200)
        } else if (anwser === 'Moda') {
          setAnwser3(400)
        } else if (anwser === 'Construção/imobiliário'){
          setAnwser3(200)
        } else if(anwser === 'Saúde'){
          setAnwser3(800)
        } else if (anwser === 'Academia/Estética/marido de aluguel') {
          setAnwser3(400)
        } else if (anwser === 'Advogado/Contador/RH') {
          setAnwser3(200)
        } else if (anwser === 'Agronegócio'){
          setAnwser3(0)
        }else if(anwser === 'Educação'){
          setAnwser3(800)
        } else if (anwser === 'Informática/Tecnologia') {
          setAnwser3(400)
        } else if (anwser === 'Venda/Marketing') {
          setAnwser3(600)
        } else if (anwser === 'Entretenimento') {
          setAnwser3(400)
        } else if (anwser === 'Industria'){
          setAnwser3(0)
        }else if(anwser === 'Outros/Não sei informar'){
          setAnwser3(200)
        }
        break;
      case 4:
        if (anwser === 'sim') {
          setAnwser4(400)
        } else if (anwser === 'nao') {
          setAnwser4(0)
        }
        break;
      case 5:
        if (anwser === '5') {
          setAnwser5(0)
        } else if (anwser === '15') {
          setAnwser5(100)
        } else if (anwser === '30'){
          setAnwser5(200)
        }else if(anwser === '60'){
          setAnwser5(300)
        }else if(anwser === '100'){
          setAnwser5(400)
        }else if(anwser === '250'){
          setAnwser5(800)
        }else if(anwser === '500'){
          setAnwser5(1200)
        }else if(anwser === '+500'){
          setAnwser5(1600)
        }
        break;
      default:
        return false
    }
  }

  const handleConfirmSubscription = (e) => {
    e.preventDefault()
    sendCheckoutDataPrivacy({
      variables:{
        answers: answers.join(','),
        finalPrice: anwser1 + anwser2 + anwser3 + anwser4 + anwser5,
        planType: typePlan
      }
    })
  }


  if (view === 0) {
      return (
        <div className="plans">
            <div className="card">
                <div className="header">
                  <h5>PLANO</h5>
                  <h1>SEMESTRAL</h1>
                </div>
                <ul>
                  <li><span className="x"><FaTimes/></span>DPO incluso</li>
                  <li><span className="check"><FaCheck/> Adequação completa da sua empresa à LGPD.</span></li>
                  <li><span className="check"><FaCheck/> 1 Treinamento Anual sobre LGPD.</span></li>
                  <li><span className="check"><FaCheck/> Acesso ilimitado via chat a um advogado especialista em data privacy</span></li>
                  <li><span className="check"><FaCheck/> SLA de 24 horas para atendimento de solicitações</span></li>
                  <li><span className="check"><FaCheck/> Entrega de documentos de Data Privacy (Política de Privacidade, Política de Reporte de Incidentes, etc.)</span></li>
                  <li><span className="check"><FaCheck/> Revisão de até 20 documentos de Data Privacy (Contratos, Q&As, Relatórios, etc.)</span></li>
                  <li><span className="check"><FaCheck/> <strong>Adicione 2 usuários  </strong> à sua conta</span></li>
                </ul>
                <div className="position">
                  <div className="value">
                    <p>A partir de</p>
                    <h5>R$ 480,00</h5>
                    <p>mensal</p>
                  </div>
                  <button className="btn" onClick={() => {
                    setTypePlan('semestral')
                    setView(1)
                  }}>Assinar plano</button>
                </div>  
            </div>
            <div className="card">
                <div className="header">
                  <h5>PLANO</h5>
                  <h1>Anual</h1>
                  <div className="recomendad"><span>Mais Recomendado</span></div>
                </div>
                <ul>
                  <li><span className="check"><FaCheck/> Atuação como <strong>DPO</strong></span></li>
                  <li><span className="check"><FaCheck/> Adequação completa da sua empresa à LGPD.</span></li>
                  <li><span className="check"><FaCheck/> 2 Treinamento Anual sobre LGPD.</span></li>
                  <li><span className="check"><FaCheck/> Acesso ilimitado 24/7 ao DPO especialista em data privacy</span></li>
                  <li><span className="check"><FaCheck/> SLA de 24 horas para atendimento de solicitações</span></li>
                  <li><span className="check"><FaCheck/> Apoio no atendimento a solicitações de titulares</span></li>
                  <li><span className="check"><FaCheck/> Adequação completa da sua empresa à LGPD</span></li>
                  <li><span className="check"><FaCheck/> Entrega de documentos de Data Privacy (Política de Privacidade, Política de Reporte de Incidentes, etc.)</span></li>
                  <li><span className="check"><FaCheck/> Revisão ilimitada de documentos de Data Privacy (Contratos, Q&As, Relatórios, etc.)</span></li>
                  <li><span className="check"><FaCheck/> Avaliação LGPD de 5 Clientes e/ou Fornecedores</span></li>
                  <li><span className="check"><FaCheck/> Relatório Mensal das Demandas de Data Privacy</span></li>
                  <li><span className="check"><FaCheck/> <strong>Adicione 2 usuários  </strong> à sua conta</span></li>
                </ul>
                <div className="position">
                  <div className="value">
                    <p>A partir de</p>
                    <h5>R$ 400,00</h5>
                    <p>mensal</p>
                  </div>
                  <button className="btn" onClick={() => {
                    setTypePlan('anual')
                    setView(1)
                  }}>Assinar plano</button>
                </div>
            </div>
        </div>
      )
  }
  if (view === 1) {
    return (
      <form onSubmit={handleConfirmSubscription}>
        <h4>Preencha o formulário a seguir e veja qual a melhor opção para a sua empresa</h4>
          {/*PERGUNTA 1*/}
          <FormControl>
            <FormLabel className="label">1. Ja fez algo sobre LGPD?</FormLabel>
            <RadioGroup
              onChange={(e) => handleAnwser(1,e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel value="nao"  data-value="1200" control={<Radio />} label="Não" />
              <FormControlLabel value="sim1" control={<Radio />} label="Sim, contratei um serviço de diagnosito e implementacao completa." />
              <FormControlLabel value="sim2" control={<Radio />} label="Sim, mas fiz algo básico como termos de uso e politica de privacidade." />
            </RadioGroup>
          </FormControl>
          {/*PERGUNTA 2*/}
          <FormControl>
            <FormLabel className="label">2. Qual o seu modelo de negócio?</FormLabel>
            <small>Caso tenha mais de um modelo, assinale o que tiver maior predominância. </small>
            <RadioGroup
              onChange={(e) => handleAnwser(2,e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel value="B2B" control={<Radio />} label="B2B" />
              <FormControlLabel value="B2C" control={<Radio />} label="B2C" />
              <FormControlLabel value="B2B e B2C" control={<Radio />} label="B2B e B2C" />
              <FormControlLabel value="B2G" control={<Radio />} label="B2G" />
            </RadioGroup>
          </FormControl>
          {/*PERGUNTA 3*/}
          <FormControl>
            <FormLabel className="label">3. Segmento da Empresa</FormLabel>
            <FormSelect
              className="mt-3" style={{width: "300px"}}
              onChange={(e) => handleAnwser(3,e.target.value)}
            >
              <option>Selecione uma opção</option>
              {options.map((option,counter) => (
                <option key={counter} value={option}>{option}</option>
              ))}
            </FormSelect>
          </FormControl>
          {/*PERGUNTA 4*/}
          <FormControl>
            <FormLabel className="label">4. Você possui atuação em outros países?</FormLabel>
            <RadioGroup
              name="radio-buttons-group"
              onChange={(e) => handleAnwser(4,e.target.value)}
            >
              <FormControlLabel value="nao" control={<Radio />} label="Não" />
              <FormControlLabel value="sim" control={<Radio />} label="Sim" />
            </RadioGroup>
          </FormControl>
          {/*PERGUNTA 5*/}
          <FormControl>
            <FormLabel className="label">5. Quantos funcionários tem a sua empresa?</FormLabel>
            <small>CLTs ou PJs que sejam full time.</small>
            <RadioGroup
              name="radio-buttons-group"
              onChange={(e) => handleAnwser(5,e.target.value)}
            >
              <FormControlLabel value="5" control={<Radio />} label="Até 5 funcionários" />
              <FormControlLabel value="15" control={<Radio />} label="Até 15 funcionários" />
              <FormControlLabel value="30" control={<Radio />} label="Até 30 funcionários" />
              <FormControlLabel value="60" control={<Radio />} label="Até 60 funcionários" />
              <FormControlLabel value="100" control={<Radio />} label="Até 100 funcionários" />
              <FormControlLabel value="250" control={<Radio />} label="Até 250 funcionários" />
              <FormControlLabel value="500" control={<Radio />} label="Até 500 funcionários" />
              <FormControlLabel value="+500" control={<Radio />} label="Acima 500 funcionários" />
            </RadioGroup>
          </FormControl>
          <button type="submit" className="btn btn-green">
              Continuar
          </button>
      </form>
    )   
  }
}
 
export default View;
