import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import { styled } from "@mui/material/styles";

const CustomCheckBox = styled('span')(({ theme }) => ({
  margin: 0,
  padding: 0,
  borderRadius: 2,
  width: 16,
  height: 16,
  boxShadow: '0 0 0 1px #9997A0',
  backgroundColor: '#ffffff',
  'input:hover ~ &': { backgroundColor: 'rgba(211, 210, 215, 0.5)' },
  'input:disabled ~ &': {  boxShadow: 'none', background: '#D3D2D7' },
}));

const CheckedCustomCheckBox = styled(CustomCheckBox)({
  margin: 0,
  padding: 0,  
  backgroundColor: '#7683F6',
  boxShadow: '0px 0px 0px 1px rgba(123, 97, 255, 0.48)',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: 'rgba(118, 131, 246, 0.48)',
    },
});

const CheckBoxForm = props => {
  return (
    <Checkbox
      id={props.id}
      name={props.name}
      onChange={props.onChange}
      checked={props.checked}
      onBlur={props.onBlur}
      error={props.error}
      checkedIcon={<CheckedCustomCheckBox />}
      icon={<CustomCheckBox />}
      inputProps={props.inputProps}
      sx={{ '&:hover': { bgcolor: 'transparent' }, '&': props.style }}
      disableRipple
      {...props}
      />
  )
}

export default CheckBoxForm