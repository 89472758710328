import React from 'react'
import styled from 'styled-components'
import { Box } from 'reflexbox'
import TextField from '@mui/material/TextField';

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  font-family: 'Roboto';
`

const CustomTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    fontFamily: 'Roboto',
    backgroundColor: '#FFF',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '4px',
  },
  '& label': {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: 'black',
    paddingLeft: '1px',
  },
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: 'rgba(0, 0, 0, 0.42)',
  },
  '& .MuiFilledInput-placeholder': {
    fontFamily: 'Roboto',
  },
  '& .MuiFormHelperText-root': {
    fontFamily: 'Roboto',
    padding: '0% 14px',
  },
});

const TextFieldMultiForm = props => {
  return (
    <Wrapper>
      <CustomTextField
        fullWidth
        id={props.id}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.helperText}
        inputProps={props.inputProps}
        required={props.required}
        InputLabelProps={{ shrink: true }}
        variant="filled"
        rows={props.rows}
        multiline
      />
    </Wrapper>
  )
}

export default TextFieldMultiForm