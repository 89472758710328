import React from 'react'
import styled from 'styled-components'

const Label = styled.h4`
  width: 100%;
  color: #555;
  font-size: 1em;
  font-weight: 600;
  margin-top: 6px;
`

const CustomLabel = props => {
    return (
        <Label style={props.style}>
            {props.children}
        </Label>
    )
}

export default CustomLabel