import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import DashboardRoutes from './routes/DashboardRoutes'
import { ApolloProvider } from '@apollo/react-hooks'
import apolloClient from './graphql/apolloClient'
import ClientInfo from './containers/ClientInfo'
import ForgotPassword from './containers/ForgotPassword'
import Login from './containers/Login'
import LoginFacebook from './containers/LoginFacebook'
import Logout from './containers/Logout'
import NotFound from './containers/NotFound'
import PrivateRoute from './components/PrivateRouter'
import ResetPassword from './containers/ResetPassword'
import RegisterStep from './containers/RegisterStep'
import SignIn from './containers/SignIn'
import FirstLogin from './containers/FirstLogin'
import usePushNotification from './hooks/usePushNotification'
import { CreditCardRegistrationRequest } from './containers'

function App() {
  usePushNotification(apolloClient)

  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path={`/login${process.env.REACT_APP_PRIVATE_TOKEN_URL}:token`} component={Login} exact />
          <Route path="/login/facebook" component={LoginFacebook} exact />
          <Route path="/primeiro-acesso" component={FirstLogin} exact />
          <Route path="/primeiro-acesso/:token" component={FirstLogin} exact />
          <Route path="/recuperar" component={ForgotPassword} exact />
          <Route path="/redefinir" component={ResetPassword} exact />
          <Route path="/redefinir/:token" component={ResetPassword} exact />
          <Route path="/nova-conta" component={SignIn} exact />
          <Route path="/completar-cadastro" component={ClientInfo} exact />
          <Route path="/andamento-cadastro" component={RegisterStep} exact />
          <Route path="/sair" component={Logout} exact />
          <Route path="/pagamento-recorrente" component={CreditCardRegistrationRequest} exact />
          <PrivateRoute path="/dashboard" component={DashboardRoutes} />
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  )
}

export default App
