import React from 'react'
import { Flex } from 'reflexbox'
import Spinner from 'ui-components/lib/components/Spinner'

const FormLoading = () => (
  <Flex alignItems="center" justifyContent="center" height="50vh" width={1}>
    <Spinner color="primary" />
  </Flex>
)

export default FormLoading
