import React from 'react'
import Box from '@mui/material/Box';
import "./OrderBNZ.css";
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

const OrderBNZ = props => {
  const { disabled } = props
  const history = useHistory()

  return (
    <Box className="orderBnz-wrapper">
      {`Consulte e solicite um serviço agora mesmo.`}
      <Button 
        sx={{
          padding: '16px 24px', 
          borderRadius: '8px', 
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: '600',
          lineHeight: 'unset', 
          letterSpacing: 'unset',
          textTransform: 'unset',
          backgroundColor: disabled ? '#D3D2D7' : '#1A2C50', 
          color: '#FFFFFF',
          marginTop: '16px',
          '&:hover': {
            backgroundColor: disabled ? '#D3D2D7' : '#1A2C50',
          },
        }}
        disabled={disabled ? true : false}
        onClick={() => history.push('/dashboard/serviços/solicitar')}
      >
        {`Solicitar serviço`}
      </Button>
    </Box>
  )
}

export default OrderBNZ
