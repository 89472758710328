import React from 'react'
import styled from 'styled-components'

const Logo = styled.img`
  margin: 0 auto;
`

const RegisterLogo = props => {
    return (
        <Logo src={props.src} alt={props.alt} width={props.width}/>
    )
}

export default RegisterLogo