import React, {useState ,useCallback, useEffect} from 'react';
import { walletQueries } from '../../graphql/queries'
import { useQuery} from '@apollo/react-hooks'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomSpinner from '../CustomSpinner';
import { PurchaseBNZ } from '../../components'
import "./CustomTable.css";
import { useHistory } from 'react-router-dom';

const CustomTable = props => {
  const {data, loading, headers,demandModal, acceptModal, refuseModal, page, totalPages, changePage, prevPage, nextPage, isJp} = props

  const history = useHistory()
  const [content, setContent] = useState()
  const [pagination, setPagination] = useState()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [servicePrice, setServicePrice] = useState('')
  const [planData, setPlanData] = useState('')

  const { data: walletData, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)

  const { loading: loadingPlanData } = useQuery(walletQueries.GET_MY_SUBSCRIPTION,{
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans'
    },
    onCompleted(data){
      setPlanData(data.subscription)
    }
  })

  const createPagination = (input) => {
    let page = 0
    let pagesArray = []
    while (page < input) {
      pagesArray[page] = page + 1
      page += 1
    }
    setPagination(pagesArray)
  }

  const goToDetails = (id) => {
    history.push(`/dashboard/serviços/detalhes/proposta/${id}?proposal`)
  }

  const openModal = useCallback((price) => {
    setServicePrice(price)
    setModalIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    refetchMyWallet()
  }, []) // eslint-disable-line

  useEffect(() => {
    if(data){
      setContent(data)
      createPagination(totalPages)
    }
  }, [data]) // eslint-disable-line


  return (
    <Box className="custom-table-proposals-wrapper">
      <div className="container">
        <table className="custom-table-proposals-table">
          <thead className="custom-table-proposals-thead">
            <tr>
              {headers && headers.length ? (
                headers.map((item, index) => (
                  <th>{item.title}</th>
                ))
              ) : (
                <th colSpan={100}>{` `}</th>
              )}
            </tr>
          </thead>
          <tbody className="custom-table-proposals-tbody">
            {!loading && !loadingPlanData && content ? (
              content.length ? (
                content.map((item, index) => (
                  item.status !== 'in_estimating' ||  item.status !== 'waiting_estimate_approval' || item.status !== 'in_auction' ?
                  item.isAuction && (
                    item.demandBids.length > 0 ? (
                      item.demandBids.map((bidsItem, bidsIndex) => (
                        <tr>
                          <td>
                            <button onClick={()=> demandModal(item._id, bidsItem._id)}>
                              #{item.code}
                            </button>
                          </td>
                          <td>
                            <button onClick={()=> goToDetails(item._id)}>
                              {item.fullDescription}
                            </button>
                          </td>
                          <td>
                            <button onClick={()=> goToDetails(item._id)}>
                              {bidsItem.deadline ? `${bidsItem.deadline} d/u` : '-'}
                            </button>
                          </td>
                          <td>
                            <button onClick={()=> goToDetails(item._id)}>
                              {
                                isJp && content[index].status !== 'waiting_estimate_approval' ?
                                  (bidsItem.price ? `R$ ${bidsItem.price * 2} ` : '-')
                                  :
                                  isJp? (bidsItem.price ? `R$ ${bidsItem.price} ` : '-')
                                    :
                                    (bidsItem.price ? `${bidsItem.price} BNZs ${bidsItem.isBNZAdquired || bidsItem.isBNZAdquired === null ? '(Adquirido)' : ''}` : '-')
                              }
                            </button>
                          </td>
                          <td>
                            <Box className="custom-table-proposals-buttons-container">
                              {
                                (
                                  (bidsItem.isBNZAdquired === true || bidsItem.isBNZAdquired === null || planData?.planIdentifier === 'plano-premium' || planData?.planIdentifier === 'plano-platinum') && 
                                    parseFloat(walletData?.wallet?.balance) >= parseFloat(bidsItem.price)
                                ) ||
                                (
                                  (bidsItem.isBNZAdquired === false) &&
                                    parseFloat(walletData?.wallet?.balance + walletData?.wallet?.creditsBonuzPlans) >= parseFloat(bidsItem.price)
                                ) ? (
                                  <>
                                    <Button onClick={()=> refuseModal(bidsItem._id)} className="custom-table-proposals-refuse-button">
                                      Recusar
                                    </Button>
                                    <Button onClick={() => acceptModal(bidsItem._id)} className="custom-table-proposals-accept-button">
                                      Aceitar
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Box className="custom-table-proposals-buttons-container">
                                      {isJp ? (
                                        'Você não possui saldo suficiente para aprovar essa proposta.'
                                        ) : (
                                        'Você não possui saldo de BNZ suficiente para aprovar essa proposta.'
                                      )}
                                    </Box>
                                    <Button onClick={() => openModal(bidsItem.price)} className="custom-table-proposals-accept-button">
                                      {isJp ? 'Pagamento' : 'Comprar BNZ'}
                                    </Button>
                                  </>
                                )
                              }
                            </Box>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="no-bids-found">
                        <td>
                          <button onClick={()=> goToDetails(item._id)}>
                            #{item.code}
                          </button>
                        </td>
                        <td>
                          <button onClick={()=> goToDetails(item._id)}>
                            {item.fullDescription}
                          </button>
                        </td>
                        <td>
                          <button onClick={()=> goToDetails(item._id)}>
                            {item.deadline ? `${item.deadline} d/u` : '-'}
                          </button>
                        </td>
                        <td>
                          <button onClick={()=> goToDetails(item._id)}>
                            {
                              isJp && content[index].status !== 'waiting_estimate_approval' ?
                                (item.price ? `R$ ${item.price * 2} ` : '-')
                                :
                                isJp? (item.price ? `R$ ${item.price} ` : '-')
                                  :
                                  (item.price ? `${item.price} BNZs` : '-')
                            }
                          </button>
                        </td>
                        <td>
                          <Box className="custom-table-proposals-buttons-container">
                            Não há propostas para essa demanda. Aguarde propostas dos advogados.
                          </Box>
                        </td>
                      </tr>
                    )
                  )
                    :
                    ''
                ))
              ) : (
                <tr>
                  <td colSpan={100}>Nenhum registro encontrado!</td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan={100}><CustomSpinner /></td>
              </tr>
            )}
          </tbody>
          <tfoot className="custom-table-proposals-tfoot">
            <tr>
              <td colSpan={100}>
                <Box className="custom-table-proposals-pagination-wrapper">
                  <IconButton onClick={() => prevPage()} className="custom-table-proposals-pagination-button-arrow">
                    <ChevronLeftIcon />
                  </IconButton>
                  {pagination && pagination.length ? (
                    pagination.map((item, index) => (
                      <Button
                        onClick={() => changePage(item)}
                        style={item === page ? {color: '#F14F5C'} : {color: '#9997A0'}}
                        className="custom-table-proposals-pagination-button-number"
                      >
                        {item}
                      </Button>
                    ))
                  ) : (
                    <Button className="custom-table-proposals-pagination-button-number">
                      1
                    </Button>
                  )}
                  <IconButton onClick={() => nextPage()} className="custom-table-proposals-pagination-button-arrow">
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </td>
            </tr>
          </tfoot>
        </table>
        <Modal
          open={modalIsOpen}
          onClose={() => {
            closeModal()
          }}
          disableAutoFocus
          sx={{zIndex: '9999'}}
        >
          <PurchaseBNZ
            closeParentModal={() => {
              closeModal()
            }}
            isJp={isJp || false}
            servicePrice={servicePrice}
          />
        </Modal>
      </div>
    </Box>
  )
}

export default CustomTable
