import React from "react"
import { Modal, Box } from "@mui/material";

import './RequestService.css'
import {ReactComponent as Owl} from '../../../../assets/img/owl/own-upgrade.svg'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../../graphql/mutations'
import Spinner from 'ui-components/lib/components/Spinner'

const RequestService = ( props ) => {
  const {
    openModal,
    handleClose,
    requestedService,
  } = props

  let service = "Solicitação"
  let option = "Upgrade de plano"

  if(requestedService){
    if(requestedService === 'add-services'){
      service = "Solicitação"
      option = "Adicionar serviços ao plano premium"
    }
  }

  const { showMessage } = useAlertStack()

  const [requestService, { loading: loadingRequestService }] = useMutation((demandsMutations.REQUEST_SERVICE), {
    variables:{
      service,
      option,
      subscription: 'planos-bonuz',
      notDiscount:true
    },
    onCompleted() {
      handleClose()
      showMessage({
        title: 'Solicitação enviada',
        message: 'Solicitação enviada com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      window.location.reload()
    },
    onError() {
      showMessage({
        title: 'Erro ao enviar solicitação',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    }
  })

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="confirmation modal-ontop"
    >
      <Box className="box purple credit-consummation">
        {loadingRequestService? (
          <div className="h-100 justify-content-center align-items-center d-flex">
            <Spinner color="primary" />
          </div>
        ):(
          <div>
          <h3 className="mb-4">
            {requestedService && requestedService === 'add-services' ? (
              'Incluir serviços no seu plano'
            ) : (
              'Assine o Plano Premium e tenha acesso completo aos serviços'
            )}
          </h3>
            <Owl />
            <p className="mt-3">Nosso time entrará em contato para os próximos passos.</p>
            <div className="mt-3">
              <button className="btn btn-out-blue btn-wd-1" onClick={() => handleClose()}>Voltar</button>
              <button className="btn btn-purple btn-wd-1" style={{marginLeft: "15px"}} onClick={() => requestService()}>Solicitar</button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default RequestService
