import * as Yup from 'yup'

const ClientSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    firstName: Yup.string().min(1, "Nome deve ser maior que 1 carácteres.").required('Nome é obrigatório'),
    lastName: Yup.string().min(1, "Sobrenome deve ser maior que 1 carácteres.").required('Sobrenome é obrigatório'),
    fullName: Yup.string().min(1, "Deve ser maior que 1 carácteres.").required('Campo obrigatório'),
    personalMobileNumber: Yup.string().min(11, "Deve ser um número válido.").required('Campo obrigatório'),
    email: Yup.string().email("Deve ser um email válido.").required('Campo obrigatório'),
    password: Yup.string().min(8, "Deve ser maior que 8 carácteres.").required('Campo obrigatório'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas não estão iguais').required('Campo obrigatório'),
    agreedTerms: Yup.boolean().oneOf([true], 'Campo obrigatório').required('Campo obrigatório'),
    agreedPolicy: Yup.boolean().oneOf([true], 'Campo obrigatório').required('Campo obrigatório'),
    partnership: Yup.string().nullable(),
    product: Yup.string().nullable(),
  })
  
  export default ClientSchema

