import React from 'react'
import styled from 'styled-components'

const FakeButtonStyle = styled.button`
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  outline:none;
  background: transparent;
  transition-duration: 0.3s;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  
  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    transform: scale3d(1.1, 1.1, 1.1);
  };

  @media only screen and (max-width: 1100px) {
    width: 100%!important;
  }
`

const FakeButton = ({ children, ...props }) => (
  <FakeButtonStyle {...props}>
    {children}
  </FakeButtonStyle>
)

export default FakeButton
