import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'
import { useQuery } from '@apollo/react-hooks'
import { clientsQueries } from '../../graphql/queries'
import Spinner from 'ui-components/lib/components/Spinner'
import { Link } from 'react-router-dom'
import Logo from '../LoginFormLogo'
import whatsappRed from '../../images/whatsapp_red.svg'


const FormWrapperContainer = styled(Box)`
  display: flex;
  width: 50%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  background: transparent;
  @media only screen and (max-width: 1100px) {
    width: 100%!important;
    height: 100%!important;
    left: 0;
  }
`

const FormWrapper = styled(Box)`
  display: flex;
  width: 88%;
  height: 92vh;
  padding: 40px 20px 40px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 1100px) {
    width: 100%!important;
    height: 100%!important;
    left: 0;
  }
`

const WhatsappLink = styled(Link)`
  font-family: 'Roboto';
  color: #F14F5C;
  font-size: 14px;
  line-height: 24px;
  text-decoration: none;
`

const SvgWraper = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 2%;
`

const LoginFormWrapper = props => {
  const [whatsAppNumber, setWhatsAppNumber] = useState()

  const { loading: loadingWhatsappNumber, data } = useQuery(clientsQueries.GET_MY_WHATSAPP, {
    onCompleted() {
      setWhatsAppNumber(data?.whatsAppContactNumber.content)
    },
  })

  return (
    <FormWrapperContainer>
      <FormWrapper width={props.width}>
        <Logo />
        {props.children}
        <Flex width={1} justifyContent="center">
          {loadingWhatsappNumber ? (
            <Spinner color="primary" />
          ) : (
            <Flex flex={1} flexDirection="row" justifyContent="center" alignItems="center" style={{marginTop: '25px'}}>
              <SvgWraper src={whatsappRed} alt="whatsapp-logo" />
              <WhatsappLink
                to={{ pathname: `https://api.whatsapp.com/send?phone=${whatsAppNumber?.replace(/\D+/g, '')}&text=Oi%2C%20tudo%20bem%3F` }}
                target="_blank"
              >
                Em caso de dúvidas, fale conosco
              </WhatsappLink>
            </Flex>
          )}
        </Flex>
      </FormWrapper>
    </FormWrapperContainer>
  )
}

export default LoginFormWrapper
