import React from 'react';

const IAttorney = ({ cor }) => {
  const color = cor || '#FB6D06'
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7707 6.25089C15.7707 8.33536 14.7802 10.1829 13.2566 11.3171C13.1822 11.3546 13.108 11.3907 13.034 11.4254C13.2889 11.468 13.5156 11.5114 13.7071 11.5528L13.7134 11.5556L13.7225 11.5592C13.7276 11.5611 13.7328 11.563 13.7379 11.5656C14.8871 12.0519 15.9313 12.7562 16.8127 13.6395C17.3661 14.1908 17.849 14.8061 18.252 15.4713C17.5677 15.6157 16.9339 15.8968 16.3803 16.2846C15.9915 15.6969 15.6251 15.2404 15.431 15.0238C14.3093 13.9021 12.9048 13.1683 11.3776 12.889L10.6833 14.2114L12 20.4812H7.61113L8.92778 14.2114L8.26546 12.9498C6.85414 13.2663 5.55911 13.9757 4.51107 15.0238C3.10105 16.4338 2.30341 18.2941 2.25195 20.2805C2.24938 20.3911 2.15932 20.4812 2.04611 20.4812H0.502293C0.474823 20.4812 0.447629 20.4757 0.422314 20.465C0.396998 20.4543 0.374074 20.4387 0.354891 20.4191C0.335708 20.3994 0.320654 20.3761 0.310618 20.3505C0.300581 20.3249 0.295764 20.2976 0.296451 20.2702C0.320076 19.0481 0.57774 17.8419 1.05549 16.7168C1.54181 15.5676 2.24606 14.5235 3.12935 13.6421C4.01705 12.7518 5.0514 12.0545 6.20412 11.5682C6.28768 11.5418 6.37251 11.5168 6.4585 11.4932C6.34455 11.4346 6.24287 11.3761 6.1544 11.3196C4.63076 10.1829 3.64027 8.33536 3.64027 6.25089C3.64027 2.79778 6.35494 0 9.70548 0C13.056 0 15.7707 2.79778 15.7707 6.25089ZM9.70548 10.5862C8.58293 10.5862 7.52641 10.135 6.73157 9.31584C5.93674 8.49667 5.49897 7.40781 5.49897 6.25089C5.49897 5.09397 5.93674 4.0051 6.73157 3.18594C7.52641 2.36677 8.58293 1.91559 9.70548 1.91559C10.828 1.91559 11.8845 2.36677 12.6794 3.18594C13.4742 4.0051 13.912 5.09397 13.912 6.25089C13.912 7.40781 13.4742 8.49667 12.6794 9.31584C11.8845 10.135 10.828 10.5862 9.70548 10.5862Z" fill=""/>
    <path d="M21.5387 19.3433H20.4305L21.0109 18.5018L21.5387 19.3433Z" fill=""/>
    <path d="M18.2943 20.354L17.6682 19.3896L16.9851 20.354H18.2943Z" fill=""/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3148 24.87C21.7387 24.87 23.7036 22.905 23.7036 20.4812C23.7036 18.0573 21.7387 16.0923 19.3148 16.0923C16.8909 16.0923 14.9259 18.0573 14.9259 20.4812C14.9259 22.905 16.8909 24.87 19.3148 24.87ZM19.1419 17.5553H19.4681V18.2333C19.5556 18.2642 19.6325 18.3193 19.6899 18.3921L20.975 18.0484C21.0094 18.0392 21.0459 18.0415 21.0789 18.0549C21.1119 18.0682 21.1396 18.092 21.1577 18.1226L21.9233 19.3433H22.2407C22.2408 19.474 22.2222 19.6045 22.1857 19.73C22.0308 20.2594 21.5663 20.6437 21.0175 20.6437C20.4476 20.6437 19.9686 20.2292 19.8327 19.6684C19.8076 19.5644 19.7943 19.4555 19.7943 19.3433H20.0345L20.6326 18.4768L19.7943 18.7007C19.7927 18.8003 19.7606 18.897 19.7023 18.9779C19.644 19.0587 19.5623 19.1199 19.4681 19.1531V22.7569H19.9573V23.082H20.9359V23.4071H17.674V23.082H18.6526V22.7569H19.1419V19.153C19.0346 19.115 18.944 19.0411 18.8853 18.9439L17.9325 19.1986L18.6828 20.354H18.8353C18.8353 20.4633 18.8224 20.5727 18.7968 20.679C18.6609 21.2398 18.1821 21.6543 17.6121 21.6543C17.0631 21.6543 16.5988 21.27 16.4438 20.7407C16.4073 20.6151 16.3888 20.4849 16.3889 20.354H16.5859L17.5408 19.0059C17.5627 18.9748 17.5949 18.9524 17.6316 18.9425L18.8206 18.6247C18.8332 18.5364 18.8698 18.4532 18.9264 18.3842C18.983 18.3152 19.0576 18.2629 19.1419 18.2331V17.5553Z" fill=""/>
    </svg>
  )
}

export default IAttorney
