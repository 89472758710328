import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import Spinner from 'ui-components/lib/components/Spinner'
import ProfileMenu from '../ProfileMenu'
import Notifications from '../Notifications'
import bnz_coin_icon from '../../images/coin.svg'


const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  backgroundColor: #FFFFFF;
`

const BoxLeft = styled(Box)`
  display: flex; 
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    display: none!important;
  }
`

const BoxCenter = styled(Box)`
  display: flex; 
  align-items: center; 
  justify-content: center;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

const BoxRight = styled(Box)`
  display: flex; 
  align-items: flex-end; 
  justify-content: flex-end;

  @media only screen and (max-width: 600px) {
    display: none!important;
  }
`

const BnzCoin = styled.img`
  width: 28px;
  height: 28px;
  box-sizing: border-box;
`

const FakeButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
`

const CustomText = styled.h4`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: #F14F5C;
  text-transform: uppercase;
  margin-top: 2px;
`

const NavHeader = props => {
    const history = useHistory()
    if(props.jpLoading || props.plansLoading || props.dataPrivacyLoading){
      return (
        <div className="h-100 justify-content-center align-items-center d-flex">
          <Spinner color="primary" />
        </div>
      )
    }
    let planText = 'Plano Freemium';
    if (props.dataBonuzPlan) {
      if (props.dataBonuzPlan?.subscription?.planIdentifier === 'plano-essencial') {
        planText = 'Plano Essencial'
      } else if (props.dataBonuzPlan?.subscription?.planIdentifier === 'plano-premium') {
        planText = 'Plano Premium'
      } else if (props.dataBonuzPlan?.subscription?.planIdentifier === 'plano-platinum') {
        planText = 'Plano Platinum'
      } else if (props.dataBonuzPlan?.subscription?.planIdentifier === 'plano-start') {
        planText = 'Plano Start'
      }
    }
    if (props.dataPrivacy) {
      if (props.dataPrivacy?.subscription?.planIdentifier === 'data-privacy-semestral') {
        planText = 'Plano Data Privacy Semestral'
      } else if (props.dataPrivacy?.subscription?.planIdentifier === 'data-privacy-anual') {
        planText = 'Plano Data Privacy Anual'
      }
    }
    return (
      <Wrapper>
        <BoxLeft width={[1/3]} style={{color: "#F14F5C", fontWeight: 600, fontSize: 24}}>
          <div className='plan'>{planText}</div>
        </BoxLeft>
        <BoxRight width={[2/3]}>      
          {props.dataBonuzPlan?.subscription?.planIdentifier !== 'plano-premium' && props.dataBonuzPlan?.subscription?.planIdentifier !== 'plano-platinum' && (    
            <>
              <BoxCenter className={!props.isJp ? "" : "d-none"}>
                <FakeButton onClick={() => history.push('/dashboard/carteira')} style={{marginBottom: '4px'}}>
                  <BnzCoin src={bnz_coin_icon} alt="Moeda B.onuz" />
                </FakeButton>  
                <CustomText>{props.bnzBalance} BNZ</CustomText>
              </BoxCenter>
              <div className='vertical-divider'></div>
            </>
          )}
          <Notifications menuItems={props.profileMenuItems} style={{width: '42px', justifyContent: 'center', paddingBottom: '5px'}} />
          <ProfileMenu 
            menuItems={props.profileMenuItems} 
            style={{justifyContent: 'center', marginTop: '1px'}}
            title={ props.clientName ? props.clientName : '' }
          />
        </BoxRight>     
      </Wrapper>
    )
}

export default NavHeader
