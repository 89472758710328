import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import MasterIcon from '../../images/mastercard.svg'
import VisaIcon from '../../images/visa.svg'
import AmericanExpressIcon from '../../images/amex.svg'
import DefaultCardIcon from '../../images/credit_card.svg'

const Icon = styled.img`
  margin-left: 8px;
`

const CardBrandVariants = ({ brand }) => {
  switch (brand) {
    case 'MASTER':
      return <Icon src={MasterIcon} width={50} />

    case 'VISA':
      return <Icon src={VisaIcon} width={50} />

    case 'AMEX':
      return <Icon src={AmericanExpressIcon} width={50} />

    default:
      return <Icon src={DefaultCardIcon} width={50} />
  }
}

CardBrandVariants.propTypes = {
  brand: PropTypes.string.isRequired,
}

export default CardBrandVariants
