import React, { useState } from 'react'
import { Card } from "react-bootstrap";
import { useQuery } from '@apollo/react-hooks'
import { demandsQueries } from '../../../graphql/queries'
import { isAfter } from 'date-fns'
import CustomSpinner from '../../../components/CustomSpinner'
import './ActivitiesSchedule.css'

const ActivitiesSchedule = (props) => {

  const { data, loading: loadingScheduledActivities } = useQuery(demandsQueries.GET_SCHEDULED_ACTIVITIES, { //eslint-disable-line
    onCompleted(data){
      countScheduledActivities(data.getScheduledActivities)

    }
  })

  const [scheduledActivities, setScheduledActivities] = useState(false);

  const countScheduledActivities = (data) => {
    let ongoingActivities = []

    data.map((item) => {
      const tomorrow = new Date()

      const splitDate = item.schedule_date.split('-')
      const splitDay = splitDate[2].replace(/^0/, '')
      const splitMonth = splitDate[1].replace(/^0/, '')
      const splitYear = splitDate[0]
  
      const verifyDay = isAfter(new Date(splitYear, splitMonth, splitDay), new Date(tomorrow.getFullYear(), tomorrow.getMonth() + 1, tomorrow.getDate()))

      if(verifyDay){
        ongoingActivities.push({
          _id: item._id,
          clientId: item.clientId,
          lawyerId: item.lawyerId,
          schedule_activity_id: item.schedule_activity_id,
          schedule_date: `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`,
          schedule_name: item.schedule_name,
          status: item.status
        })
      }

      return true;
    })

    setScheduledActivities(ongoingActivities);
  }

  return (
    <Card className="big-balloon">
      <div>
          <h4>Próximas atividades</h4>
          <p><small>Gerencie as suas atividades agendadas.</small></p>
      </div>
      <div className="data-privacy-scheduled-activities-container">
        {loadingScheduledActivities ? (
          <CustomSpinner />
        ) : (
          scheduledActivities && scheduledActivities.length ? (
            scheduledActivities.map((item, index) => {
              return (
                <div className="d-flex justify-content-between">
                  <div><p>{item.schedule_name}</p></div>
                  <div><p className="date">{item.schedule_date}</p></div>
                </div>
              )
            })
          ) : (
            <div className="d-flex justify-content-between">
              <div><p>Você não possui atividades cadastradas</p></div>
            </div>
          )
        )}
      </div>
    </Card>
  );
}

export default ActivitiesSchedule;
