import React, { useEffect, useState, useCallback } from 'react'
import TextField from 'ui-components/lib/components/TextField'
import Button from 'ui-components/lib/components/Button'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Spinner from 'ui-components/lib/components/Spinner'
import Typography from 'ui-components/lib/components/Typography'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import { Flex, Box } from 'reflexbox'
import { useFormik } from 'formik'
import { LegalPersonAccount } from '../../models'
import { LegalPersonAccountSchema } from '../../validate'
import cep from 'cep-promise'
import InputMask from 'react-input-mask'
import { FormTitle, RegisterForm, CustomLabel, Loading, Dropzone } from '../../components'
import { clientsMutations } from '../../graphql/mutations'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { clientsQueries } from '../../graphql/queries'
import { createRef } from 'react'

const LegalPersonAccountForm = (props) => {
  const { showMessage } = useAlertStack()
  const [mobilePhoneMask, setMobilePhoneMask] = useState('(99) 99999-9999')

  const { data: dataMyInfos, loading: loadingMyInfos, refetch: refetchMyInfos } = useQuery(clientsQueries.GET_MY_INFOS)

  const [isFilesUploading, setIsFilesUploading] = useState(false)

  const [updateClient, { loading: loadingUpdate }] = useMutation(clientsMutations.UPDATE_CLIENT_ACCOUNT, {
    onCompleted() {
      showMessage({
        title: 'Cadastro atualizado com sucesso',
        message: 'Seu cadastro foi atualizado.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    refetchQueries: [
      {
        query: clientsQueries.GET_MY_INFOS,
      },
      {
        query: clientsQueries.GET_STATUS,
      },
    ],
    update: graphqlHelpers.deleteItemsFromCache('Client'),
    onError(error) {
      const customError = ((((((error.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).message || {}).errors

      showMessage({
        title: 'Cadastro não atualizado',
        message: customError || 'Ocorreu algum erro desconhecido',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: new LegalPersonAccount(),
    validationSchema: LegalPersonAccountSchema,
    onSubmit: (values) => {
      updateClient({ variables: values })
      refetchMyInfos()
    },
  })

  const searchZipcode = async () => {
    const zipcodeToFind = formik.values.addressZipcode
    await cep(zipcodeToFind)
      .then((userZipcode) => {
        formik.setValues({
          ...formik.values,
          addressUF: userZipcode.state,
          addressStreet: userZipcode.street,
          addressCity: userZipcode.city,
          addressNeighborhood: userZipcode.neighborhood,
        })
      })
      .catch(() => {
        showMessage({
          title: 'CEP inválido',
          message: 'Por favor, verifique o número e tente novamente.',
          color: 'danger',
          position: 'bottom-left',
          time: 4000,
        })
      })
  }

  useEffect(() => {
    let myInformations = (dataMyInfos || {}).me
    myInformations = { ...myInformations, clientURL: formik.values.clientURL }
    if (myInformations) {
      formik.setValues(myInformations)
    }
  }, [dataMyInfos, formik.values.clientURL]) //eslint-disable-line

  const handleFiles = (files = []) => {
    if (files && !!files.length) {
      const { fileName, randomFileName, mimeType, fileExtension, fileSize } = files?.[0]
      formik.setValues({
        ...formik.values,
        attachment: null,
        attachmentMime: mimeType,
        attachmentExtension: fileExtension,
        attachmentRandomName: randomFileName,
        attachmentFileSize: fileSize,
        name: fileName,
      })
    }
  }

  const formRef = createRef()

  const handleSubmit = (event) => {
    event.preventDefault()

    // eslint-disable-next-line no-unused-expressions
    formRef.current?.reportValidity?.() // manually trigger html validation through ref

    formik.handleSubmit()
  }

  const updateMobilePhoneMaskAndChangeFormik = useCallback(
    (event) => {
      if (event.target.value.length > 14) {
        setMobilePhoneMask('(99) 99999-9999')
      } else {
        setMobilePhoneMask('(99) 9999-9999?')
      }

      formik.handleChange(event)
    },
    [formik]
  )

  if (loadingMyInfos) return <Loading />

  return (
    <>
      <RegisterForm onSubmit={handleSubmit} ref={formRef}>
        <Box width={[1]}>
          <FormTitle>Informações da empresa</FormTitle>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={3}>
          <TextField
            required
            id="businessEmail"
            name={'businessEmail'}
            label={'E-mail'}
            placeholder={'john@bonuz.com'}
            value={formik.values.businessEmail}
            onChange={formik.handleChange}
            error={formik.errors.businessEmail}
            m={2}
            onBlur={formik.handleBlur}
            inputProps={{
              disabled: true,
            }}
          />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={3}>
          <InputMask
            mask={mobilePhoneMask}
            value={formik.values.businessTelephoneNumber}
            onChange={updateMobilePhoneMaskAndChangeFormik}
            onBlur={formik.handleChange}
            maskChar={null}
            formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }}
          >
            {(inputProps) => (
              <TextField
                required
                id="businessTelephoneNumber"
                name={'businessTelephoneNumber'}
                label={'Telefone'}
                placeholder={'(99) 99999-9999'}
                value={formik.values.businessTelephoneNumber}
                // onChange={formik.handleChange}
                error={formik.errors.businessTelephoneNumber}
                m={2}
              />
            )}
          </InputMask>
        </Box>
        <Box width={[1]} mt={4}>
          <FormTitle>Endereço</FormTitle>
        </Box>
        <Box width={[1 / 2, 2 / 7, 2 / 7, 2 / 7]} mt={3}>
          <InputMask mask="99999-999" value={formik.values.addressZipcode} onChange={formik.handleChange} onBlur={searchZipcode}>
            {(inputProps) => (
              <TextField
                required
                id="addressZipcode"
                name={'addressZipcode'}
                label={'CEP'}
                placeholder={'99999-999'}
                value={formik.values.addressZipcode}
                // onChange={formik.handleChange}
                error={formik.errors.addressZipcode}
                m={2}
              />
            )}
          </InputMask>
        </Box>
        <Box width={[1 / 2, 2 / 7, 2 / 7, 2 / 7]} mt={3}>
          <TextField
            required
            id="addressUF"
            name={'addressUF'}
            label={'Estado'}
            placeholder={'SP'}
            value={formik.values.addressUF}
            onChange={formik.handleChange}
            error={formik.errors.addressUF}
            m={2}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[1, 3 / 7, 3 / 7, 3 / 7]} mt={3}>
          <TextField
            required
            id="addressCity"
            name={'addressCity'}
            label={'Município'}
            placeholder={'São Paulo'}
            value={formik.values.addressCity}
            onChange={formik.handleChange}
            error={formik.errors.addressCity}
            m={2}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[1]} mt={3}>
          <TextField
            required
            id="addressStreet"
            name={'addressStreet'}
            label={'Endereço'}
            placeholder={'Avenida Paulista'}
            value={formik.values.addressStreet}
            onChange={formik.handleChange}
            error={formik.errors.addressStreet}
            m={2}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[1, 2 / 5, 2 / 5, 2 / 5]} mt={3}>
          <TextField
            required
            id="addressNeighborhood"
            name={'addressNeighborhood'}
            label={'Bairro'}
            placeholder={'Jardim Floresta'}
            value={formik.values.addressNeighborhood}
            onChange={formik.handleChange}
            error={formik.errors.addressNeighborhood}
            m={2}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[1 / 3, 1 / 5, 1 / 5, 1 / 5]} mt={3}>
          <TextField
            required
            id="addressNumber"
            name={'addressNumber'}
            label={'Número'}
            placeholder={'123'}
            value={formik.values.addressNumber}
            onChange={formik.handleChange}
            error={formik.errors.addressNumber}
            m={2}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[2 / 3, 2 / 5, 2 / 5, 2 / 5]} mt={3}>
          <TextField
            id="addressComplement"
            name={'addressComplement'}
            label={'Complemento'}
            placeholder={'Apartamento'}
            value={formik.values.addressComplement}
            onChange={formik.handleChange}
            error={formik.errors.addressComplement}
            m={2}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[1, 1, 2 / 3, 2 / 3]} mt={3}>
          <CustomLabel>Contrato/Estatuto Social</CustomLabel>
          <Dropzone onLoad={(loading) => setIsFilesUploading(loading)} onComplete={handleFiles} multiple={false} maxFiles={1} />
        </Box>
        <Box width={[1]} mt={4}>
          <FormTitle>Outras informações</FormTitle>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={3}>
          <TextField
            id="businessDescription"
            name="businessDescription"
            label="Conta para gente qual seu produto e/ou serviço?"
            placeholder="Descreva seu produto em poucas palavras"
            value={formik.values.businessDescription}
            onChange={formik.handleChange}
            error={formik.errors.businessDescription}
            m={2}
            multiline
            inputProps={{
              maxLength: 250,
            }}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={3}>
          <TextField
            id="businessTarget"
            name="businessTarget"
            label="Qual seu cliente final?"
            placeholder="Seu público alvo"
            value={formik.values.businessTarget}
            onChange={formik.handleChange}
            error={formik.errors.businessTarget}
            m={2}
            multiline
            inputProps={{
              maxLength: 250,
            }}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Box width={[1]} mt={[4]}>
          <Typography style={{ fontWeight: 'bold', fontSize: '20px' }}>Redefinir senha</Typography>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={3}>
          <TextField
            id={'password'}
            name={'password'}
            label={'Nova Senha'}
            placeholder={'******'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.errors.password}
            type={'password'}
            m={2}
            onBlur={formik.handleBlur}
          />
          <Typography ml={[2]} style={{ fontStyle: 'italic', fontSize: '15px' }}>
            Deixe em branco para não alterar
          </Typography>
        </Box>
        <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} mt={3}>
          <TextField
            id={'passwordConfirmation'}
            name={'passwordConfirmation'}
            label={'Confirmar nova senha'}
            placeholder={'******'}
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            error={formik.errors.passwordConfirmation}
            type={'password'}
            m={2}
            onBlur={formik.handleBlur}
          />
        </Box>
        <Flex justifyContent="flex-end" width={1} mt={4}>
          <Button color="primary" px={4} type={'submit'} disabled={isFilesUploading}>
            {loadingUpdate ? <Spinner /> : 'Salvar'}
          </Button>
        </Flex>
      </RegisterForm>
    </>
  )
}

export default LegalPersonAccountForm
