/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import firebase from 'firebase/app';
import 'firebase/messaging'
import { useMutation } from '@apollo/react-hooks'
import { pushNotificationMutations } from '../graphql/mutations'
import { getAccessToken, addAuthListener } from '../services/auth'

export default (client) => {
  if (!firebase.messaging.isSupported()) return

  const [registerToken] = useMutation(pushNotificationMutations.TOKEN, {
    client,
  })

  useEffect(() => {
    firebase.initializeApp({
      apiKey: 'AIzaSyA0ScKK-uemI8cwAW2uKFBNi278Q5TbLSM',
      authDomain: 'bonuz-889f3.firebaseapp.com',
      databaseURL: 'https://bonuz-889f3.firebaseio.com',
      projectId: 'bonuz-889f3',
      storageBucket: 'bonuz-889f3.appspot.com',
      messagingSenderId: '12532838554',
      appId: '1:12532838554:web:4eb7f6dced15e5a85a2f23',
      measurementId: 'G-C537Z009Z5',
    })

    let authListener
    let messageListener
    ;(async () => {
      const result = await Notification.requestPermission()

      if (result !== 'granted') return

      const accessToken = getAccessToken()

      const initialize = async () => {
        const token = await firebase.messaging().getToken({
          vapidKey: 'BHilHH_YvflmCSa0tJmhVpSj3Fg-X8_Ygz8WNXSZ0mdAW1goI3L6qjwpnCO_nZuJbGiTLAe4Us7cKI8gfgtMgTc',
        })

        try {
          registerToken({ variables: { token } })
        } catch {}

        messageListener = firebase.messaging().onMessage(({ notification: { title, body } }) => {
          new Notification(title, { body, icon: require('../images/favicon-96x96.png') })
        })
      }

      if (accessToken) {
        initialize()
      }

      authListener = addAuthListener((authenticated) => {
        if (authenticated) {
          initialize()
        } else {
          messageListener && messageListener()
        }
      })
    })()

    return () => {
      authListener && authListener()
      messageListener && messageListener()
    }
  }, [])
}
