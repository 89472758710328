import React from 'react'
import styled from 'styled-components'
import Spinner from 'ui-components/lib/components/Spinner'
import { Flex } from 'reflexbox'

const FullScreen = styled(Flex)`
  height: 80vh;
  justify-content: center;
  align-items: center;
`

const Loading = props => {
    return (
        <FullScreen>
            <Spinner color='primary'/>
        </FullScreen>
    )
}

export default Loading