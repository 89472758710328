const CategoryChips = ({ status }) => {

  switch(status) {
    case 'clientes':
      return "Clientes"
    case 'prestador-de-servico-fornecedor':
      return "Prestador de serviço/Fornecedores"
    case 'parcerias':
      return 'Parcerias'
    case 'funcionario-clt-pj':
      return "Funcionário CLT/PJ"
    case 'investimento':
      return "Investimento"
    default:
      return 'Não especificado'
  }
}

export default CategoryChips;
