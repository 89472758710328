import React, {useContext, useState, useEffect} from "react";
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomSpinner from '../CustomSpinner';
import { useQuery } from '@apollo/react-hooks'
import { walletQueries, clientsQueries } from '../../graphql/queries'
import DashboardContext from '../../contexts/DashboardContext'
import { Hints } from '../../components'
import "./PackagesBNZ.css";

const PackagesBNZ = props => {

  const { openModal } = props

  const [packageId, setPackageId] = useContext(DashboardContext); // eslint-disable-line
  const [bnzPackages, setBnzPackages] = useState([{}])

  const [installments, setInstallments] = useState()
  const [price, setPrice] = useState()
  const [whatsAppNumber, setWhatsAppNumber] = useState()
  const [enablePackagesBonuses, setEnablePackagesBonuses] = useState(true)

  let filteredBnzPackages = []

  const { data: dataWalletSettings, loading: loadingWalletSettings } = useQuery(walletQueries.GET_WALLET_SETTINGS)
  const { data: dataWalletStatements, loading: loadingWalletStatements  } = useQuery(walletQueries.GET_MY_STATEMENTS);
  const { data: dataWalletSubscriptions, loading: loadingWalletSubscription  } = useQuery(walletQueries.GET_MY_SUBSCRIPTIONS);

  const { data: dataMyWhatsapp } = useQuery(clientsQueries.GET_MY_WHATSAPP, {
    onCompleted() {
      setWhatsAppNumber(dataMyWhatsapp?.whatsAppContactNumber.content)
    },
  })

  useEffect(() => {
    let bnzPrice = dataWalletSubscriptions?.subscriptions?.standardBnzPrice
    setBnzPackages(dataWalletSettings?.walletSettings?.bnzPackages)

    if(!dataWalletStatements?.statements?.data.length){
      setInstallments(dataWalletSettings?.walletSettings?.creditCardMaximumInstallmentsFirstBuy)
    } else {
      setInstallments(dataWalletSettings?.walletSettings?.creditCardMaximumInstallments)
    }

    if(dataWalletSubscriptions?.subscriptions?.subscriptions?.length){
      setEnablePackagesBonuses(false)
      if(dataWalletSubscriptions?.subscriptions?.currentBnzPrice < dataWalletSubscriptions?.subscriptions?.standardBnzPrice){
        bnzPrice = dataWalletSubscriptions?.subscriptions?.currentBnzPrice
      }
    }

    setPrice(bnzPrice)
  }, [dataWalletSettings, dataWalletStatements, dataWalletSubscriptions])

  useEffect(() => {
    if(packageId !== 'false'){
      openModal()
    }
  }, [packageId]) // eslint-disable-line

  const handleBnzPackage = (index) => {
    setPackageId(index)
  }


  if(bnzPackages && bnzPackages.length > 0) {
    // eslint-disable-next-line
    bnzPackages.map((item, key) => {
      let packagesBackgroundColorButton = '#7683F6'
      let packagesBackgroundColorBorder = '#7683F6'
      let packagesTextColorButton = '#FFFFFF'
      let packagesTextColorPrice = '#7683F6'

      if(item.packageName === 'Silver'){
        packagesBackgroundColorButton = 'linear-gradient(105.08deg, #E5E3E0 -29.8%, #D6D4D2 -18.16%, #B0ADAF 4.05%, #959296 17.98%, #F8F8F7 64.76%, #EAE9E9 76.51%, #C4C2C3 99.04%, #898689 128.81%, #B1B1B0 151.18%)'
        packagesBackgroundColorBorder = '#B0ADAF'
        packagesTextColorButton = '#4E4B59'
        packagesTextColorPrice = '#9997A0'
      }

      if(item.packageName === 'Gold'){
        packagesBackgroundColorButton = 'linear-gradient(105.08deg, #EFCA2E -28.78%, #FFFAD2 15.95%, #F8EFC1 24.97%, #E5D494 42.88%, #C8A84C 67.78%, #BB952D 77.97%, #E7D589 123.7%, #EBDB91 127.79%, #FFFAD2 151.18%)'
        packagesBackgroundColorBorder = '#C8A84C'
        packagesTextColorButton = '#8A5D17'
        packagesTextColorPrice = '#8A5D17'        
      }

      filteredBnzPackages.push({
        packageInstallments: item.packageInstallments,
        packageMaximumInstallmentsBankSlip: item.packageMaximumInstallmentsBankSlip,
        packageMaximumInstallmentsCreditCard: item.packageMaximumInstallmentsCreditCard,
        packageName: item.packageName,
        packagePrice: item.packagePrice,
        packageBonuses: item.packageBonuses,
        packagesBackgroundColorButton: packagesBackgroundColorButton,
        packagesBackgroundColorBorder: packagesBackgroundColorBorder,
        packagesTextColorButton: packagesTextColorButton,
        packagesTextColorPrice: packagesTextColorPrice
      })
   })
  }

  return (
    <Box className="packagesBnz-wrapper">
      <Box className="packagesBnz-header-section">
        Pacotes
      </Box>
      <Box className="packagesBnz-hint-section">
        <Hints
          hintInfo={'O valor do seu BNZ é fixo! Caso compre BNZs de forma avulsa será aplicada uma taxa.'}
        />
      </Box>
      {loadingWalletSettings || loadingWalletStatements || loadingWalletSubscription ? (
          <CustomSpinner />
      ) : (
        <Box className="packagesBnz-packages-wrapper">
          {filteredBnzPackages && filteredBnzPackages.length > 0 ? (
            filteredBnzPackages.map((item, index) => (
              <Box className="packagesBnz-package-container" style={{borderColor: item.packagesBackgroundColorBorder}}>
                <Box className="packagesBnz-package-text-small">
                  Pacote
                </Box>
                <Box className="packagesBnz-package-text-big" style={{paddingTop: '12px', paddingBottom: '8px'}}>
                  {item.packageName}
                </Box>
                <Box className="packagesBnz-package-price-container">
                  <Box className="packagesBnz-package-text-big" style={{fontSize: '20px', color: item.packagesTextColorPrice}}>
                    {`${item.packagePrice} BNZs`}
                  </Box>
                  {item.packageInstallments ? (
                    <Box className="packagesBnz-package-text-medium">
                      {`${item.packageMaximumInstallmentsCreditCard}x R$${
                        ((item.packagePrice * price) / item.packageMaximumInstallmentsCreditCard).toFixed(2)
                      }`}
                    </Box>
                  ) : (
                    <Box className="packagesBnz-package-text-medium">
                      {`${installments}x R$${((item.packagePrice * price) / installments).toFixed(2)}`}
                    </Box>
                  )}
                  <Box className="packagesBnz-package-text-small">
                    {`R$${item.packagePrice * price},00`}
                  </Box>
                </Box>
                {enablePackagesBonuses && (
                  <Box className="packagesbnz-package-text-bonuses">
                    {item.packageBonuses ? (`*Ganhe ${item.packageBonuses} BNZs GRÁTIS`) : (<>&nbsp;</>)}
                  </Box>
                )}
                <Button 
                  sx={{
                    marginTop: '24px',
                    padding: '8px 12px', 
                    borderRadius: '4px', 
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: 'unset', 
                    letterSpacing: 'unset',
                    textTransform: 'unset',
                    background: item.packagesBackgroundColorButton, 
                    color: item.packagesTextColorButton,
                    '&:hover': {
                      background: item.packagesBackgroundColorButton,
                    },
                  }}
                  onClick={() => handleBnzPackage(index)}
                >
                  {`Comprar`}
                </Button>
                <Box className="packagesBnz-package-text-contact">
                  <Link            
                    to={{ pathname: `https://api.whatsapp.com/send?phone=${whatsAppNumber?.replace(/\D+/g, '')}&text=Oi%2C%20tudo%20bem%3F` }}
                    target="_blank"
                    style={{textDecoration: 'none', color: '#4E4B59'}}
                  >
                    Dúvidas? Fale com a Bonuz
                  </Link>
                </Box>
              </Box>  
            ))
          ) : ('')}
        </Box>
      )}
    </Box>
  )
}

export default PackagesBNZ