import { useEffect, useState } from "react";

// https://github.com/aruniverse/adblock-detect-react
// https://github.com/aruniverse/adblock-detect-react/tree/master/adblock-detect-react/src/hooks

const useDetectAdBlock = () => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
    const url = "https://www3.doubleclick.net";
    fetch(url, {
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    })
      .catch((err) => {
        setAdBlockDetected(true);
      });
  }, []);

  return adBlockDetected;
};

export default useDetectAdBlock