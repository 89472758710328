import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

const BgButton = styled(Button)({
  '&.MuiButton-root': {
    width: '100%', 
    marginTop: '8px',
    padding: '10px 0',    
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '8px',
    color: '#FFFFFF',
    backgroundColor: '#F14F5C',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: "'Inter', Helvetica, sans-serif",
  },
  '&.MuiButton-root:hover': {
    backgroundColor: '#F1747E',
  },
  '&.MuiButton-root:active': {
    backgroundColor: '#F1747E',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    backgroundColor: '#E9E9E9',
    color: '#D3D2D7',
  },
});

const BgButtonSecondary = styled(Button)({
  '&.MuiButton-root': {
    width: '100%', 
    marginTop: '8px',
    padding: '10px 0',       
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '8px',
    color: '#FFFFFF',
    backgroundColor: '#501A2C',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: "'Inter', Helvetica, sans-serif",
  },
  '&.MuiButton-root:hover': {
    backgroundColor: '#502634',
  },
  '&.MuiButton-root:active': {
    backgroundColor: '#502634',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    backgroundColor: '#E9E9E9',
    color: '#D3D2D7',
  },
});

const ButtonIcon = styled(IconButton)({
  '&.MuiButton-root': {
    color: '#FFFFFF',
    backgroundColor: '#501A2C',
    border: 'none',
    boxShadow: 'none',  
  },
  '&.MuiButton-root:hover': {
    backgroundColor: '#502634',
  },
  '&.MuiButton-root:active': {
    backgroundColor: '#502634',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    backgroundColor: '#E9E9E9',
    color: '#D3D2D7',
  },
});

const ButtonLink = styled(Button)({
  '&.MuiButton-root': {
    margin: '0',
    padding: '0',        
    fontSize: '16px',
    fontWeight: '400',
    color: '#4E4B59',
    backgroundColor: '#transparent',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    textDecoration: 'underline',    
    fontFamily: "'Inter', Helvetica, sans-serif",    
  },
  '&.MuiButton-root:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',       
    color: 'rgba(78, 75, 89, 0.5)',
  },
  '&.MuiButton-root:active': {
    color: '#4E4B59',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    color: '#D3D2D7',
  },
});

const ButtonExternalLink = styled(Button)({
  '&.MuiButton-root': {
    margin: '0',
    padding: '0',      
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'italic',    
    color: '#7683F6',
    backgroundColor: '#transparent',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',   
    fontFamily: "'Inter', Helvetica, sans-serif",    
  },
  '&.MuiButton-root:hover': {
    backgroundColor: 'transparent',     
    color: '#7683F6',
  },
  '&.MuiButton-root:active': {
    color: '#7683F6',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    color: '#D3D2D7',
  },
});

const ButtonInputField = styled(Button)({
  '&.MuiButton-root': {
    margin: 0,
    padding: 0,
    minWidth: 0,  
    fontSize: '14px',
    fontWeight: '500',
    color: '#4E4B59',
    backgroundColor: '#transparent',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',   
    fontFamily: "'Inter', Helvetica, sans-serif",    
  },
  '&.MuiButton-root:hover': {
    backgroundColor: 'transparent',    
    fontWeight: 700,
    color: '#4E4B59',
  },
  '&.MuiButton-root:active': {
    backgroundColor: 'transparent',    
    fontWeight: 700,
    color: '#4E4B59',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    color: '#D3D2D7',
  },
});

const LoginButtonForm = props => {
  if(props.buttonType === 'primaryButton') {
    return (
      <BgButton style={props.style} onClick={props.onClick || null} disabled={props.disabled} type={props.type || 'button'}>
        {props.children}
      </BgButton>
    )
  }
  if(props.buttonType === 'secondaryButton') {
    return (    
      <BgButtonSecondary style={props.style} onClick={props.onClick || null} disabled={props.disabled} type={props.type || 'button'}>
        {props.children}
      </BgButtonSecondary>
    )
  }  
  if(props.buttonType === 'iconButton') {
    return (    
      <ButtonIcon style={props.style} onClick={props.onClick || null} disabled={props.disabled} type={props.type || 'button'}>
        {props.children}
      </ButtonIcon>
    )
  }
  if(props.buttonType === 'linkButton') {
    return (    
      <ButtonLink style={props.style} onClick={props.onClick || null} disabled={props.disabled} type={props.type || 'button'}>
        {props.children}
      </ButtonLink>
    )
  }
  if(props.buttonType === 'externalLinkButton') {
    return (    
      <ButtonExternalLink style={props.style} onClick={props.onClick || null} disabled={props.disabled} type={props.type || 'button'}>
        {props.children}
      </ButtonExternalLink>
    )
  } 
  if(props.buttonType === 'inputFieldButton') {
    return (    
      <ButtonInputField style={props.style} onClick={props.onClick || null} disabled={props.disabled} type={props.type || 'button'}>
        {props.children}
      </ButtonInputField>
    )
  } 
}

export default LoginButtonForm