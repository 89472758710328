import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const LostPasswordLinkWrapper = styled(Link)`
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  color: #501A2C;
  &:hover {
    color: #501A2C;
    text-decoration: underline;
  }
  &:visited {
    color: #501A2C;
  }
`

const RedirectLinkWrapper = styled(Link)`
  font-family: 'Inter', Helvetica, sans-serif;
  padding-top: 16px;  
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  font-style: italic;
  color: #7683F6;
  &:hover {
    color: #7683F6;
    text-decoration: underline;
  }
  &:visited {
    color: #7683F6;
  }
`

const LinkForm = props => {
    if(props.linkType === 'lostPassword') {
      return (
        <LostPasswordLinkWrapper style={props.style} to={props.to}>
          {props.children}
        </LostPasswordLinkWrapper>      
      )
    }
    if(props.linkType === 'redirect') {
      return (
        <RedirectLinkWrapper style={props.style} to={props.to}>
          {props.children}
        </RedirectLinkWrapper> 
      )
    }
}

export default LinkForm