import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import mail from '../../assets/icons/mail.svg'
import trash from '../../assets/icons/trash.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom';


const EmailManager = ({ onEmailsChange, required = true, initialEmails }) => {
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  
  useEffect(() => {
    setEmails(initialEmails)
  }, [initialEmails])

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleAddEmail = () => {
    const isValid = validateEmail(email);
    setValidEmail(isValid);
    if (isValid) {
      const exists = emails.find(item => item === email);
      if (!exists) {
        const novo = [...emails, email];
        setEmails(novo);
        setEmail('');
        onEmailsChange(novo);
      }
    }
  };

  const handleRemoveEmail = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  const validateEmail = (email) => {
    if (String(email) === '') {
      return false;
    }
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };


  return (
    <>
    <Row style={{marginBottom: '20px'}}>
      <Col sm={5}>
        <Form.Control
          type="email"
          placeholder="email@exemplo.com"
          value={email}
          onChange={(event) => handleEmailChange(event)}
          className={`${!validEmail && required ? 'is-invalid' : ''}`}
        />
        {!validEmail && required && (
          <div class="invalid-feedback">E-mail inválido</div>
        )}
      </Col>
      <Col sm={3}>
        <Button type='button' variant="danger" onClick={handleAddEmail}>
          Adicionar
        </Button>
      </Col>
    </Row>
    {emails.map((email, index) => (
      <>
        <Row style={{marginBottom: '10px', marginTop: '5px'}}>
          <Col lx="12">
            Email {index+1}
          </Col>
        </Row>
        <Row style={{marginTop: '10px'}}>
          <Col lx="12">
            <span style={{border: '1px solid #D3D2D7', padding: '8px 16px', borderRadius: '8px', maxWidth: '50%'}}>
              <img src={mail} alt='' width={15} /> <span style={{marginLeft: '10px', marginRight: '10px'}}>{email}</span> <Link onClick={() => handleRemoveEmail(index)}><img src={trash} width={15} alt='' /></Link>
            </span>
          </Col>
        </Row>
      </>
    ))}
  </>
  );
};

export default EmailManager;