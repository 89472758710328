import { useLocation, useHistory,  } from 'react-router-dom'
import { useEffect, useState, useCallback } from 'react'

const useQueryString = (QUERY_NAME, initialValue = '') => {
    const searchParams = new URLSearchParams(useLocation().search)
    const [value, setValue] = useState(initialValue)
    const history = useHistory()
    useEffect(() => {
        setValue(searchParams.get(QUERY_NAME) || initialValue)
    }, [searchParams]) // eslint-disable-line
    const setQueryValue = useCallback((value) => {
        searchParams.set(QUERY_NAME, value)
        history.push({ search: `?${searchParams.toString()}` })
    },[searchParams]) // eslint-disable-line
    return [value, setQueryValue] // [value, setSearchParams]
}
export default useQueryString
