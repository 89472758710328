import React, { useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import UserIcon from '../../../images/icons/user_colors.svg'
import AccountUserModal from '../../Modal/AccountUserModal'

const Users = () => {

  const [openAddUserModal,setOpenAddUserModal] = useState(false);

  return (
    <>
      <Col sm='3'>
        <Card className="small-balloon">
        <div className="d-flex justify-content-between">
          <div>
            <h4>Adicionar usuário</h4>
          </div>
          <div><img src={UserIcon} alt=""/></div>
        </div>
        <p>No máximo 2 usuários</p>
        <button onClick={()=>setOpenAddUserModal(true)}>Adicionar</button>
      </Card>
      </Col>
      <AccountUserModal
        openModal={openAddUserModal}
        handleClose={()=>setOpenAddUserModal(false)}
        userType="usuário"
      />
    </>
  );
}

export default Users;
