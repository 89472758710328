import gql from 'graphql-tag'

export const CREATE_SIGNED_CONTRACT = gql`
  mutation createSignedContract (
    $contracts:[FileInput]!,
    $deadlineAt:String!,
    $signatories:[SignatoryInput]!,
    $remindInterval:Int!,
    $locale:String!,
    $message:String!,
  ){
    createSignedContract (
      contracts:$contracts
      deadlineAt:$deadlineAt
      signatories:$signatories
      remindInterval: $remindInterval
      locale: $locale
      message: $message
    ){
      _id
    }
  }
`

export const CREATE_CONTRACT = gql`
  mutation createDocument (
    $contractName:String!,
    $contractMembers:String!,
    $categoryName:String!,
    $contract:[FileInput]!,
    $contractDueDate:String!,
    $notificationAlertPeriod:Int!,
    $contractValue:Float!,
    $contractValueIncreaseType:String!,
    $contractPenalty:Float!,
    $observation:String!,
    $contractObject:String!,
    $contractReviewRequest:Boolean!,
    $contractContactName:String!,
    $contractContactTelephone:String!,
    $contractContactEmail:String!,
    $alertId:String,
    $contractPartEmail:String
  ){
    createDocument(
    contractName:$contractName
    contractMembers:$contractMembers
    categoryName:$categoryName
    contract:$contract
    contractDueDate:$contractDueDate
    notificationAlertPeriod:$notificationAlertPeriod
    contractValue:$contractValue
    contractValueIncreaseType:$contractValueIncreaseType
    contractPenalty:$contractPenalty
    observation:$observation
    contractObject:$contractObject
    contractReviewRequest:$contractReviewRequest
    contractContactName:$contractContactName
    contractContactTelephone:$contractContactTelephone
    contractContactEmail:$contractContactEmail
    alertId:$alertId
    contractPartEmail:$contractPartEmail
    ){
      _id
      contractName
      contractMembers
      categoryName
      contractFile {
        _id
        name
        size
        fileURL
        fileName
        extension
        mimeType
        createdAt
        clientName
        lawyerName  
      }
      contractDueDate
      notificationAlertPeriod
      contractValue
      contractValueIncreaseType
      contractPenalty
      observation
      contractObject
      contractReviewRequest
      contractContactName
      contractContactTelephone
      contractContactEmail
      createdAt
      contractPartEmail
      alertId
    }
  }
`

export const UPDATE_CONTRACT = gql`
  mutation updateDocument (
    $id:String!
    $contractName:String,
    $contractMembers:String,
    $categoryName:String,
    $contract:[FileInput],
    $contractDueDate:String,
    $notificationAlertPeriod:Int,
    $contractValue:Float,
    $contractValueIncreaseType:String,
    $contractPenalty:Float,
    $observation:String,
    $contractObject:String,
    $contractReviewRequest:Boolean,
    $contractContactName:String,
    $contractContactTelephone:String,
    $contractContactEmail:String,
    $alertId:String,
    $contractPartEmail:String
  ){
    updateDocument(
      id:$id
      contractName:$contractName
      contractMembers:$contractMembers
      categoryName:$categoryName
      contract:$contract
      contractDueDate:$contractDueDate
      notificationAlertPeriod:$notificationAlertPeriod
      contractValue:$contractValue
      contractValueIncreaseType:$contractValueIncreaseType
      contractPenalty:$contractPenalty
      observation:$observation
      contractObject:$contractObject
      contractReviewRequest:$contractReviewRequest
      contractContactName:$contractContactName
      contractContactTelephone:$contractContactTelephone
      contractContactEmail:$contractContactEmail
      alertId:$alertId
      contractPartEmail:$contractPartEmail
    ){
      _id
      contractName
      contractMembers
      categoryName
      contractFile {
        _id
        name
        size
        fileURL
        fileName
        extension
        mimeType
        createdAt
        clientName
        lawyerName
      }
      contractDueDate
      notificationAlertPeriod
      contractValue
      contractValueIncreaseType
      contractPenalty
      observation
      contractObject
      contractReviewRequest
      contractContactName
      contractContactTelephone
      contractContactEmail
      createdAt
      contractPartEmail
      alertId
    }
  }
`

export const DELETE_CONTRACT = gql`
  mutation deleteDocument($id: String!) {
    deleteDocument(id: $id) {
      _id
      contractName
      contractMembers
      categoryName
      contractFile {
        _id
        name
        size
        fileURL
        fileName
        extension
        mimeType
        createdAt
        clientName
        lawyerName  
      }
      contractDueDate
      notificationAlertPeriod
      contractValue
      contractValueIncreaseType
      contractPenalty
      observation
      contractObject
      contractReviewRequest
      contractContactName
      contractContactTelephone
      contractContactEmail
      createdAt
    }
  }
`

export const CREATE_DOCUMENT = gql`
  mutation createRepositoryFile($name: String!, $fileURL: String!, $extension: String!, $mimeType: String!, $randomFileName: String!, $fileSize: Int!) {
    createRepositoryFile(
      name: $name
      fileURL: $fileURL
      extension: $extension
      mimeType: $mimeType
      randomFileName: $randomFileName
      fileSize: $fileSize
    ) {
      _id
      name
      size
      fileURL
      fileName
      extension
      mimeType
      createdAt
    }
  }
`

export const RENAME_DOCUMENT = gql`
  mutation renameRepositoryFile($id: String!, $name: String!) {
    renameRepositoryFile(id: $id, name: $name) {
      name
      extension
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation deleteRepositoryFile($id: String!) {
    deleteRepositoryFile(id: $id) {
      name
    }
  }
`

export const CREATE_ALERT = gql`
  mutation createAlert(
      $alertName: String!
      $alertDescription: String!
      $alertPeriod: String!
      $alertReceivers: String!
      $alertRepeatPeriod: Int!
      $isDefault: Boolean! 
   ){
    createAlert(
      alertName: $alertName,
      alertDescription: $alertDescription,
      alertPeriod: $alertPeriod,
      alertReceivers: $alertReceivers,
      alertRepeatPeriod: $alertRepeatPeriod,
      isDefault: $isDefault
    ) {
      _id,
      alertName,
      alertDescription,
      alertPeriod,
      alertReceivers,
      alertRepeatPeriod,
      isDefault,
      createdAt,
      updatedAt
      }
  }
`

export const UPDATE_ALERT = gql`
  mutation updateAlert(
      $id: String!,
      $alertName: String!
      $alertDescription: String!
      $alertPeriod: String!
      $alertReceivers: String!
      $alertRepeatPeriod: Int!
      $isDefault: Boolean! 
   ){
    updateAlert(
      id:$id,
      alertName: $alertName,
      alertDescription: $alertDescription,
      alertPeriod: $alertPeriod,
      alertReceivers: $alertReceivers,
      alertRepeatPeriod: $alertRepeatPeriod,
      isDefault: $isDefault 
    ) {
      _id,
      alertName,
      alertDescription,
      alertPeriod,
      alertReceivers,
      alertRepeatPeriod,
      isDefault,
      createdAt,
      updatedAt
      }
  }
`

export const DELETE_ALERT = gql`
  mutation deleteAlert($id: String!) {
    deleteAlert(id: $id) {
      _id,
      alertName,
      alertDescription,
      alertPeriod,
      alertReceivers,
      alertRepeatPeriod,
      createdAt,
      updatedAt
    }
  }
`

