import React from "react";
import "./Hints.css"
import { ReactComponent as Info } from "../../images/Info.svg"

const Hints = (props) => {
    return (
      <div className="container-hints">
        <div className="inner-container-hints">
          <Info />
          <div className="spacer-hints"></div>
          <p>
            {props.hintText && <><b>{props.hintText}</b><br/></>}
            {props.hintInfo && props.hintInfo}
          </p>
        </div>
      </div>
    )
}

export default Hints;