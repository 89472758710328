import gql from 'graphql-tag'

export const PURCHASE_BNZ = gql`
  mutation purchaseBNZ($bnzAmount: Int!, $finalPrice: Float, $cardToken: String, $installments: Int, $isBankSlip: Boolean, $bnzPackageId: String, $voucherId: String) {
    purchaseBNZ(bnzAmount: $bnzAmount, finalPrice: $finalPrice, cardToken: $cardToken, installments: $installments, isBankSlip: $isBankSlip, bnzPackageId: $bnzPackageId, voucherId: $voucherId) {
      invoiceId
      invoiceUrl
    }
  }
`

export const PURCHASE_CONTRACT_CREDITS = gql`
  mutation purchaseContractCredits($creditsAmount: Int!, $finalPrice: Float, $cardToken: String) {
    purchaseContractCredits(creditsAmount: $creditsAmount, finalPrice: $finalPrice, cardToken: $cardToken) {
      invoiceId
      invoiceUrl
    }
  }
`

export const PURCHASE_SUBSCRIPTION = gql`
  mutation purchaseSubscription($subscription: String!, $plan: String!, $price: Int!, $originalPrice: Int, $cardToken: String, $isBankSlip: Boolean, $voucherId: String) {
    purchaseSubscription(subscription: $subscription, plan: $plan, price: $price, originalPrice: $originalPrice, cardToken: $cardToken, isBankSlip: $isBankSlip, voucherId: $voucherId) {
      invoiceUrl
    }
  }
`

export const PURCHASE_WITH_AME_PAYMENT = gql`
  mutation purchaseWithAmePayment($price: Int, $bnzAmount: Int, $subscription: String, $plan: String, $bnzPackageId: String, $voucherId: String) {
    purchaseWithAmePayment(price: $price, bnzAmount: $bnzAmount, subscription: $subscription, plan: $plan, bnzPackageId: $bnzPackageId, voucherId: $voucherId) {
      orderId
      amount
      cashbackAmountValue
      qrCodeLink
      deepLink
    }
  }
`

export const SAVE_CARD = gql`
  mutation saveCard($token: String!, $isDefault: Boolean, $brand: String!, $displayNumber: String!, $holderName: String!, $month: Int!, $year: Int!) {
    saveCard(
      token: $token
      isDefault: $isDefault
      brand: $brand
      displayNumber: $displayNumber
      holderName: $holderName
      month: $month
      year: $year
    ) {
      _id
      clientId
      holderName
      displayNumber
      brand
      month
      year
      default
      createdAt
      updatedAt
    }
  }
`

export const MAKE_MAIN_CARD = gql`
  mutation makeMainCard($cardId: String!) {
    makeMainCard(cardId: $cardId) {
      _id
    }
  }
`

export const DELETE_CARD = gql`
  mutation deleteCard($id: String!) {
    deleteCard(id: $id) {
      _id
    }
  }
`

export const CREDIT_CARD_REGISTRATION_REQUEST = gql`
  mutation creditCardRegistration($token_: String!, $token: String!, $isDefault: Boolean, $brand: String!, $displayNumber: String!, $holderName: String!, $month: Int!, $year: Int!) {
    creditCardRegistration(
      token_: $token_
      token: $token
      isDefault: $isDefault
      brand: $brand
      displayNumber: $displayNumber
      holderName: $holderName
      month: $month
      year: $year
    ) {
      _id
      clientId
      holderName
      displayNumber
      brand
      month
      year
      default
      createdAt
      updatedAt
    }
  }
`
