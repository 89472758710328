import * as queryString from 'query-string'

const loginWithFacebookURL = `https://www.facebook.com/v8.0/dialog/oauth?${queryString.stringify({
  client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
  redirect_uri: process.env.REACT_APP_FACEBOOK_REDIRECT_URL,
  scope: ['email', 'public_profile'].join(','),
  response_type: 'code',
  auth_type: 'rerequest',
  display: 'popup',
})}`

const getAccessTokenFromCode = async (code) => {
  const response = await fetch(
    `https://graph.facebook.com/v8.0/oauth/access_token?${queryString.stringify({
      client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
      client_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET,
      redirect_uri: process.env.REACT_APP_FACEBOOK_REDIRECT_URL,
      code,
    })}`
  )

  const { access_token: accessToken } = await response.json()

  return accessToken
}

export { getAccessTokenFromCode, loginWithFacebookURL }
