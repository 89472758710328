import React, { useEffect } from 'react'
import './MyBnzResume.css'
import { Flex } from 'reflexbox'
import bnz_coin_icon from '../../images/coin.svg'
import { Link } from 'react-router-dom'
import chevron from '../../assets/icons/chevron.svg'

const MyBnzResume = (props) => {

  useEffect(()=>{

  },[])

  return (
    <Flex width={1} id="my-bnz-resume" flexDirection="column" >
        <Flex width={1} className={'bnz-resume'}>
          <Flex width={1}  justifyContent={'flex-start'}>
            <h2>Seu saldo BNZ</h2>
          </Flex>
        </Flex>
        <Flex width={1} justifyContent={'flex-start'}>
          <div style={{display: 'flex', alignItems: 'center', marginLeft: '40px', marginTop: '20px'}}>
            <img src={bnz_coin_icon} alt='Bnz coin icon' width={'34px'} style={{marginRight: '10px'}} />
            <div style={{fontFamily: 'Inter', fontSize: '18px', fontWeight: '700'}}>{props.balance} {props.reserved ? ' | ' : ''} </div>
          </div>
          {parseFloat(props.reserved) > 0 && (
            <div style={{width: '120px', display: 'flex', alignItems: 'center', marginLeft: '5px'}}>
              <img src={bnz_coin_icon} alt='Bnz coin icon' width={'16px'}  style={{marginRight: '5px'}} />
              <div>{props.reserved} Reservados</div>
            </div>
          )}
        </Flex>

      <Flex width={1} alignItems={'center'}  className={'bnz-resume'} justifyContent={'center'}>
        <Flex width={1} alignItems={'center'} justifyContent={'flex-start'} ml={40} mt={40}>
          <Link to={'/dashboard/carteira'}>
            <button style={{backgroundColor: '#501A2C'}}>Compre BNZ's <img src={chevron}  alt='' /></button>
          </Link>
          </Flex>
      </Flex>
    </Flex>
  );
}

export default MyBnzResume;
