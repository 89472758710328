class ClientFirstLogin {
    constructor(data = {}) {
      this.email = data.email || ''
      this.token = data.token || ''
      this.password = data.password || ''
      this.passwordConfirmation = data.passwordConfirmation || ''
    }
  }
  
  export default ClientFirstLogin
  