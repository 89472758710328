import React  from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import "./AcceptModal.css";
import Coruja from "../../../../images/owl-accept-proposal.svg"
import CustomSpinner from "../../../CustomSpinner";

const AcceptModal = (props) => {

    const {
        handleClose,
        openModal,
        action,
        loading
    } = props;


    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            disableAutoFocus
        >
            <div className="modal-accept-proposal">
              {/* <CloseIcon /> */}
              <h2>Aceitar proposta enviada!</h2>
              <div><img src={Coruja} alt="Bonuz owl"/></div>
              <p>Tem certeza que gostaria de aceitar essa proposta?</p>
              <Box className="modal-accept-proposal-buttons-container">
                <Button onClick={handleClose} className="modal-accept-proposal-reject-button">
                  Cancelar
                </Button>
                <Button onClick={action} className="modal-accept-proposal-accept-button">
                  {loading ? <CustomSpinner /> : 'Sim'}
                </Button>
              </Box>
            </div>
        </Modal>

    );
};

export default AcceptModal;