import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import "./ClientBalance.css";
import CustomSpinner from '../CustomSpinner';
import { ReactComponent as CoinIcon } from '../../images/coin.svg'

const ClientBalance = props => {
  const { disabled, loading, data, openModal } = props

  const handleOpenModal = () => {
    openModal()
  }

  return (
    <Box className="client-balance-wrapper">
      <Box className="client-balance-left-container">
        Meu Saldo BNZ
        <Button 
          sx={{
            padding: '16px 24px', 
            borderRadius: '8px', 
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: 'unset', 
            letterSpacing: 'unset',
            textTransform: 'unset',
            backgroundColor: disabled ? '#D3D2D7' : '#1A2C50',
            color: '#FFFFFF',
            marginTop: '16px',
            '&:hover': {
              backgroundColor: disabled ? '#D3D2D7' : '#1A2C50',
            },
          }}
          disabled={disabled ? true : false}
          onClick={() => handleOpenModal()}
        >
          {`Comprar BNZ's`}
        </Button>
      </Box>
      <Box className="client-balance-right-container">
        Saldo
        <Box className="client-balance-inner-container">
          <CoinIcon style={{width: '32px', height: '32px', paddingRight: '10px'}} />{loading ? <CustomSpinner /> : data}
        </Box>
      </Box>            
    </Box>
  )
}

export default ClientBalance