import styled from 'styled-components'
import { Box } from 'reflexbox'

const DropZoneContainer = styled(Box)`
  border: 2px dashed ${({isDragActive, theme }) => !isDragActive ? theme.colors.primary : '#545454' };
  border-radius: 8px;
  padding: 1.6em 1em;
  color: #545454;
  opacity: ${({isDragActive }) => isDragActive ? .6 : 1 };
  margin-bottom: 1em;
`

export default DropZoneContainer
