import React from 'react'
import styled from 'styled-components'

const TextTitle = styled.h2`
  margin: 0 0 0 -2px;
  padding: 50px 0 0 0;
  color: #4E4B59;
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
`

const TextTitleForm = props => {
    return (
      <TextTitle style={props.style}>
        {props.children}
      </TextTitle>
    )
}

export default TextTitleForm