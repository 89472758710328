import React, {useState} from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { Flex } from 'reflexbox';
import { Pagination, Select, Table } from 'ui-components';
import { FaEllipsisV } from 'react-icons/fa'
import './styles.css'
import {useMutation, useQuery} from "@apollo/react-hooks";

import {documentsQueries} from "../../graphql/queries";
import {Typography} from "@mui/material";
import CategoryChips from "../../components/ContractualManagement/CategoryChips/CategoryChips";
import Spinner from "ui-components/lib/components/Spinner";
import {documentsMutations} from "../../graphql/mutations";
import useAlertStack from "ui-components/lib/hooks/useAlertStack";
import SearchField from 'ui-components/lib/components/SearchField'
import useSearchTerm from 'ui-components/lib/hooks/useSearchTerm'
import { HiBell, HiDownload, HiPencil, HiTrash } from 'react-icons/hi'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'

const ContractualManagement = () => {
  const history = useHistory()
  const [sort, setSort] = useState('contractDueDate') //eslint-disable-line
  const [order,setOrder] = useState('desc') //eslint-disable-line
  const [searchTerm, lazySearchTerm, { handleChangeSearchTerm }] = useSearchTerm('', 400)
  const [currentPage, setCurrentPage] = useState(1)
  const offset = 10

  const { showMessage } = useAlertStack()

  const { loading: contractsLoading, data: contractsData,
    refetch: refetchContracts //eslint-disable-line
  } = useQuery(documentsQueries.GET_CONTRACTS, {

    variables: {
      search:lazySearchTerm,
      sort: sort,
      page: currentPage,
      order,
      offset,
      queryBy: 'status'
    },
  })

  function formatCurrency(value){
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatter.format(value)
  }

  const { loading: contractsDueDateLoading, data: contractsDueDateData,
    refetch: refetchContractsDueDate //eslint-disable-line
  } = useQuery(documentsQueries.GET_CONTRACTS_CLOSE_DUE_DATE)


  const [deleteContract,{loading: loadingDelete //eslint-disable-line
  }] = useMutation(documentsMutations.DELETE_CONTRACT,

    {
    onCompleted(){
      showMessage({
        title: 'Contrato deletado',
        message: 'Contrato deletado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      window.location.reload()
    },
    onError(){
      showMessage({
        title: 'Erro ao deletar contrato',
        message: 'Tente novamente mais tarde ou entre em contato com a equipe de suporte.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    }
  })

  const handleChangeNext = () => {
    const nextPage = currentPage + 1
    if (nextPage <= contractsData?.getDocuments?.pagination?.pagesAmount) setCurrentPage(nextPage)
  }

  const handleChangePrev = () => {
    const prevPage = currentPage - 1
    if (prevPage > 0) setCurrentPage(currentPage - 1)
  }

  const handleChangeOrder = (item) => {
    if (item.key === sort) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    } else {
      setSort(item.key)
      setOrder('asc')
    }
  }

  const navigationLinks = [
    { label: 'Início', onClick: () => history.push('/dashboard') },
    { label: 'Gestão Contratual', onClick: () => history.push('/dashboard/gestao-contratual') },
    { label: 'Documentos' }
  ]

  return (
    <>
      <Flex width={1} justifyContent="center" flexDirection="column" className="service">
        <Flex width={1} mb={3} className="breadCrumb">
          <BreadCrumb ml={-3} navigationLinks={navigationLinks} />
        </Flex>
      </Flex>
      <div className='cm' style={{paddingLeft: '2.5%', paddingRight: '2.5%'}} id="document-create">
        <main>
          <Row style={{marginBottom: '20px'}}>
            <Col md='10' style={{marginTop: '20px'}}>
              <h2 className='textBlack'>Documentos</h2>
            </Col>
          </Row>
          <div className='formBorder'>
            <Row>
              <Col sm='4'>
                <h3>Documentos inseridos</h3>
              </Col>
              <Col sm='2' className='ms-auto'>
                <Link
                  to="/dashboard/gestao-contratual/document"
                >
                  <button
                    className='btn btn-red p-2'
                  >
                    + Novo Documento
                  </button>
                </Link>
              </Col>
              <Col sm='3'>
                <SearchField
                  id="search"
                  name="search"
                  placeholder="Buscar contrato"
                  className="input"
                  value={searchTerm}
                  onChange={(event) => handleChangeSearchTerm(event.target.value)}
                />
              </Col>
              <Col
                sm='3'
              >
                <Select onChange={(e)=>setSort(e.target.value)}>
                  <option value=''>Ordenar por</option>
                  <option value='contractDueDate'>Prazo</option>
                  <option value='contractValue'>Valor</option>
                  <option value='contractMembers'>Parte</option>
                  <option value='categoryName'>Categoria</option>
                </Select>
              </Col>
            </Row>
            <Row className='pt-3'>
              <div className='formBorder table'>
                <Table
                  flex={1}
                  width={1}
                  onChangeOrder={handleChangeOrder}
                  loading={contractsLoading}
                  overflow={'auto'}
                  className="document-table"
                  pagination={{
                    sort,
                    order,
                  }}
                  style={{ cursor: 'pointer' }}
                  headers={[
                    {
                      title: 'Contrato',
                      key: 'contractName',
                      align: 'left',
                      width: '60px',
                      cellComponent: ({ value, row }) => value ? <Typography onClick={()=>window.location.href = '/dashboard/gestao-contratual/document/'+row?._id }>{value}</Typography> : '-',
                    },
                    {
                      title: 'Parte',
                      key: 'contractMembers',
                      align: 'left',
                      width: '60px',
                      cellComponent: ({ value, row }) => value ? <Typography onClick={()=>window.location.href = '/dashboard/gestao-contratual/document/'+row?._id }>{value}</Typography> : '-',
                    },
                    {
                      title: 'Multa rescisória',
                      key: 'contractPenalty',
                      align: 'center',
                      width: '60px',
                      cellComponent: ({ value, row }) => value ? <Typography onClick={()=>window.location.href = '/dashboard/gestao-contratual/document/'+row?._id }>{formatCurrency(value)}</Typography> : '-',
                    },
                    {
                      title: 'Categoria',
                      key: 'categoryName',
                      align: 'left',
                      width: '60px',

                      cellComponent: ({ value, row }) => value ? <Typography onClick={()=>window.location.href = '/dashboard/gestao-contratual/document/'+row?._id }> <CategoryChips status={value}/> </Typography>  : '-',

                    },
                    {
                      title: 'Prazo',
                      key: 'contractDueDate',
                      align: 'left',
                      width: '60px',

                      cellComponent: ({ value, row }) => value ? <Typography onClick={()=>window.location.href = '/dashboard/gestao-contratual/document/'+row?._id }>{new Date(value).toLocaleDateString('pt-BR')}</Typography>  : '-',

                    },
                    {
                      title: 'Valor',
                      key: 'contractValue',
                      align: 'left',
                      width: '60px',
                      cellComponent: ({ value, row }) => value ? <Typography onClick={()=>window.location.href = '/dashboard/gestao-contratual/document/'+row?._id }>{formatCurrency(value)}</Typography>  : '-',
                    },
                    {
                      title: '',
                      key: '_id',
                      align: 'left',
                      width: '60px',
                      cellComponent: ({ value, row }) =>
                      <OverlayTrigger
                        rootClose={true}
                        trigger="click"
                        key={'up'}
                        overlay={
                          <Popover className="submenu-document" id={`popover-positioned-up-${row._id}`}>
                            <Popover.Body>
                              <p style={{marginBottom: '20px'}}>
                                <HiPencil color={'#000000'} size={'30'}/>&nbsp;<Link className="contract-submenu-option" to={'/dashboard/gestao-contratual/document/'+row?._id}>Editar documento</Link>
                              </p>
                              <p style={{marginBottom: '20px'}}>
                                <HiDownload color={'#000000'} size={'30'}/>&nbsp;<a className="contract-submenu-option" href={row?.contractFile?.fileURL} target="_blank" download>Baixar documento</a> {/* eslint-disable-line  */}
                              </p>
                              <p style={{marginBottom: '20px'}}>
                                <HiTrash color={'#000000'} size={'30'}/>&nbsp;<button className="contract-submenu-option" onClick={(e)=>{
                                  e.preventDefault()
                                  deleteContract({variables:{id:row?._id}})
                                }}>Deletar documento</button>
                              </p>
                              <p style={{marginBottom: '20px'}}>
                                <HiBell color={'#000000'} size={'30'}/>&nbsp;<Link className="contract-submenu-option" to={'/dashboard/gestao-contratual/alerts'}>Alertas</Link>
                              </p>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <button style={{background: 'transparent', border: 'none', color: '#9997A0'}}><FaEllipsisV/></button>
                      </OverlayTrigger>
                    },
                  ]}
                  // <button style={{background: 'transparent', border: 'none'}}><FaEllipsisV/></button>
                  data={contractsData?.getDocuments?.data || []}
                />
                {contractsData?.getDocuments?.pagination?.pagesAmount > 1 && (
                  <Flex justifyContent="center">
                    <Pagination
                      color="primary"
                      m={20}
                      onChangePage={(item) => setCurrentPage(item)}
                      onPrevPage={(item) => handleChangePrev()}
                      onNextPage={(item) => handleChangeNext()}
                      total={contractsData?.getDocuments?.pagination?.pagesAmount}
                      page={contractsData?.getDocuments?.pagination?.current || 1}
                    />
                  </Flex>
                )}
              </div>
            </Row>
            <h3 className='my-3'>Contratos próximo ao vencimento</h3>
            <Row>
                    {
                      [{ month: 0 }, { month: 1 }, { month: 2 }].map(({ month },counter)=>{
                        let date = new Date();
                        date.setMonth(date.getMonth() + month)
                        let formattedMonth = date.toLocaleString('pt-BR', { month: 'long' });

                        return (
                          <Col sm={4}>
                            <div className='formBorder'>
                              <h4 className='title-card'>{formattedMonth}</h4>
                              {
                                contractsDueDateLoading ? (
                                    <div className="h-100 justify-content-center align-items-center d-flex">
                                      <Spinner color="primary" />
                                    </div>
                                  ):
                                  contractsDueDateData?.getDocumentsCloseToDueDate?.data.map((contract,counter)=>{
                                    let contractDueDate = new Date(contract.contractDueDate)
                                    let isInTheRightMonth = contractDueDate.toLocaleString('pt-BR', { month: 'long' }) === formattedMonth;
                                    return (
                                      isInTheRightMonth ? (
                                      <div className='d-flex flex-column' key={counter} onClick={()=>window.location.href = '/dashboard/gestao-contratual/document/'+contract?._id }>
                                        <p className='mb-1'>{contract.contractName}</p>
                                        <div className='d-flex justify-content-between'>
                                          <div><p style={{color: '#9997A0'}}>{contract.contractMembers}</p></div>
                                          <div><p><strong>{new Date(contract.contractDueDate).toLocaleDateString('pt-BR')}</strong></p></div>
                                        </div>
                                      </div>
                                        ) : <div id="no-contract"></div>
                                    )
                                  })
                              }
                            </div>
                          </Col>
                        )
                      })
                    }
            </Row>
          </div>
        </main>
      </div>
    </>
  )
}

export default ContractualManagement;
