import React from 'react'
import { Typography } from '@mui/material'

const AlertReceiver = (props) => {
  const alertReceivers = props.alertReceivers;

  return (
    <Typography>
      {alertReceivers.replace(';', ', ')}
    </Typography>
  )
}

export default AlertReceiver;
