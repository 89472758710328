import React, { useEffect } from 'react'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Spinner from 'ui-components/lib/components/Spinner'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useMutation } from '@apollo/react-hooks'
import { useFormik } from 'formik'
import { clientsMutations } from '../../graphql/mutations'
import { ResetPassword } from '../../models'
import { ResetPasswordSchema } from '../../validate'
import useQueryString from '../../hooks/useQueryString'

import {
  LoginLogo,
  LoginWrapper,
  LoginContainer,
  LoginFormContainer,
  LoginButtonForm,
  LinkForm,
  TextTitleForm, 
  TextFieldInput,
  TextDescriptionForm,
  FieldLabelForm
} from '../../components'


const ResetPasswordForm = (props) => {
  const history = useHistory()

  const { showMessage } = useAlertStack()

  const [autoCompleteEmail] = useQueryString('email')

  const [resetPasswordClients, { loading }] = useMutation(clientsMutations.RESET_PASSWORD_CLIENT, {
    onCompleted() {
      showMessage({
        title: 'Senha redefinida',
        message: 'Sua senha foi atualizada com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      history.push('/')
    },
    onError(error) {
      showMessage({
        title: 'Erro',
        message: 'Código inválido ou algum erro inesperado.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const formik = useFormik({
    initialValues: new ResetPassword(),
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      resetPasswordClients({ variables: values });
      setSubmitting(false);
    },
  })

  useEffect(() => {
    const token = (((props || {}).match || {}).params || {}).token

    if (token) {
      formik.setValues({ ...formik.values, token })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (autoCompleteEmail) {
      formik.setValues({ ...formik.values, email: autoCompleteEmail })
    }
  }, [autoCompleteEmail]) // eslint-disable-line

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginFormContainer onSubmit={formik.handleSubmit}>
          <LoginLogo />
          <TextTitleForm>Redefinição de senha</TextTitleForm>
          <TextDescriptionForm textType={'description'} style={{padding: '16px 0 0 0'}}>Por favor, preencha os campos abaixo para criar <br /> uma nova senha.</TextDescriptionForm>
            <Box width={[1]} mt={24}>
              <FieldLabelForm>Código</FieldLabelForm>
              <TextFieldInput 
                id="token"
                name={'token'}
                textFieldType={'codeField'}
                placeholder={'código enviado no e-mail'}
                helperText={formik.touched.token && formik.errors.token}                
                value={formik.values.token}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onTouched={formik.touched.token}
                error={formik.touched.token && formik.errors.token && true}
                type={'text'}
              />
            </Box>
            <Box width={[1]} mt={17}>
              <FieldLabelForm>E-mail</FieldLabelForm>
              <TextFieldInput 
                id="email"
                name={'email'}
                textFieldType={'mailField'}
                placeholder={'seunegocio@suaempresa.com'}
                helperText={formik.touched.email && formik.errors.email}                
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onTouched={formik.touched.email}                            
                error={formik.touched.email && formik.errors.email && true}
                type={'text'}
              />
            </Box>
            <Box width={[1]} mt={17}>
              <FieldLabelForm>Nova senha</FieldLabelForm>
              <TextFieldInput 
                id="password"
                name={'password'}
                textFieldType={'passwordField'}
                placeholder={'no mínimo 8 dígitos'}
                helperText={formik.touched.password && formik.errors.password}                
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onTouched={formik.touched.password}                            
                error={formik.touched.password && formik.errors.password && true}
                type={'password'}
              />
            </Box>
            <Box width={[1]} mt={17}>
              <FieldLabelForm>Confirmar senha</FieldLabelForm>
              <TextFieldInput 
                id="passwordConfirmation"
                name={'passwordConfirmation'}
                textFieldType={'passwordField'}
                placeholder={'nova senha'}
                helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}                
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}
                onTouched={formik.touched.passwordConfirmation}                             
                error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && true}
                type={'password'}
              />
            </Box>
            <Flex width={1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} style={{padding: '22px 0 16px 0'}}>
              <LoginButtonForm buttonType={'primaryButton'} disabled={!formik.isValid || formik.isSubmitting} type={'submit'}>
                {loading ? <Spinner color="primary" /> : 'Enviar'}
              </LoginButtonForm>
              <LinkForm linkType={'redirect'} to="/">Voltar</LinkForm>
            </Flex>
        </LoginFormContainer>
      </LoginContainer>
    </LoginWrapper>
  )
}

export default ResetPasswordForm