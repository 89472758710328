import React  from "react";
import Modal from '@mui/material/Modal';
import "./PlansPresentation.css";

const PlansPresentation = (props) => {

  const { handleClose, openModal, planType } = props;

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      contentModalProps={{ width: '100px' }}
    >
      <div className="modal-bonuz-plans-presentation">
        {planType === 'plano-start' && (
          <>
            <h4>Entregáveis</h4>
            <ul>
              <li>600 BNZs para usar nas demandas do dia a dia</li>
              <li>Fale com Advogado nas demandas</li>
              <li>SLA conforme o prazo da plataforma</li>
            </ul>
          </>
      )}
        {planType === 'plano-essencial' && (
          <>
            <h4>Entregáveis</h4>
            <ul>
              <li>10% Off Na Gestão Contratual</li>
              <li>50BNZs Call Com O Advogado</li>
              <li>1.350 BNZs Para Uso (Mensal)</li>
              <li>10% Off Na Compra De BNZs.</li>
            </ul>
          </>
      )}
      {planType === 'plano-premium' && (
          <>
            <h4>Entregáveis</h4>
            <ul>
              <li>20% Off Na Gestão Contratual</li>
              <li>15 Calls Com O Advogado</li>
              <li>Cobrança Dinâmica Com Tabela Premium</li>
              <li>20% Off Na Compra De BNZs.</li>
              <li>Legal Account Manager</li>
            </ul>
          </>
      )}      
      {planType === 'plano-platinum' && (
          <>
            <h4>Entregáveis</h4>
            <ul>
              <li>Gestão Contratual</li>
              <li>15 serviços por mês já incluso</li>
              <li>Solicitação de serviços via WhatsApp ou E-mail</li>
              <li>30% Off Na Compra De BNZs.</li>
              <li>Legal Account Manager</li>
            </ul>
          </>
      )}    
      </div>
    </Modal>
  );
};

export default PlansPresentation;
