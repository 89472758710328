import React, {useEffect, useState} from "react";
import './ServiceDetails.css'
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Dropzone from "../../../Dropzone";

const CreateDemandModal = (props) => {

  const {nextStep, subject, description, file, finalDate} = props;

  const [contract,setContract] = useState([]);
  const [contractFilename,setContractFilename] = useState("");

  useEffect(() => {
    file(contract)
  }, [contract,file]);

  function setFilesHandler(file){

        let actualFilesList = [];

        actualFilesList.push(file);

        let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
            attachment: '',
            name,
            randomFileName,
            mime:mimeType,
            extension,
            fileSize:size,
        }))

        setContractFilename(actualFilesListFiltered[0].name)
        setContract(actualFilesListFiltered);

    }

  return (
    <Container id={"service-details"}>
      <Card>
        <Card.Body>
          <Row>
            <h3>Qual a sua necessidade?</h3>
          </Row>
          <Row>
            <Col>
              <label htmlFor="subject-id"><strong>Assunto</strong></label>
              <Form.Select id="subject-id" onChange={(e)=>subject(e.target.value)}>
                <option disabled>Selecione</option>
                <option value="consulta">Consulta</option>
                <option value="elaboração">Elaboração</option>
                <option value="revisão">Revisão</option>
                <option value="outro">Outro</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="description"><strong>Descrição</strong></label>
              <Form.Control
                onChange={(e)=>description(e.target.value)}
                placeholder="Ex. Notificação a cliente inadiplente|"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Anexar arquivo</Form.Label>
                <button className='form-control' style={{padding: '6px 11px'}} type="file"
                        onClick={(e)=>{
                          e.preventDefault();
                          let fileRefuseDemand = document.getElementById('file-demand');
                          fileRefuseDemand.click();
                        }
                        }
                >
                  { contractFilename !== ""? contractFilename : "Enviar arquivo" }
                </button>
                <span>Formatos permitidos: .pdf, .png, .jpeg, .jpg, .xls, .xlsx, .doc, .docx, .ppt, .pptx</span>
                <Dropzone  onLoad={()=>{}} onComplete={(file)=>setFilesHandler(file)}/>
              </Form.Group>
            </Col>

          </Row>
          <Row>
            <Col>
                <label htmlFor="final-date">
                  <strong>Data final de entrega</strong>
                </label>
              <input type="date" onChange={(e)=>finalDate(e.target.value)} className="form-control" id={"final-date"}/>
            </Col>
            <Col xs={6}></Col>
          </Row>
          <Row>
            <div id="service-details-options-buttons">
              <Row>
                <Col>
                  <Button variant="outline-primary" onClick={()=>nextStep(0)}>
                    Voltar
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" onClick={()=>nextStep(2)}>
                    Avançar
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>

        </Card.Body>
      </Card>
    </Container>

  )
}

export default CreateDemandModal

