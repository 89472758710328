import React, { useState, useEffect, useCallback } from 'react'
import {useParams, useHistory, useLocation} from 'react-router-dom'
import { Flex} from 'reflexbox'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../graphql/mutations'
import { demandsQueries, walletQueries } from '../../graphql/queries'
import Modal from '@mui/material/Modal'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'
import { ChipStatusVariants, Loading, PurchaseBNZ } from '../../components'
import './DemandDetails.css'
import ChatDemand from '../../components/ChatDemand'
import { ReactComponent as Anexar } from "../../assets/icons/anexar.svg";
import { ReactComponent as Chat } from "../../assets/icons/chat.svg";
import RefuseModal from '../../components/Modal/Proposals/RefuseModal'
import AcceptModal from '../../components/Modal/Proposals/AcceptModal'
import ConfirmationModal from '../../components/Modal/ConfirmationModal'
import RatingModal from '../../components/Modal/RatingModal'




const Details = () => {
  const location = useLocation()
  const { id } = useParams()
  const { showMessage } = useAlertStack()
  const { push } = useHistory()
  const timestampToDate = require('timestamp-to-date');
  // eslint-disable-next-line no-unused-vars
  const [currentInteraction,setCurrentInteraction] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [documentInteraction,setDocumentInteraction] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [lastInteraction,setLastInteraction] = useState(0);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [isProposal, setIsProposal] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showService, setShowService] = useState(true);

  const [acceptDemandBidId, setAcceptDemandBidId] = useState('');
  const [refuseDemandBidId, setRefuseDemandBidId] = useState('');
  const [demandId, setDemandId] = useState('');
  const [modalType, setModalType] = useState('');
  const [openRefuseModal, setOpenRefuseModal] = useState(false)
  const [openAcceptModal, setOpenAcceptModal] = useState(false)
  const [openRatingModal, setRatingModal] = useState(false)

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [servicePrice, setServicePrice] = useState('')
  const [clientSubscription, setClientSubscription] = useState('')

  useEffect(() => {
    if (location.search.slice(1) === "proposal") setIsProposal(true)
  },[location])


  // function setFilesHandler(file){
  //     let actualFilesList = files;

  //     actualFilesList.push(file);
  //     let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
  //     attachment: '',
  //     name,
  //     randomFileName,
  //     mimeType,
  //     extension,
  //     size,
  //     }))
  //     setFiles(actualFilesListFiltered);

  // }

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })

  const {data: planData, loading: planDataLoading } = useQuery(walletQueries.GET_MY_SUBSCRIPTION,{
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans'
    }
  })

  const { loading: demandLoading, data: demandData } = useQuery(demandsQueries.GET_DEMAND, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })


  const { refetch: refetchDemands } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'in_estimating',
    },
  })

  const { data: walletData, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)

  const [reproveDemandBid, { loading: reproveDemandBidLoading }] = useMutation(demandsMutations.REPROVE_DEMAND_BID, {
    variables: {
      bidId: refuseDemandBidId,
    },
    onCompleted() {
      setOpenRefuseModal(false)
      // handleOpenSuccessModal('bidRefused')
      refetchDemands()
      push('/dashboard/serviços/propostas')
    },
    // refetchQueries: [
    //   {
    //     query: demandsQueries.GET_DEMAND,
    //     variables: { id },
    //   },
    // ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      setOpenRefuseModal(false)
      showMessage({
        title: 'Erro ao reprovar a proposta',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [approveDemandBid, { loading: approveDemandBidLoading }] = useMutation(demandsMutations.APPROVE_DEMAND_BID, {
    variables: {
      bidId: acceptDemandBidId,
    },
    onCompleted() {
      setOpenAcceptModal(false)
      refetchDemands()
      push('/dashboard/serviços/propostas')
    },
    // refetchQueries: [
    //   {
      //     query: demandsQueries.GET_DEMAND,
      //     variables: { id },
      //   },
    //   {
    //     query: walletQueries.GET_MY_WALLET,
    //   },
    // ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError({ graphQLErrors }) {
      setOpenAcceptModal(false)
      showMessage({
        title: 'Erro ao aceitar a proposta',
        message: graphQLErrors[0]?.extensions?.response?.body?.message || 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const handleRefuseDemandBid = useCallback(() => {
    reproveDemandBid()
  }, [reproveDemandBid])

  const handleAcceptDemandBid = useCallback(() => {
    approveDemandBid()
  }, [approveDemandBid]) 

  const handleOpenAcceptModal = useCallback((id) => {
    setAcceptDemandBidId(id)
    setOpenAcceptModal(!openAcceptModal)
  }, []) //eslint-disable-line
  
  const handleOpenRefuseModal = useCallback((id) => {
    setRefuseDemandBidId(id)
    setOpenRefuseModal(!openRefuseModal)
  }, []) //eslint-disable-line

  const handleConfirmationModal = useCallback((type) => {
    setDemandId(id)
    setModalType(type)
    setOpenConfirmationModal(!openConfirmationModal)
  }, []) //eslint-disable-line

  
  const handleChatModal = () => {
    setOpenChatModal(!openChatModal)
  }

  const handleCloseRatingModal = () => {
    setRatingModal(false)
    push('/dashboard/serviços')
  }

  const openModal = useCallback((price) => {
    setServicePrice(price)
    setModalIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    refetchMyWallet()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (dataClientSubscription){
      setClientSubscription(dataClientSubscription.subscription)
    }
  }, [dataClientSubscription]) //eslint-disable-line

  let demandInteractions = demandData?.demand?.demandInteractionsHistory;

   useEffect(()=>{
    if(demandInteractions != null){
      demandInteractions.forEach((demandInteraction,counter) => {

        if(demandInteraction.status === "transfered_lawyer"){
          delete demandInteractions[counter];
          return;
        }else{
          demandInteractions[counter].counter = counter
          setCurrentInteraction(demandInteractions[counter])
          setDocumentInteraction(demandInteractions[counter].documents)
          setLastInteraction(counter)
        }
      })
    }
  },[demandInteractions])



  const navigationLinks = [
    { label: 'Home', onClick: () => push('/dashboard') },
    { label: 'Serviços', onClick: () => push('/dashboard/serviços') },
    { label: 'Detalhes', onClick: () => push('/dashboard/serviços/detalhes') },
  ]

  if (demandLoading || planDataLoading) return <Loading />


  return (
    <div style={{paddingLeft: '2.5%', paddingRight: '2.5%'}}>
      <Flex width={1} justifyContent="center" flexDirection="column" className='service-details'>
          <Flex width={1} mb={3}>
            <BreadCrumb ml={-3} color="primary" navigationLinks={navigationLinks} />
          </Flex>
        <Flex width={1} flexDirection="column">
          <div className="d-flex align-items-center justify-content-between">
              <h4>{demandData?.demand?.fullDescription}</h4>
              <div className="d-flex align-items-end flex-column">
                <span className="sm-txt-info">Status</span>
                <div>
                    <ChipStatusVariants status={demandData?.demand.status}/>
                </div>
              </div>
          </div>
          <div className="d-flex">
            {(demandData?.demand.status !== 'in_auction' && demandData?.demand.status !== 'demand_sent' ) && (
              <div className="d-flex flex-column" style={{marginRight: "15px"}}>
              <span className="sm-txt-info">data de entrega</span>
                  <div>
                      {/*ARRUMAR*/}
                      <strong>{demandData?.demand?.finalDate? timestampToDate(demandData?.demand.finalDate,'dd/MM/yyyy'):'Não há data informada'}</strong>
                  </div>
              </div>
              )}
              <div className="d-flex flex-column">
                  <span className="sm-txt-info">Advogado responsável</span>
                  <div>
                      <strong>{demandData?.demand?.lawyer?.displayAs}</strong>
                  </div>
              </div>
          </div>
          <div className="mt-3">
              <span className="sm-txt-info">Observação/Coomentário</span>
              <p>{demandData?.demand?.demandInteractionsHistory.length > 0 ?demandData?.demand?.demandInteractionsHistory[demandData?.demand?.demandInteractionsHistory.length -1].comment:'Sem interações registradas' }</p>
          </div>
          {
            demandData?.demand.status === 'waiting_approval' || demandData?.demand.status !== 'in_auction' ?
              (
                <div className="mt-3">
                  <span className="sm-txt-info">Escopo da demanda</span>
                  <p>{demandData?.demand?.demandBids[0]?.comment }</p>
                </div>
              )
              :
              (<h1>tchau</h1>)
          }
          <div className="mt-3">
            <span className="sm-txt-info">Informações do serviço</span><br/>
            <span onClick={() => setShowService(!showService)} style={{color: "#7683F6", cursor: "pointer"}}>Detalhes do serviço <i className={showService ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}/></span>
            <div id='demand-answers-modal'>
            {showService ? "" :
                demandData?.demand?.demandAnswers.map((answer, counter)=> {
                  return (
                    <div>
                      <p>
                        <b>{answer.description}</b>
                      </p>
                      <p>
                        Resposta: <b>{answer.answer}</b>
                      </p>
                      <br/>
                    </div>
                  );
                })
            }
            </div>
          </div>
          {
            isProposal ?
            <div>
              <div id='demand-interactions-modal'>
                <span className="sm-txt-info">historico</span>
                  <table>
                    <thead>
                        <th className="sm-txt-info">Numero</th>
                        <th className="sm-txt-info ml-3">ChatAdvogado</th>
                    </thead>
                    <tbody>
                      <td width='30px'><strong>#{demandData?.demand.code}</strong></td>
                      <td className='mx-3'><Chat onClick={()=>setOpenChatModal(true)}/></td>
                    </tbody>
                  </table>
              </div>
            </div>
            
            :
            <div className="mt-2">
              <span className="sm-txt-info">Observação/Coomentário</span>
              <div id='demand-interactions-modal'>
                <table width="650px">
                    <thead>
                        <th width='10%'>Numero</th>
                        <th width='20%'>Data da interação</th>
                        <th width='50%'>Status</th>
                        <th width='10%'>Anexo</th>
                        <th width='10%'>Fale com Advogado</th>
                    </thead>
                    <tbody>
                  {
                    demandData?.demand?.demandInteractionsHistory.length > 0 ? (
                    demandData?.demand?.demandInteractionsHistory?.filter(item => item.status).map((interaction, counter)=> {
                      return (
                        <tr>
                          <td>{counter}</td>
                          <td>{timestampToDate(interaction.createdAt,'dd/MM/yyyy HH:mm:ss')}</td>
                          <td><ChipStatusVariants status={interaction.status}/></td>
                          <td>
                            {
                              interaction.documents.length > 0
                                ?
                                interaction.documents.map((document,counter) => (
                                    <Anexar className="attach-button" onClick={()=>window.open(document.fileURL,'_blank')} />
                                  ))
                                :
                                ''
                          }
                          </td>
                          <td><Chat onClick={()=>setOpenChatModal(true)}/></td>
                        </tr>
                      );
                    })
                    ):(
                      <tr>
                        <td colSpan="6">
                          <p className='text-center'>Sem interações registradas</p>
                        </td>
                      </tr>
                    )
                  }
                    </tbody>
                </table>
              </div>
            </div>

          }
          <div className="dg-modal">
            <h3>
              {demandData?.demand?.status === 'in_estimating' ? (
                demandData?.demand?.demandBids[0]?.price ? `${!clientSubscription ? `${demandData?.demand?.demandBids[0]?.price} BNZs ${demandData?.demand?.demandBids[0]?.isBNZAdquired || demandData?.demand?.demandBids[0]?.isBNZAdquired === null ? '(Adquirido)' : ''}` : `R$ ${Number(demandData?.demand?.demandBids[0]?.price) * 2}`}` : ``
                ) : (
                  demandData?.demand?.price ? `${!clientSubscription ? `${demandData?.demand?.price} BNZs` : `R$ ${Number(demandData?.demand?.price) * 2}`}` : ``
                )
              }
            </h3>
            {
              (
                demandData?.demand.status === 'waiting_approval') && demandData?.demand.status !== 'in_auction' ?
              (
                <div className="d-flex justify-content-between">
                  <button className="btn" style={{backgroundColor: "#44E47A", marginRight: "10px"}} onClick={() => handleConfirmationModal('revisar')}>Revisar</button>
                  <button className="btn" style={{backgroundColor: "#7683F6"}} onClick={() => handleConfirmationModal('concluir')}>Concluir</button>
                </div>
              )
            :
              (
                demandData?.demand.status === 'waiting_estimate_approval') && demandData?.demand.status !== 'in_auction' ?
              (
                <>
                  {walletData.wallet.balance >= demandData?.demand?.price ? (
                    <div className="d-flex justify-content-between">
                      <button className="btn" style={{backgroundColor: "#44E47A", marginRight: "10px"}} onClick={() => handleConfirmationModal('recusar')}>Recusar</button>
                      <button className="btn" style={{backgroundColor: "#7683F6"}} onClick={() => handleConfirmationModal('aceitar')}>Aceitar</button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      <div>
                        <strong>Você não possui saldo {!clientSubscription ? `de BNZs` : ``} suficiente para aprovar essa demanda.</strong>
                      </div>
                      <button className="btn" style={{backgroundColor: "#44E47A"}} onClick={() => openModal(demandData?.demand?.price)}>{clientSubscription ? 'Pagamento' : 'Comprar BNZ'}</button>                    
                    </div>  
                  )}
                </>
              )
            :
              (
                demandData?.demand.status === 'pending') && demandData?.demand.status !== 'in_auction' ?
              (
                <div className="d-flex justify-content-between">
                  <button className="btn" style={{backgroundColor: "#44E47A", marginRight: "10px"}} onClick={() => handleConfirmationModal('cancelar')}>Cancelar</button>
                </div>
              )
            :
              (
                isProposal)?
              (
                (
                  (demandData?.demand?.demandBids[0]?.isBNZAdquired === true || demandData?.demand?.demandBids[0]?.isBNZAdquired === null || planData?.subscription?.planIdentifier === 'plano-premium' || planData?.subscription?.planIdentifier === 'plano-platinum') && 
                    parseFloat(walletData?.wallet.balance) >= parseFloat(demandData?.demand?.demandBids[0]?.price)
                ) ||
                (
                  (demandData?.demand?.demandBids[0]?.isBNZAdquired === false) &&
                    parseFloat(walletData?.wallet.balance + walletData?.wallet?.creditsBonuzPlans) >= parseFloat(demandData?.demand?.demandBids[0]?.price)
                ) ? (
                <div className="d-flex justify-content-end">
                  <button className="accept-button" onClick={() => handleOpenAcceptModal(demandData?.demand?.demandBids[0]?._id)}>Aceitar</button>
                  <button className="denied-button mx-3" onClick={() => handleOpenRefuseModal(demandData?.demand?.demandBids[0]?._id)}>Recusar</button>
                </div>
                ) : (
                <div className="d-flex justify-content-between">
                  <div>
                    <strong>Você não possui saldo {!clientSubscription ? `de BNZs` : ``} suficiente para aprovar essa proposta.</strong>
                  </div>
                  <button className="btn" style={{backgroundColor: "#44E47A"}} onClick={() => openModal(demandData?.demand?.demandBids[0]?.price)}>{clientSubscription ? 'Pagamento' : 'Comprar BNZ'}</button>                    
                </div> 
                )
              )
            :
              ''
            }
          </div>
        </Flex>
        <ChatDemand
          handleClose={handleChatModal}
          openModal={openChatModal}
          demandId={id}
          demandLawyer={demandData?.demand?.lawyer || ''}
        />
        <AcceptModal 
          openModal={openAcceptModal}
          handleClose={() => setOpenAcceptModal(false)}
          action={handleAcceptDemandBid}
          loading={approveDemandBidLoading}
        />
        <RefuseModal 
          openModal={openRefuseModal}
          handleClose={() => setOpenRefuseModal(false)}
          action={handleRefuseDemandBid}
          loading={reproveDemandBidLoading}
        />
        <Modal
          open={modalIsOpen}
          onClose={() => {
            closeModal()
          }}
          disableAutoFocus
          sx={{zIndex: '9999'}}
        >
          <PurchaseBNZ
            closeParentModal={() => {
              closeModal()
            }}
            isJp={clientSubscription || false}
            servicePrice={servicePrice}
          />
        </Modal>
        <ConfirmationModal 
          openModal={openConfirmationModal}
          handleClose={() => setOpenConfirmationModal(false)}
          type={modalType}
          id={demandId}
          openRating={() => setRatingModal(true)}
        />
        <RatingModal 
          openModal={openRatingModal}
          handleClose={() => handleCloseRatingModal()}
          demandId={id}
          lawyerId={demandData?.demand?.lawyer?._id}
        />
      </Flex>
    </div>
  )
}

export default Details
