import React, { useState, useCallback, useContext, useEffect, useLayoutEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import DashboardContext from '../../contexts/DashboardContext'
import Modal from '@mui/material/Modal'
import Select from 'ui-components/lib/components/Select'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { useQuery } from '@apollo/react-hooks'
import { walletQueries, clientsQueries } from '../../graphql/queries'
import { Wallet } from '../../models'
import styled from 'styled-components'
import {decodedToken} from "../../services/auth";
import "./Transactions.css";

import { 
  Loading, 
  AlertsVariants, 
  PurchaseBNZ, 
  ClientBalance, 
  OrderBNZ,
  CustomTable
} from '../../components'

const HeaderWrapper = styled(Flex)`
  width: 100%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
`

const InnerWrapper = styled(Flex)`
  width: 100%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
`

const Transactions = (props) => {
  const history = useHistory()
  const [packageId, setPackageId] = useContext(DashboardContext);
  const [selectedPackage, setSelectedPackage] = useState([{}]) //eslint-disable-line
  const [currentPage, setCurrentPage] = useState(1)
  const [filterType, setFilterType] = useState('')
  const [filterPeriod, setFilterPeriod] = useState('')
  const [sort] = useState('createdAt')
  const [order] = useState('desc')
  const [purchaseUnvailable, setPurchaseUnvailable] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [search] = useState('')
  const [offset] = useState(10)

  const { data: dataWalletSettings } = useQuery(walletQueries.GET_WALLET_SETTINGS);

  const { data: dataMyWallet, loading: loadingWallet, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)
  const { data: dataStatus, loading: loadingStatus } = useQuery(clientsQueries.GET_STATUS)
  const { data: dataMyStatement, loading, refetch: refetchMyStatements } = useQuery(walletQueries.GET_MY_STATEMENTS, {
    variables: { page: currentPage, search, filterType, filterPeriod, sort, order, offset },
  })


  const formik = useFormik({
    initialValues: new Wallet(),
  })

  const statementTypes = [
    { name: 'Tipos', type: '' },
    { name: 'Débito', type: 'debit' },
    { name: 'Crédito', type: 'credit' },
    { name: 'Reserva', type: 'reserve' },
  ]

  const period = [
    { name: 'Período', period: '' },
    { name: 'Últimos 30 dias', period: 'last_30_days' },
    { name: 'Últimos 3 meses', period: 'last_3_months' },
    { name: 'Últimos 6 meses', period: 'last_6_months' },
    { name: 'Último ano', period: 'last_1_year' },
  ]

  useEffect(() => {
    let myWallet = dataMyWallet?.wallet
    if (myWallet) {
      formik.setValues(myWallet)
    }
  }, [dataMyWallet]) //eslint-disable-line

  useEffect(() => {
    if (dataStatus && dataStatus?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [dataStatus]) // eslint-disable-line

  useLayoutEffect(() => {
    const filteredSelectedPackage = dataWalletSettings?.walletSettings?.bnzPackages.filter((item, partnerIndex, array) => {
      return array.indexOf(item) === Number(packageId)
    })
    setSelectedPackage(filteredSelectedPackage)
  }, [packageId]) // eslint-disable-line

  useLayoutEffect(() => {
    const jwtSettings = decodedToken()
    
    if (jwtSettings?.adminId && jwtSettings?.adminAsUser) {
      setPurchaseUnvailable(true)
    }
  }, []) // eslint-disable-line

  const handleChangePrev = () => {
    const prevPage = currentPage - 1
    if (prevPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleChangeNext = () => {
    const nextPage = currentPage + 1
    if (nextPage <= dataMyStatement?.statements?.pagination?.pagesAmount || 0) {
      setCurrentPage(nextPage)
    }
  }

  const handleTypeFilter = (event) => setFilterType(event.target.value)
  const handlePeriodFilter = (event) => setFilterPeriod(event.target.value)

  const openModal = useCallback(() => {
    setModalIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    setPackageId('false')
    refetchMyStatements()
    refetchMyWallet()
  }, []) // eslint-disable-line


  if (loadingStatus) return <Loading />

  if (dataStatus?.me?.status !== 'active' && dataStatus?.me?.status !== 'pending_articles_of_association')
    return <AlertsVariants status={dataStatus?.me?.status} />

  return (
    <Flex 
      flexDirection={'column'} 
      width={'100%'} 
      style={{background: '#FFFFFF', gap: '60px', padding: '34px 2.5% 0 2.5%'}}
    >
      <HeaderWrapper>
        <OrderBNZ openModal={openModal} disabled={purchaseUnvailable} />
        <ClientBalance openModal={openModal} loading={loadingWallet} data={formik.values.balance} disabled={purchaseUnvailable} />
      </HeaderWrapper>
      <InnerWrapper>
        <Box className="transactions-inner-wrapper-header-section">
          <Box className="transactions-inner-wrapper-header-title">
            Lançamentos
          </Box>
          <Box className="transactions-inner-wrapper-container-filters">
            <Box>
              <Select id="filterType" name="filterType" value={filterType} onChange={handleTypeFilter}>
                <option disabled>Filtrar por tipo</option>
                {statementTypes.map((row) => {
                  return (
                    <option key={row.name} value={row.type}>
                      {row.name}
                    </option>
                  )
                })}
              </Select>
            </Box>
            <Box>
              <Select id="filterPeriod" name="filterPeriod" value={filterPeriod} onChange={handlePeriodFilter}>
                <option disabled>Filtrar por período</option>
                {period.map((row) => {
                  return (
                    <option key={row.name} value={row.period}>
                      {row.name}
                    </option>
                  )
                })}
              </Select>
            </Box>            
          </Box>
        </Box>
        <CustomTable
          loading={loading}
          data={dataMyStatement?.statements?.data}
          headers={[
            {title: 'Data'},
            {title: 'Lançamento'},
            {title: 'Valor'},
            {title: 'Saldo'}
          ]}
          changePage={(item) => setCurrentPage(item)}
          prevPage={() => handleChangePrev()}
          nextPage={() => handleChangeNext()}
          totalPages={dataMyStatement?.statements?.pagination?.pagesAmount}
          page={currentPage}
        />
      </InnerWrapper>
      <Modal
        open={modalIsOpen}
        onClose={() => {
          closeModal()
        }}
        disableAutoFocus
        sx={{zIndex: '9999'}}
      >
        <PurchaseBNZ
          closeParentModal={() => {
            closeModal()
          }}
        />
      </Modal>
    </Flex>
  )
}

export default Transactions
