import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Button from 'ui-components/lib/components/Button'
import { Flex, Box } from 'reflexbox'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { useMutation } from '@apollo/react-hooks'
import { S3_UPLOADER } from '../graphql/mutations/demands'
import { fileExtension } from '../utils'
import Spinner from 'ui-components/lib/components/Spinner'
import Typography from 'ui-components/lib/components/Typography'
import styled from 'styled-components'
import folderIcon from '../images/folderIcon.svg'
import fileS3Exists from '../services/fileS3Exists'


const DropZoneContainer = styled(Box)`
  color: #545454;
  opacity: ${({isDragActive }) => isDragActive ? .6 : 1 };
`

const SvgWraper = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 15px;
`

export default ({
  required = false,
  onLoad = () => {},
  onComplete = () => {},
  allowedFormatFiles = '.pdf, .png, .jpeg, .jpg, .xls, .xlsx, .doc, .docx, .ppt, .pptx',
  maxFileSize = 15728640,
  maxFiles = 3,
  multiple = true,
}) => {
  const { showMessage } = useAlertStack()

  const [isFilesUploading, setUploadingFiles] = useState(false)

  useEffect(() => {
    onLoad(isFilesUploading)
  }, [onLoad, isFilesUploading])

  const [uploadFile] = useMutation(S3_UPLOADER)

  const onDrop = async (accepted, rejected, event) => {
    setUploadingFiles(true)

    const uploadedFiles = []

    for await (let file of accepted) {
      const { data: { s3uploader: { signedUrl, randomFileName } } = {} } = await uploadFile({
        variables: { fileName: file.name, mimeType: file.type, extension: fileExtension.format(file.name) },
      })

      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      })

      let uploaded = false;
      if (response.ok) {
        const path = new URL(signedUrl)
        uploaded = await fileS3Exists(path.pathname)
      }

      if (uploaded) {
        uploadedFiles.push({
          fileName: file.name,
          randomFileName,
          mimeType: file.type,
          fileExtension: fileExtension.format(file.name),
          fileSize: file.size,
        })
      } else {
        showMessage({
          title: 'Falha de upload do arquivo',
          message: 'Ocorreu um erro durante o upload de um ou mais arquivos, porfavor, tente novamente.',
          color: 'danger',
          position: 'bottom-left',
          time: 4000,
        })
      }
    }

    setUploadingFiles(false)

    onComplete(uploadedFiles)
  }

  // eslint-disable-next-line
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({
    onDrop,
    accept: allowedFormatFiles,
    maxSize: maxFileSize,
    multiple,
    maxFiles,
    onDropRejected() {
      showMessage({
        title: 'Falha ao selecionar um ou mais arquivos',
        message: `O arquivo foi rejeitado porque excede o tamanho máximo, ou foram selecionados mais de ${maxFiles} arquivos.`,
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  return (
    <Flex width={1} flexWrap={'wrap'} alignItems={'flex-start'} justifyContent={'space-between'}>
      <Box width={[1, 4.6 / 10, 4.6 / 10, 4.6 / 10]} style={{paddingLeft: '2%'}}>
        <p style={{margin: 0, fontFamily: 'Roboto', fontSize: '14px', lineHeight: '18px'}}>Você pode arrastar, ou clicar no botão para enviar até {maxFiles} arquivo{maxFiles > 1 && 's'}.</p>
        <p style={{fontFamily: 'Roboto', fontSize: '14px', lineHeight: '18px'}}>Os formatos suportados são {allowedFormatFiles}.</p>
        <p style={{fontFamily: 'Roboto', fontSize: '14px', lineHeight: '18px'}}>O limite do tamanho de cada arquivo não deve exceder {maxFileSize / 1024 / 1024} megabytes.</p>
      </Box>
      <Box width={[1, 4.6 / 10, 4.6 / 10, 4.6 / 10]}>
        <DropZoneContainer aria-required={required} isDragActive={isDragActive && !isFilesUploading} {...getRootProps()}>
          <input {...getInputProps()} />
          <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
            <Button 
              disabled={isFilesUploading}  
              color="primary"
              style={{
                width: '100%',
                minHeight: '36px',
                textTransform: 'uppercase', 
                fontFamily: 'Roboto', 
                fontSize: '14px',
                borderRadius: '2px',
                fontWeight: '500',
                letterSpacing: '0.16px',
                filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))',
              }}  
            >              
              {isFilesUploading ? <Spinner color="primary" /> : 'Selecionar arquivo'}
            </Button>
          </Box>
          <Box mt={3} style={{minHeight: '95px', padding: '15px', border: '1px solid rgba(0, 0, 0, 0.42)', borderRadius: '4px'}}>
            {!isFilesUploading && !acceptedFiles.length && (
              <>
              <Box width={1} style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                <SvgWraper src={folderIcon} alt="folder-icon" /><Typography m={0} style={{ whiteSpace: 'nowrap', overflow: 'hidden', color: 'rgba(0, 0, 0, 0.42)', textOverflow: 'ellipsis', paddingTop: '3px', maxWidth: '165px' }}>arquivo-envio.pdf</Typography>
              </Box>
              </>              
            )}
            {!isFilesUploading && (
              <>
                {acceptedFiles.map((file) => (
                  <Box width={1} style={{display: 'flex', flexDirection: 'row', marginBottom: '5px'}}>
                    <SvgWraper src={folderIcon} alt="folder-icon" /><Typography m={0} style={{ whiteSpace: 'nowrap', overflow: 'hidden', color: 'rgba(0, 0, 0, 0.87)', textOverflow: 'ellipsis', paddingTop: '3px', maxWidth: '165px' }}>{file.name.substring(0, file.name.length - 4)}</Typography>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </DropZoneContainer>
      </Box>  
    </Flex>  
  )
}