import React from 'react'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'
import { Flex } from 'reflexbox'
import { useHistory } from 'react-router-dom'
import './style.css'
import paperAirplane from '../../assets/icons/paperAirplane.svg'
import mailbox from '../../assets/icons/mailbox.svg'
import chevron from '../../assets/icons/chevron.svg'

const ForCompanies = (props) => {
  const history = useHistory()
  const navigationLinks = [{ label: 'Início', onClick: () => history.push('/dashboard') }, { label: 'Para empresas' }]

  return (
    <Flex width={1} justifyContent="center" flexDirection="column" className="service">
      <Flex width={1} mb={3} className="breadCrumb">
        <BreadCrumb ml={-3} navigationLinks={navigationLinks} />
      </Flex>
      <Flex width={1} flexDirection="column">
        <div className='my-services'>
          <h4>Para empresas</h4>
          <div className='items'>
            <div className='item'>
              <img src={mailbox} alt='' />
              <h5>Planos Data Privacy</h5>
              <div className='text'>
                Solução completa para adequar sua empresa a Lei Geral de Proteção de Dados, incluindo pacote de segurança da informação e DPO as a Service.
              </div>
              <button type='button' onClick={() => history.push('/dashboard/para-empresas/data-privacy')}> Ver planos <img src={chevron}  alt='' /></button>
            </div>
            <div className='item'>
              <img src={paperAirplane}  alt='' />
              <h5>Planos B.onuz</h5>
              <div className='text'>
                Nossos planos para você deixar o jurídico conosco e concentrar seus esforços no que importa!
              </div>
              <button type='button' onClick={() => history.push('/dashboard/para-empresas/planos')}> Ver planos <img src={chevron}  alt='' /></button>
            </div>
          </div>
        </div>
      </Flex>
    </Flex>
  )
}

export default ForCompanies
