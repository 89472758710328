import React from 'react'
import styled from 'styled-components'

const WrapperContainer = styled.img`
  margin: 0;
`

const ImgWrapper = props => {
    return (
      <WrapperContainer src={props.src} alt={props.alt} style={props.style}>
        {props.children}
      </WrapperContainer>
    )
}

export default ImgWrapper