import React from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'
import login_bg from '../../images/login_bg.svg'

const WrapperContainerLogin = styled(Flex)`
  width: 100%;
  min-height: 0;
  padding: 32px 0;
  background-color: #F14F5C;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: auto;    
  align-items: flex-start;
  justify-content: center;
  background: url(${login_bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`

const LoginWrapper = props => {
    return (
      <WrapperContainerLogin>
        {props.children}
      </WrapperContainerLogin>
    )
}

export default LoginWrapper