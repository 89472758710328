import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import Button from 'ui-components/lib/components/Button'
import { PlayArrow, ErrorOutline, QuestionAnswer } from '@mui/icons-material'
import { SuportVideoPopup } from '../../components'
import supportVideoBg from '../../images/support_video_bg.png'

const Wrapper = styled(Flex)`
  width: 100%;
  max-height: 311px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
`

const ContainerBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${supportVideoBg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 311px;
  padding: 5%;
  box-sizing: border-box;
`

const BoxLeft = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const BoxRight = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  @media only screen and (max-width: 600px) {
    display: none!important;
  }
`

const TextSmall = styled.span`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #FFFFFF;
`

const TextLarge = styled.h2`
  font-family: Roboto;
  font-size: 48px;
  font-weight: 900;
  color: #FFFFFF;
`

const FakeButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
`

const SupportVideoInfo = props => {
    const [showPopup, setShowPopup] = useState(false);

    return (
      <>
      <Wrapper>
        <ContainerBackground>
          <BoxLeft>
            <Box width={1} display={'flex'} flexDirection={'row'} alignItems={'center'}>
              {props.showPresentationVideo ? (
                <ErrorOutline sx={{fontSize: 40, color: '#FFFFFF', marginRight: '10px'}} />
              ) : (
                <QuestionAnswer sx={{fontSize: 40, color: '#FFFFFF', marginRight: '10px'}} />
              )}  
              <TextLarge style={{margin: 0}}>
                {props.showPresentationVideo ? (
                  'Adquira BNZs'
                ) : (
                  'Saiba mais'
                )} 
              </TextLarge>
            </Box>
            <Box width={1} style={{padding: '2% 0'}}>
              <TextSmall>
                {props.showPresentationVideo ? (
                  'Para habilitar acesso total ao sistema é necessário adquirir BNZs.'
                ) : (
                  'Possui alguma dúvida na utilização do sistema? Assista ao vídeo de apresentação ou entre em contato conosco clicando no botão do Whatsapp.'
                )}                
              </TextSmall>
            </Box>
            <Box width={1} style={{padding: '2% 0'}}>  
              <Button 
                style={{
                  color: '#F14F5C',
                  backgroundColor: '#FFF',
                  borderColor: '#FFF',
                  minWidth: '180px',
                  maxHeight: '36px',
                  textTransform: 'uppercase', 
                  fontFamily: 'Roboto', 
                  fontSize: '14px',
                  borderRadius: '2px',
                  fontWeight: '500',
                  letterSpacing: '0.16px',
                  filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))',
                }}  
                color={'default'}
                onClick={() => setShowPopup(true)}
              >
                {props.showPresentationVideo ? (
                  'Saiba mais'
                ) : (
                  'Assistir'
                )}                 
              </Button>
            </Box>  
          </BoxLeft>
          <BoxRight>
            <FakeButton onClick={() => setShowPopup(true)}>
              <PlayArrow sx={{
                  fontSize: 240, 
                  color: 'rgba(255, 255, 255, 0.4)', 
                  "&:hover": {
                    color: 'rgba(255, 255, 255, 0.8)', 
                    transition: '0.3s',
                  }
                }} 
              />
            </FakeButton>
          </BoxRight>              
        </ContainerBackground>
      </Wrapper>
      <SuportVideoPopup show={showPopup} onCloseModal={() => setShowPopup(false)}/>
      </>
    )
}

export default SupportVideoInfo