import React, { forwardRef } from 'react'
import styled from 'styled-components'

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${(props) => props.marginTop};
`

const RegisterForm = forwardRef((props, ref) => (
  <Form marginTop={props.mt} noValidate onSubmit={props.onSubmit} ref={ref}>
    {props.children}
  </Form>
))

export default RegisterForm
