import React from 'react'
import styled from 'styled-components'

const ContainerFormLogin = styled.form`
    display: flex;
    padding: 30px 56px 0 56px;
    flex-direction: column;

    @media only screen and (max-width: 980px) {
    width: 100%;
    }
`

const LoginFormContainer = props => {
    return (
        <ContainerFormLogin novalidate onSubmit={props.onSubmit}>
            {props.children}
        </ContainerFormLogin>
    )
}

export default LoginFormContainer