import React, { useCallback, useState } from "react";
import { useHistory } from 'react-router-dom';
import okIcon from '../../assets/icons/ok.svg'
import notOkIcon from '../../assets/icons/notok.svg'
import Modal from "ui-components/lib/components/Modal";

const CardInfo = ({info}) => {
  const {
    title,
    colors,
    optionsTitle,
    options=[],
    monthlyValue,
    services
  } = info;

  const history = useHistory()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const stylesCard = {
    background: colors.background,
    border: colors.border ? `1px solid ${colors.border}` : '',
    borderRadius: '16px'
  }

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
  }, [])

  const openModal = useCallback(() => {
    setModalIsOpen(true)
  }, [])

  return (
    <div className='plan' style={stylesCard}>
      <header>
        <h3 style={{color: colors.title, fontSize: '30px', paddingLeft: '30px'}}>{title}</h3>
        <div className="row-price">
          <span className="price">R$ {monthlyValue}</span>
          <span className="month">/mês</span>
        </div>
        <div style={{marginTop: '30px', borderBottom: '1px solid #F14F5C', paddingBottom: '20px'}}>
          <button
            type="button"
            className="add"
            style={ colors.button === '#041181' ? {color: '#fff', background: colors.button} : {color: '#FFFFFF', background: colors.button}}
            onClick={() => history.push('/dashboard/para-empresas/planos/pagamento')}
          >
            Adquirir Plano
          </button>
          <button
            className="services"
            type="button"
            onClick={openModal}
          >
            Tabela de serviços
          </button>
        </div>
      </header>
      <div className="content">
        <div className="title">
          {optionsTitle}
        </div>
        {options.map(item => (
          <p className="option-value"><img src={item.icon === 'ok' ? okIcon : notOkIcon} alt='' /> {item.title}</p>
        ))}
      </div>
      <Modal
        id="modalServices"
        open={modalIsOpen}
        onClose={() => {
          closeModal()
        }}
        boxProps={{style: {backgroundColor: services.backgroundColor}}}
        contentModalProps={{ width: '35vw' }}
      >
        <h3 style={{fontFamily: 'Inter', fontSize: '24px', fontWeight: '700', color: services.titleColor, marginBottom: '10px'}}>{services.title}</h3>
        {services.subTitle && (
          <p style={{color: services.subTitleColor, fontFamily: 'Inter', fontSize: '18px', fontWeight: '400'}}>{services.subTitle}</p>
        )}  
        <div class="tags">
          {services.tags.map(item => (
            <span className={`tag-${item.class}`}>{item.title}</span>
          ))}
        </div>
        <table style={{border: `1px solid ${services?.borderColor}`, width: '100%', borderSpacing: '0', borderCollapse: 'separate', borderRadius: '8px'}}>
          <thead>
            <tr>
              {services?.columns?.map((item, index) => (
                <th style={{borderTopLeftRadius: index === 0 ? '8px' : 'none', borderTopRightRadius: index === services?.columns?.length - 1 ? '8px' : 'none' ,padding: '5px', textAlign: 'center', color: item.color, backgroundColor: item.backgroundColor, border: `1px solid ${services.borderColor}`}}>{item.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {services?.data?.map(item => (
              <tr style={{backgroundColor: item.backgroundColor}}>
                <td style={{padding: '5px',textAlign: 'center', color: item.fontColor, fontSize: '16px', border: `1px solid ${services.borderColor}`}}>{item.title}</td>
                {item.price && (
                  <td style={{padding: '5px',textAlign: 'center', color: item.fontColor, fontSize: '16px', border: `1px solid ${services.borderColor}`}}>{item.price}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{color: '#9997A0', fontSize: '16px', fontWeight: '700', marginTop: '20px'}}>
          Não achou o serviço? <a className="link-services" href="https://api.whatsapp.com/send?phone=5548991548687&text=Oi quero conhecer todos os serviços">Venha orçar conosco</a>
        </div>
      </Modal>
    </div>
  )
}

export default CardInfo;
