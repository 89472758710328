const { REACT_APP_CLIENT_BASE_URL } = process.env
class Client {
  constructor(data = {}) {
    this.id = data._id || null   
    this.firstName = data.firstName || ''
    this.lastName = data.lastName || ''
    this.fullName = data.fullName || ''
    this.personalMobileNumber = data.personalMobileNumber || ''
    this.password = data.password || ''
    this.passwordConfirmation = data.passwordConfirmation || ''
    this.email = data.email || ''
    this.agreedTerms = data.agreedTerms || false
    this.agreedPolicy = data.agreedPolicy || false            
    this.clientURL = REACT_APP_CLIENT_BASE_URL
    this.partnership = data.partnership || null
    this.product = data.product || null
  }
}

export default Client