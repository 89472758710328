import React from 'react'
import styled from 'styled-components'
import { Box } from 'reflexbox'
import owlFull from '../../images/owl_full.svg'


const Wrapper = styled(Box)`
  width: 50%;
  height: 100%;
  min-height: 0;
  font-family: 'Roboto';
  @media only screen and (max-width: 1100px) {
    display: none!important;
  }
`

const SystemIntroductionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: url(${owlFull});
  background-repeat: no-repeat;
  background-position: right bottom;
  margin-right: calc(50vw - 205px);
  background-size: contain;
  width: 61vw;
  height: 100vh;
  box-sizing: border-box;
`

const LoginBoxTitle = styled.h2`
  max-width: 36%;
  max-height: 27%;
  margin-left: 1.4em;
  margin-top: 1.45em;
  font-style: normal;
  font-weight: 900;
  font-size: 5vw;
  line-height: 4.3vw;
`

const SystemIntroduction = props => {
  var bgColor
  var textColor

  switch (props.theme) {
    case 'light':
      bgColor = '#FFF5E5'
      textColor = '#F14F5C'
      break
    case 'dark':
      bgColor = '#000000'
      textColor = '#FFFFFF'
      break            
    default:
      bgColor = '#FFF5E5'
      textColor = '#F14F5C'
      break        
  }

  return (
    <Wrapper width={props.width} backgroundColor={bgColor}>
      <SystemIntroductionBox>
        <LoginBoxTitle style={{color: textColor}}>
          Simplificando sua vida jurídica
        </LoginBoxTitle>
      </SystemIntroductionBox>  
    </Wrapper>
  )
}

export default SystemIntroduction