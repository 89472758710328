import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { demandsQueries } from "../graphql/queries";
import './UseCountProposals.css'

const UseCountProposals = (props) => {
  const [countProposals, setCountProposals] = useState(0);
  const { data: demandsData } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      search: '',
      sort: "code",
      page: 1,
      order: "desc",
      offset: 999,
      queryBy: 'status'
    }
  });

  useEffect(() => {
    const countProposalsLogic = () => {
      if (demandsData?.listDemands?.data) {
        let numberOfProposals = 0;
        demandsData.listDemands.data.forEach((demand) => {
          if (
            (demand.status !== 'in_estimating' || demand.status !== 'waiting_estimate_approval' || demand.status !== 'in_auction') &&
            demand.isAuction
          ) {
            numberOfProposals++;
          }
        });
        setCountProposals(numberOfProposals);
      }
    };

    countProposalsLogic();
  }, [demandsData]);

  return (
    <div>
      {countProposals > 0 ? (
        <span className="countProposals">{countProposals}</span>
      ) : ''}
    </div>
  );
};

export default UseCountProposals;
