import React from 'react'
import PropTypes from 'prop-types'
import { faExclamationTriangle, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import Alert from 'ui-components/lib/components/Alert'

const AlertsVariants = ({ status }) => {
  switch (status) {
    case 'pending_articles_of_association':
      return (
        <Alert
          color="warning"
          icon={faExclamationTriangle}
          title="Ainda não recebemos seu contrato/estatuto social"
          message='Acesse "Minha conta" para envia-lo.'
          m={10}
        />
      )

    case 'pending_package':
      return (
        <Alert
          color="info"
          icon={faHourglassHalf}
          title="Você ainda não foi associado a um plano"
          message="Nossa equipe está analisando seu perfil, em breve você receberá uma notificação com mais informações."
          m={10}
        />
      )
    case 'pending_password':
      return <Alert color="info" icon={faExclamationTriangle} title="Senha pendente" message="Contate o administrador." m={10} />
    default:
      return <></>
  }
}

AlertsVariants.propTypes = {
  status: PropTypes.string.isRequired,
}

export default AlertsVariants
