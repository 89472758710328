import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import Spinner from 'ui-components/lib/components/Spinner'
import queryString from 'query-string'
import { NotFound } from '..'
import login_aside_owl from '../../images/login_aside_owl.svg'
import InputMask from 'react-input-mask'
import CustomTextField from '../../components/CustomTextField'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import Button from '@mui/material/Button'
import CustomSpinner from '../../components/CustomSpinner'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import client from '../../graphql/apolloClient'


import {
  LoginLogo,
  LoginWrapper,
  LoginContainer,
  LoginFormContainer,
  TextTitleForm, 
  TextDescriptionForm,
  FieldLabelForm,
  LoginContainerAside,
  LoginContentAside
} from '../../components'
import { useMutation } from 'react-apollo'
import { walletsMutations } from '../../graphql/mutations'
import { walletQueries } from '../../graphql/queries'
import styled from 'styled-components'
import alertify from "alertifyjs"
import "alertifyjs/build/css/alertify.css"
import "alertifyjs/build/css/themes/default.css"

alertify.set("notifier", "position", "top-right")

const BgButton = styled(Button)({
  '&.MuiButton-root': {
    width: '100%', 
    marginTop: '8px',
    padding: '10px 0',    
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '8px',
    color: '#FFFFFF',
    backgroundColor: '#F14F5C',
    border: 'none',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: "'Inter', Helvetica, sans-serif",
  },
  '&.MuiButton-root:hover': {
    backgroundColor: '#F1747E',
  },
  '&.MuiButton-root:active': {
    backgroundColor: '#F1747E',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    backgroundColor: '#E9E9E9',
    color: '#D3D2D7',
  },
});

const { REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID, REACT_APP_PAYMENT_GATEWAY_SANDBOX } = process.env

const CreditCardRegistrationRequest = (props) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [token, setToken] = useState(null)
  const [cardBrandMask, setCardBrandMask] = useState('9999 9999 9999 9999')
  const [expirationMask, setExpirationMask] = useState('99/99')
  const [errors, setErrors] = useState([])
  const [loadingIugu, setLoadingIugu] = useState(false)
  const { Iugu } = window

  useEffect(() => {
    const setParam = () => {
      const queryParams = queryString.parse(props.location.search);
      const token = queryParams.token || null;
      setError(!token)
      setToken(token)
    };

    setParam();
  }, [props.location.search]);

  useEffect(() => {
    if(Iugu){
      const isSandbox = REACT_APP_PAYMENT_GATEWAY_SANDBOX === 'true' ? true : false
      Iugu.setAccountID(REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID)
      Iugu.setTestMode(isSandbox)
    }
  }, [Iugu]) // eslint-disable
  
  const updateCardBrandMask = useCallback((event) => {
    let brand
    const cardNumber = document.getElementById('number')

    if (cardNumber && Iugu) {
      brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber.value.replace(/\D/g, ''))
    }

    if(event.key === "Backspace"){
      setCardBrandMask("9999999999999999")
    } else {
      if (brand === 'amex') {
        setCardBrandMask('9999 9999999 9999')
      } else if (brand === 'diners') {
        setCardBrandMask('9999 999999 9999')
      } else {
        setCardBrandMask('9999 9999 9999 9999')
      }
    }
  }, []) //eslint-disable-line

  const updateExpirationMask = useCallback((event) => {
    if(event.key === "Backspace"){
      setExpirationMask("9999")
    } else {
      setExpirationMask('99/99')
    }
  }, [])

  const [saveCard, { loading: loadingSaveCard }] = useMutation(walletsMutations.CREDIT_CARD_REGISTRATION_REQUEST, {
    onCompleted() {
      alertify.alert('Sucesso', 'Cadastro efetuado com sucesso!', function(){ history.push('/') });
    },
    update: graphqlHelpers.deleteItemsFromCache('Card'),
    onError(error) {
      alert('Erro desconhecido. Verifique as informações.')
    },
  })

  const handleChangeCard = (e) => {
    e.preventDefault()
    const element = document.getElementById('newPaymentCard')
    setErrors([])
    setLoadingIugu(true)
    Iugu.createPaymentToken(element, (response) => {
      setLoadingIugu(false)
      if (response.errors) {
        setErrors(response.errors )
        toast.error('Por favor, verifique as informações.', {
          theme: 'dark'
        })
      } else {
        const { id: cardToken, extra_info } = response
        const { brand, display_number: displayNumber, holder_name: holderName, month, year } = extra_info

        saveCard({ variables: { token: cardToken, isDefault: true, brand, displayNumber, holderName, month, year, token_: token } })
      }
    })
  }

  useEffect(() => {
    if (token) {
      const getExists = async () => {
        try {
          await client.query({
            query: walletQueries.GET_CREDIT_CARD_REGISTRATION,
            variables: { token_: token }
          });
          setError(false)
        } catch (error) {
          setError(true)
        } finally {
          setLoading(false)
        }
      }
      getExists()
    }
  }, [token])

  return (
    <>
      {!error && loading && (
        <Flex justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
          <Box>
            <Spinner color="primary" />
          </Box>
        </Flex>
      )}
      {!error && !loading && (
        <LoginWrapper>
          <LoginContainer>
            <LoginFormContainer onSubmit={handleChangeCard}>
              <LoginLogo />
              <TextTitleForm  style={{textAlign: 'center'}}>Bem-vindo a Bonuz !</TextTitleForm>
              <TextDescriptionForm textType={'description'}  style={{textAlign: 'center'}}>Preencha os campos abaixo </TextDescriptionForm>
              <form id="newPaymentCard">
                <Box width={[1]} mt={32}>
                  <FieldLabelForm>Número do Cartão</FieldLabelForm>
                  <InputMask mask={cardBrandMask} onChange={updateCardBrandMask} onKeyDown={updateCardBrandMask} maskChar={null}>
                    {(inputCardProps) => (
                      <CustomTextField
                        id="number"
                        name="number"
                        placeholder="**** **** **** ****"
                        inputProps={{ 'data-iugu': 'number' }}
                        {...inputCardProps}
                        helperText={(errors['number'] || false) ? 'Informe um número válido' : ''}
                        error={errors['number'] || false}
                      />
                    )}
                  </InputMask>           
                </Box>
                <Box width={[1]} mt={32}>
                  <FieldLabelForm>Nome do Cartão</FieldLabelForm>
                  <CustomTextField
                    id="cardName"
                    name='cardName'
                    placeholder='Nome do cartão'
                    inputProps={{ 'data-iugu': 'full_name' }}
                    error={(errors['first_name'] || errors['last_name']) || false}
                    helperText={((errors['first_name'] || errors['last_name']) || false) ? 'Informe um nome válido' : ''}
                  />           
                </Box>
                <Box mt={32} className="purchase-bonuz-plans-add-card-fields-container">
                  <Box className="purchase-bonuz-plans-add-card-field">
                    <FieldLabelForm>Vencimento</FieldLabelForm>
                    <InputMask mask={expirationMask} maskChar={null} onChange={updateExpirationMask} onKeyDown={updateExpirationMask}>
                      {(inputCardProps) => (
                        <CustomTextField 
                          id="cardExpire"
                          name='cardExpire'
                          placeholder='xx/xx'
                          inputProps={{ 'data-iugu': 'expiration' }}
                          {...inputCardProps}
                          helperText={(errors['expiration'] || false) ? 'Informe um vencimento válido' : ''}
                          error={errors['expiration'] || false}
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box className="purchase-bonuz-plans-add-card-field">
                    <FieldLabelForm>Cód. segurança</FieldLabelForm>
                    <InputMask mask="999?" formatChars={{ "9": "[0-9]", "?": "[0-9 ]" }} maskChar={null} >
                      {(inputProps) => (
                        <CustomTextField 
                          id='safe_number' 
                          name='safe_number'
                          inputProps={{ 'data-iugu': 'verification_value' }} 
                          placeholder="***"
                          helperText={(errors['verification_value'] || false) ? 'Informe um código válido' : ''}
                          error={errors['verification_value'] || false}
                        />
                      )}
                    </InputMask>
                  </Box>
                </Box>
                <Box width={[1]} mt={32} style={{textAlign: 'center'}}>
                  <BgButton      
                    disabled={loadingSaveCard} 
                    type="submit"
                  >
                    {loadingSaveCard || loadingIugu ? <CustomSpinner color="#FFFFFF" /> : 'Continuar'}
                  </BgButton>
                </Box>
              </form>
            </LoginFormContainer>
            <LoginContainerAside style={{padding: '159px 75px 114px 123px'}}>
              <LoginContentAside src={login_aside_owl} alt={'coruja-bonuz'} style={{width: '100%', height: '100%'}}/>
            </LoginContainerAside>  
          </LoginContainer>
        </LoginWrapper>
      )}
      {error && (
        <Flex justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
          <Box>
            <NotFound />
          </Box>
        </Flex>
      )}
    </>
  )
}

export default CreditCardRegistrationRequest
