class Demand {
  constructor(data = {}) {
    this.serviceId = data.serviceId || null
    this.subserviceId = data.subserviceId || null
    this.urgent = data.urgent || null
    this.bicolumn = data.bicolumn || null
    this.extraLanguage = data.extraLanguage || null
    this.answers = data.answers || []
    this.draft = data.draft || null
  }
}
export default Demand
