import React, { useState } from "react"

const SubtabsDropdown = ({children, className}) => {

  const [openDropdown, setOpenDropdown] = useState(false)

  return (
    <div className={openDropdown ? `${className} dropdown open` : `${className} dropdown`} onClick={() => setOpenDropdown(!openDropdown)}>
      {children}
    </div>
  )
}

export default SubtabsDropdown
