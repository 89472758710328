class Person {
    constructor(data = {}) {
      this.id = data._id || null
      this.document = data.document || null
      this.cnpj = data.cnpj || null
      this.companyName = data.companyName || null
      this.tradeName = data.tradeName || null
      this.financialEmail = data.financialEmail || null
    }
  }
  
  export default Person