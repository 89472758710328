import React, { useState } from 'react'
import { Card } from 'react-bootstrap'
import { useQuery } from '@apollo/react-hooks'
import { demandsQueries } from '../../../graphql/queries'
import ExternalLink from '../../../assets/icons/external-link.svg'
import './AcitivitiesStatus.css'

const ActivitiesStatus = () => {

  const { data, loading: loadingScheduledActivities } = useQuery(demandsQueries.GET_SCHEDULED_ACTIVITIES, { //eslint-disable-line
    onCompleted(data){
      countScheduledActivities(data.getScheduledActivities)
    }
  })

  const [doneActivitiesLength, setDoneActivitiesLength] = useState({count:0, percentage:0});
  const [inProgressActivitiesLength, setInProgressActivitiesLength] = useState({count:0, percentage:0});
  const [pendingActivitiesLength, setPendingActivitiesLength] = useState({count:0, percentage:0});

  const [scheduledActivities, setScheduledActivities] = useState(false);

  const countScheduledActivities = (data) => {
    let doneActivities = 0
    let inProgressActivities = 0
    let pendingActivities = 0
    let totalActivities = 0
    let ongoingActivities = []

    data.map((item) => {
      switch (item.status){
        case 'done':
          doneActivities += 1
          break;
        case 'in_progress':
          inProgressActivities += 1
          break;
        case 'pending':
          pendingActivities += 1
          break;
        default:
          return true;
      }
      totalActivities += 1

      ongoingActivities.push({
        _id: item._id,
        clientId: item.clientId,
        lawyerId: item.lawyerId,
        schedule_activity_id: item.schedule_activity_id,
        schedule_date: item.schedule_date,
        schedule_name: item.schedule_name,
        status: item.status
      })

      return true;
    })

    setDoneActivitiesLength({count: doneActivities, percentage: percentageScheduledActivities(doneActivities, totalActivities)});
    setInProgressActivitiesLength({count: inProgressActivities, percentage: percentageScheduledActivities(inProgressActivities, totalActivities)});
    setPendingActivitiesLength({count: pendingActivities, percentage: percentageScheduledActivities(pendingActivities, totalActivities)});
    setScheduledActivities(ongoingActivities);
  }

  const percentageScheduledActivities = (count, total) => {
    return Math.round((count / total) * 100)
  }


  return (
    <Card className="big-balloon">
      <div>
          <h4>Implementação</h4>
          <p><small>Acompanhe o andamento das atividades.</small></p>
      </div>      
      <div>
        <div class="progress mb-2" style={{height: '8px'}}>
          <div class="progress-bar" role="progressbar" style={{width:`${doneActivitiesLength.percentage}%`, background:'#44E47A'}} aria-valuenow={doneActivitiesLength.percentage} aria-valuemin="0" aria-valuemax="100"></div>
          <div class="progress-bar" role="progressbar" style={{width:`${inProgressActivitiesLength.percentage}%`, background:'#7683F6'}} aria-valuenow={inProgressActivitiesLength.percentage} aria-valuemin="0" aria-valuemax="100"></div>
          <div class="progress-bar" role="progressbar" style={{width:`${pendingActivitiesLength.percentage}%`, background:'#FBC229'}} aria-valuenow={pendingActivitiesLength.percentage} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div id='progress-multi-color-status-hint'>
          <div className='progress-hint done'></div><span>Concluído ({doneActivitiesLength.count})</span>
          <div className='progress-hint in_progress'></div><span>Em progresso ({inProgressActivitiesLength.count})</span>
          <div className='progress-hint review'></div><span>Pendente ({pendingActivitiesLength.count})</span>
        </div>
        <div className='demands-list'>
          <ul>
          {!loadingScheduledActivities && scheduledActivities.length > 0 && scheduledActivities.map((activities,key) => {
            return (
              <li key={key} className='list-item-demands-dp'>
                <div className={`progress-hint ${activities.status}`}></div><span>{activities.schedule_name}</span>
                <div className='list-item-demands-dp-img'>
                  <img src={ExternalLink} alt='External link icon ' />
                </div>
              </li>
            )
          })}
          </ul>
        </div>
      </div>
    </Card>
  );
}

export default ActivitiesStatus;
