import React, { useState } from 'react'
import { Card, Col } from 'react-bootstrap'
import MessageIcon from '../../../images/icons/messageIcon.svg'
import ChatDemand from '../../ChatDemand'

const Chat = () => {
  const [openChatModal, setOpenChatModal] = useState(false);

  return (
    <>
      <Col sm='3'>
        <Card className="small-balloon">
        <div className="d-flex justify-content-between">
          <div>
            <h4>Chat</h4>
          </div>
          <div><img src={MessageIcon} alt=""/></div>
        </div>
        <p>Fale com o advogado</p>
        <button onClick={()=>setOpenChatModal(true)}>Enviar</button>
      </Card>
      </Col>
      <ChatDemand
        handleClose={()=>setOpenChatModal(false)}
        openModal={openChatModal}
        subscription={'data-privacy'}
      />
    </>
  );
}

export default Chat;
