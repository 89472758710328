import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Modal } from "@mui/material";
import { cards } from './script';

const BonuzPlansModal = (props) => {
  const { handleClose, openModal, whatsAppNumber } = props;

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      disableAutoFocus
    >
      <Box className="box bonuz-plans-modal">
          <h2>Serviços disponíveis</h2>
          <p>Você pode poderá solicitar serviços nas respectivas áreas, caso queira um serviço não listado não se preocupe!Garantimos 10% de desconto na compra de BNZs para você!</p>
        <div className='cards'>
            {cards.map(({title, options, icon}) => (
              <div className='card' style={{padding:'0 10px'}}>
                  <img src={icon} alt=""/>
                  <h3>{title}</h3>
                  {options.map(option => (
                    <Link to={option.link}>
                      <p className='link'>{option.title}</p>
                    </Link>
                  ))}
              </div>
            ))}
        </div>
        <p className='mt-2 mb-0'>Não encontrou o serviço desejado?</p>
        <Link             
            to={{ pathname: `https://api.whatsapp.com/send?phone=${whatsAppNumber?.replace(/\D+/g, '')}&text=Oi%2C%20tudo%20bem%3F` }}
            target="_blank"
        >
          Fale conosco
        </Link>
      </Box>
    </Modal>
  )
}

export default BonuzPlansModal;
