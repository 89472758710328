import React, { useState, useCallback, useContext, useEffect, useLayoutEffect } from 'react'
import { Flex } from 'reflexbox'
import DashboardContext from '../../contexts/DashboardContext'
import Modal from '@mui/material/Modal'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { useQuery } from '@apollo/react-hooks'
import { walletQueries, clientsQueries } from '../../graphql/queries'
import { Wallet } from '../../models'
import styled from 'styled-components'
import {decodedToken} from "../../services/auth";
import { 
  Loading, 
  AlertsVariants, 
  PurchaseBNZ, 
  ClientBalance, 
  OrderBNZ, 
  ClientCards, 
  ClientPurchase, 
  PackagesBNZ 
} from '../../components'

const HeaderWrapper = styled(Flex)`
  width: 100%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
`

const InnerWrapper = styled(Flex)`
  width: 100%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 25px;
`

const LeftContainer = styled(Flex)`
  width: 58%;
  min-height: 0;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 60px;
`

const MyWallet = (props) => {
  const history = useHistory()
  const [packageId, setPackageId] = useContext(DashboardContext);
  const [selectedPackage, setSelectedPackage] = useState([{}]) //eslint-disable-line
  const [currentPage] = useState(1)
  const [filterType] = useState('')
  const [filterPeriod] = useState('')
  const [sort] = useState('createdAt')
  const [order] = useState('desc')
  const [purchaseUnvailable, setPurchaseUnvailable] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [search] = useState('compra')
  const [offset] = useState(9999)

  const { data: dataWalletSettings } = useQuery(walletQueries.GET_WALLET_SETTINGS);

  const { data: dataMyWallet, loading: loadingWallet, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)
  const { data: dataStatus, loading: loadingStatus } = useQuery(clientsQueries.GET_STATUS)
  const { data: dataMyStatement, loading, refetch: refetchMyStatements } = useQuery(walletQueries.GET_MY_STATEMENTS, {
    variables: { page: currentPage, search, filterType, filterPeriod, sort, order, offset },
  })

  const { data: dataMyInfos, loading: loadingMyInfos, refetch: refetchMyInfos } = useQuery(clientsQueries.GET_MY_INFOS)

  const formik = useFormik({
    initialValues: new Wallet(),
  })

  useEffect(() => {
    let myWallet = dataMyWallet?.wallet
    if (myWallet) {
      formik.setValues(myWallet)
    }
  }, [dataMyWallet]) //eslint-disable-line

  useEffect(() => {
    if (dataStatus && dataStatus?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [dataStatus]) // eslint-disable-line

  useLayoutEffect(() => {
    const filteredSelectedPackage = dataWalletSettings?.walletSettings?.bnzPackages.filter((item, partnerIndex, array) => {
      return array.indexOf(item) === Number(packageId)
    })
    setSelectedPackage(filteredSelectedPackage)
  }, [packageId]) // eslint-disable-line

  useLayoutEffect(() => {
    const jwtSettings = decodedToken()
    
    if (jwtSettings?.adminId && jwtSettings?.adminAsUser) {
      setPurchaseUnvailable(true)
    }
  }, []) // eslint-disable-line

  const openModal = useCallback(() => {
    setModalIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    setPackageId('false')
    refetchMyStatements()
    refetchMyWallet()
    refetchMyInfos()
  }, []) // eslint-disable-line


  if (loadingStatus) return <Loading />

  if (dataStatus?.me?.status !== 'active' && dataStatus?.me?.status !== 'pending_articles_of_association')
    return <AlertsVariants status={dataStatus?.me?.status} />

  return (
    <Flex 
      flexDirection={'column'} 
      width={'100%'} 
      style={{background: '#FFFFFF', gap: '60px', padding: '34px 2.5% 0 2.5%'}}
    >
      <HeaderWrapper>
        <OrderBNZ openModal={openModal} disabled={purchaseUnvailable} />
        <ClientBalance openModal={openModal} loading={loadingWallet} data={formik.values.balance} disabled={purchaseUnvailable} />
      </HeaderWrapper>
      <InnerWrapper>
        <LeftContainer>
          <PackagesBNZ openModal={openModal} />
          <ClientCards openModal={openModal} loading={loadingMyInfos} data={dataMyInfos?.me?.clientCards} />
        </LeftContainer>
        <ClientPurchase data={dataMyStatement?.statements?.data} loading={loading} /> 
      </InnerWrapper>
      <Modal
        open={modalIsOpen}
        onClose={() => {
          closeModal()
        }}
        disableAutoFocus
        sx={{zIndex: '9999'}}
      >
        <PurchaseBNZ
          closeParentModal={() => {
            closeModal()
          }}
        />
      </Modal>
    </Flex>
  )
}

export default MyWallet
