import React, { useEffect, useState, createRef } from 'react';
import { Flex, Box } from 'reflexbox'
import Modal from '@mui/material/Modal';
import "./styles.css";
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { clientsMutations } from '../../../graphql/mutations'
import { clientsQueries } from '../../../graphql/queries'
import { AccountUser } from '../../../models'
import { AccountUserSchema } from '../../../validate'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import InputMask from 'react-input-mask'
import { useFormik } from 'formik'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import Button from 'ui-components/lib/components/Button'
import Spinner from 'ui-components/lib/components/Spinner'
import TextField from 'ui-components/lib/components/TextField'
import Select from 'ui-components/lib/components/Select'
import LittleInfoBalloon from '../../LittleInfoBallon'
// import Coruja from "../../../assets/img/owl/seeingSomeFiles.svg"

const AccountUserModal = (props) => {
    const {
        handleClose,
        openModal,
        userType,
        userId
    } = props;

  const { showMessage } = useAlertStack()

  const [cpfMask, setCpfMask] = useState("999.999.999-99")
  const [accountTypes, setAccountTypes] = useState('')

  const { data: dataAccountTypes } = useQuery(clientsQueries.GET_ACCOUNT_TYPES)

  const [getAccountUser, { data: dataAccountUser }] = useLazyQuery(clientsQueries.GET_ACCOUNT_USER)

  const [createAccountUser, { loading: loadingCreateAccountUser }] = useMutation(clientsMutations.CREATE_ACCOUNT_USER, {
    onCompleted() {
      showMessage({
        title: 'Cadastrado realizado com sucesso',
        message: 'Seu cadastro foi atualizado.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      handleClose()
    },
    update: graphqlHelpers.deleteItemsFromCache('Client'),
    onError(error) {
      const customError = ((((((error.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).message || {}).errors

      showMessage({
        title: 'Cadastro não atualizado',
        message: customError || 'Ocorreu algum erro desconhecido',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
      handleClose()
    },
  })

  const [updateAccountUser, { loading: loadingUpdateAccountUser }] = useMutation(clientsMutations.UPDATE_ACCOUNT_USER, {
    onCompleted() {
      showMessage({
        title: 'Cadastrado atualizado com sucesso',
        message: 'Seu cadastro foi atualizado.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      handleClose()
    },
    update: graphqlHelpers.deleteItemsFromCache('Client'),
    onError(error) {
      const customError = ((((((error.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).message || {}).errors

      showMessage({
        title: 'Cadastro não atualizado',
        message: customError || 'Ocorreu algum erro desconhecido',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
      handleClose()
    },
  })

  const formRefAccountUser = createRef()

  const formikAccountUser = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: new AccountUser(),
    validationSchema: AccountUserSchema,
    onSubmit: (values) => {
      if(userId){
        updateAccountUser({ 
          variables: values 
        })
      } else {
        createAccountUser({ 
          variables: values 
        })
      }
    },
  })

  const handleChangeCpfMask = (event) => {
    if(event.key === "Backspace"){
      setCpfMask("99999999999")
    } else {
      setCpfMask("999.999.999-99")
    }
  }

  const handleSubmitAccountUser = (event) => {
    event.preventDefault()

    // eslint-disable-next-line no-unused-expressions
    formRefAccountUser.current?.reportValidity?.() // manually trigger html validation through ref

    formikAccountUser.handleSubmit()
  }

  useEffect(() => {
    if (userId){
      getAccountUser({ variables: { id: userId } })
    }
  }, [userId]) //eslint-disable-line

  useEffect(() => {
    if (dataAccountTypes){
      setAccountTypes(dataAccountTypes.getAccountTypes.filter(reg => reg.type === 'Administrador' || reg.type === 'Secundário'))
    }
  }, [dataAccountTypes]) //eslint-disable-line

  useEffect(() => {
    if (dataAccountUser && dataAccountTypes){
      const filteredSelectedPackage = dataAccountTypes.getAccountTypes.filter((typeItem, typeIndex, array) => {
        return typeItem._id === dataAccountUser.getAccountUser.accountTypeId
      })

      formikAccountUser.setValues({
        ...formikAccountUser.values,
        id: dataAccountUser.getAccountUser._id,
        name: dataAccountUser.getAccountUser.name,
        email: dataAccountUser.getAccountUser.email,
        document: dataAccountUser.getAccountUser.document,
        accountType: filteredSelectedPackage[0].type,
        accountTypeId: dataAccountUser.getAccountUser.accountTypeId,
        product: dataAccountUser.getAccountUser.product,
        relatedness: dataAccountUser.getAccountUser.relatedness
      })
    }

  }, [dataAccountUser, dataAccountTypes]) //eslint-disable-line


  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      style={{zIndex:'999'}}
      disableAutoFocus
    >
      <Box className="account-user-modal-wrapper">
        <h2>{userId ? 'Editar' : 'Adicionar'} {userType === 'Dependentes' ? 'dependente' : 'usuário'}</h2>
        <form noValidate onSubmit={handleSubmitAccountUser} ref={formRefAccountUser} style={{width:'100%', height:'100%'}}>
          <Box style={{width:'100%'}}>
            <TextField
              id="name"
              name={'name'}
              label={'Nome'}
              placeholder={'Nome'}
              value={formikAccountUser.values.name}
              onChange={formikAccountUser.handleChange}
              error={formikAccountUser.errors.name}
              mt={3}
              onBlur={formikAccountUser.handleBlur}
            />
          </Box>
          <Box style={{width:'100%'}}>
            <InputMask
              id="document"
              name={'document'}
              label={'CPF'}
              placeholder={'123.456.789-10'}
              mask={cpfMask} 
              maskChar={null} 
              value={formikAccountUser.values.document} 
              onChange={formikAccountUser.handleChange} 
              onKeyDown={handleChangeCpfMask} 
              onBlur={formikAccountUser.handleBlur}                  
              error={formikAccountUser.errors.document ? true : false}
              helperText={formikAccountUser.errors.document}
              mt={3}
              >
              {(inputFormProps) => (
                <TextField 
                  {...inputFormProps}
                />
              )}
            </InputMask>
          </Box>
          <Box style={{width:'100%'}}>
            <TextField
              id="email"
              name={'email'}
              label={'Email'}
              placeholder={'email@email.com'}
              value={formikAccountUser.values.email}
              onChange={formikAccountUser.handleChange}
              error={formikAccountUser.errors.email}
              mt={3}
              onBlur={formikAccountUser.handleBlur}
            />
          </Box>
          <Box style={{width:'100%'}}>
            <TextField       
              id={'password'}
              name={'password'}
              label={'Nova Senha'}
              placeholder={'******'}
              value={formikAccountUser.values.password}
              onChange={formikAccountUser.handleChange}
              error={formikAccountUser.errors.password}
              type={'password'}
              mt={3}
              onBlur={formikAccountUser.handleBlur}
            />
          </Box>
          <Box style={{width:'100%'}}>
            <TextField
              id={'passwordConfirmation'}
              name={'passwordConfirmation'}
              label={'Confirmar nova senha'}
              placeholder={'******'}
              value={formikAccountUser.values.passwordConfirmation}
              onChange={formikAccountUser.handleChange}
              error={formikAccountUser.errors.passwordConfirmation}
              type={'password'}
              mt={3}
              onBlur={formikAccountUser.handleBlur}
            />
          </Box>
          <Box style={{width:'100%', marginTop:'20px'}}>
            <p className="select-label">Acesso</p>
            <Select
              id={'product'}
              name={'product'}
              value={formikAccountUser.values.product} 
              onChange={formikAccountUser.handleChange} 
              style={{lineHeight:'unset', marginTop:'5px'}}
            >
              <option key={""} value={""} disabled>Selecionar</option>
              <option key="data-privacy" value="data-privacy">Data Privacy</option>
              <option key="bonuz-plans" value="bonuz-plans">Planos B.onuz</option>                  
              <option key="contractual-management" value="contractual-management">Gestão Contratual</option>
              <option key="personal-legal" value="personal-legal">Jurídico Familiar</option>
            </Select>
          </Box>
          {userType === 'Dependentes' ? (
            <Box style={{width:'100%'}}>
              <TextField
                id="relatedness"
                name={'relatedness'}
                label={'Parentesco'}
                placeholder={'Mãe/Pai - Filho/Filha ...'}
                value={formikAccountUser.values.relatedness}
                onChange={formikAccountUser.handleChange}
                error={formikAccountUser.errors.relatedness}
                mt={3}
                onBlur={formikAccountUser.handleBlur}
              />
            </Box>
          ) : ('')}
          <Box style={{width:'100%', marginTop:'20px'}}>
            <span>Tipo de Perfil</span>
            {(accountTypes || []).map((item, index) => (
              <div key={index}>
              {userType === 'Dependentes' && item.type === 'Dependentes' ? (
                <Box style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start', marginTop:'8px'}}>
                  <input
                    name="accountTypeId"
                    type="radio" 
                    value={item._id} 
                    checked={formikAccountUser.values.accountTypeId === item._id}
                    onChange={formikAccountUser.handleChange}
                  />
                  <p>{item.type}</p>
                  <div className="info-toggle"><LittleInfoBalloon info={item.description} /></div>
                </Box>
              ) : ('')}

              {userType !== 'Dependentes' && item.type !== 'Dependentes' ? (
                <Box style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-start', marginTop:'8px'}}>
                  <input
                    name="accountTypeId"
                    type="radio" 
                    value={item._id} 
                    checked={formikAccountUser.values.accountTypeId === item._id}
                    onChange={formikAccountUser.handleChange}
                  />
                  <p>{item.type}</p>
                  <div className="info-toggle"><LittleInfoBalloon info={item.description} /></div>
                </Box>
              ) : ('')}
              </div>
            ))}
          </Box>
          {formikAccountUser.values.id ? (
            <Box style={{width:'100%', marginTop:'16px'}}>
              <p style={{fontSize:'12px', fontStyle:'italic'}}>*Para atualizar a senha, preencha os campos com a nova senha.</p>
            </Box>
          ) : ('')}
          <Flex flexDirection="column" alignItems="center" justifyContent="center" mt={4} style={{width:'100%'}}>
            <Button
              mt={2}
              px={4} 
              type={'submit'} 
              // disabled={formikAccountUser.errors}
              style={{
                width: '90%', 
                height: '48px', 
                backgroundColor: '#2D4677', 
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: '16px',
                borderRadius: '8px'
              }}>
              {loadingCreateAccountUser || loadingUpdateAccountUser ? (<Spinner />) : (formikAccountUser.values.id ? 'Atualizar' : 'Adicionar')}
            </Button>
            <Button
              mt={2}
              px={4}
              onClick={() => handleClose()}
              style={{
                width: '90%', 
                height: '48px', 
                backgroundColor: '#FAFAFA', 
                borderColor: '#FAFAFA', 
                color: '#4E4B59',
                fontWeight: 'bold',
                fontSize: '16px',
                borderRadius: '8px'
              }}>
              {'Voltar'}
            </Button>
          </Flex>
        </form>
      </Box>
    </Modal>
  );
};

export default AccountUserModal;
