import React from 'react'
import { Box } from 'reflexbox'
import { FormTitle, CustomLabel, RegisterForm } from '../../components/'
import Typography from 'ui-components/lib/components/Typography'

const NaturalPersonForm = () => {

  return (
    <>
      <RegisterForm mt={"50px"}>
        <Box width={[1]}>
          <FormTitle>Informações pessoais</FormTitle>
        </Box>
        <Box width={[1, 1/3, 1/3, 1/3]} mt={3}>
          <CustomLabel>CPF ou RG</CustomLabel>
          <Typography color={'gray'}>123.456.789-10</Typography>
        </Box>
        <Box width={[1, 1/3, 1/3, 1/3]} mt={3}>
          <CustomLabel>Telefone celular pessoal</CustomLabel>
          <Typography color={'gray'}>(99) 99999-9999</Typography>
        </Box>
        <Box width={[1, 1/3, 1/3, 1/3]} mt={3}>
          <CustomLabel>Telefone fixo pessoal</CustomLabel>
          <Typography color={'gray'}>(99) 9999-9999</Typography>
        </Box>
        <Box width={[1]} mt={4}>
          <FormTitle>Endereço</FormTitle>
        </Box>
        <Box width={[1/2, 2/7, 2/7, 2/7]} mt={3}>
          <CustomLabel>CEP</CustomLabel>
          <Typography color={'gray'}>99999-999</Typography>
        </Box>
        <Box width={[1/2, 2/7, 2/7, 2/7]} mt={3}>
          <CustomLabel>Estado</CustomLabel>
          <Typography color={'gray'}>SP</Typography>
        </Box>
        <Box width={[1, 3/7, 3/7, 3/7]} mt={3}>
          <CustomLabel>Município</CustomLabel>
          <Typography color={'gray'}>São Paulo</Typography>
        </Box>
        <Box width={[1, 4/7, 4/7, 4/7]} mt={3}>
          <CustomLabel>Endereço</CustomLabel>
          <Typography color={'gray'}>Avenida Paulista</Typography>
        </Box>
        <Box width={[1/3, 1/7, 1/7, 1/7]} mt={3}>
          <CustomLabel>Número</CustomLabel>
          <Typography color={'gray'}>123</Typography>
        </Box>
        <Box width={[2/3, 2/7, 2/7, 2/7]} mt={3}>
          <CustomLabel>Complemento</CustomLabel>
          <Typography color={'gray'}>Apartamento 6-B</Typography>
        </Box>
        <Box width={[1]} mt={3}>
          <CustomLabel>Indicado por quem?</CustomLabel>
          <Typography color={'gray'}>John</Typography>
        </Box>
        <Box width={[1]} mt={4}>
          <FormTitle>Informações para o plano</FormTitle>
        </Box>
        <Box width={[1, 1/2, 1/2, 1/2]} mt={3}>
          <CustomLabel>Faturamento anual (aproximado)</CustomLabel>
          <Typography color={'gray'}>Faturamento anual</Typography>
        </Box>
        <Box width={[1, 1/2, 1/2, 1/2]} mt={3}>
          <CustomLabel>Captou investimento no ultimo ano?</CustomLabel>
          <Typography color={'gray'}>Entre 200k até 1M</Typography>
        </Box>
        <Box width={[1, 1/2, 1/2, 1/2]} mt={3}>
          <CustomLabel>Número de funcionários</CustomLabel>
          <Typography color={'gray'}>Funcionários</Typography>
        </Box>
        <Box width={[1, 1/2, 1/2, 1/2]} mt={3}>
          <CustomLabel>Região de atuação</CustomLabel>
          <Typography color={'gray'}>Região</Typography>
        </Box>
      </RegisterForm>
    </>
  )
}

export default NaturalPersonForm
