import gql from 'graphql-tag'

export const SEND_CHAT_MESSAGES = gql`
  mutation sendChatMessages($demandId: String, $lawyerId: String, $chatId: String, $message: String, $subscription: String, $files:[FileInput]!) {
    sendChatMessages(demandId: $demandId, lawyerId: $lawyerId, chatId: $chatId, message: $message, subscription: $subscription, files: $files) {
      _id
      chatId
      message
      sender
    }
  }
`
