import React  from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import "./SuccessModal.css";
import Coruja from "../../../../images/owl-success-proposal.svg"
// import CloseIcon from '@mui/icons-material/Close';

const SuccessModal = (props) => {

  const { handleClose, openModal, modalType } = props;

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      disableAutoFocus
    >
      <div className="modal-success-proposal">
        {/* <CloseIcon /> */}
        <h2>{modalType === 'bidAccepted' ? ('Proposta aprovada com sucesso!') : ('Proposta recusada com sucesso!')}</h2>
        <div><img src={Coruja} alt="Bonuz owl"/></div>
        {modalType === 'bidAccepted' && <p>Você receberá uma notificação para os próximos passos.</p>}
        <Box className="modal-success-proposal-buttons-container">
          <Button onClick={handleClose} className="modal-success-proposal-success-button">
            OK
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default SuccessModal;