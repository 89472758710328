import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'
import theme from 'ui-components/lib/theme'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/900.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';

const GlobalStyles = createGlobalStyle`
  ${normalize}
  html,body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${theme.fontFamily}, Roboto, Montserrat, Inter;
  }
  body {
    font-family: ${theme.fontFamily}, Roboto, Montserrat, Inter;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    height: 100%;
  }
  #root > div {
    height: 100vh;
  }
`

export default GlobalStyles
