import * as React from 'react';
import styled from 'styled-components'
import { Box } from 'reflexbox'
import Switch from '@mui/material/Switch';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Wrapper = styled(Box)`
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto';
`

const Label = styled.span`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.16px;
  text-transform: uppercase;   
`;

const themeOptions = {
  palette: {
    type: 'main',
    primary: {
      main: '#F14F5C',
    },
    secondary: {
      main: '#9B9B9B',
    },
  },
};

const theme = createTheme(themeOptions);

const SwitchForm = props => {
  return (
    <Wrapper>
      <Label>{props.checked ? ('SIM') : ('NÃO')}</Label>
      <ThemeProvider theme={theme}>
        <Switch
          id={props.id}
          name={props.name}
          checked={props.checked}
          onChange={props.onChange} 
        />
      </ThemeProvider>  
    </Wrapper>
  );
}

export default SwitchForm