import * as Yup from 'yup'

const LegalPersonAccountSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    addressZipcode: Yup.string().min(8, "Deve ser um CEP válido.").required('Campo obrigatório'),
    addressUF: Yup.string().required('Campo obrigatório'),
    addressCity: Yup.string().required('Campo obrigatório'),
    addressStreet: Yup.string().min(2, "Deve ser maior que 2 carácteres.").required('Campo obrigatório'),
    addressNeighborhood: Yup.string().min(2, "Deve ser maior que 2 carácteres.").required('Campo obrigatório'),
    addressNumber: Yup.string().min(1, "Deve ser maior que 1 caráctere.").required('Campo obrigatório'),
    addressComplement: Yup.string().nullable(),
    businessEmail: Yup.string().typeError().email("Deve ser um email válido.").required('Campo obrigatório'),
    businessTelephoneNumber: Yup.string().typeError().min(9, "Deve ser um número válido.").required('Campo obrigatório'),
    businessDescription: Yup.string().nullable(),
    businessTarget: Yup.string().nullable(),
    password: Yup.string().min(6, 'Deve conter no mínimo 6 caracteres').nullable(),
    passwordConfirmation: Yup.string().when('password', {
      is: (value) => value,
      then: Yup.string()
        .oneOf([Yup.ref('password'), null], 'As senhas não estão iguais')
        .required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
  })
  
  export default LegalPersonAccountSchema
