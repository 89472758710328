import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from 'reflexbox'
import styled from 'styled-components'
import { faFileWord, faFileImage, faFilePdf, faFilePowerpoint, faFileExcel, faFolder } from '@fortawesome/free-solid-svg-icons'
import Typography from 'ui-components/lib/components/Typography'
import { Link } from 'react-router-dom'

const FileName = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  color: #000 !important;
`

const DocumentName = (props) => {
  const changeIcon = (type, text, folderId, fileURL) => {
    switch (type) {
      case '.pdf':
        return (
          <a href={fileURL} target="noopener noreferrer _blank" style={{ textDecoration: 'none' }}>
            <Flex>
              <FontAwesomeIcon icon={faFilePdf} color={'#f94e5c'} style={{ fontSize: '36px' }} /> <FileName>{text}</FileName>
            </Flex>
          </a>
        )
      case '.doc':
      case '.docx':
        return (
          <a href={fileURL} target="noopener noreferrer _blank" style={{ textDecoration: 'none' }}>
            <Flex>
              <FontAwesomeIcon icon={faFileWord} color={'#2185d0'} style={{ fontSize: '36px' }} /> <FileName>{text}</FileName>
            </Flex>
          </a>
        )
      case '.ppt':
      case '.pptx':
        return (
          <a href={fileURL} target="noopener noreferrer _blank" style={{ textDecoration: 'none' }}>
            <Flex>
              <FontAwesomeIcon icon={faFilePowerpoint} color={'#db3a37'} style={{ fontSize: '36px' }} /> <FileName>{text}</FileName>
            </Flex>
          </a>
        )
      case '.xls':
      case '.xlsx':
        return (
          <a href={fileURL} target="noopener noreferrer _blank" style={{ textDecoration: 'none' }}>
            <Flex>
              <FontAwesomeIcon icon={faFileExcel} color={'#4bba47'} style={{ fontSize: '36px' }} /> <FileName>{text}</FileName>
            </Flex>
          </a>
        )
      case '.png':
      case '.jpg':
      case '.jpeg':
        return (
          <a href={fileURL} target="noopener noreferrer _blank" style={{ textDecoration: 'none' }}>
            <Flex>
              <FontAwesomeIcon icon={faFileImage} color={'#6962c9'} style={{ fontSize: '36px' }} /> <FileName>{text}</FileName>
            </Flex>
          </a>
        )

      default:
        return (
          <Link to={`/dashboard/documentos/${folderId}/${text}`} style={{ textDecoration: 'none' }}>
            <Flex>
              <FontAwesomeIcon icon={faFolder} color={'#6962c9'} style={{ fontSize: '36px' }} /> <FileName>{text}</FileName>
            </Flex>
          </Link>
        )
    }
  }

  return <Typography>{changeIcon(props.type, props.text, props.folderId, props.fileURL)}</Typography>
}

export default DocumentName
