import React, { useState } from 'react'
import { Modal } from "@mui/material"
import { useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../graphql/mutations'
import * as localStorageService from '../../../services/localStorageService'
import './styles.css'
import logo from '../../../assets/img/logorating.svg';
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as faStarBold } from '@fortawesome/free-solid-svg-icons'
import Spinner from "ui-components/lib/components/Spinner";

const RatingModal = ({ openModal, handleClose, demandId, lawyerId, lawyerName }) => {
  const [demandRating] = useState(5)
  const [lawyerRating, setLawyerRating] = useState(0)
  const [lawyerRatingMouse, setLawyerRatingMouse] = useState(0)
  const [clientFeedback] = useState('')
  const [view, setView] = useState(0)

  const [evaluateDemand, { loading: loadingRating }] = useMutation(demandsMutations.EVALUATE_DEMAND, {
    variables: {
      demandId: demandId,
      lawyerId: lawyerId,
      evaluation: demandRating,
      lawyerEvaluation: lawyerRating,
      clientFeedback: clientFeedback
    },
    onCompleted() {
      setView(2)
    },
    onError() {
      setView(3)
    },
  })

  const handleCloseModal = () => {
    localStorageService.createStorage('demand-evaluation', true)
    handleClose()
  }

  const handleSendRating = (value) => {
    setLawyerRating(value)
    evaluateDemand();
  }

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`rating-modal-${view}`}
    >
      <div className={`content-rating ${view === 0 ? 'message-rating' : 'box md-size-1'}`}>
        {view === 0 && (
          <>
            <div className='rating-logo'>
              <img src={logo} alt='' />
            </div>
            <div className='rating-message'>
              <h2>O que achou do trabalho do {lawyerName || 'Advogado(a)'}?</h2>
              <p>Por favor, avalie o trabalho de {lawyerName || 'Advogado(a)'} e nos ajude a aprimorar sua experiência com nossos serviços jurídicos.</p>
              <Button variant="danger" onClick={() => setView(1)}>
                Avaliar agora <FontAwesomeIcon width={18} color='#FFFFFF' icon={faStar} />
              </Button>
            </div>
          </>
        )}
        {view === 1 &&(
          <>
            {loadingRating && (
              <Spinner color="primary" /> 
            )}
            {!loadingRating && (
              <>
                <p>Em uma escala de 5 estrelas, deixe sua avaliação</p>
                <p>
                  <FontAwesomeIcon
                    width={24} 
                    style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}} 
                    color='#F14F5C' 
                    icon={lawyerRating >= 1 || (lawyerRatingMouse !== null && lawyerRatingMouse >= 1) ? faStarBold : faStar }
                    onMouseOver={() => setLawyerRatingMouse(1)}
                    onMouseOut={() => setLawyerRatingMouse(null)}
                    onClick={() => handleSendRating(1)}
                  />
                  <FontAwesomeIcon
                    width={24} 
                    style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}} 
                    color='#F14F5C' 
                    icon={lawyerRating >= 2 || (lawyerRatingMouse !== null && lawyerRatingMouse >= 2) ? faStarBold : faStar }
                    onMouseOver={() => setLawyerRatingMouse(2)}
                    onMouseOut={() => setLawyerRatingMouse(null)}
                    onClick={() => handleSendRating(2)}
                  />
                  <FontAwesomeIcon
                    width={24} 
                    style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}} 
                    color='#F14F5C' 
                    icon={lawyerRating >= 3 || (lawyerRatingMouse !== null && lawyerRatingMouse >= 3) ? faStarBold : faStar }
                    onMouseOver={() => setLawyerRatingMouse(3)}
                    onMouseOut={() => setLawyerRatingMouse(null)}
                    onClick={() => handleSendRating(3)}
                  />
                  <FontAwesomeIcon
                    width={24} 
                    style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}} 
                    color='#F14F5C'
                    icon={lawyerRating >= 4|| (lawyerRatingMouse !== null && lawyerRatingMouse >= 4) ? faStarBold : faStar }
                    onMouseOver={() => setLawyerRatingMouse(4)}
                    onMouseOut={() => setLawyerRatingMouse(null)}
                    onClick={() => handleSendRating(4)}
                  />
                  <FontAwesomeIcon
                    width={24} 
                    style={{marginLeft: '5px', marginRight: '5px', cursor: 'pointer'}} 
                    color='#F14F5C' 
                    icon={lawyerRating >= 5 || (lawyerRatingMouse !== null && lawyerRatingMouse >= 5) ? faStarBold : faStar }
                    onMouseOver={() => setLawyerRatingMouse(5)}
                    onMouseOut={() => setLawyerRatingMouse(null)}
                    onClick={() => handleSendRating(5)}
                  />
                </p>
              </>
            )}
          </>
        )}
        {view === 2 && (
          <>
            <p>Muito obrigado por avaliar!</p>
            <img src={logo} alt='' />
          </>
        )}
      </div>
    </Modal>
  )
}

export default RatingModal
