import * as Yup from 'yup'
import cnpj from 'node-cnpj'

const LegalPersonSchema = Yup.object().shape({
  _id: Yup.string().nullable(),
  type: Yup.string().required(),
  buyAsLegalPerson: Yup.string().required(),
  cnpj: Yup.string()
    .test('cnpj-test', 'CNPJ inválido', (value) => cnpj.validate(value))
    .min(8, 'Deve ser maior que 8 carácteres.')
    .required('Campo obrigatório'),
  companyName: Yup.string().min(10, 'Deve ser maior que 10 caracteres.').required('Campo obrigatório'),
  addressZipcode: Yup.string().min(8, 'Deve ser um CEP válido.').required('Campo obrigatório'),
  addressUF: Yup.string().required('Campo obrigatório'),
  addressCity: Yup.string().required('Campo obrigatório'),
  addressStreet: Yup.string().min(2, 'Deve ser maior que 2 carácteres.').required('Campo obrigatório'),
  addressNeighborhood: Yup.string().min(2, 'Deve ser maior que 2 carácteres.').required('Campo obrigatório'),
  addressNumber: Yup.string().min(1, 'Deve ser maior que 1 caráctere.').required('Campo obrigatório'),
  addressComplement: Yup.string().nullable(),
  // businessEmail: Yup.string().email("Deve ser um email válido.").required('Campo obrigatório'),
  businessTelephoneNumber: Yup.string().min(9, 'Deve ser um número válido.').required('Campo obrigatório'),
  indication: Yup.string().nullable(),
  // contacts: Yup.array().of(
  //   Yup.object().shape({
  //     firstName: Yup.string().required('Campo obrigatório'),
  //     lastName: Yup.string().required('Campo obrigatório'),
  //     document: Yup.string().nullable().min(9, "Deve ser maior que 9 carácteres.").required('Campo obrigatório'),
  //     telephoneNumber: Yup.string().nullable(),
  //     email: Yup.string().email("Deve ser um email válido.").required('Campo obrigatório'),
  //     role: Yup.string().nullable()
  //   })
  // ),
  businessCaptions: Yup.string().required('Campo obrigatório'),
  businessBillings: Yup.string().required('Campo obrigatório'),
  businessEmployees: Yup.string().required('Campo obrigatório'),
  businessRegion: Yup.string().required('Campo obrigatório'),
  businessDescription: Yup.string().required('Campo obrigatório'),
  businessTarget: Yup.string().required('Campo obrigatório'),
  bonuzContact: Yup.string().required('Campo obrigatório'),
  financialEmail: Yup.string().nullable()
})

export default LegalPersonSchema
