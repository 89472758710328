import gql from 'graphql-tag'

export const AUTH_LOGIN = gql`
  mutation auth($email: String!, $password: String!, $keepAlive: String) {
    auth(email: $email, password: $password, keepAlive: $keepAlive) {
      token
    }
  }
`

export const AUTH_GOOGLE = gql`
  mutation authGoogle($idToken: String!) {
    authGoogle(idToken: $idToken) {
      token
    }
  }
`

export const AUTH_FACEBOOK = gql`
  mutation authFacebook($accessToken: String!) {
    authFacebook(accessToken: $accessToken) {
      token
    }
  }
`
