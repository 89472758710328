export function formatDate(date) {
  const dateToFormat = new Date(date)

  const formattedDate = dateToFormat.toLocaleDateString("pt-br", {
    hour: 'numeric',
    minute: 'numeric',
  })

  return formattedDate
}

export function formatISOTimestampToDate(date, dateType) {
  let dateToFormat

  if(dateType === 'ISO'){
    dateToFormat = new Date(Date.parse(date))
  } else {
    dateToFormat = new Date(date)
  }

  const formattedDate = dateToFormat.toLocaleDateString("pt-br")

  const formattedHourMinute = dateToFormat.toLocaleTimeString("pt-br", {
    hour: '2-digit',
    minute:'2-digit'
  });

  return {formattedDate, formattedHourMinute}
}

export default {formatDate, formatISOTimestampToDate}