import React, { useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import Spinner from 'ui-components/lib/components/Spinner'
import * as queryString from 'query-string'
import { getAccessTokenFromCode } from '../services/facebookAuthService'
import { useMutation } from '@apollo/react-hooks'
import { authMutations } from '../graphql/mutations'
import * as authService from '../services/auth'
import { useHistory } from 'react-router-dom'

export default () => {
  const { code } = queryString.parse(window.location.search)

  const history = useHistory()

  const [authFacebook] = useMutation(authMutations.AUTH_FACEBOOK, {
    async onCompleted({ authFacebook }) {
      const token = authFacebook?.token
      authService.login({ token })
      const decodedToken = authService.decodedToken()

      if (decodedToken.status === 'incomplete') {
        history.push('/completar-cadastro')
      } else {
        history.push('/')
      }
    },
    async onError(error) {
      const { email, firstName, lastName } = error?.graphQLErrors?.[0]?.extensions?.response?.body?.payload
      history.push(`/nova-conta?email=${email}&firstName=${firstName}&lastName=${lastName}`)
    },
  })

  useEffect(() => {
    ;(async () => {
      const accessToken = await getAccessTokenFromCode(code)

      authFacebook({ variables: { accessToken } })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  return (
    <Flex justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
      <Box>
        <Spinner color="primary" />
      </Box>
    </Flex>
  )
}
