import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { clientsQueries } from '../../graphql/queries'
import { clientsMutations } from '../../graphql/mutations'
import { Person } from '../../models'
import { PersonSchema } from '../../validate'
import {cpfMask, cnpjMask} from '../../utils/documentMask'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Spinner from 'ui-components/lib/components/Spinner'
import login_aside_owl from '../../images/login_aside_owl.svg'

import {
  LoginLogo,
  LoginWrapper,
  LoginContainer,
  LoginContainerAside,
  LoginContentAside,
  LoginFormContainer,
  LoginButtonForm,
  LinkForm,
  TextTitleForm, 
  TextFieldInput,
  TextDescriptionForm,
  FieldLabelForm,
} from '../../components'


const ClientInfo = (props) => {

  const { showMessage } = useAlertStack()
  const history = useHistory()

  const [documentField, setDocumentField] = useState('CPF')

  const { data: statusData } = useQuery(clientsQueries.GET_STATUS, {
    fetchPolicy: 'network-only'
  })

  const [updateClient, { loading: loadingUpdate }] = useMutation(clientsMutations.UPDATE_CLIENT, {
    onCompleted() {
      history.push('/')
    },
    update: graphqlHelpers.deleteItemsFromCache('Client'),
    onError(error) {
      const customError = ((((((error.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).message || {}).errors
      showMessage({
        title: 'Cadastro não atualizado',
        message: customError || 'Ocorreu algum erro desconhecido',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const formik = useFormik({
    initialValues: new Person(),
    validationSchema: PersonSchema,
    onSubmit: (values, { setSubmitting }) => {
      updateClient({ variables: {
        ...values,
        document: values.type === 'natural' ? values.document : '',
        cnpj: values.type === 'legal' ? values.cnpj : ''
      } 
      });
      setSubmitting(false);
    },
  })

  const handleChangePersonDocument = (event) => {
    if(event.target.value.length >= 15){
      setDocumentField('CNPJ')
      formik.setValues({ ...formik.values, type: 'legal', cnpj: cnpjMask(event.target.value), document: cpfMask(event.target.value) })        
    } else {
      setDocumentField('CPF')
      formik.setValues({ ...formik.values, type: 'natural', cnpj: cnpjMask(event.target.value), document: cpfMask(event.target.value) })
    }
  }

  useEffect(() => {
    if (statusData && statusData?.me?.status !== 'incomplete') {
      history.push('/dashboard')
    }
  }, [statusData]) // eslint-disable-line

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginFormContainer onSubmit={formik.handleSubmit}>
          <LoginLogo />
          <TextTitleForm>Bem-vindo a Bonuz !</TextTitleForm>
          <TextDescriptionForm textType={'description'}>Preencha os campos abaixo e crie sua conta</TextDescriptionForm>
            <TextDescriptionForm textType={'formLabel'} style={{padding: '22px 0 0 0'}}>Dados da empresa</TextDescriptionForm>
            <Box width={[1]} mt={17}>
                <Flex flexDirection={'row'} style={{padding: '0 0 3px 0'}}>
                <FieldLabelForm>
                  {documentField === 'CNPJ' ? <b>CNPJ</b> : 'CNPJ'} / {documentField === 'CPF' ? <b>CPF</b> : 'CPF'}
                </FieldLabelForm>
                <FieldLabelForm style={{padding: '0 0 0 5px', color: '#D3D2D7'}}>
                  (somente números)
                </FieldLabelForm>
                </Flex>
                {documentField === 'CPF' ? (
                    <TextFieldInput 
                        id={"document"}
                        name={"document"}
                        textFieldType={'identityField'}
                        placeholder={"000.000.000-00"}
                        helperText={formik.errors.type ? (
                          formik.errors.type
                          ) : (
                          formik.touched.document && formik.errors.document
                          )
                        }                 
                        value={formik.values.document} 
                        onChange={handleChangePersonDocument}
                        onBlur={formik.handleBlur}
                        onTouched={formik.touched.document}
                        error={formik.errors.type ? (
                          formik.errors.type
                          ) : (
                          formik.touched.document && formik.errors.document
                          )
                        }  
                        type={'text'}
                        autoFocus={documentField === 'CPF' ? true : false}
                    />
                ) : ('')}
                {documentField === 'CNPJ' ? (
                    <TextFieldInput 
                        id={"cnpj"}
                        name={"cnpj"}
                        textFieldType={'identityField'}
                        placeholder={"00.000.000/0000-00"}
                        helperText={formik.errors.type ? (
                          formik.errors.type
                          ) : (
                          formik.touched.cnpj && formik.errors.cnpj
                          )
                        }                
                        value={formik.values.cnpj} 
                        onChange={handleChangePersonDocument}
                        onBlur={formik.handleBlur}
                        onTouched={formik.touched.cnpj}
                        error={formik.errors.type ? (
                          formik.errors.type
                          ) : (
                          formik.touched.cnpj && formik.errors.cnpj
                          )
                        } 
                        type={'text'}
                        autoFocus={documentField === 'CNPJ' ? true : false}
                    />
                ) : ('')}
            </Box>
            <Box width={[1]} mt={17}>
            <Flex flexDirection={'row'} style={{padding: '0 0 2px 0'}}>
                <FieldLabelForm>Razão social</FieldLabelForm>
                <FieldLabelForm style={{padding: '0 0 0 5px', color: '#D3D2D7'}}>(opcional)</FieldLabelForm>
            </Flex>
            <TextFieldInput 
                id="companyName"
                name={'companyName'}
                textFieldType={'companyField'}
                placeholder={'Nome de Registro da empresa'}
                helperText={formik.touched.companyName && formik.errors.companyName}                
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}                
                onTouched={formik.touched.companyName}                            
                error={formik.touched.companyName && formik.errors.companyName && true}
                defaultStyle={true}
                type={'text'}
            />            
            </Box>
            <Box width={[1]} mt={17}>
            <Flex flexDirection={'row'} style={{padding: '0 0 2px 0'}}>
                <FieldLabelForm>Nome fantasia</FieldLabelForm>
                <FieldLabelForm style={{padding: '0 0 0 5px', color: '#D3D2D7'}}>(opcional)</FieldLabelForm>
            </Flex>                
            <TextFieldInput 
                id="tradeName"
                name={'tradeName'}
                textFieldType={'companyField'}
                placeholder={'Nome comercial'}
                helperText={formik.touched.tradeName && formik.errors.tradeName}                
                value={formik.values.tradeName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}                
                onTouched={formik.touched.tradeName}                            
                error={formik.touched.tradeName && formik.errors.tradeName && true}
                defaultStyle={true}
                type={'text'}
            />            
            </Box>
            <Box width={[1]} mt={17}>
            <Flex flexDirection={'row'} style={{padding: '0 0 2px 0'}}>
                <FieldLabelForm>E-mail financeiro</FieldLabelForm>
                <FieldLabelForm style={{padding: '0 0 0 5px', color: '#D3D2D7'}}>(opcional)</FieldLabelForm>
            </Flex>                 
            <TextFieldInput 
                id="financialEmail"
                name={'financialEmail'}
                textFieldType={'mailField'}
                placeholder={'Email por onde será emitido NF'}
                helperText={formik.touched.financialEmail && formik.errors.financialEmail}                
                value={formik.values.financialEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyUp={formik.handleBlur}                
                onTouched={formik.touched.financialEmail}                            
                error={formik.touched.financialEmail && formik.errors.financialEmail && true}
                defaultStyle={true}                
                type={'text'}
            />            
            </Box>
            <Flex width={1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} style={{padding: '22px 0 16px 0'}}>
            <LoginButtonForm 
                buttonType={'primaryButton'} 
                disabled={!formik.isValid || formik.isSubmitting} 
                type={'submit'}
            >
                {loadingUpdate ? <Spinner color="primary" /> : 'Finalizar e cadastrar'}
            </LoginButtonForm>
            <LinkForm linkType={'redirect'} to="/sair">Voltar</LinkForm>
            </Flex>
        </LoginFormContainer>
        <LoginContainerAside style={{padding: '159px 75px 114px 123px'}}>
          <LoginContentAside src={login_aside_owl} alt={'coruja-bonuz'} style={{width: '100%', height: '100%'}}/>
        </LoginContainerAside>
      </LoginContainer>
    </LoginWrapper>
  )
}

export default ClientInfo
