import React from 'react'
import styled from 'styled-components'

const FieldLabel = styled.h4`
  margin: 0;
  padding: 0 0 8px 0;
  color: #4E4B59;
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`

const FieldLabelForm = props => {
    return (
      <FieldLabel style={props.style}>
        {props.children}
      </FieldLabel>
    )
}

export default FieldLabelForm