import React, { useEffect, useState } from 'react'
import DropZoneContainer from "../../../services/DropZoneContainer";
import { useDropzone } from 'react-dropzone'
import Button from 'ui-components/lib/components/Button'
import { Flex, Box } from 'reflexbox'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { useMutation } from '@apollo/react-hooks'
import { S3_UPLOADER } from '../../../graphql/mutations/demands'
import { fileExtension } from '../../../utils'
import Spinner from 'ui-components/lib/components/Spinner'
import FilePresentIcon from "@mui/icons-material/FilePresent";
import fileS3Exists from '../../../services/fileS3Exists';

export default ({
                    required = false,
                    onLoad = () => {},
                    onComplete = () => {},
                    allowedFormatFiles = '.pdf, .docx',
                    maxFileSize = 15728640,
                    maxFiles = 5,
                    multiple = true,
                    classe = ''
                }) => {
    const { showMessage } = useAlertStack()

    const [isFilesUploading, setUploadingFiles] = useState(false)

    useEffect(() => {
        onLoad(isFilesUploading)
    }, [onLoad, isFilesUploading])

    const [uploadFile] = useMutation(S3_UPLOADER)

    const onDrop = async (accepted, rejected, event) => {
        setUploadingFiles(true)

        const uploadedFiles = []

        for await (let file of accepted) {
            const { data: { s3uploader: { signedUrl, randomFileName } } = {} } = await uploadFile({
                variables: { fileName: file.name, mimeType: file.type, extension: fileExtension.format(file.name) },
            })

            const response = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                },
                body: file,
            })

            let uploaded = false;
            if (response.ok) {
                const path = new URL(signedUrl)
                uploaded = await fileS3Exists(path.pathname)
            }

            if (uploaded) {
                uploadedFiles.push({
                    fileName:file.name,
                    randomFileName,
                    mimeType: file.type,
                    fileExtension: fileExtension.format(file.name),
                    fileSize: file.size,
                })
            } else {
                showMessage({
                    title: 'Falha de upload do arquivo',
                    message: 'Ocorreu um erro durante o upload de um ou mais arquivos, porfavor, tente novamente.',
                    color: 'danger',
                    position: 'bottom-left',
                    time: 4000,
                })
            }
        }

        setUploadingFiles(false)

        onComplete(uploadedFiles)
    }

    // eslint-disable-next-line
    const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({
        onDrop,
        accept: allowedFormatFiles,
        maxSize: maxFileSize,
        multiple,
        maxFiles,
        onDropRejected() {
            showMessage({
                title: 'Falha ao selecionar um ou mais arquivos',
                message: `O arquivo foi rejeitado porque excede o tamanho máximo, ou foram selecionados mais de ${maxFiles} arquivos.`,
                color: 'danger',
                position: 'bottom-left',
                time: 4000,
            })
        },
    })

    return (
        <DropZoneContainer aria-required={required} className={`dropzone ${classe} dropzone-bonuz-sign`} isDragActive={isDragActive && !isFilesUploading} mt={2} ml={0} {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
                <center>
                    <FilePresentIcon sx={[{ color: "#9997A0" }, { fontSize: 64 }]}/>
                    <p>
                        <strong>
                            Arrastar e soltar
                            <br/>
                            ou
                        </strong>
                    </p>
                    <Box>
                        <Button p={[1, 2]} disabled={isFilesUploading} className={"bonuz-sign-primary"}>
                            <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                                {isFilesUploading ? <Spinner color="primary" /> : 'Selecionar'}
                            </Flex>
                        </Button>
                        <center>
                            <p>.pdf / .docx</p>
                        </center>
                    </Box>
                </center>
            </div>
        </DropZoneContainer>
    )
}
