import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { termsQueries } from '../../graphql/queries'
import { clientsMutations } from '../../graphql/mutations'
import { Client } from '../../models'
import { authMutations } from '../../graphql/mutations'
import * as authService from '../../services/auth'
import { parseName } from '../../utils'
import { ClientSchema } from '../../validate'
import InputMask from 'react-input-mask'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import useModal from 'ui-components/lib/hooks/useModal'
import Modal from 'ui-components/lib/components/Modal'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Spinner from 'ui-components/lib/components/Spinner'
import * as queryString from 'query-string'
import login_aside_owl from '../../images/login_aside_owl.svg'
import header_modal from '../../images/header_modal_small.svg'
import ImgWrapper from '../../components/ImageWrapper/ImgWrapper'

import {
  LoginLogo,
  LoginWrapper,
  LoginContainer,
  LoginContainerAside,
  LoginContentAside,
  LoginFormContainer,
  LoginButtonForm,
  LinkForm,
  TextTitleForm, 
  TextFieldInput,
  TextDescriptionForm,
  FieldLabelForm,
  CheckBoxForm,
} from '../../components'


const SignIn = (props) => {

  const { showMessage } = useAlertStack()
  const history = useHistory()
  const [mobilePhoneMask, setMobilePhoneMask] = useState('(99) 99999-9999')

  const { data: privacyPolicyData, loading: privacyPolicyLoading } = useQuery(termsQueries.GET_PRIVACY_POLICY)

  const { data: termsOfUseData, loading: termsOfUseLoading } = useQuery(termsQueries.GET_TERMS_OF_USE)

  const [authLogin, { loading }] = useMutation(authMutations.AUTH_LOGIN, {
    onCompleted({ auth }) {
      const token = (auth || {}).token
      authService.login({ token })
      const decodedToken = authService.decodedToken()
      if (decodedToken.status === 'incomplete') {
        history.push('/completar-cadastro')
      } else if (decodedToken.status === 'pending_password') {
        history.push('/primeiro-acesso')
      } else {
        history.push('/')
      }
    },
    onError(error) {
      showMessage({
        title: 'Usuário ou senha incorretos',
        message: 'Verifique as informações e tente novamente.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [createClient, { loading: loadingCreate }] = useMutation(clientsMutations.CREATE_CLIENT, {
    onCompleted() {
      showMessage({
        title: 'Primeira parte do cadastro realizada com sucesso',
        message: 'Você será redirecionado para continuar seu cadastro.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      authLogin({ 
        variables: {
          email: formik.values.email, password: formik.values.password, keepAlive: 'true'
        }
      })
    },
    refetchQueries: props.refetchQueries || [],
    update: graphqlHelpers.deleteItemsFromCache('Client'),
    onError(error) {
      const customError = ((((((error.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).message || {}).errors

      showMessage({
        title: 'Cadastro não realizado',
        message: customError || 'Ocorreu um erro desconhecido, tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const { isOpen: openTerms, openModal: openTermsModal, closeModal: closeTerms } = useModal()
  const { isOpen: openPolicy, openModal: openPolicyModal, closeModal: closePolicy } = useModal()

  const useQuerySearchParams = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuerySearchParams()

  const { tipo, produto } = queryString.parse(window.location.search)

  const formik = useFormik({
    initialValues: new Client({ email: query.get('email'), firstName: query.get('firstName'), lastName: query.get('lastName') }),
    validationSchema: ClientSchema,
    onSubmit: (values, { setSubmitting }) => {
      createClient({ variables: values });
      setSubmitting(false);
    },
  })

  useEffect(() => {
    let clientType
    let clientProduct

    if (tipo && tipo.length < 20) {
      clientType = tipo
    }
    if (produto && produto.length < 20) {
      clientProduct = produto
    }

    formik.setValues({ 
      ...formik.values, 
      partnership: clientType ? clientType : null, 
      product: clientProduct ? clientProduct : null 
    })
  }, []) // eslint-disable-line


  const handleChangeMobilePhoneMask = (event) => {
    if(event.key === "Backspace"){
      setMobilePhoneMask('99999999999')
    } else {
      setMobilePhoneMask('(99) 99999-9999')
    }
  }

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  const handleChangeName = (event) => {
    const splitName = parseName(event.target.value)
    formik.setValues({ ...formik.values, fullName: titleCase(event.target.value), firstName: titleCase(splitName.name), lastName: titleCase(splitName.lastName)})
  }

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginFormContainer onSubmit={formik.handleSubmit}>
          <LoginLogo />
          <TextTitleForm>Bem-vindo a Bonuz !</TextTitleForm>
          <TextDescriptionForm textType={'description'}>Preencha os campos abaixo e crie sua conta</TextDescriptionForm>
              <TextDescriptionForm textType={'formLabel'} style={{padding: '22px 0 0 0'}}>Dados pessoais</TextDescriptionForm>
              <Box width={[1]} mt={17}>
                <FieldLabelForm>Nome</FieldLabelForm>
                <TextFieldInput 
                  id="fullName"
                  name={'fullName'}
                  textFieldType={'nameField'}
                  placeholder={'Nome completo'}
                  helperText={(formik.touched.fullName && (formik.errors.fullName || formik.errors.lastName))}                
                  value={formik.values.fullName}
                  onChange={handleChangeName}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}                  
                  onTouched={formik.touched.fullName}                            
                  error={(formik.touched.fullName && (formik.errors.fullName || formik.errors.lastName))}
                  type={'text'}
                />            
              </Box>
              <Box width={[1]} mt={17}>
                <FieldLabelForm>E-mail</FieldLabelForm>
                <TextFieldInput 
                  id="email"
                  name={'email'}
                  textFieldType={'mailField'}
                  placeholder={'seunegocio@suaempresa.com'}
                  helperText={formik.touched.email && formik.errors.email}                
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}                  
                  onTouched={formik.touched.email}                            
                  error={formik.touched.email && formik.errors.email && true}
                  type={'text'}
                />            
              </Box>
              <Box width={[1]} mt={17}>
                <FieldLabelForm>Telefone</FieldLabelForm>
                <InputMask
                  id="personalMobileNumber"
                  name={'personalMobileNumber'}
                  textFieldType={'telephoneField'}              
                  placeholder={'(00) 00000-0000'}
                  helperText={formik.touched.personalMobileNumber && formik.errors.personalMobileNumber}              
                  mask={mobilePhoneMask} 
                  maskChar={null}
                  formatChars={{ 9: '[0-9]', '?': '[0-9 ]' }} 
                  value={formik.values.personalMobileNumber} 
                  onChange={formik.handleChange} 
                  onKeyDown={handleChangeMobilePhoneMask}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}                 
                  onTouched={formik.touched.personalMobileNumber}                            
                  error={formik.touched.personalMobileNumber && formik.errors.personalMobileNumber && true}
                  type={'text'}
                >
                  {(inputFormProps) => (
                    <TextFieldInput 
                      {...inputFormProps}
                    />
                  )}
                </InputMask>             
              </Box>
              <Box width={[1]} mt={17}>
                <FieldLabelForm>Senha</FieldLabelForm>
                <TextFieldInput 
                  id="password"
                  name={'password'}
                  textFieldType={'passwordField'}
                  placeholder={'no mínimo 8 dígitos'}
                  helperText={formik.touched.password && formik.errors.password}                
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}                  
                  onTouched={formik.touched.password}                            
                  error={formik.touched.password && formik.errors.password && true}
                  type={'password'}
                />
              </Box>
              <Box width={[1]} mt={17}>
                <FieldLabelForm>Confirmar senha</FieldLabelForm>
                <TextFieldInput 
                  id="passwordConfirmation"
                  name={'passwordConfirmation'}
                  textFieldType={'passwordField'}
                  placeholder={'no mínimo 8 dígitos'}
                  helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}                
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}                  
                  onTouched={formik.touched.passwordConfirmation}                             
                  error={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && true}
                  type={'password'}
                />
              </Box>
              <Flex width={1} mt={'13px'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                <CheckBoxForm
                  id="agreedTerms"
                  name="agreedTerms"
                  checked={formik.values.agreedTerms}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  error={formik.errors.agreedTerms}
                  style={{padding: '0 7px 0 2px'}}          
                />
                <TextDescriptionForm textType={'linkLabel'} style={{padding: '0 5px 0 0'}}>
                  Li e aceito os
                </TextDescriptionForm>
                <LoginButtonForm buttonType={'linkButton'} onClick={() => openTermsModal()} disabled={termsOfUseLoading}>
                  Termos de Serviço
                </LoginButtonForm>
              </Flex>
              <Flex width={1} mt={0} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                <CheckBoxForm
                  id="agreedPolicy"
                  name="agreedPolicy"
                  checked={formik.values.agreedPolicy}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  error={formik.errors.agreedPolicy}
                  style={{padding: '0 7px 0 2px'}}
                />            
                <TextDescriptionForm textType={'linkLabel'} style={{padding: '0 5px 0 0'}}>
                  Li e aceito a
                </TextDescriptionForm>
                <LoginButtonForm buttonType={'linkButton'} onClick={() => openPolicyModal()} disabled={privacyPolicyLoading}>
                  Política de Privacidade
                </LoginButtonForm>
              </Flex>
              <Flex width={1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} style={{padding: '22px 0 16px 0'}}>
                <LoginButtonForm 
                  buttonType={'primaryButton'} 
                  disabled={formik.isSubmitting} 
                  type={'submit'}
                >
                  {loading || loadingCreate ? <Spinner color="primary" /> : 'Avançar'}
                </LoginButtonForm>
                <LinkForm linkType={'redirect'} to="/login">Já tenho uma conta</LinkForm>
              </Flex>
        </LoginFormContainer>
        <LoginContainerAside style={{padding: '159px 75px 114px 123px'}}>
          <LoginContentAside src={login_aside_owl} alt={'coruja-bonuz'} style={{width: '100%', height: '100%'}}/>
        </LoginContainerAside>
      </LoginContainer>
      <Modal
        id="termsModal"
        open={openTerms}
        onClose={closeTerms}
        boxProps={{ p: 28 }}
        contentModalProps={{ width: '80vw' }}
      >
        <Flex flexDirection="column" alignItems={'center'}>
          <ImgWrapper src={header_modal} alt={'header modal - bonuz'} style={{width: '100%', borderRadius: '8px'}} />
          <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: termsOfUseData?.termsOfUse?.content }} 
            style={{fontFamily: "'Inter', Helvetica, sans-serif", fontSize: 14, fontWeight: 400, color: '#4E4B59'}}
          />
          <Flex width={1/5} mt={4} alignItems="flex-end">
            <LoginButtonForm buttonType={'primaryButton'} onClick={() => closeTerms()}>
              Fechar
            </LoginButtonForm>
          </Flex>
        </Flex>
      </Modal>
      <Modal
        id="policyModal"      
        open={openPolicy}
        onClose={closePolicy}
        boxProps={{ p: 28 }}
        contentModalProps={{ width: '80vw' }}
      >
        <Flex flexDirection="column" alignItems={'center'}>
          <ImgWrapper src={header_modal} alt={'header modal - bonuz'} style={{width: '100%', borderRadius: '8px'}} />
          <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: privacyPolicyData?.privacyPolicy?.content }} 
            style={{fontFamily: "'Inter', Helvetica, sans-serif", fontSize: 14, fontWeight: 400, color: '#4E4B59'}}
          />
          <Flex width={1/5} mt={4} alignItems="flex-end">
            <LoginButtonForm buttonType={'primaryButton'} onClick={() => closePolicy()}>
              Fechar
            </LoginButtonForm>
          </Flex>
        </Flex>
      </Modal>
    </LoginWrapper>
  )
}

export default SignIn
