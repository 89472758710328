import React from 'react'
import styled from 'styled-components'
import {ReactComponent as IconCanceled} from '../../images/StatusChips/canceled.svg';
import {ReactComponent as IconConcluido} from '../../images/StatusChips/concluido.svg';
import {ReactComponent as IconDraft} from '../../images/StatusChips/draft.svg';
import {ReactComponent as IconPending} from '../../images/StatusChips/pending.svg';
import {ReactComponent as IconWaitingApproval} from '../../images/StatusChips/waiting_approval.svg';
import {ReactComponent as IconProgress} from '../../images/StatusChips/in_progress.svg';
import {ReactComponent as IconDemandSent} from '../../images/StatusChips/demand_sent.svg';
import {ReactComponent as IconInReview} from '../../images/StatusChips/in_review.svg';
import {ReactComponent as IconStandby} from '../../images/StatusChips/standby.svg';
import {ReactComponent as IconInEstimate} from '../../images/StatusChips/in_estimate.svg';
import {ReactComponent as WaitingEstimateApproval} from '../../images/StatusChips/waiting_estimate_approval.svg';


const StyledChip = styled.div`
  display: flex;
  align-items: center;
`
const StyledText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ color }) => `${color}`};
  background: ${({ backgroundColor }) => `${backgroundColor}`};
  padding: 8px 13px;
  border-radius: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`


const ChipStatusVariants = ({ status, onClick, noStyle = false }) => {
    if(noStyle){
      switch(status) {
        case 'pending':
          return <span onClick={onClick}>
              Pendente
          </span>
        case 'in_progress':
          return <span onClick={onClick}>
              Em Progresso
          </span>
        case 'stand_by':
          return <span onClick={onClick}>
              Stand-by
          </span>
        case 'waiting_approval':
          return <span onClick={onClick}>
              Aguardando Aprovação
          </span>
        case 'in_estimating':
          return <span onClick={onClick}>
              Aguardando Orçamento
          </span>
        case 'draft':
          return <span onClick={onClick}>
              Pendente de Informação
          </span>
        case 'demand_sent':
          return <span onClick={onClick}>
              Demanda Enviada
          </span>
        case 'in_review':
          return <span onClick={onClick}>
              Aguardando Revisão
          </span>
        case 'canceled':
          return <span onClick={onClick}>
              Cancelado
          </span>
        case 'done':
          return <span onClick={onClick}>
              Demanda Concluída
          </span>
        case 'waiting_estimate_approval':
          return <span onClick={onClick}>
              Aguardando Aprovação do Orçamento
          </span>
        case "waiting_lawyer_acceptance":
          return <span onClick={onClick}>
              Aguardando Aceite do Advogado
          </span>
        default:
          return <span onClick={onClick}>
              Demanda solicitada
          </span>
      }
    }else{
      switch(status) {
        case 'pending':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#E9D3FB'} color={'#350A7C'}>
              <IconPending style={{marginRight: "5px"}}/> Pendente
            </StyledText>
          </StyledChip>
        case 'in_progress':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#D6DAFF'} color={'#5A67DF'}>
              <IconProgress style={{marginRight: "5px"}}/> Em Progresso
            </StyledText>
          </StyledChip>
        case 'stand_by':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#FFF9D7'} color={'#F4C51F'}>
              <IconStandby style={{marginRight: "5px"}}/> Stand-by
            </StyledText>
          </StyledChip>
        case 'waiting_approval':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#DCDCDC'} color={'#5A5A5A'}>
              <IconWaitingApproval style={{marginRight: "5px"}}/> Aguardando Aprovação
            </StyledText>
          </StyledChip>
        case 'in_estimating':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#FFF6D7'} color={'#F0BA06'}>
              <IconInEstimate style={{marginRight: "5px"}}/> Aguardando Orçamento
            </StyledText>
          </StyledChip>
        case 'draft':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#FFE7D6'} color={'#FF6007'}>
              <IconDraft style={{marginRight: "5px"}}/> Pendente de Informação
            </StyledText>
          </StyledChip>
        case 'demand_sent':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#D3FBE5'} color={'#3AC59B'}>
              <IconDemandSent style={{marginRight: "5px"}}/> Demanda Enviada
            </StyledText>
          </StyledChip>
        case 'in_review':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#FBEDD3'} color={'#68480A'}>
              <IconInReview style={{marginRight: "5px"}}/> Aguardando Revisão
            </StyledText>
          </StyledChip>
        case 'canceled':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#F8D0D0'} color={'#C31E1E'}>
              <IconCanceled style={{marginRight: "5px"}}/> Cancelado
            </StyledText>
          </StyledChip>
        case 'done':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#D6FBD3'} color={'#49A96A'}>
              <IconConcluido style={{marginRight: "5px"}}/> Demanda Concluída
            </StyledText>
          </StyledChip>
        case 'waiting_estimate_approval':
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#FFD7F4'} color={'#D4169F'}>
              <WaitingEstimateApproval style={{marginRight: "5px"}}/> Aguardando Aprovação do Orçamento
            </StyledText>
          </StyledChip>
        case "waiting_lawyer_acceptance":
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#FFD7F4'} color={'#D4169F'}>
              <WaitingEstimateApproval style={{marginRight: "5px"}}/> Aguardando Aceite do Advogado
            </StyledText>
          </StyledChip>
        // case "in_auction":
        //     return <div className="status-chip">Em Leilão</div>
        // case "transfered_lawyer":
        //     return <div className="status-chip">Transferência de Advogado</div>
        // case "without_history":
        //     return <div className="status-chip">Sem Status / Status não Registrado</div>
        default:
          return <StyledChip onClick={onClick}>
            <StyledText backgroundColor={'#D6FBD3'} color={'#49A96A'}>
              Demanda solicitada
            </StyledText>
          </StyledChip>
      }
    }


}

export default ChipStatusVariants;
