import React from 'react';

const IPerson = ({ cor }) => {
  const color = cor || '#FB6D06'
  return (
    <svg width="32" height="24" viewBox="0 0 32 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M16 14.2246C17.3261 14.2246 18.5979 13.6978 19.5355 12.7601C20.4732 11.8225 21 10.5507 21 9.22461C21 7.89853 20.4732 6.62676 19.5355 5.68908C18.5979 4.75139 17.3261 4.22461 16 4.22461C14.6739 4.22461 13.4021 4.75139 12.4645 5.68908C11.5268 6.62676 11 7.89853 11 9.22461C11 10.5507 11.5268 11.8225 12.4645 12.7601C13.4021 13.6978 14.6739 14.2246 16 14.2246Z" fill=""/>
    <path d="M4 0.125C2.93913 0.125 1.92172 0.546427 1.17157 1.29657C0.421427 2.04672 0 3.06413 0 4.125L0 20.125C0 21.1859 0.421427 22.2033 1.17157 22.9534C1.92172 23.7036 2.93913 24.125 4 24.125H28C29.0609 24.125 30.0783 23.7036 30.8284 22.9534C31.5786 22.2033 32 21.1859 32 20.125V4.125C32 3.06413 31.5786 2.04672 30.8284 1.29657C30.0783 0.546427 29.0609 0.125 28 0.125H4ZM25.596 22.125C24.69 19.585 22.076 16.125 16 16.125C9.926 16.125 7.31 19.585 6.404 22.125H4C3.46957 22.125 2.96086 21.9143 2.58579 21.5392C2.21071 21.1641 2 20.6554 2 20.125V4.125C2 3.59457 2.21071 3.08586 2.58579 2.71079C2.96086 2.33571 3.46957 2.125 4 2.125H28C28.5304 2.125 29.0391 2.33571 29.4142 2.71079C29.7893 3.08586 30 3.59457 30 4.125V20.125C30 20.6554 29.7893 21.1641 29.4142 21.5392C29.0391 21.9143 28.5304 22.125 28 22.125H25.596Z" fill=""/>
    </svg>
  )
}

export default IPerson