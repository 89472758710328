import React, {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import './CreateDemandModal.css'
import ServiceDetails from "./steps/ServiceDetails/ServiceDetails";
import ServiceRequester from "./steps/ServiceRequester/ServiceRequester";
import ServiceProvider from "./steps/ServiceProvider/ServiceProvider";
import ServiceTypeOption from "./steps/ServiceTypeOption/ServiceTypeOption";
import useAlertStack from "ui-components/lib/hooks/useAlertStack";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {servicesQueries} from "../../graphql/queries";
import {demandsMutations} from "../../graphql/mutations";
import {CustomSpinner} from "../index";
import {Card, Container, Row} from "react-bootstrap";

const CreateDemandModal = (props) => {
    const {openModal, handleClose} = props
    const [step, setStep] = useState(0);
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState([]);
    const [finalDate, setFinalDate] = useState("");
    const [clientId, setClientId] = useState("");
    const [lawyerId, setLawyerId] = useState("");


    const [subservice, setSubservice] = useState({});
    const [subserviceQuestion, setSubserviceQuestion] = useState({})

    const handleNextStep = (step) => {
        setStep(step)
    }

    const closeModal = () => {
        setStep(0);
        setSubject("")
        setDescription("")
        setFile([]);
        setFinalDate("");
        setClientId("");
        setLawyerId("");
        handleClose()
    }

    const {showMessage} = useAlertStack()
    const [searchSubservice, {loading: loadingSearch}] = useLazyQuery(servicesQueries.SEARCH_SUBSERVICE, {
        onCompleted(data) {
            setSubservice(data.searchSubservice[0])

        },
    })

    const [getQuestions, {loading: subservicesQuestionsLoading}] = useLazyQuery(servicesQueries.GET_SUBSERVICES_QUESTIONS, {
        onCompleted(data) {
            setSubserviceQuestion(data.subServicesQuestions)
        }
    })


    useEffect(() => {
        searchSubservice({
            variables: {search: 'Atendimento terceirizado'},
        })

        if (subservice !== {}) {
            getQuestions({
                variables: {subserviceId: subservice?._id}
            })
        }

    }, [searchSubservice, subservice, getQuestions])


    const [createDemand, {loading: loadingCreateDemand}] = useMutation((demandsMutations.CREATE_DEMAND), {
        onCompleted(data) {
            console.log(data)
            showMessage({
                title: 'Demanda criada',
                message: 'Nova demanda criada com sucesso.',
                color: 'success',
                position: 'bottom-left',
                time: 4000,
            })
            const demandId = data.createDemands._id
            if (clientId !== '') {
                window.location = '/dashboard/serviços/detalhes/' + demandId
            }
        },
        onError() {
            showMessage({
                title: 'Erro ao criar nova demanda',
                message: 'Tente novamente mais tarde.',
                color: 'danger',
                position: 'bottom-left',
                time: 4000,
            })
        }
    })

    const createNewDemand = () => {
        let answers = [
            'Assunto:' + subject,
            'Descrição: ' + description,
        ]

        let questionWithAnswers = subserviceQuestion.map((question, counter) => {
            return {
                "questionId": question._id,
                "attachments": [file[0]],
                "answer": answers[counter]
            }
        })

        let createObject = {
            "serviceId": subservice?.service?._id,
            "subserviceId": subservice?._id,
            "urgent": false,
            "bicolumn": false,
            "extraLanguage": false,
            "draft": false,
            "answers": questionWithAnswers,
            "clientId": clientId === 'Eu'?undefined:clientId,
            "lawyerId": lawyerId,
            "finalDate": finalDate
        }
        createDemand({
            variables: createObject
        })
    }


    function showStep() {
        switch (step) {
            case 0:
                return <ServiceTypeOption nextStep={handleNextStep}/>
            case 1:
                return <ServiceDetails
                    nextStep={handleNextStep}
                    subject={handleSubject}
                    description={handleDescription}
                    file={handleFile}
                    finalDate={handleFinalDate}
                />
            case 2:
                return <ServiceRequester nextStep={handleNextStep} requester={handleClientId}/>
            case 3:
                return <ServiceProvider nextStep={handleNextStep} provider={handleLawyerId}
                                        createDemand={createNewDemand}/>
            default:
                return <ServiceTypeOption nextStep={handleNextStep}/>
        }
    }

    function handleSubject(subject) {
        setSubject(subject)
    }

    function handleDescription(description) {
        setDescription(description)
    }

    function handleFile(file) {
        setFile(file)
    }

    function handleFinalDate(finalDate) {
        setFinalDate(finalDate)
    }

    function handleClientId(clientId) {
        setClientId(clientId)
    }

    function handleLawyerId(lawyerId) {
        setLawyerId(lawyerId)
    }

    return (
        <Modal
            open={openModal}
            onClose={() => closeModal()}
            disableAutoFocus
            sx={{zIndex: '9999'}}
            id={"create-demand-modal"}
        >
            {loadingCreateDemand || loadingSearch || subservicesQuestionsLoading ?
                (
                    <Container id={"service-requester"}>
                        <Card id={"loader"}>
                            <Card.Body>
                                <Row>
                                    <CustomSpinner/>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>
                ) : showStep()
            }
        </Modal>
    )
}

export default CreateDemandModal
