import React from "react";
import './ServiceProvider.css'
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useQuery} from "@apollo/react-hooks";
import {clientsQueries} from "../../../../graphql/queries";
import {CustomSpinner} from "../../../index";

const ServiceProvider = (props) => {
  const {nextStep, provider, createDemand} = props;
  const { data: dataAvailableLawyers, loading } = useQuery(clientsQueries.GET_AVAILABLE_LAWYERS)

  if(loading){
    return (
      <Container id={"service-provider"}>
        <Card id={"loader"}>
          <Card.Body>
            <Row>
              <CustomSpinner />
            </Row>
          </Card.Body>
        </Card>
      </Container>
    )
  }

  return (
    <Container id={"service-provider"}>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h3>
                <strong>Quem será o responsável?<br/>(demandado)</strong>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <label htmlFor="subject-id"><strong>Responsável</strong></label>
              <Form.Select id="subject-id" onChange={(e)=>provider(e.target.value)}>
                <option disabled selected>Selecione</option>
                {
                  dataAvailableLawyers?.getAvailableLawyers.filter(user => user.homologated === true).map((user,counter)=>{
                    return (
                      <option key={counter} value={user._id}>{user.name} ({user.email})</option>
                    )
                  })
                }
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <div id="service-details-options-buttons">
              <Row>
                <Col>
                  <Button variant="outline-primary" onClick={()=>nextStep(2)}>
                    Voltar
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" onClick={()=>createDemand()}>
                    Criar demanda
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
          <Row>
            <Col>
              <strong>OBS: para cadastrar novos responsáveis, <a href='https://adv.bonuz.it/' target='_blank' rel='noopener noreferrer'>clique aqui!</a></strong>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default ServiceProvider
