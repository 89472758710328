import React from 'react'
import styled from 'styled-components'
import Spinner from 'ui-components/lib/components/Spinner'
import Button from 'ui-components/lib/components/Button'
import { useHistory } from 'react-router-dom'
import { Flex } from 'reflexbox'

const Form = styled.form`
  position: relative;
`

const PaceWrapper = styled.div`
  position: relative;
`

const PaceLoading = styled.div`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CrudForm = ({ children, loading = false, ...rest }) => {
  const history = useHistory()
  return (
    <Form {...rest}>
      <PaceWrapper>
        {!!loading && (
          <PaceLoading>
            <Spinner color={'primary'} />
          </PaceLoading>
        )}
        {children}
      </PaceWrapper>
      <Flex flexWrap={'wrap'} justifyContent={'flex-end'}>
        <Button ml={[1, 4]} px={[3, 4]} onClick={() => history.push('/dashboard/especialidades')}>
          Voltar
        </Button>
        <Button ml={[1, 4]} px={[3, 4]} loading={loading} color={'primary'} type={'submit'}>
          Adicionar
        </Button>
      </Flex>
    </Form>
  )
}

export default CrudForm
