import React, {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import "./ServicesBalloons.css";
import estabilished from "../../images/icons/established.png";
import ideation from "../../images/icons/ideation.png";
import mvp  from "../../images/icons/Mvp.png";
import scale from "../../images/icons/scale.png";
import traction from "../../images/icons/traction.png"
import {useQuery} from "@apollo/react-hooks";
import {roadmapQueries} from "../../graphql/queries";
import {Loading} from "../index";

const ServicesBalloons = props =>{
  //const [openServiceBaloon, setOpenServiceBaloon] = useState('')
  const [roadmap,setRoadmap] = useState({
    selectedRoadmapFirstStepServices: [],
    selectedRoadmapSecondStepServices: [],
    selectedRoadmapThirdStepServices: [],
    selectedRoadmapFourthStepServices: [],
    selectedRoadmapFifthStepServices: []
  })

  const { data: dataRoadmap,loading } = useQuery(roadmapQueries.GET_ROADMAP)

  useEffect(()=>{
    if(dataRoadmap!== undefined){
      setRoadmap(dataRoadmap?.getRoadmap)
    }
  },[dataRoadmap])
  
  const baloons = [
    {
      icon:<img alt={'Ideação'} className='Mvp' src={ideation} style={{height:'60px', width:'60px'}}/>,
      title: "1.Fase de Ideação",
      lines: roadmap.selectedRoadmapFirstStepServices
    },
    {
      icon:<img alt={'mvp'} className='Mvp' src={mvp} style={{height:'60px', width:'60px'}}/>,
      title: "2.Fase MVP",
      lines: roadmap.selectedRoadmapSecondStepServices
    },
    {
      icon:<img alt={'tração'} className='Mvp' src={traction} style={{height:'60px', width:'60px'}}/>,
      title: "3.Fase de tração",
      lines: roadmap.selectedRoadmapThirdStepServices
    },
    {
      icon:<img alt={'Escala'} className='Mvp' src={scale} style={{height:'60px', width:'60px'}}/>,
      title: "4.Fase de escala",
      lines: roadmap.selectedRoadmapFourthStepServices
    },
    {
      icon:<img alt={'estabelecido'} className='Mvp' src={estabilished} style={{height:'60px', width:'60px'}}/>,
      title: "5.Negócio estabelecido",
      lines: roadmap.selectedRoadmapFifthStepServices
    }
  ]

  if (loading) {
    return <Loading />
  }

  if(!props.balloonsOpen){
    return ''
  }

  return (
    <Box className="servicesBallons-wrapper">
      {baloons?.map((balloon, index) => (
          <>
            <Box className='servicesBallons-container'>
              <div className='balloon-icon'>{balloon.icon}</div>
              <h4>{balloon.title}</h4>
              {balloon.lines.map((balloonItem, balloonIndex) => (
                <div key={balloonIndex} className='servicesBalloons-container-balloons-items'>
                  <span className="arrow-icon">&raquo;</span>
                  <button
                    onClick={() => props.handleSelect(balloonItem?.serviceId, balloonItem?.serviceType, balloonItem?.subserviceId)}
                  >
                    {balloonItem?.subserviceName || ''}
                  </button>
                </div>
              ))}
               {/*<img*/}
               {/*   src={minimize}*/}
               {/*   alt="Minimize Icon"*/}
               {/*   className="minimize-icon"*/}
               {/*   onClick={() => handleServiceBaloon('')}*/}
               {/* />*/}
            </Box>
          </>
        ))}
    </Box>
  )
}

export default ServicesBalloons
