import gql from 'graphql-tag'

export const GET_CHAT_MESSAGES = gql`
  query getChatMessages($demandId: String, $chatId: String, $subscription: String) {
    getChatMessages(demandId: $demandId, chatId: $chatId, subscription: $subscription) {
      _id
      chatId
      message
      files {
        _id
        name
        size
        extension
        fileURL
        mimeType
        createdAt
        clientName
        lawyerName
      }
      sender
      createdAt
      created_at
    }
  }
`
