import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../graphql/mutations'
import { demandsQueries, walletQueries } from '../../../graphql/queries'
import Modal from '@mui/material/Modal'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import { ChipStatusVariants, Loading, PurchaseBNZ } from '../../../components'
import './DemandDetailsV3.css'
import './DemandDetails.css'
import ChatDemand from '../../../components/ChatDemand'
import RefuseModal from '../../../components/Modal/Proposals/RefuseModal'
import AcceptModal from '../../../components/Modal/Proposals/AcceptModal'
import ConfirmationModal from '../../../components/Modal/ConfirmationModal'
import { Col, Row, Popover, OverlayTrigger, Dropdown } from 'react-bootstrap'
import Switch from '@mui/material/Switch'
import Dropzone from '../../../components/Dropzone'
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { Flex } from 'reflexbox'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faInbox, faInfoCircle, faPen, faEllipsisV, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-regular-svg-icons'
import ChatDemandFixedV3 from '../../../components/ChatDemandV3/ChatDemandFixedV3'
import IconButton from '@mui/material/IconButton';
import RatingModal from '../../../components/Modal/RatingModal';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

alertify.set("notifier", "position", "top-right");

const DemandDetailsV3 = () => {
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const { push } = useHistory()
  const timestampToDate = require('timestamp-to-date')
  const [openChatModal, setOpenChatModal] = useState(false)
  const [isProposal, setIsProposal] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [acceptDemandBidId, setAcceptDemandBidId] = useState('')
  const [refuseDemandBidId, setRefuseDemandBidId] = useState('')
  const [demandId, setDemandId] = useState('')
  const [modalType, setModalType] = useState('')
  const [openRefuseModal, setOpenRefuseModal] = useState(false)
  const [openAcceptModal, setOpenAcceptModal] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [servicePrice, setServicePrice] = useState('')
  const [clientSubscription, setClientSubscription] = useState('')
  const [documentPending, setDocumentsPending] = useState([])
  const [documentWaiting, setDocumentsWaiting] = useState([])
  const [documentDone, setDocumentsDone] = useState([])
  const [documentPendingApprove, setDocumentPendingApprove] = useState([])
  const [documentsSending, setDocumentsSending] = useState([])
  const [requests, setRequests] = useState([])
  const [showAllStatus, setShowAllStatus] = useState(false)
  const [statusList, setStatusList] = useState([])
  const [stepActual, setStepActual] = useState('default')
  const [showRating, setShowRating] = useState(false)

  useEffect(() => {
    if (location.search.slice(1) === 'proposal') setIsProposal(true)
  }, [location])

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal',
    },
  })

  const { loading: demandLoading, data: demandData } = useQuery(demandsQueries.GET_DEMAND, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { refetch: refetchDemands } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'in_estimating',
    },
  })

  const { data: walletData, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)

  useQuery(demandsQueries.GET_DEMAND_REQUESTS, {
    variables: {
      id,
    },
    onCompleted(response) {
      setRequests(response.getRequest);
    }
  })

  useEffect(() => {
    const items = requests?.filter((item) => item.status !== 'refused')
    setDocumentsPending(
      items
        ?.flatMap((item) => item.documents.map((reg) => ({ ...reg, requestStatus: item.status, requestDebitBNZ: item.debitBNZ, demandId: item.demandId })))
        .filter((item) => ['pending', 'refused'].includes(item.status))
    )
    setDocumentsWaiting(
      items
        ?.flatMap((item) => item.documents.map((reg) => ({ ...reg, requestStatus: item.status, requestDebitBNZ: item.debitBNZ, demandId: item.demandId })))
        .filter((item) => item.status === 'waiting_lawyer_acceptance')
    )
    setDocumentsDone(
      items
        ?.flatMap((item) => item.documents.map((reg) => ({ ...reg, requestStatus: item.status, requestDebitBNZ: item.debitBNZ, demandId: item.demandId })))
        .filter((item) => item.status === 'done')
    )
    const documents = items?.filter((item) => item.status === 'pending' && item.debitBNZ)
    let pendingApprove = null
    if (documents?.length > 0) {
      pendingApprove = documents[0]
    }
    setDocumentPendingApprove(pendingApprove)
  }, [requests])

  const [reproveDemandBid, { loading: reproveDemandBidLoading }] = useMutation(demandsMutations.REPROVE_DEMAND_BID, {
    variables: {
      bidId: refuseDemandBidId,
    },
    onCompleted() {
      setOpenRefuseModal(false)
      refetchDemands()
      push('/dashboard/serviços/propostas')
    },
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      setOpenRefuseModal(false)
      toast.error('Erro ao reprovar a proposta, tente novamente mais tarde', {
        theme: 'dark'
      })
    },
  })

  const [approveDemandBid, { loading: approveDemandBidLoading }] = useMutation(demandsMutations.APPROVE_DEMAND_BID, {
    variables: {
      bidId: acceptDemandBidId,
    },
    onCompleted() {
      setOpenAcceptModal(false)
      refetchDemands()
      push('/dashboard/serviços/propostas')
    },
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      setOpenAcceptModal(false)
      toast.error('Erro ao aceitar a proposta, tente novamente mais tarde', {
        theme: 'dark'
      })
    },
  })

  const [approveRequest, { loading: loadingApproveRequest }] = useMutation(demandsMutations.APPROVE_REQUEST, {
    onCompleted() {
      toast.success('Sua aprovação foi enviada com sucesso.', {
        theme: 'dark'
      })
    },
    onError({ graphQLErrors }){
      toast.error(graphQLErrors[0].extensions.response.body.message || 'tente novamente novamente mais tarde', {
        theme: 'dark'
      })
    }
  })

  const [refuseRequest, { loading: loadingRefuseRequest }] = useMutation(demandsMutations.REFUSE_REQUEST, {
    onCompleted() {
      toast.success('Sua recusa foi enviada com sucesso.', {
        theme: 'dark'
      })
    },
    onError({ graphQLErrors }){
      toast.error(graphQLErrors[0].extensions.response.body || 'tente novamente novamente mais tarde', {
        theme: 'dark'
      })
    }
  })

  const handleRefuseRequest = async (request) => {
    alertify.confirm('Confirmação', 'Deseja realmente recusar a solicitação de documentos?', async () => {
      try {
        await refuseRequest({
          variables: {
            demandId: request.demandId,
            requestId: request._id
          }
        })
        const updatedRequests = requests.map(item => {
          if (item._id === request._id) {
            item.status = 'refused'
          }
          return item
        })
        setRequests(updatedRequests)
      } catch (error) {
      }
    }, null).set({labels:{ok:'Sim', cancel: 'Não'}})
  }

  const handleApproveRequest = async (request) => {
    alertify.confirm('Confirmação', 'Deseja realmente aprovar a solicitação de documentos?', async () => {
      try {
        const approve = await approveRequest({
          variables: {
            demandId: request.demandId,
            requestId: request._id
          }
        })
        if (approve) {
          const updatedRequests = requests.map(item => {
            if (item._id === request._id) {
              item.status = 'approved'
            }
            return item
          })
          setRequests(updatedRequests)
        }
      } catch (error) {
      }
    }, null).set({labels:{ok:'Sim', cancel: 'Não'}})
  }

  const handleRefuseDemandBid = useCallback(() => {
    reproveDemandBid()
  }, [reproveDemandBid])

  const handleAcceptDemandBid = useCallback(() => {
    approveDemandBid()
  }, [approveDemandBid])

  const handleOpenAcceptModal = useCallback((id) => {
    setAcceptDemandBidId(id)
    setOpenAcceptModal(!openAcceptModal)
  }, []) //eslint-disable-line

  const handleOpenRefuseModal = useCallback((id) => {
    setRefuseDemandBidId(id)
    setOpenRefuseModal(!openRefuseModal)
  }, []) //eslint-disable-line

  const handleConfirmationModal = useCallback((type) => {
    setDemandId(id)
    setModalType(type)
    setOpenConfirmationModal(!openConfirmationModal)
  }, []) //eslint-disable-line

  const handleChatModal = () => {
    setOpenChatModal(!openChatModal)
  }

  const openModal = useCallback((price) => {
    setServicePrice(price)
    setModalIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    refetchMyWallet()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (dataClientSubscription) {
      setClientSubscription(dataClientSubscription.subscription)
    }
  }, [dataClientSubscription]) //eslint-disable-line

  const [sendFileRequestDocument] = useMutation(demandsMutations.SEND_FILE_REQUEST_DOCUMENT, {
    onCompleted() {
      toast.success('Seu arquivo foi enviada com sucesso.', {
        theme: 'dark'
      })
    },
    onError() {
      toast.error('Houve um erro ao tentar enviar seu arquivo. Tente novamente', {
        theme: 'dark'
      })
    },
  })

  const setUploadDocument = async (document, isLoading) => {
    if (isLoading) {
      setDocumentsSending((prevDocuments) => [...prevDocuments, document._id])
    }
  }

  const setFilesHandler = async (sendFiles, document) => {
    const variables = {
      demandId: document?.demandId,
      requestId: document?.requestId,
      documentId: document?._id,
      files: sendFiles.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
        attachment: '',
        name,
        randomFileName,
        mimeType,
        extension,
        size,
      }))
    }
    try {
      const { data: { sendFileRequestDocument: { files } } = {} } = await sendFileRequestDocument({
        variables
      })
      document.status = 'waiting_lawyer_acceptance'
      document.filesDelivery = files

      const updatedRequests = requests.map(request => {
        request.documents = request.documents.map(item => item._id === document._id ? document : item)
        return request;
      });
      setRequests(updatedRequests)
    } catch (error) {
    } finally {
      setDocumentsSending((prevDocuments) => prevDocuments.filter((item) => item !== document._id))
    }
  }

  const handleCloseRatingModal = () => {
    setShowRating(false)
    push('/dashboard/serviços')
  }

  useEffect(() => {
    let itens = demandData?.demand?.demandInteractionsHistory?.filter(item => item.status);
    if (!showAllStatus && itens && itens.length > 0) {
      itens = itens.slice(0,2);
    }
    setStatusList(itens);
  }, [showAllStatus, demandData]);

  if (demandLoading) return <Loading />

  const navigationLinks = [
    { label: 'Início', onClick: () => history.push('/dashboard') },
    { label: 'Serviços', onClick: () => history.push('/dashboard/serviços') },
    { label: 'Demandas', onClick: () => history.push('/dashboard/serviços/demands') },
    { label: 'Demanda' }
  ]

  return (
    <>
      <Flex width={1} justifyContent="center" flexDirection="column" className="service">
        <Flex width={1} mb={3} className="breadCrumb">
          <BreadCrumb ml={-3} navigationLinks={navigationLinks} />
        </Flex>
      </Flex>
      <div style={{paddingLeft: '2.5%', paddingRight: '2.5%'}}>
        <div className="title">
          <h3>
            <strong>{demandData?.demand?.fullDescription}</strong>
          </h3>
        </div>
        <div id={'demand-info-main-container'}>
          <table>
            <thead>
              <tr>
                <th>Prazo final</th>
                <th>Responsável</th>
                <th>Status</th>
                <th>Participantes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{demandData?.demand?.finalDate ? timestampToDate(demandData?.demand.finalDate, 'dd/MM/yyyy') : '-'}</td>
                <td>{demandData?.demand?.lawyer?.displayAs || '-'}</td>
                <td><ChipStatusVariants status={demandData?.demand.status} noStyle={true} /></td>
                <td> - </td>
              </tr>
            </tbody>
          </table>
          <div class="actions">
            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
              <Popover.Body>
                Estapas
              </Popover.Body>
            </Popover>}>
              <div class="icon" style={{cursor: 'pointer'}} onClick={() => setStepActual('default')}>
                <FontAwesomeIcon width={18} color='#4E4B59' icon={faChartLine} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
              <Popover.Body>
                Chat
              </Popover.Body>
            </Popover>}>
              <div class="icon" style={{cursor: 'pointer'}} onClick={() => setStepActual('chat')}>
                <FontAwesomeIcon width={18} color='#4E4B59' icon={faCommentDots} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
              <Popover.Body>
                Solicitação de documentos
              </Popover.Body>
            </Popover>}>
              <div class="icon" style={{cursor: 'pointer'}} onClick={() => setStepActual('requests')}>
                <FontAwesomeIcon width={18} color='#4E4B59' icon={faInbox} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
              <Popover.Body>
                Informações da demanda
              </Popover.Body>
            </Popover>}>
              <div class="icon" style={{cursor: 'pointer'}} onClick={() => setStepActual('info')}>
                <FontAwesomeIcon width={18} color='#4E4B59' icon={faInfoCircle} />
              </div>
            </OverlayTrigger>
            <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
              <Popover.Body>
                Assinatura eletrônica (em breve)
              </Popover.Body>
            </Popover>}>
              <div class="icon" style={{cursor: 'pointer'}}>
                <FontAwesomeIcon width={18} color='#4E4B59' icon={faPen} />
              </div>
            </OverlayTrigger>
            <Dropdown>
              <Dropdown.Toggle variant='empty'>
                <FontAwesomeIcon width={18} color='#4E4B59' icon={faEllipsisV} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <h6>Apoio da Bonuz</h6>
                  <Switch checked={true} />
                </Dropdown.Item>
                {demandData?.demand.status === 'waiting_approval' && demandData?.demand.status !== 'in_auction' ? (
                  <>
                    <Dropdown.Item onClick={() => handleConfirmationModal('revisar')}>Revisar</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleConfirmationModal('concluir')}>Concluir</Dropdown.Item>
                  </>
                ) : demandData?.demand.status === 'waiting_estimate_approval' && demandData?.demand.status !== 'in_auction' ? (
                  <>
                    {walletData.wallet.balance >= demandData?.demand?.price ? (
                      <>
                        <Dropdown.Item onClick={() => handleConfirmationModal('recusar')}>Recusar</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleConfirmationModal('aceitar')}>Aceitar</Dropdown.Item>
                      </>
                    ) : (
                      <>
                        <Dropdown.Item><strong>Você não possui saldo {!clientSubscription ? `de BNZs` : ``} suficiente para aprovar essa demanda.</strong></Dropdown.Item>
                        <Dropdown.Item onClick={() => openModal(demandData?.demand?.price)}>{clientSubscription ? 'Pagamento' : 'Comprar BNZ'}</Dropdown.Item>
                      </>
                    )}
                  </>
                ) : demandData?.demand.status === 'pending' && demandData?.demand.status !== 'in_auction' ? (
                  <>
                    <Dropdown.Item onClick={() => handleConfirmationModal('cancelar')}>Cancelar</Dropdown.Item>
                  </>
                ) : isProposal ? (
                  walletData.wallet.balance > demandData?.demand?.demandBids[0]?.price ? (
                    <>
                      <Dropdown.Item onClick={() => handleOpenAcceptModal(demandData?.demand?.demandBids[0]?._id)}>Aceitar</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleOpenRefuseModal(demandData?.demand?.demandBids[0]?._id)}>Recusar</Dropdown.Item>
                    </>
                  ) : (
                    <>
                      <Dropdown.Item><strong>Você não possui saldo {!clientSubscription ? `de BNZs` : ``} suficiente para aprovar essa proposta.</strong></Dropdown.Item>
                      <Dropdown.Item onClick={() => openModal(demandData?.demand?.demandBids[0]?.price)}>{clientSubscription ? 'Pagamento' : 'Comprar BNZ'}</Dropdown.Item>
                    </>
                  )
                ) : (
                  ''
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='divider'></div>
          <Row style={{height: '74%'}}>
            {stepActual === 'requests' && (
              <Col xs={5}>
                <div className={`demand-requests ${documentPending?.length === 0 && documentWaiting?.length === 0 && documentDone?.length === 0 ? 'empty' : ''}`}>
                  {documentPending.length > 0 && (
                    <>
                      <div className="demand-request-title">Documentos pendentes</div>
                      {documentPendingApprove && (
                        <>
                          <div style={{marginTop: '10px'}}>
                            <Col xs={12}>
                              <span className="debit-add">Débito adicional</span>
                            </Col>
                          </div>
                          <div style={{width: '100%', display: 'flex'}}>
                            <div style={{width: '60%'}}>
                              <span className="value-bnz">{documentPendingApprove.valueBNZ}BNZs</span>
                            </div>
                            <div style={{width: '20%'}}>
                              <button
                                type="button"
                                className="btn" 
                                style={{ backgroundColor: '#9997A0', color: '#FFFFFF' }}
                                onClick={() => handleRefuseRequest(documentPendingApprove)}
                                disabled={loadingRefuseRequest || loadingApproveRequest}
                              >
                                {loadingRefuseRequest || loadingApproveRequest ? 
                                  (<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)
                                : ('Recusar')}
                              </button>
                            </div>
                            <div style={{width: '20%'}}>
                              <button
                                type="button"
                                className="btn"
                                style={{ backgroundColor: '#49A96A', color: '#FFFFFF' }}
                                onClick={() => handleApproveRequest(documentPendingApprove)}
                                disabled={loadingRefuseRequest || loadingApproveRequest}
                              >
                                {loadingRefuseRequest || loadingApproveRequest ? 
                                  (<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>)
                                : ('Aprovar')}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="demand-request-pending-documents">
                        {documentPending.map((item) => (
                          <div className={`demand-request-document ${item.status === 'refused' ? 'demand-reproved' : ''}`}>
                            <div className="description">{item.description}</div>
                            {!documentPendingApprove && item.status === 'refused' && (
                              <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
                                <Popover.Body>
                                  {item.comment}
                                </Popover.Body>
                              </Popover>}>
                                <IconButton>
                                  <FontAwesomeIcon icon={faInfoCircle} color='#F14F5C' />
                                </IconButton>
                              </OverlayTrigger>
                            )}
                            {item.filesRequest.length > 0 && (
                              <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
                                <Popover.Body>
                                  Baixar modelo
                                </Popover.Body>
                              </Popover>}>
                                <IconButton
                                  onClick={() => window.open(item.filesRequest[0].fileURL, '_blank')}
                                >
                                  <FontAwesomeIcon icon={faDownload} color={item.status === 'refused' ? '#F14F5C' : null} />
                                </IconButton>
                              </OverlayTrigger>
                            )}
                            {!documentPendingApprove && (
                               <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
                                <Popover.Body>
                                  Enviar documento
                                </Popover.Body>
                              </Popover>}>
                                <IconButton
                                  onClick={(e) => {
                                    e.preventDefault()
                                    let fileHandler = document.getElementById(item._id)
                                    fileHandler.click()
                                  }}
                                  disabled={
                                    (documentsSending && documentsSending?.includes(item._id)) ||
                                    (item.reqjuestDebitBNZ && item.requestStatus === 'pending')
                                  }
                                >
                                  {documentsSending && documentsSending?.includes(item._id) ? (
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  ) : (
                                    <>
                                      <FontAwesomeIcon icon={faUpload} color={item.status === 'refused' ? '#F14F5C' : null} />
                                    </>
                                  )}
                                </IconButton>
                              </OverlayTrigger>
                            )}
                            <Dropzone
                              multiple={false}
                              onLoad={(isLoading) => setUploadDocument(item, isLoading)}
                              id={item._id}
                              onComplete={(file) => setFilesHandler(file, item)}
                              classe="dropzone-hide"
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {documentWaiting.length > 0 && (
                    <>
                      <div className="demand-request-title">Documentos aguardando aprovação</div>
                      <div className="demand-request-waiting-documents">
                      {documentWaiting.map((item) => (
                        <div className='demand-request-document'>
                          <div className="description">{item.description}</div>
                            {item.filesRequest.length > 0 && (
                              <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
                                <Popover.Body>
                                  Baixar modelo
                                </Popover.Body>
                              </Popover>}>
                                <IconButton
                                  onClick={() => window.open(item.filesRequest[0].fileURL, '_blank')}
                                >
                                  <FontAwesomeIcon icon={faDownload} color='#F8AE3F' />
                                </IconButton>
                              </OverlayTrigger>
                            )}
                            {item?.filesDelivery && item?.filesDelivery?.length > 0 && (
                              <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
                                <Popover.Body>
                                  Baixar documento enviado
                                </Popover.Body>
                              </Popover>}>
                                <IconButton
                                  onClick={() => window.open(item.filesDelivery[0].fileURL, '_blank')}
                                >
                                  <FontAwesomeIcon icon={faDownload} color='#F8AE3F' />
                                </IconButton>
                              </OverlayTrigger>
                            )}
                        </div>
                      ))}
                      </div>
                    </>
                  )}
                  {documentDone.length > 0 && (
                    <>
                      <div className="demand-request-title">Documentos concluídos</div>
                      <div className="demand-request-done-documents">
                        {documentDone.map((item) => (
                          <div className='demand-request-document'>
                            <div className="description">{item.description}</div>
                              {item.filesRequest.length > 0 && (
                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
                                  <Popover.Body>
                                    Baixar modelo
                                  </Popover.Body>
                                </Popover>}>
                                  <IconButton
                                    onClick={() => window.open(item.filesRequest[0].fileURL, '_blank')}
                                  >
                                    <FontAwesomeIcon icon={faDownload} color='#44E47A' />
                                  </IconButton>
                                </OverlayTrigger>
                              )}
                              {item?.filesDelivery && item?.filesDelivery?.length > 0 && (
                                <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={<Popover>
                                  <Popover.Body>
                                    Baixar documento enviado
                                  </Popover.Body>
                                </Popover>}>
                                  <IconButton
                                    onClick={() => window.open(item.filesDelivery[0].fileURL, '_blank')}
                                  >
                                    <FontAwesomeIcon icon={faDownload} color='#44E47A' />
                                  </IconButton>
                                </OverlayTrigger>
                              )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {documentPending?.length === 0 && documentWaiting?.length === 0 && documentDone?.length === 0 && (
                    <div style={{textAlign: 'center', height: '100%', verticalAlign: 'middle'}}>Nenhuma solicitação encontrada</div>
                  )}
                </div>
              </Col>
            )}
            {stepActual === 'info' && (
              <Col xs={5}>
                <div className='demand-info'>
                  <div className="demand-obs">
                    <h4>Observações/Comentário</h4>
                    <p>
                      {demandData?.demand?.demandInteractionsHistory[0]?.comment !== undefined
                        && (demandData?.demand?.demandInteractionsHistory[0]?.comment)
                      }
                    </p>
                  </div>
                  <div className="demand-awnsers">
                    <h4>Detalhes da demanda</h4>
                    {demandData?.demand?.demandAnswers.length > 0 ? (
                      demandData?.demand?.demandAnswers.map((answer, counter) => {
                        return (
                          <div>
                            <p>
                              <b>{answer.description}</b>
                            </p>
                            <p>
                              Resposta: <b>{answer.answer}</b>
                            </p>
                            <br />
                          </div>
                        )
                      })
                    ) : (
                      <p>Não há questionário preenchido</p>
                    )}
                  </div>
                </div>
              </Col>
            )}
            {stepActual === 'default' && (
              <Col xs={5}>
                <div className={`list-status ${statusList?.length === 0 ? 'empty' : ''}`}>
                  {statusList?.length > 0 && statusList?.map((interaction, counter) => {
                    return (
                      <div className="status-container">
                        <div className={counter === 0 ? 'circle' : 'circle-old'}></div>
                        <div className="line"></div>
                        <div className="data">{timestampToDate(interaction.createdAt, 'dd/MM/yyyy')}</div>
                        <div className="text"><ChipStatusVariants noStyle={true} status={interaction.status} style={{ marginRight: '1px' }} /></div>
                      </div>
                    )
                  })}
                  {statusList?.length === 0 && (
                    <div style={{textAlign: 'center', height: '100%', verticalAlign: 'middle'}}>Nenhum movimento encontrado</div>
                  )}
                  {demandData?.demand?.demandInteractionsHistory.length > 2 && (<div className='more' onClick={() => setShowAllStatus(!showAllStatus)}>ver {showAllStatus ? 'menos' : 'mais'}</div>)}
                </div>
              </Col>
            )}
            <Col xs={stepActual === 'chat' ? 12 : 7} style={{paddingLeft: '15px'}}>
              <div className='chat'>
                <ChatDemandFixedV3
                  demandId={id}
                  demandLawyer={demandData?.demand?.lawyer || ''}
                />
              </div>
            </Col>
          </Row>
        </div>
        <ChatDemand handleClose={handleChatModal} openModal={openChatModal} demandId={id} demandLawyer={demandData?.demand?.lawyer || ''} />
        <AcceptModal
          openModal={openAcceptModal}
          handleClose={() => setOpenAcceptModal(false)}
          action={handleAcceptDemandBid}
          loading={approveDemandBidLoading}
        />
        <RefuseModal
          openModal={openRefuseModal}
          handleClose={() => setOpenRefuseModal(false)}
          action={handleRefuseDemandBid}
          loading={reproveDemandBidLoading}
        />
        <Modal
          open={modalIsOpen}
          onClose={() => {
            closeModal()
          }}
          disableAutoFocus
          sx={{ zIndex: '9999' }}
        >
          <PurchaseBNZ
            closeParentModal={() => {
              closeModal()
            }}
            isJp={clientSubscription || false}
            servicePrice={servicePrice}
          />
        </Modal>
        <ConfirmationModal
          openModal={openConfirmationModal}
          handleClose={() => setOpenConfirmationModal(false) }
          type={modalType}
          id={demandId}
          openRating={() => { console.log('foi no rating'); setShowRating(true); }}
        />
        <RatingModal
          openModal={showRating}
          handleClose={() => handleCloseRatingModal()}
          demandId={id}
          lawyerId={demandData?.demand?.lawyer?._id}
          lawyerName={demandData?.demand?.lawyer?.displayAs}
        />
      </div>
    </>
  )
}

export default DemandDetailsV3
