import gql from 'graphql-tag'

export const GET_ROADMAP = gql`
  query getRoadmap {
    getRoadmap {
      selectedRoadmapFirstStepServices {
        serviceId
        serviceType
        subserviceId
        subserviceName
      }
      selectedRoadmapSecondStepServices {
        serviceId
        serviceType
        subserviceId
        subserviceName
      }
      selectedRoadmapThirdStepServices {
        serviceId
        serviceType
        subserviceId
        subserviceName
      }
      selectedRoadmapFourthStepServices {
        serviceId
        serviceType
        subserviceId
        subserviceName
      }
      selectedRoadmapFifthStepServices {
        serviceId
        serviceType
        subserviceId
        subserviceName
      }
    }
  }
`
