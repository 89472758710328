import React, { useState } from 'react'
import { Modal, Box } from "@mui/material";
import './style.css'
import InfoDemand from '../InfoDemand'
import { useQuery } from '@apollo/react-hooks'
import { walletQueries } from '../../../graphql/queries'



const CardInfoModal = (props) => {

    const {
        openModal,
        handleClose,
        demand
    } = props;

    const [openInfoDemand, setOpenInfoDemand] = useState(false);
    const [demandId, setDemandId] = useState(0);

    const handleSetDemand = (id)=>{
        setDemandId(id);
        setOpenInfoDemand(true);
    }

  const { data: dataClientSubscription} = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })

    return(
      <>
        <InfoDemand
          openModal={openInfoDemand}
          handleClose={()=>setOpenInfoDemand(false)}
          id={demandId}
          isJp={dataClientSubscription !== undefined}
        />
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="card-info"
        >
            <Box className="box md-size-2" style={{cursor: "pointer"}}>
                <div className="d-flex align-items-center">
                    <h3>{demand?.title}</h3>
                    <div className={`icon ${demand?.icon}`}></div>
                </div>
                <table>
                    <thead>
                        <th>Número</th>
                        <th>Demanda</th>
                    </thead>
                    <tbody>
                        {demand?.comments && demand?.comments.length > 0 ?
                            demand.comments.map((comment, count) => {
                                count = count + 1;
                                return(
                                    <tr>
                                        <td width="100px" onClick={()=>handleSetDemand(comment.id)}>
                                          {`000${comment.code}`}
                                        </td>
                                        <td onClick={()=>handleSetDemand(comment.id)}>
                                          {comment.text}
                                        </td>

                                    </tr>
                                )
                            })
                        : <p>Sem demandas</p>}
                    </tbody>
                </table>
                
            </Box>
        </Modal>
      </>
    );
};

export default CardInfoModal;
