import React, { useState } from 'react'
import { Modal, Box } from "@mui/material";
import "./style.css";
import ChipStatus from "../../ChipStatusVariants/index";
import { ReactComponent as Anexar } from "../../../assets/icons/anexar.svg";
import { ReactComponent as Chat } from "../../../assets/icons/chat.svg";
import ConfirmationModal from '../ConfirmationModal/index'
import { useQuery } from '@apollo/react-hooks'
import { demandsQueries } from '../../../graphql/queries'
import { Loading } from '../../index'
import ChatDemand from '../../ChatDemand'

const InfoDemand = (props) => {



    const [showService, setShowService] = useState(true);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [modalType, setModalType] = useState(null)
    const timestampToDate = require('timestamp-to-date');
    const [openChatModal, setOpenChatModal] = useState(false);


    const {
        openModal,
        handleClose,
        id
    } = props;

  const { loading: demandLoading, data: demandData} = useQuery(demandsQueries.GET_DEMAND, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleConfirmationModal = (type) => {
        setModalType(type)
        setOpenConfirmation(!openConfirmation)
    }

    if(demandLoading) return (
      <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="info-demand"
      >
        <Box className="box md-size-5">
          <Loading />
        </Box>
      </Modal>
      </>
    )


    return(
        <>
            <ConfirmationModal
                openModal={openConfirmation}
                handleClose={handleConfirmationModal}
                type={modalType}
                id={id}
            />
            <ChatDemand
              handleClose={()=> setOpenChatModal(false)}
              openModal={openChatModal}
              demandId={id}
              demandLawyer={demandData?.demand?.lawyer || ''}
            />
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="info-demand"
            >
                <Box className="box md-size-5">
                    <div className="d-flex align-items-center">
                        <h4>{demandData?.demand?.fullDescription}</h4>
                        <div className="d-flex align-items-end flex-column">
                            <span className="sm-txt-info">Status</span>
                            <div>
                                <ChipStatus status={demandData?.demand.status}/>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex">
                      {(demandData?.demand.status !== 'in_auction' && demandData?.demand.status !== 'demand_sent' ) && (
                        <div className="d-flex flex-column" style={{marginRight: "15px"}}>
                        <span className="sm-txt-info">data de entrega</span>
                            <div>
                                {/*ARRUMAR*/}
                                <strong>{demandData?.demand?.finalDate? timestampToDate(demandData?.demand.finalDate,'dd/MM/yyyy'):'Não há data informada'}</strong>
                            </div>
                        </div>
                        )}
                        <div className="d-flex flex-column">
                            <span className="sm-txt-info">Advogado responsável</span>
                            <div>
                                <strong>{demandData?.demand?.lawyer?.displayAs}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="sm-txt-info">Observação/Coomentário</span>
                        <p>{demandData?.demand?.demandInteractionsHistory.length > 0 ?demandData?.demand?.demandInteractionsHistory[demandData?.demand?.demandInteractionsHistory.length -1].comment:'Sem interações registradas' }</p>
                    </div>
                    <div className="mt-3">
                        <span className="sm-txt-info">Informações do serviço</span><br/>
                        <span onClick={() => setShowService(!showService)} style={{color: "#7683F6", cursor: "pointer"}}>Detalhes do serviço <i className={showService ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}/></span>
                        <div id='demand-answers-modal'>
                        {showService ? "" :
                            demandData?.demand?.demandAnswers.map((answer, counter)=> {
                              return (
                                <div>
                                  <p>
                                    <b>{answer.description}</b>
                                  </p>
                                  <p>
                                    Resposta: <b>{answer.answer}</b>
                                  </p>
                                  <br/>
                                </div>

                              );
                            })
                        }
                        </div>
                    </div>
                  <div className="dg-modal">
                    {
                      props.isJp ? (
                          <h3>
                            {
                              demandData?.demand?.price !== null
                                ?
                                'R$'+(demandData?.demand?.price * 2)
                                :
                                ''
                            }
                          </h3>
                        )
                        : (
                        <h3>
                      {
                      demandData?.demand?.price !== null
                        ?
                        demandData?.demand?.price+ 'BNZs'
                        :
                        ''
                      }
                      </h3>
                    )}

                    {(
                      demandData?.demand.status === 'waiting_approval') && demandData?.demand.status !== 'in_auction' ?
                      (
                      <div className="d-flex justify-content-between">
                        <button className="btn" style={{backgroundColor: "#44E47A", marginRight: "10px"}} onClick={() => handleConfirmationModal('revisar')}>Revisar</button>
                        <button className="btn" style={{backgroundColor: "#7683F6"}} onClick={() => handleConfirmationModal('concluir')}>Concluir</button>
                      </div>
                      )
                      :
                      (
                        demandData?.demand.status === 'pending') && demandData?.demand.status !== 'in_auction' ?
                        (
                          <div className="d-flex justify-content-between">
                            <button className="btn" style={{backgroundColor: "#44E47A", marginRight: "10px"}} onClick={() => handleConfirmationModal('cancelar')}>Cancelar</button>
                          </div>
                        )
                        :
                        (demandData?.demand.status === 'waiting_estimate_approval')?
                          (
                           <div className="d-flex justify-content-between">
                             <button className="btn" style={{backgroundColor: "#44E47A", marginRight: "10px"}} onClick={() => handleConfirmationModal('recusar')}>Recusar</button>
                             <button className="btn" style={{backgroundColor: "#7683F6"}} onClick={() => handleConfirmationModal('aceitar')}>Aceitar</button>
                           </div>
                          )
                          :
                          ''
                        }
                  </div>
                    <div className="mt-2">
                    <span className="sm-txt-info">Observação/Coomentário</span>
                      <div id='demand-interactions-modal'>
                        <table width="650px">
                            <thead>
                                <th width='10%'>Numero</th>
                                <th width='20%'>Data da interação</th>
                                <th width='50%'>Status</th>
                                <th width='10%'>Anexo</th>
                                <th width='10%'>Fale com Advogado</th>
                            </thead>
                            <tbody>
                          {
                            demandData?.demand?.demandInteractionsHistory.length > 0 ? (
                            demandData?.demand?.demandInteractionsHistory?.filter(item => item.status).map((interaction, counter)=> {
                              return (
                                <tr>
                                  <td>{counter}</td>
                                  <td>{timestampToDate(interaction.createdAt,'dd/MM/yyyy HH:mm:ss')}</td>
                                  <td><ChipStatus status={interaction.status}/></td>
                                  <td>
                                    {
                                      interaction.documents.length > 0
                                        ?
                                        interaction.documents.map((document,counter) => (
                                            <Anexar className="attach-button" onClick={()=>window.open(document.fileURL,'_blank')} />
                                          ))
                                        :
                                        ''
                                  }
                                  </td>
                                  <td><Chat onClick={()=>setOpenChatModal(true)}/></td>
                                </tr>
                              );
                            })
                            ):(
                              <tr>
                                <td>

                                </td>
                                <td>

                                </td>
                                <td>
                                  <p>Sem interações registradas</p>
                                </td>
                              </tr>
                            )
                          }
                            </tbody>
                        </table>
                      </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default InfoDemand;
