import React from 'react'
import {Doughnut,Bar,Line} from 'react-chartjs-2'

const DynamicChart = (props) => {

  let dataConfig = {
          labels: props.labels,
          datasets: [{
            label: props.labelTitle,
            data: props.dataValues,
            backgroundColor: [
              '#E9D3FB',
              '#D6DAFF',
              '#FFF9D7',
              '#D3F4FB',
              '#FFD7F4',
              '#FFE7D6',
              '#D3FBE5',
              '#FBEDD3',
              '#F8D0D0',
              '#D6FBD3',
              '#FFD7F4',
              '#FFD7F4'
            ]
          }]
        };

  let lineDataConfig = {
      labels: props.labels,
      datasets: [{
          label: 'reserva',
          data: props.dataValues[0],
          fill:true,
          borderColor: [
            '#a333c8',
          ],
          borderWidth: 1
        },
        {
          label: 'crédito',
          data: props.dataValues[1],
          fill:true,
          borderColor: [
            '#a333c8',
          ],
          borderWidth: 1
        },
        {
          label: 'débito',
          data: props.dataValues[2],
          fill:true,
          borderColor: [
            '#b8b209'
          ],
          borderWidth: 1
        }]
    };

  let chart;

  if(props.type === 'Dognhut'){
    chart = <Doughnut
      data={dataConfig}
      options={{
        responsive:true
      }}
    />;
  }else if(props.type === 'Bar'){
    chart = <Bar
      data={dataConfig}
      options={{
        responsive: true,
        scales: {
        x: {
        stacked: true,
      },
        y: {
        stacked: true
      }
      }
    }}
    />
  }else if(props.type === 'Line'){
    chart = <Line data={lineDataConfig}/>
  }

  return chart
}

export default DynamicChart;
