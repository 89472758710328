import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, Col } from 'react-bootstrap'
import ClipesIcon from '../../../images/icons/clipes.svg'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/material'
import './Documents.css'
import uploadIcon from '../../../assets/icons/uploadIcon.svg'
import Dropzone from '../../Dropzone'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../graphql/mutations'
import { demandsQueries } from '../../../graphql/queries'
import Spinner from 'ui-components/lib/components/Spinner'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
const Documents = (props) => {

  const {clientId} = props
  const [openDocuments,setOpenDocuments] = useState(false);
  const [fileType,setFileType] = useState("");
  const [files,setFiles] = useState([]);
  const [filesSended,setFilesSended] = useState([]);
  const [sendingLoading,setLoadingSending] = useState(false)

  const { showMessage } = useAlertStack()

  const [checkDataPrivacyClientDocuments] = useMutation((demandsMutations.CHECK_DATAPRIVACY_CLIENT_DOCUMENTS), {
    variables:{
      clientId:clientId,
      type:fileType,
      files:files
    },
    onCompleted(){
      setLoadingSending(false)
      showMessage({
        title: 'Arquivo enviado',
        message: 'Novo arquivo enviado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    }
  })

  const [fetchCheckDataPrivacyClientDocuments,{loading:loadingGetCheckDataPrivacyClientDocuments}] = useLazyQuery(demandsQueries.GET_DATAPRIVACY_CHECKOUT_FILES,{
    onCompleted(data){
      setFilesSended(data?.getCheckDataPrivacyClientDocuments?.data)
    }
  });

  const sendFile = (fileType) => {
    setFileType(fileType);
    let fileRefuseDemand = document.getElementById('file-demand');
    fileRefuseDemand.click();
  }

  function setFilesHandler(file){
    let actualFilesList = files;

    actualFilesList.push(file);
    let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
      attachment: '',
      name,
      randomFileName,
      mimeType,
      extension,
      size,
    }))
    setFiles(actualFilesListFiltered);
    setLoadingSending(true)
    checkDataPrivacyClientDocuments()
  }


  useEffect(() => {
      if(clientId !== undefined){
        fetchCheckDataPrivacyClientDocuments({
          variables:{
            clientId:clientId
          }
        })
      }
  }, [clientId,fetchCheckDataPrivacyClientDocuments])



  return (
    <>
      <Col sm='3'>
        <Card className="small-balloon">
          <div className="h-100 w-100 d-flex justify-content-between">
            {
              loadingGetCheckDataPrivacyClientDocuments ?
                <div className='h-100 w-100 justify-content-center align-items-center d-flex'>
                  <Spinner color='primary' />
                </div>
                :
                <div className='w-100 justify-content-between align-items-center flex-column d-flex'>
                  <div className='w-100 justify-content-between align-items-center d-flex'>
                    <h4>Meus Documentos</h4>
                    <img src={ClipesIcon} alt="" />
                  </div>
                  <div className='w-100 justify-content-between align-items-center d-flex'>
                    <button style={{width:'86px', position:'unset'}} onClick={()=>setOpenDocuments(true)}>Anexar</button>
                    <Link to="/dashboard/documentos" >
                      <button style={{width:'86px', position:'unset'}}>Acessar</button>
                    </Link>
                  </div>
                </div>
            }
          </div>
        </Card>
      </Col>
      <Modal
        open={openDocuments}
        onClose={()=>setOpenDocuments(false)}
      >
        <Box className="box md-size-5 no-padding">
          {sendingLoading ?
            <div className='h-100 w-100 justify-content-center align-items-center d-flex'>
              <Spinner color='primary' />
            </div>
            :
            <>
              <div className="box-cards-title docs-card-title">
                <h6>Documentos necessários</h6>
              </div>
              <div className="card-body">
              <ul className="docs-card-list">
              <li>
              <p>
              Cartão CNPJ
            {filesSended.find(( e => e.type === 'cartao-cnpj'))?<span className="file-sended">Enviado</span>:""}
              </p>
              <button disabled={filesSended.find(( e => e.type === 'cartao-cnpj'))}
              className="upload-document-button"
              style={{cursor:'pointer'}}
              onClick={()=>sendFile("cartao-cnpj")}
              >
              <img src={uploadIcon} alt='An arrow up, that means you can something to the cloud' />
              </button>
              </li>
              <li>
              <p>
              Política de Privacidade (optativo)
            {filesSended.find((e => e.type === 'politica-privacidade'))?<span className="file-sended">Enviado</span>:""}
              </p>
              <button disabled={filesSended.find((e => e.type === 'politica-privacidade'))}
              className="upload-document-button"
              style={{cursor:'pointer'}}
              onClick={()=>sendFile("politica-privacidade")}
              >
              <img src={uploadIcon} alt='An arrow up, that means you can something to the cloud' />
              </button>
              </li>
              <li>
              <p>
              Termo de Uso (optativo)
            {filesSended.find((e => e.type === 'termo-uso'))?<span className="file-sended">Enviado</span>:""}
              </p>
              <button disabled={filesSended.find((e => e.type === "termo-uso"))}
              className="upload-document-button"
              style={{cursor:'pointer'}}
              onClick={()=>sendFile("termo-uso")}
              >
              <img src={uploadIcon} alt='An arrow up, that means you can something to the cloud' />
              </button>
              </li>
              <li>
              <p>
              Política de segurança da informação (optativo)
            {filesSended.find((e => e.type === 'politica-seguranca-informacao'))?<span className="file-sended">Enviado</span>:""}
              </p>
              <button disabled={filesSended.find((e => e.type === 'politica-seguranca-informacao'))}
              className="upload-document-button"
              style={{cursor:'pointer'}}
              onClick={()=>sendFile("politica-seguranca-informacao")}
              >
              <img src={uploadIcon} alt='An arrow up, that means you can something to the cloud' />
              </button>
              </li>
              <li>
              <p>
              Outros documentos de LGPD existentes (optativo)
            {filesSended.find((e => e.type === "outros-docs-lgpd"))?<span className="file-sended">Enviado</span>:""}
              </p>
              <button disabled={filesSended.find((e => e.type === 'outros-docs-lgpd"'))}
              className="upload-document-button"
              style={{cursor:'pointer'}}
              onClick={()=>sendFile("outros-docs-lgpd")}
              >
              <img src={uploadIcon} alt='An arrow up, that means you can something to the cloud' />
              </button>
              </li>
              <li>
              <p>
              Relatório de LGPD Elaborado por Prestador de Serviço (optativo)
            {filesSended.find((e => e.type === 'relatorio-lgpd'))?<span className="file-sended">Enviado</span>:""}
              </p>
              <button disabled={filesSended.find(( e => e.type === 'relatorio-lgpd'))}
              className="anexar upload-document-button"
              style={{cursor:'pointer'}}
              onClick={()=>sendFile("relatorio-lgpd")}
              >
              <img src={uploadIcon} alt='An arrow up, that means you can something to the cloud' />
              </button>
              </li>
              <li>
              <p>
              Outros documentos (geral)
            {filesSended.find((e => e.type === 'outros-docs-geral'))?<span className="file-sended">Enviado</span>:""}
              </p>
              <button disabled={filesSended.find(( e => e.type === 'outros-docs-geral'))}
              className="anexar upload-document-button"
              style={{cursor:'pointer'}}
              onClick={()=>sendFile("outros-docs-geral")}
              >
              <img src={uploadIcon} alt='An arrow up, that means you can something to the cloud' />
              </button>
              </li>
              </ul>
              </div>
            </>
          }
        </Box>
      </Modal>
      <Dropzone clientId={clientId} onLoad={()=>{}} onComplete={(file)=>setFilesHandler(file)}/>
    </>
  );
}

export default Documents;
