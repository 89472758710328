import React from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'

const AlertWrapper = styled(Flex)`
  width: 100%;
  justify-content: center;
`

const RegisterWrapper = props => {
    return (
        <AlertWrapper my={props.my}>
            {props.children}
        </AlertWrapper>
    )
}

export default RegisterWrapper