import React from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'

const InnerContainerLogin = styled(Flex)`
  overflow-y: auto;  
  overflow-x: auto;    
  background: #FFFFFF;
  box-shadow: 0px 2px 14px 2px rgba(80, 45, 45, 0.15);
  border-radius: 8px;
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`

const LoginContainer = props => {
    return (
      <InnerContainerLogin style={{margin: 'auto 16px'}}>
        {props.children}
      </InnerContainerLogin>
    )
}

export default LoginContainer