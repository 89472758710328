import React from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 0 25%;

  @media only screen and (max-width: 52em) {
    padding: 0 10%;
  }
`

const RegisterWrapper = (props) => {
  return <Wrapper>{props.children}</Wrapper>
}

export default RegisterWrapper
