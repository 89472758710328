import React from 'react'
import styled from 'styled-components'

const LoginText = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-align: center;
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  width: 100%;
`

const LoginTextWrapper = props => {
    return (
        <LoginText marginTop={props.marginTop} marginBottom={props.marginBottom}>
            {props.children}
        </LoginText>
    )
}

export default LoginTextWrapper