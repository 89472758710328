import React, {useState, useCallback} from "react";
import { useMutation  } from '@apollo/react-hooks';
import { walletsMutations } from '../../graphql/mutations';
import "@fontsource/ibm-plex-mono";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CustomSpinner from '../CustomSpinner';
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import "./ClientCards.css";
import { ReactComponent as AddIcon } from "../../images/icons/add_icon.svg";
import { ReactComponent as DeleteCardIcon } from "../../images/icons/delete_card_icon.svg";
import { ReactComponent as AmexIcon } from "../../images/amex.svg";
import { ReactComponent as MastercardIcon } from "../../images/mastercard.svg";
import { ReactComponent as VisaIcon } from "../../images/visa.svg";


const ClientCards = props => {

  const {data, loading, openModal} = props

  const [openDeleteCardModal, setOpenDeleteCardModal] = useState(false);
  const [cardToDelete, setCardToDelete] = useState({id: '', displayNumber: ''});

  const { showMessage } = useAlertStack()


  const [deleteCard, { loading: loadingDeleteCard }] = useMutation(walletsMutations.DELETE_CARD, {
    onCompleted() {
      handleCloseDeleteCardModal()
      showMessage({
        title: 'Cartão removido com sucesso',
        message: 'Sucesso ao excluir o cartão selecionado.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    onError() {
      handleCloseDeleteCardModal()
      showMessage({
        title: 'Erro ao remover o cartão',
        message: 'Erro ao remover o cartão selecionado, tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const handleDeleteCardModal = (id, displayNumber) => {
    setCardToDelete({id: id, displayNumber: displayNumber.slice(-4)})
    setOpenDeleteCardModal(!openDeleteCardModal)
  }
  
  const handleCloseDeleteCardModal = () => {
    setOpenDeleteCardModal(!openDeleteCardModal)
  }

  const handleDeleteCard = useCallback((id) => {
      deleteCard({ variables: { id } })
    },
   [deleteCard]
  )

  const handleOpenModal = () => {
    openModal()
  }


  return (
    <>
      <Box className="box-cards-wrapper">
        <Box className="box-cards-header-section">
          Meus Cartões
        </Box>
        {loading ? (
          <CustomSpinner />
        ) : (
          <>
            <Box className="box-cards-client-cards-wrapper">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <Box className="box-cards-client-cards-container">
                    <Box className="box-cards-client-cards-remove">
                      <IconButton onClick={() => handleDeleteCardModal(item._id, item.displayNumber)}>
                        <DeleteCardIcon />
                      </IconButton>
                    </Box>
                    <Box className="box-cards-client-cards-details-card-brand">
                      {item.brand === 'VISA' && <VisaIcon />}
                      {item.brand === 'MASTERCARD' && <MastercardIcon />}
                      {item.brand === 'AMEX' && <AmexIcon />}
                      {(item.brand !== 'AMEX' || item.brand !== 'MASTERCARD' || item.brand !== 'VISA') && ' '}
                    </Box>
                    <Box className="box-cards-client-cards-details-card-number">
                      {`**** **** **** ${item.displayNumber.slice(-4)}`}
                    </Box>
                    <Box className="box-cards-client-cards-details-card-expire">
                      {`${item.month}/${item.year}`}
                    </Box>
                    <Box className="box-cards-client-cards-details-card-name">
                      {item.holderName}
                    </Box>
                  </Box>
                ))
              ) : ('')}
              <Box className="box-cards-client-cards-new-card-container">
                <IconButton 
                  onClick={() => handleOpenModal()}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </>  
        )}
      </Box>
      <Modal
        open={openDeleteCardModal}
        onClose={handleCloseDeleteCardModal}
        disableAutoFocus
        sx={{zIndex: '9999'}}
      >
        <Box className="box-cards-delete-card-container">
          Deseja deletar o cartão {`**** **** **** ${cardToDelete.displayNumber}`}?
          <Box className="box-cards-delete-card-inner-container">
            <Button onClick={() => handleCloseDeleteCardModal()} className="box-cards-cancel-button">
              Cancelar
            </Button>
            <Button onClick={() => handleDeleteCard(cardToDelete.id)} className="box-cards-confirm-button">
              {loadingDeleteCard ? <CustomSpinner color={'#FFFFFF'} /> : 'Remover'}              
            </Button>
          </Box>
        </Box>
      </Modal>
    </>  
  )
}

export default ClientCards
