import gql from 'graphql-tag'

export const GET_TERMS_OF_USE = gql`
  query termsOfUse {
    termsOfUse {
      content
    }
  }
`
export const GET_PRIVACY_POLICY = gql`
  query privacyPolicy {
    privacyPolicy {
      content
    }
  }
`
