import React, { useEffect } from 'react'
import Spinner from 'ui-components/lib/components/Spinner'
import { Flex, Box } from 'reflexbox'
import { useApolloClient } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import * as authService from '../services/auth'
import * as localStorageService from '../services/localStorageService'

const Logout = () => {
  const apolloClient = useApolloClient()
  const history = useHistory()

  useEffect(() => {
    localStorageService.removeStorage('demand-evaluation')
    authService.onLogout()
    apolloClient.clearStore()
    history.push('/')
    location.reload() // eslint-disable-line
  }, []) // eslint-disable-line

  return (
    <Flex justifyContent="center" alignItems="center">
      <Box>
        <Spinner color={'primary'} style={{ width: 50, height: 50 }} />
      </Box>
    </Flex>
  )
}

export default Logout
