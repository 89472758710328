import * as React from 'react';
import styled from 'styled-components'
import { Box } from 'reflexbox'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Wrapper = styled(Box)`
  width: 70%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const themeOptions = {
  palette: {
    type: 'main',
    primary: {
      main: '#F14F5C',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: '3vw',
          paddingRight: '3vw',
          backgroundColor: '#ec6873',
          color: "#1b1c1d4d",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontWeight: "500",
          letterSpacing: "0.16px",
          textTransform: "uppercase",   
          "&.Mui-selected": {
            background: "#F14F5C",
            color: "#FFFFFF",
          },
          "&.Mui-disabled": {
            background: "#ababab",
            color: "#FFFFFF",
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

const TabSelectorForm = props => {
  const mediumViewport = useMediaQuery('(min-width:1100px)');
  
  return (
    <Wrapper>
      <ThemeProvider theme={theme}>
        <Tabs
          value={props.value}
          onChange={props.onChange}
          indicatorColor="primary"
          orientation={mediumViewport ? "horizontal" : "vertical"}
        >
          <Tab value="legalPerson" disabled={props.disabled} label="PESSOA JURÍDICA" />
          <Tab value="naturalPerson" disabled={props.disabled} label="PESSOA FÍSICA" />
        </Tabs>
      </ThemeProvider>  
    </Wrapper>
  );
}

export default TabSelectorForm