import React, {useState ,useEffect} from 'react';
import Box from '@mui/material/Box';
import CustomSpinner from '../CustomSpinner';
import "./ClientPurchase.css";

const ClientPurchase = props => {
  const {data, loading} = props

  const [packagesBnz, setPackagesBnz] = useState()

  useEffect(() => {
    let packages

    if(data){
      packages = data.map((item, index) => {
        if (item.description.includes('pacote')){
          let splitName = item.description.split("pacote")
          let splitPackageName = splitName[1].split("+")
          let splitAmount = item.description.split("Compra de")
          let splitPackageAmount = splitAmount[1].split("BNZ")
          return { packageName: splitPackageName[0], amount: splitPackageAmount[0] }
        } else if(item.description.includes('pacote')){
          let splitAmount = item.description.split("Compra de")
          let splitPackageAmount = splitAmount[1].split("BNZ")
          return { packageName: 'Avulsa', amount: splitPackageAmount[0] }
        } else {
          return { packageName: 'Débito', amount: item.amount }
        }
      })
    }
    setPackagesBnz(packages)
  }, [data]) // eslint-disable-line


  return (
    <Box className="client-purchase-wrapper">
      <Box className="client-purchase-section-header">
        Minhas Compras
      </Box>
        <Box className="client-purchase-inner-wrapper">
          {loading ? (
            <CustomSpinner style={{alignSelf: 'center', justifySelf: 'center'}}/>
          ) : (
            packagesBnz && packagesBnz.length > 0 ? (
              packagesBnz.map((item, index) => (
              <Box className="client-purchase-purchase-container">
                <Box className="client-purchase-left-container">
                  <Box className="client-purchase-description-text">
                    {item.packageName === 'Avulsa' ? 'Compra' : 'Pacote'}
                  </Box>
                  <Box className="client-purchase-text">
                    {item.packageName}
                  </Box>
                </Box>
                <Box className="client-purchase-right-container">
                  <Box className="client-purchase-description-text">
                    Valor
                  </Box>
                  <Box style={{fontSize: '20px', color: '#F14F5C'}} className="client-purchase-text">
                    {`${item.amount} BNZ`}
                  </Box>
                </Box>
              </Box>
            ))
            ) : (
              <Box className="client-purchase-no-purchases">
                Você ainda não realizou <br/> nenhuma compra.
              </Box>            
            )
          )}
        </Box>
    </Box>
  )
}

export default ClientPurchase
