import * as Yup from 'yup'

const ClientFirstLogInSchema = Yup.object().shape({
  email: Yup.string().email('Deve ser um e-mail válido').required('Campo obrigatório'),
  token: Yup.string().required('Campo obrigatório'),
  password: Yup.string().min(6, 'Deve conter no mínimo 6 caracteres').required('Campo obrigatório'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas não estão iguais'),
  resetClientURL: Yup.string(),
})

export default ClientFirstLogInSchema
