import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import { UploadFile } from '@mui/icons-material'
import bnzIcon from '../../images/coin.svg'

const Wrapper = styled(Flex)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Roboto';
`

const InnerContainer = styled(Box)`
  width: 100%;
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
`

// const BoxRight = styled(Box)`
//   width: 35%; 
//   display: flex;
//   align-self: flex-end; 

//   @media only screen and (max-width: 768px) {
//     display: none!important;
//   }
// `

const ContainerBnzInfo = styled(Flex)`
  width: 100%;
  flex-wrap: wrap; 
  justify-content: space-between;
  
  @media only screen and (max-width: 768px) {
    justify-content: space-around;
  }
`

// const OwlRight = styled.img`
//   width: 100%;
//   height: 100%;
//   box-sizing: border-box;
// `

const LabelInfo = styled.span`
  font-family: Roboto;
  font-size: 36px;
  font-weight: 900;
  letter-spacing: 0.15px;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
`

const LabelSmall = styled.span`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.15px;
  margin: 0;
  text-transform: uppercase;
`

const LabelMedium = styled.h4`
  font-family: Roboto;
  font-size: 36px;
  font-weight: 900;
  color: #F14F5C;
  margin: 0;
  text-transform: uppercase;
`

const CustomBox = styled(Box)`
  display: flex; 
  flex-direction: column; 
  justify-content: space-around; 
  border: 2px solid #F14F5C;
  border-radius: 11px;
  padding: 2.5% 3%;

  @media only screen and (max-width: 768px) {
    align-items: center;
  }
`

const BnzCoinSmall = styled.img`
  min-width: 25px;
  min-height: 25px;
  width: 3vw;
  height: 3vw;
  box-sizing: border-box;
`

const BnzCoinLarge = styled.img`
  min-width: 40px;
  min-height: 40px;
  width: 4vw;
  height: 4vw;
  box-sizing: border-box;
`

const FakeButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  outline:none;
  transition-duration: 0.3s;
  
  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2));
  };
`

const WalletInfo = props => {
    const history = useHistory()

    return (
      <Wrapper>
        <InnerContainer>
          <LabelMedium width={1}>
            {'Minha Carteira & Serviços'}
          </LabelMedium>
          <ContainerBnzInfo>
            <CustomBox alignItems={'flex-start'} width={[14/30, 7/30, 7/30, 7/30]} style={{marginTop: '22px'}}>
              <LabelSmall>
                bnz’s disponíveis
              </LabelSmall>
              <Box display={'flex'} alignItems={'center'}>
                <BnzCoinSmall src={bnzIcon} alt="Moeda B.onuz" />
                <LabelInfo style={{marginLeft: '1vw'}}>
                  {props.walletBalance}
                </LabelInfo>
              </Box>
            </CustomBox>
            <CustomBox alignItems={'flex-start'} width={[14/30, 7/30, 7/30, 7/30]} style={{marginTop: '22px'}}>
              <LabelSmall>
                bnz’s reservados
              </LabelSmall>
              <Box display={'flex'} alignItems={'center'}>
                <BnzCoinSmall src={bnzIcon} alt="Moeda B.onuz" />
                <LabelInfo style={{marginLeft: '1vw'}}>
                  {props.walletReserved}
                </LabelInfo>
              </Box>
            </CustomBox>
            <CustomBox alignItems={'center'} width={[12/30, 5/30, 5/30, 5/30]} style={{backgroundColor: '#F14F5C', marginTop: '22px'}}>
              <FakeButton onClick={() => history.push('/dashboard/carteira')} style={{marginTop: '5%'}}>
                <LabelSmall style={{color: '#FFFFFF'}}>
                  comprar
                </LabelSmall>
              </FakeButton>
              <FakeButton onClick={() => history.push('/dashboard/carteira')} style={{marginTop: '5%'}}>  
                <BnzCoinLarge src={bnzIcon} alt="Moeda B.onuz" />
              </FakeButton>
            </CustomBox>
            <CustomBox alignItems={'center'} justifyContent={'center'} width={[16/30, 9/30, 9/30, 9/30]} style={{marginTop: '22px', backgroundColor: '#F1F1F1'}}>
              <FakeButton onClick={() => history.push('/dashboard/serviços/solicitar')}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-end'}>
                  <LabelSmall style={{color: '#F14F5C'}}>
                    solicitar serviços
                  </LabelSmall>  
                  <UploadFile sx={{ minWidth: '40px', minHeight: '40px', fontSize: '4.5vw', color: '#F14F5C', marginTop: '1%', marginBottom: '-1%' }} />
                </Box>
              </FakeButton>
            </CustomBox>
          </ContainerBnzInfo>  
        </InnerContainer>
        {/* <BoxRight>
          <OwlRight src={owlSide} alt="Coruja B.onuz" />
        </BoxRight>                     */}
      </Wrapper>
    )
}

export default WalletInfo