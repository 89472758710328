import React, {useState ,useEffect} from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomSpinner from '../CustomSpinner';
import { formatDate } from '../../utils';
import "./CustomTable.css";

const CustomTable = props => {
  const {data, loading, headers, page, totalPages, changePage, prevPage, nextPage} = props

  const [content, setContent] = useState()
  const [pagination, setPagination] = useState()

  const createPagination = (input) => {
    let page = 0
    let pagesArray = []
    while (page < input) {
      pagesArray[page] = page + 1
      page += 1
    }
    setPagination(pagesArray)
  }

  useEffect(() => {
    if(data){
      setContent(data)
      createPagination(totalPages)
    }
  }, [data]) // eslint-disable-line


  return (
    <Box className="custom-table-wrapper">
      <table className="custom-table-table">
        <thead className="custom-table-thead">
          <tr>
            {headers && headers.length ? (
              headers.map((item, index) => (
                <th>{item.title}</th>
              ))
            ) : (
              <th colSpan={100}>{` `}</th>
            )}
          </tr>
        </thead>
        <tbody className="custom-table-tbody">
          {!loading && content ? (
            content.length ? (
              content.map((item, index) => (
                <tr>
                  <td>{formatDate(item.createdAt)}h</td>
                  <td>{item.description}</td>
                  <td>{item.amount}</td>
                  <td>{item.balance}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={100}>Nenhum registro encontrado!</td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan={100}><CustomSpinner /></td>
            </tr>
          )}
        </tbody>
        <tfoot className="custom-table-tfoot">
          <tr>
            <td colSpan={100}>
              <Box className="custom-table-pagination-wrapper">
                <IconButton onClick={() => prevPage()} className="custom-table-pagination-button-arrow">
                  <ChevronLeftIcon />
                </IconButton>
                {pagination && pagination.length ? (
                  pagination.map((item, index) => (
                    <Button 
                      onClick={() => changePage(item)} 
                      style={item === page ? {color: '#F14F5C'} : {color: '#9997A0'}} 
                      className="custom-table-pagination-button-number"
                    >
                      {item}
                    </Button>
                  ))
                ) : (
                  <Button className="custom-table-pagination-button-number">
                    1
                  </Button>
                )}
                <IconButton onClick={() => nextPage()} className="custom-table-pagination-button-arrow">
                  <ChevronRightIcon />
                </IconButton>
              </Box>
            </td>
          </tr>
        </tfoot>
      </table>
    </Box>
  )
}

export default CustomTable