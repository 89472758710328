import React, { useState, useEffect } from 'react'
import Spinner from 'ui-components/lib/components/Spinner'
import {useLazyQuery, useMutation} from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { authMutations } from '../../graphql/mutations'
import * as authService from '../../services/auth'
import { loginWithFacebookURL } from '../../services/facebookAuthService'
import { useFormik } from 'formik'
import { LoginModel } from '../../models'
import { LoginSchema } from '../../validate'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import * as queryString from 'query-string'
import googleG from '../../images/google_g.svg'
import facebook from '../../images/facebook_icon.svg'
import lock_red_icon from '../../images/icons/lock_red_icon.svg'
import login_aside_owl from '../../images/login_aside_owl.svg'
import SvgWrapper from '../../components/ImageWrapper/SvgWrapper'
import ImgWrapper from '../../components/ImageWrapper/ImgWrapper'

import {
  LoginLogo,
  LoginWrapper,
  LoginContainer,
  LoginContainerAside,
  LoginContentAside,
  LoginFormContainer,
  LoginButtonForm,
  LinkForm,
  TextTitleForm, 
  TextFieldInput,
  TextDescriptionForm,
  FieldLabelForm,
  ContentSpacerForm,
  SwitchInput
} from '../../components'
import {walletQueries} from "../../graphql/queries";


const Login = (props) => {
  const [googleUserBasicInformation, setGoogleUserBasicInformation] = useState({})
  const [typeRedirectedFromLP, setTypeRedirectedFromLP] = useState('')
  const [productRedirectedFromLP, setProductRedirectedFromLP] = useState('')
  const [previousPath, setPreviousPath] = useState('/')

  const { showMessage } = useAlertStack()

  const history = useHistory()

  const [fetchSubscriptionBonuzPlans, {loading: subscriptionLoading}] = useLazyQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans'
    },
    onCompleted({subscription}){
      const planos = [
        'plano-essencial',
        'plano-premium',
        'plano-platinum',
        'plano-start'
      ]
      if(planos.indexOf(subscription?.planIdentifier) >= 0){
        history.push('/dashboard/para-empresas/planos')
      } else {
        history.push('/')
      }
    },
    onError(error){
      if (error && error.toString().indexOf("404") >= 0) {
        fetchSubscriptionDataPrivacy()
      } else {
        history.push('/')
      }
    }
  })

  const [fetchSubscriptionDataPrivacy, {loading: subscriptionLoadingPrivacy}] = useLazyQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'data-privacy'
    },
    onCompleted({subscription}){
      const dataPrivacy = [
        'data-privacy-semestral',
        'data-privacy-anual'
      ]
      if(dataPrivacy.indexOf(subscription?.planIdentifier) >= 0){
        history.push('/dashboard/data-privacy')
      } else {
        history.push('/')
      }
    },
    onError(){
      history.push('/')
    }
  })

  const [authLogin, { loading }] = useMutation(authMutations.AUTH_LOGIN, {
    onCompleted({ auth }) {
      const token = (auth || {}).token
      authService.login({ token })
      const decodedToken = authService.decodedToken()
      
      if (decodedToken.status === 'incomplete') {
        history.push('/completar-cadastro')
      } else if (decodedToken.status === 'pending_password') {
        history.push('/primeiro-acesso')
      } else {
        if (previousPath !== '/') {
          history.push(previousPath)
        } else {
          fetchSubscriptionBonuzPlans()
        }
      }
    },
    onError(error) {
      const customError = (((((error.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).message
      showMessage({
        title: 'Erro ao logar na plataforma',
        message: customError || 'Ocorreu algum erro desconhecido',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const formik = useFormik({
    initialValues: new LoginModel(),
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      authLogin({ variables: values });
      setSubmitting(false);
    },
  })

  const handleKeepAlive = () => {
    if(formik.values.keepAlive === 'false'){
      formik.setValues({
        ...formik.values,
        keepAlive: 'true',
      })      
    }else{
      formik.setValues({
        ...formik.values,
        keepAlive: 'false',
      })
    }
  }

  const [authGoogle, { loadingGoogleSignin }] = useMutation(authMutations.AUTH_GOOGLE, {
    onCompleted({ authGoogle }) {
      const token = authGoogle?.token
      authService.login({ token })
      const decodedToken = authService.decodedToken()

      if (decodedToken.status === 'incomplete') {
        history.push('/completar-cadastro')
      } else {
        history.push('/')
      }
    },
    onError() {
      const { firstName, lastName, email } = googleUserBasicInformation
      history.push(`/nova-conta?email=${email}&firstName=${firstName}&lastName=${lastName}`)
    },
  })

  const handleGoogleSignIn = (e) => {
    e.preventDefault()

    // eslint-disable-next-line no-undef
    gapi.load('auth2', () => {
      // eslint-disable-next-line no-undef
      const auth2 = gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      })

      auth2
        .signIn()
        .then((googleUser) => {
          const profile = googleUser.getBasicProfile()

          setGoogleUserBasicInformation({ email: profile.getEmail(), firstName: profile.getGivenName(), lastName: profile.getFamilyName() })

          const { id_token: idToken } = googleUser.getAuthResponse()

          authGoogle({ variables: { idToken } })

          auth2.signOut()
        })
        .catch((error) => {
          showMessage({
            title: 'Falha no login',
            message: 'Ocorreu um erro no login com a sua conta Google, tente novamente.',
            color: 'danger',
            position: 'bottom-left',
            time: 4000,
          })
        })
    })
  }

  useEffect(() => {
    const authAdmin = (tokenAuthAdmin) => {
        authService.loginAsAdmin(tokenAuthAdmin);
        const decodedToken = authService.decodedToken();
        if (decodedToken.status === 'incomplete') {
            history.push('/completar-cadastro');
        } else if (decodedToken.status === 'pending_password') {
            history.push('/primeiro-acesso');
        } else {
            history.push('/');
        }
    };

    const tokenAuthAdmin = (new URLSearchParams(props.location.search)).get('token');
    if (tokenAuthAdmin) {
        authAdmin(tokenAuthAdmin);
    }
  }, [props.location.search, history]);

  const { tipo, produto } = queryString.parse(window.location.search)

  useEffect(() => {
    if (tipo && tipo.length < 20) {
      setTypeRedirectedFromLP(tipo)
    }
    if (produto && produto.length < 20) {
      setProductRedirectedFromLP(produto)
    }
  }, []) // eslint-disable-line
  
  useEffect(() => {
    const { state } = props.location
    if (state?.from) {
      setPreviousPath(state.from)
    }
  }, [props.location])

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginFormContainer onSubmit={formik.handleSubmit}>
          <LoginLogo />
          <TextTitleForm>Bem vindo de volta a Bonuz!</TextTitleForm>
          <TextDescriptionForm textType={'description'}>Preencha os campos abaixo e acesse sua conta</TextDescriptionForm>
          <Box width={[1]} mt={32}>
            <FieldLabelForm>E-mail</FieldLabelForm>
            <TextFieldInput 
              id="email"
              name={'email'}
              textFieldType={'mailField'}
              placeholder={'seunegocio@suaempresa.com'}
              helperText={formik.touched.email && formik.errors.email}                
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onTouched={formik.touched.email}                            
              error={formik.touched.email && formik.errors.email && true}
              defaultStyle={true}
              type={'text'}
            />            
          </Box>
          <Box width={[1]} mt={17}>
            <FieldLabelForm>Senha</FieldLabelForm>
            <TextFieldInput 
              id="password"
              name={'password'}
              textFieldType={'passwordField'}
              placeholder={'Senha'}
              helperText={formik.touched.password && formik.errors.password}                
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}              
              onTouched={formik.touched.password}                            
              error={formik.touched.password && formik.errors.password && true}
              defaultStyle={true}              
              type={'password'}
            />
          </Box>
          <Flex flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} style={{padding: '10px 0 0 10px'}}>
           <SwitchInput
              id="keepAlive"
              name={'keepAlive'}  
              onChange={handleKeepAlive}
              checked={
                formik.values.keepAlive === 'false' ? false : true
              }             
            />
            <FieldLabelForm style={{padding: '2px 0 0 0'}}>Manter conectado</FieldLabelForm>
          </Flex>
          <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'} style={{padding: '22px 0 16px 0'}}>
            <LoginButtonForm buttonType={'primaryButton'} disabled={!formik.isValid || formik.isSubmitting} type={'submit'}>
              {loading || subscriptionLoading || subscriptionLoadingPrivacy ? <Spinner color="primary" /> : 'Acessar'}
            </LoginButtonForm>
            <LoginButtonForm buttonType={'secondaryButton'} onClick={() => (history.push(`/nova-conta${typeRedirectedFromLP ? `?tipo=${typeRedirectedFromLP}${productRedirectedFromLP ? `&produto=${productRedirectedFromLP}` : ''}` : `${productRedirectedFromLP ? `?produto=${productRedirectedFromLP}` : ''}`}`))}>
              {'Criar uma conta'}
            </LoginButtonForm>
          </Flex>
          <Flex width={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <ContentSpacerForm />
          </Flex>
          <Flex width={1} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} style={{padding: '5px 0 16px 0'}}>
            <LoginButtonForm style={{marginRight: '2px'}} buttonType={'iconButton'} disabled={loading} onClick={handleGoogleSignIn}>
              {loadingGoogleSignin ? (
                <Spinner color="primary" />
              ) : (
                <SvgWrapper src={googleG} alt="google-g-logo" />
              )}
            </LoginButtonForm>
            <LoginButtonForm style={{marginLeft: '2px'}} buttonType={'iconButton'} disabled={loading} onClick={() => (window.location = loginWithFacebookURL)}>
              <SvgWrapper src={facebook} alt="facebook-logo" />
            </LoginButtonForm>
          </Flex> 
          <Flex width={1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <LinkForm linkType={'lostPassword'} to="/recuperar">
              <ImgWrapper src={lock_red_icon} alt="esqueci-a-senha" style={{width: '16px', height: '19px', margin: '0 16px -3px 0'}} />
              Esqueci minha senha
            </LinkForm>
            <LoginButtonForm style={{margin: '10px 0 0 2px'}} buttonType={'externalLinkButton'} disabled={loading} onClick={() => (window.location = "https://adv.bonuz.it/register/1")}>
              Cadastre-se como advogado
            </LoginButtonForm>           
          </Flex>    
        </LoginFormContainer>
        <LoginContainerAside style={{padding: '159px 75px 114px 123px'}}>
          <LoginContentAside src={login_aside_owl} alt={'coruja-bonuz'} style={{width: '100%', height: '100%'}}/>
        </LoginContainerAside>  
      </LoginContainer>  
    </LoginWrapper>
  )
}

export default Login

