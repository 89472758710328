import React from 'react'
import { useQuery} from "@apollo/react-hooks";
import {clientsQueries} from "../../graphql/queries";
import {Spinner} from "ui-components";

const DefendantName = (props) => {
  const { clientId } = props

  const {data, loading } = useQuery(clientsQueries.GET_ACCOUNT_USER,{variables:{id:clientId}})


  return (
    <div>
      { loading ?
         ( <Spinner color={'primary'}/> )
        :
          ( <p>{data?.getAccountUser?.name}</p> )
      }
    </div>
  )
}

export default DefendantName
