import gql from 'graphql-tag'

export const GET_PLANS_OPTIONS_OPTIONS = gql`
  query {
    plansOptions {
      employees
      captions
      billings
      regions
    }
  }
`