import gql from 'graphql-tag'

export const REQUEST_SERVICE = gql`
  mutation requestService(
    $service: String!,
    $option: String!,
    $subscription:String,
    $notDiscount:Boolean
  ) {
    requestService(
      service: $service,
      option: $option,
      subscription: $subscription,
      notDiscount: $notDiscount
    ) {
      message
    }
  }
`

export const CREATE_DEMAND = gql`
  mutation createDemands(
    $serviceId: String!,
    $subserviceId: String!,
    $answers: [DemandSubserviceQuestionAnswer],
    $bicolumn: Boolean!,
    $extraLanguage: Boolean!,
    $urgent: Boolean!,
    $draft: Boolean!,
    $clientId: String,
    $lawyerId: String,
    $finalDate: String
  ) {
    createDemands(
      serviceId: $serviceId,
      subserviceId: $subserviceId,
      answers: $answers,
      bicolumn: $bicolumn,
      extraLanguage: $extraLanguage,
      urgent: $urgent,
      draft: $draft,
      clientId: $clientId,
      lawyerId: $lawyerId,
      finalDate: $finalDate
    ) {
      _id
      status
      price
      deadline
      finalDate
    }
  }
`

export const UNDRAFT_DEMAND = gql`
  mutation undraftDemand(
    $demandId: String!,
    $serviceId: String!,
    $subserviceId: String!,
    $answers: [DemandSubserviceQuestionAnswerUndraft],
    $bicolumn: Boolean!,
    $extraLanguage: Boolean!,
    $urgent: Boolean!,
    $draft: Boolean!
  ) {
    undraftDemand(
      demandId: $demandId,
      serviceId: $serviceId,
      subserviceId: $subserviceId,
      answers: $answers,
      bicolumn: $bicolumn,
      extraLanguage: $extraLanguage,
      urgent: $urgent,
      draft: $draft
    ) {
      _id
      status
      price
      deadline
      finalDate
    }
  }
`


export const CONCLUDE_DEMAND = gql`
  mutation concludeDemand($demandId: String!) {
    concludeDemand(demandId: $demandId) {
      _id
    }
  }
`

export const REFUSE_DEMAND = gql`
  mutation refuseDemand($demandId: String!, $comment: String!, $files: [FileInput]) {
    refuseDemand(demandId: $demandId, comment: $comment, files: $files) {
      _id
    }
  }
`

export const APPROVE_ESTIMATE_DEMAND = gql`
  mutation approveEstimateDemand($demandId: String!) {
    approveEstimateDemand(demandId: $demandId) {
      _id
    }
  }
`

export const REPROVE_ESTIMATE_DEMAND = gql`
  mutation reproveEstimateDemand($demandId: String!, $comment: String!) {
    reproveEstimateDemand(demandId: $demandId, comment: $comment) {
      _id
    }
  }
`

export const APPROVE_DEMAND_BID = gql`
  mutation approveDemandBid($bidId: String!) {
    approveDemandBid(bidId: $bidId) {
      _id
    }
  }
`

export const REPROVE_DEMAND_BID = gql`
  mutation reproveDemandBid($bidId: String!) {
    reproveDemandBid(bidId: $bidId) {
      _id
    }
  }
`

export const EVALUATE_DEMAND = gql`
  mutation evaluateDemands(
    $id: String, 
    $demandId: String!, 
    $lawyerId: String!, 
    $evaluation: Int!, 
    $lawyerEvaluation: Int!, 
    $clientFeedback: String
  ) {
      evaluateDemands(
        id: $id, 
        demandId: $demandId, 
        lawyerId: $lawyerId, 
        evaluation: $evaluation, 
        lawyerEvaluation: $lawyerEvaluation, 
        clientFeedback: $clientFeedback
      ){
        _id
      }
  } 
`

export const S3_UPLOADER = gql`
  mutation s3uploader($fileName: String, $mimeType: String!, $extension: String!) {
    s3uploader(fileName: $fileName, mimeType: $mimeType, extension: $extension){
       signedUrl
       randomFileName
     }
  } 
`

export const DATAPRIVACY_CHECKOUT = gql`
  mutation createDataPrivacyCheckout(
    $answers: String,
    $finalPrice: Int,
    $planType: String
  ) {
    createDataPrivacyCheckout(
      answers: $answers
      finalPrice: $finalPrice,
      planType: $planType
    ) {
      id,
      finalPrice,
      planType
    }
  }
`

export const CHECK_DATAPRIVACY_CLIENT_DOCUMENTS = gql`
    mutation checkDataPrivacyClientDocuments(
      $type: String,
      $clientId: String
      $files:[FileInput]
    ){
      checkDataPrivacyClientDocuments(
        type:$type
        clientId:$clientId
        files:$files
      ){
        _id,
        clientId,
        type
      }
    }
`

export const SEND_FILE_REQUEST_DOCUMENT = gql`
    mutation sendFileRequestDocument(
      $demandId: String,
      $requestId: String,
      $documentId: String,
      $files:[FileInput]
    ){
      sendFileRequestDocument(
        demandId:$demandId
        requestId:$requestId
        documentId:$documentId
        files:$files
      ){
        message
        files {
          _id
          name
          size
          fileURL
          fileName
          extension
          mimeType
          createdAt
          clientName
          lawyerName
        }
      }
    }
`

export const APPROVE_REQUEST = gql`
    mutation approveRequest(
      $demandId: String,
      $requestId: String
    ){
      approveRequest(
        demandId:$demandId
        requestId:$requestId
      ){
        _id
        demandId
        lawyerId
        debitBNZ
        valueBNZ
        status
        createdAt
        updatedAt
      }
    }
`

export const REFUSE_REQUEST = gql`
    mutation refuseRequest(
      $demandId: String,
      $requestId: String
    ){
      refuseRequest(
        demandId:$demandId
        requestId:$requestId
      ){
        _id
        demandId
        lawyerId
        debitBNZ
        valueBNZ
        status
        createdAt
        updatedAt
      }
    }
`