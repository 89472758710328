import React, { useCallback } from 'react'
import { Flex, Box } from 'reflexbox'
import { useHistory } from 'react-router-dom'
import Logo from 'ui-components/lib/components/Logo'
import Button from 'ui-components/lib/components/Button'
import Header from 'ui-components/lib/components/Header'
import logo from 'ui-components/assets/bonuz_logo_colorido.svg'

const Logout = () => {
  const history = useHistory()

  const goToHome = useCallback(() => {
    history.push('/')
  }, []) // eslint-disable-line

  return (
    <Flex justifyContent={'center'} alignItems={'center'} textAlign={'center'}>
      <Box>
        <Logo src={logo} alt={'logo Bonuz'} width={150} m={50} />
        <Header title={'Página não encontrada'} />
        <Button m={'auto'} linkButton color={'primary'} onClick={goToHome}>
          ir para a tela de início.
        </Button>
      </Box>
    </Flex>
  )
}

export default Logout
