import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

const StyledSelect = styled(Select)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: "8px"
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#D3D2D7"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#D3D2D7",
    boxShadow: "0px 0px 0px 1px rgba(211, 210, 215, 0.43)"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#F14F5C",
    boxShadow: "0px 0px 0px 1px rgba(241, 79, 92, 0.52)"    
  },
  [`& .${outlinedInputClasses.input}`]: {
    fontFamily: "'Inter', Helvetica, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    paddingTop: "13px",
    paddingBottom: "13px",
    color: "#4E4B59"
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: "#4E4B59"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
    color: "#4E4B59",
  },
  [`& .${inputLabelClasses.outlined}`]: {
    color: "#D3D2D7"
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: "#D3D2D7"
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: "#D3D2D7"
  }
});

const CustomSelectInput = props => {

  return (
    // <StyledTextField
    // fullWidth
    // id={props.id}
    // name={props.name}
    // label={props.label}
    // placeholder={props.placeholder}
    // value={props.value}
    // onChange={props.onChange}
    // onBlur={props.onBlur}
    // onKeyDown={props.onKeyDown}
    // onKeyUp={props.onKeyUp}
    // error={props.error}
    // helperText={props.helperText}
    // sx={props.sx}
    // required={props.required}
    // autoFocus={props.autoFocus}        
    // InputLabelProps={{ shrink: true }}
    // // variant="standard"
    // select
    // >
    //   {props.children}
    // </StyledTextField>
    <StyledSelect
      fullWidth
      id={props.id}
      name={props.name}
      label={props.label}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      onKeyUp={props.onKeyUp}
      error={props.error}
      helperText={props.helperText}
      sx={props.sx}
      required={props.required}
      autoFocus={props.autoFocus}        
      InputLabelProps={{ shrink: true }}
    >
      <MenuItem value={10}>Ten</MenuItem>
      <MenuItem value={20}>Twenty</MenuItem>
      <MenuItem value={30}>Thirty</MenuItem>
    </StyledSelect>
  )
}

export default CustomSelectInput