import gql from 'graphql-tag'

export const ACCEPT_TERMS = gql`
  mutation acceptTermsAndPolicy {
    acceptTermsAndPolicy {
      _id
    }
  }
`

export const CREATE_CLIENT = gql`
  mutation createClient(
    $firstName: String
    $lastName: String
    $fullName: String
    $personalMobileNumber: String
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $agreedTerms: Boolean
    $agreedPolicy: Boolean
    $clientURL: String!
    $partnership: String
    $product: String
  ) {
    createClient(
      firstName: $firstName
      lastName: $lastName
      fullName: $fullName
      personalMobileNumber: $personalMobileNumber
      email: $email
      password: $password
      passwordConfirmation: $passwordConfirmation
      agreedTerms: $agreedTerms
      agreedPolicy: $agreedPolicy
      clientURL: $clientURL
      partnership: $partnership
      product: $product
    ) {
      firstName
      lastName
      email
    }
  }
`

export const UPDATE_CLIENT = gql`
  mutation completeClientInformation(
    $document: String
    $cnpj: String
    $companyName: String
    $tradeName: String
    $financialEmail: String
    $type: String!
  ) {
    completeClientInformation(
      document: $document
      cnpj: $cnpj
      companyName: $companyName
      tradeName: $tradeName
      financialEmail: $financialEmail
      type: $type
    ) {
      document
      cnpj
      companyName
      tradeName
      financialEmail
    }
  }
`

export const UPDATE_CLIENT_ACCOUNT = gql`
  mutation updateMyAccount(
    $email: String!
    $type: String!
    $personalMobileNumber: String
    $personalTelephoneNumber: String
    $addressZipcode: String
    $addressStreet: String
    $addressNumber: String
    $addressNeighborhood: String
    $addressCity: String
    $addressUF: String
    $addressComplement: String
    $businessEmail: String
    $businessTelephoneNumber: String
    $clientURL: String!
    $attachment: String
    $attachmentMime: String
    $attachmentExtension: String
    $attachmentRandomName: String
    $attachmentFileSize: Int
    $name: String
    $password: String
    $passwordConfirmation: String
    $businessDescription: String
    $businessTarget: String   
  ) {
    updateMyAccount(
      email: $email
      type: $type
      personalMobileNumber: $personalMobileNumber 
      personalTelephoneNumber: $personalTelephoneNumber
      addressZipcode: $addressZipcode 
      addressStreet: $addressStreet 
      addressNumber: $addressNumber 
      addressNeighborhood: $addressNeighborhood 
      addressCity: $addressCity 
      addressUF: $addressUF 
      addressComplement: $addressComplement 
      businessEmail: $businessEmail 
      businessTelephoneNumber: $businessTelephoneNumber 
      clientURL: $clientURL 
      articlesOfAssociation: $attachment 
      articlesOfAssociationName: $name 
      articlesOfAssociationExtension: $attachmentExtension 
      articlesOfAssociationMime: $attachmentMime
      articlesOfAssociationRandomName: $attachmentRandomName
      articlesOfAssociationFileSize: $attachmentFileSize
      password: $password
      passwordConfirmation: $passwordConfirmation
      businessDescription: $businessDescription
      businessTarget: $businessTarget
    ) {
      email
      type
      personalMobileNumber
      personalTelephoneNumber
      addressZipcode
      addressStreet
      addressNumber
      addressNeighborhood
      addressCity
      addressUF
      addressComplement
      businessEmail
      businessTelephoneNumber
      businessDescription
      businessTarget
      articlesOfAssociation
    }
  }
`

export const DELETE_CLIENT = gql`
  mutation closeAccount {
    closeAccount {
      firstName
    }
  }
`

export const RESET_PASSWORD_CLIENT = gql`
  mutation resetPasswordClient($token: String!, $email: String!, $password: String!, $passwordConfirmation: String!) {
    resetPasswordClient(token: $token, email: $email, password: $password, passwordConfirmation: $passwordConfirmation) {
      _id
    }
  }
`

export const FORGOT_PASSWORD_CLIENT = gql`
  mutation forgotPasswordClient($email: String!, $resetClientURL: String!) {
    forgotPasswordClient(email: $email, resetClientURL: $resetClientURL) {
      _id
    }
  }
`

export const UPDATE_CLIENT_PACKAGE = gql`
  mutation updatePackageInformation(
    $clientURL: String!, 
    $businessEmployees: String!,
    $businessBillings: String!,
    $businessCaptions: String!,
    $businessRegion: String!,
  ) {
    updatePackageInformation(
      clientURL: $clientURL, 
      businessEmployees: $businessEmployees,
      businessBillings: $businessBillings,
      businessCaptions: $businessCaptions,
      businessRegion: $businessRegion,
    ) {
      _id
    }
  }
`

export const SET_READ_NOTIFICATION = gql`
  mutation setReadNotification($id: String!) {
    setReadNotification(id: $id) {
      _id
    }
  }
`

export const CREATE_ACCOUNT_USER = gql`
  mutation createAccountUser(
    $name: String!, 
    $email: String!,
    $password: String!,
    $passwordConfirmation: String!,
    $document: String!,
    $accountTypeId: String!,
    $relatedness: String,
    $product: String,
    $clientURL: String!
  ) {
    createAccountUser(
      name: $name, 
      email: $email,
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      document: $document,
      accountTypeId: $accountTypeId,
      relatedness: $relatedness,
      product: $product,
      clientURL: $clientURL
    ) {
      _id
    }
  }
`

export const UPDATE_ACCOUNT_USER = gql`
  mutation updateAccountUser(
    $id: String!,
    $name: String!, 
    $email: String!,
    $password: String,
    $passwordConfirmation: String,
    $document: String!,
    $accountTypeId: String!,
    $product: String,    
    $relatedness: String,
    $clientURL: String!
  ) {
    updateAccountUser(
      id: $id,
      name: $name, 
      email: $email,
      password: $password,
      passwordConfirmation: $passwordConfirmation,
      document: $document,
      accountTypeId: $accountTypeId,
      product: $product,      
      relatedness: $relatedness,
      clientURL: $clientURL
    ) {
      _id
    }
  }
`

export const DELETE_ACCOUNT_USER = gql`
  mutation deleteAccountUser($id: String!) {
    deleteAccountUser(id: $id) {
      _id
    }
  }
`

export const ACCEPT_CLIENT_PLAN = gql`
  mutation acceptClientPlan {
    acceptClientPlan {
      _id
    }
  }
`
