import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import styled from 'styled-components'
import { ArrowDropDown } from '@mui/icons-material';
import { AccountCircleOutlined } from '@mui/icons-material';

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  font-family: 'Roboto';
`

const ProfileMenu = props => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Wrapper style={props.style}>
      <>
      <Box sx={{ display: 'flex', alignItems: 'left', textAlign: 'left' }}>
        <Button
          onClick={handleClick}
          style={{
            color: '#ff5c5c', // Cor semelhante à da imagem
            textTransform: 'none', // Para manter o nome capitalizado como na imagem
          }}
          endIcon={<ArrowDropDown />} // Ícone de seta
          startIcon={
            <AccountCircleOutlined style={{width: '32px', height: '32px'}} />
          }
        >
          {props.title}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.20))',
            mt: 0.5,
            borderRadius: '2px',
            '& .MuiMenuItem-root': {
                paddingLeft: '20px',
                paddingRight: '20px',
            },
            '& .MuiMenuItem-root:hover': {
                bgcolor: '#DCDCDC',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.menuItems.map((item) => {
          return (
            <MenuItem
              onClick={() => history.push(item.path)}
            >
              {item.text}
            </MenuItem>
          )
        })}
      </Menu>
      </>
    </Wrapper>
  )
}

export default ProfileMenu

