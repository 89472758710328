import React from 'react'

const Terms = () => {

  return (
    <div>
      <p>TERMOS DE USO E POLÍTICA DE PRIVACIDADE BONUZ PLANO JURÍDICO FAMILIAR</p>

      <p>Os presentes termos e condições de uso (“Termos de Uso”) visam regular as operações no canal virtual disponibilizado ao público em geral pela Bonuz DO BRASIL ASSESSORIA EMPRESARIAL LTDA., inscrita no CNPJ sob o nº 36.454.710/0001-39, com sede na Cidade de São Paulo, Estado de São Paulo, na Rua Estados Unidos, 1140, CEP 01427-001 (“Bonuz”), com objetivo de viabilizar o oferecimento do produto de assessoria técnica em problemas do dia a diaa para pessoas físicas da Bonuz (“Bonuz Jurídico Pessoal”).</p>

      <p>Leia atentamente os Termos de Uso a seguir, pois que tais disposições, amparadas na Lei 8.078/1990 (Código de Defesa do Consumidor), na Lei 12.965/2014 (Marco Civil da internet) e na Lei 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD), estabelecerão as regras de acesso e uso do Bonuz Plano Jurídico Familiar, bem como as informações e condições aplicáveis às transações comerciais, por intermédio deles disponibilizadas aos(as) usuários(as) em geral.</p>

      <p>1. CONDIÇÕES GERAIS APLICÁVEIS A TODOS OS USUÁRIOS DO BONUZ JURÍDICO PESSOAL</p>

      <p>1.1 Soluções oferecidas pelo Bonuz Plano Jurídico Familiar</p>

      <p>O Bonuz Plano Jurídico Familiar disponibiliza atualmente assessoria técnica nos seguintes temas:  a) diversidade e inclusão;  b) imobiliário;  c) consumidor;  d) tributário;  e) digital;  f) contratual;  g) societário;  h) trabalhista e previdenciário;  i) abertura de MEI;  j) saúde; e  k) família.</p>

      <p>Por intermédio de seus canais virtuais, a Bonuz informa detalhadamente as características (tais como abrangência, serviços, planos, e modo de acesso), bem como os respectivos preços e formas de pagamento do Bonuz Plano Jurídico Familiar.</p>

      <p>Eventuais valores promocionais, assim entendida a concessão de descontos no valor do Bonuz Plano Jurídico Familiar, serão informados previamente nos canais virtuais e aplicados já no processo de contratação.</p>

      <p>Leia atentamente todas as informações antes de contratar quaisquer planos do Bonuz Jurídico Pessoal, garantido assim que nossos serviços atendam plenamente suas expectativas. Qualquer dúvida, a Bonuz fica à disposição pelo e-mail [=].</p>

      <p>Os serviços de declaração de imposto de renda na pessoa física e abertura de empresa que não seja MEI será somente disponível ao titular do Plano Jurídico Familiar.</p>

      <p>Os serviços de assistência técnica em contratos serão limitados da seguinte forma: elaboração de contratos de até 3 (três) páginas e revisão de contratos de até 6 (seis) páginas.</p>

      <p>A Bonuz reserva-se ao direito de alterar a qualquer momento os planos, características, preços e formas de pagamento do Bonuz Plano Jurídico Familiar, resguardadas os termos e condições previamente já contratados.</p>

      <p>1.2 Cadastro</p>

      <p>Para dar início à utilização de nossos serviços, o(a) usuário(a) deverá preencher um cadastro online, informando nome completo, CPF, endereço completo, telefone e e-mail e, ainda, criar a senha de sua conta pessoal para futuro acesso ao plano do Bonuz Plano Jurídico Familiar por ele contratado(s).</p>

      <p>Além disso, o usuário(a) poderá informar quais familiares de 1º grau (pais, filhos e marido ou mulher) terão acesso ao plano para poder solicitar serviços. Neste caso, será necessário informar o nome completo, e-mail e CPF de cada beneficiário do respectivo plano adquirido.</p>

      <p>O(A) usuário(a) será inteiramente responsável pela veracidade dos dados por ele(a) inseridos quando do preenchimento de seu cadastro, sendo que o endereço eletrônico informado será oficialmente utilizado pela Bonuz para encaminhamento de todas as informações e avisos referentes ao plano contratado, razão pela qual eventual alteração posterior deverá ser imediatamente comunicada pelo(a) usuário(a), sob pena de presumir-se como devidamente por ele(a) recebidas as informações repassadas ao endereço eletrônico originalmente informado.</p>

      <p>O(A) usuário(a) será o único responsável por seu login e senha na conta pessoal junto ao Bonuz Plano Jurídico Familiar, respondendo por todos os atos praticados por intermédio de sua conta de acesso. Portanto, é dever do(a) usuário(a) zelar pela guarda e confidencialidade de sua senha, não fornecendo para terceiros, evitando assim potenciais transtornos. O(A) usuário(a) deverá notificar imediatamente a Bonuz pelo e-mail [=] sobre qualquer uso não autorizado de sua conta de acesso, além de qualquer quebra de segurança ou anormalidade no uso do sistema.</p>

      <p>Menores de 18 anos deverão proceder exclusivamente ao cadastro por intermédio de seus responsáveis legais, sendo que estes se responsabilizam pela veracidade e compartilhamento das informações dos menores de idade com a Bonuz.</p>

      <p>1.3 Aceitação aos Termos de Uso</p>

      <p>Após realizar seu cadastro, o(a) usuário(a) deverá ler e aceitar expressamente (mediante clique no “li e aceito” do processo de compra do Site) o conteúdo integral dos Termos de Uso. Uma vez aceitos pelo(a) usuário(a), os Termos de Uso tornar-se-ão vinculantes e executáveis para ambas as partes, regrando a partir de então a relação entre elas.</p>

      <p>1.4 Registro e monitoramento da comunicação com a Bonuz</p>

      <p>Todas as comunicações entre Bonuz e usuário(a), incluindo conversas telefônicas, e-mails, mensagens pelo aplicativo WhatsApp (ou outros semelhantes), vídeo conferências, etc., poderão ser monitoradas e registradas pela empresa para fins de segurança, garantia de qualidade, registro legal ou de treinamento.</p>

      <p>1.5 Isenção de Responsabilidade</p>

      <p>A Bonuz não poderá ser responsabilizada pelos seguintes fatos: a. Equipamento infectado ou invadido por hackers; b. Equipamento avariado no momento do consumo de serviços; c. Proteção do computador; d. Proteção das informações baseadas nos computadores dos usuários; e. Abuso de uso dos computadores dos usuários; f. Monitoração clandestina do computador dos usuários; g. Vulnerabilidades ou instabilidades existentes nos sistemas dos usuários; h. Perímetro inseguro; i. Incidentes de violação de dados pessoais; j. Multas ou sanções impostas pela Autoridade Nacional de Proteção de Dados; k. Ações judiciais resultantes de incidentes envolvendo dados pessoais.</p>

      <p>Em nenhuma hipótese, a Bonuz será responsável pela instalação no equipamento do(a) usuário(a) ou de terceiros, de códigos maliciosos (vírus, trojans, malware, worm, bot, backdoor, spyware, rootkit, ou de quaisquer outros que venham a ser criados), em decorrência da navegação na internet pelo(a) usuário(a).</p>

      <p>1.6 Responsabilidades do(a) usuário(a)</p>

      <p>O login e senha só poderão ser utilizados pelo(a)s usuário(a)s cadastrados. Eles se comprometem em manter o sigilo da senha, que somente poderá ser utilizada pelo titular do plano e seus familiares de 1º grau, de modo que não será possível, em qualquer hipótese, a alegação de uso indevido, após o ato de compartilhamento com indivíduos que não seja autoridados..</p>

      <p>O(a) usuário da plataforma é responsável pela atualização das suas informações pessoais e consequências na omissão ou erros nas informações pessoais cadastradas.</p>

      <p>O(a) usuário(a) é responsável pela reparação de todos e quaisquer danos, diretos ou indiretos (inclusive decorrentes de violação de quaisquer direitos de outros usuários, de terceiros, inclusive direitos de propriedade intelectual, de sigilo e de personalidade), que sejam causados à Bonuz, a qualquer outro usuário(a), ou, ainda, a qualquer terceiro, inclusive em virtude do descumprimento do disposto nestes Termos de Uso ou de qualquer ato praticado a partir de seu acesso à internet e ao sítio https://www.bonuz.it/.</p>

      <p>1.7 Pagamento e confirmação da compra</p>

      <p>Após realizar seu cadastro e aceitar expressamente aos Termos de Uso, o(a) usuário(a) poderá efetuar o pagamento por cartão de crédito ou, de forma excepcional, por boleto bancário via cadastro dos respectivos dados pela plataforma de gestão de pagamento online IUGU disponibilizada ao longo do processo de contratação disponibilizados pelo Bonuz Plano Jurídico Familiar.</p>

      <p>Ao informar seus dados para adquirir algum(uns) dos planos do Bonuz Plano Jurídico Familiar via cartão de crédito, o(a) usuário(a) autoriza automaticamente a cobrança do respectivo preço integral do serviço contratado, nos exatos termos e condições de pagamento informados previamente pelo Bonuz Plano Jurídico Familiar.</p>

      <p>Para a maior segurança dos usuários, os dados do cartão de crédito não são armazenados nos servidores da Bonuz. Tais informações são inseridas pelo(a) usuário(a) por operações criptografadas diretamente na(s) plataforma(s) de pagamento antes referida(s), razão pela qual o(a) usuário(a), ao anuir aos Termos de Uso, consente expressamente com a coleta e compartilhamento de dados pessoais, incluindo o número do cartão de crédito para validação do meio de pagamento (com a plataforma IUGU).</p>

      <p>Poderá estar disponível formas de pagamento em diferentes canais com eventuais descontos e promoções.</p>

      <p>Após aprovação e conclusão do pagamento, o(a) usuário (a) receberá uma confirmação via e-mail no endereço eletrônico por ele(a) informado quando do respectivo cadastro.</p>

      <p>A Bonuz se reserva ao direito de não confirmar a compra ou promover o cancelamento da compra de seu plano na hipótese de constatar (i) que o(a) usuário(a) incluiu informações cadastrais incorretas ou inválidas, ou (iii) que houve fraude, má-fé, descumprimento de quaisquer das condições e políticas do Site ou demais situações que impliquem em violação de Lei.</p>

      <p>Algumas das etapas de contratação podem, eventualmente, ser alteradas ou adaptadas parcialmente mediante prévio aviso ao(à) usuário(a) diante das peculiaridades do plano contratado.</p>

      <p>Eventuais valores promocionais, assim entendida a concessão de descontos no valor de quaisquer dos planos, não são cumulativos.</p>

      <p>No momento em que o(a) usuário(a) tiver esgotado no mês a possibilidade de fazer mais solicitações de serviços, este ( a ) poderá adquirir mais de forma avulsa, para tanto será necessário fazer a solicitação no sistema.</p>

      <p>Para ter acesso a determinados serviços de cobertura o ( a ) usuário ( a ) deverá ter que, eventualmente, realizar o upgrade necessário de sua conta para plano superior para ter acesso. Neste caso, deverá realizar diretamente no sistema e aprovar o pagamento adicional mensal de acordo com o plano escolhido.</p>

      <p>O número de solicitações mensais disponíveis para o usuário(a) será limitado de acordo com o plano contratado conforme detalhado no momento da contratação, sendo que as solicitações não são cumulativas ao longo do plano, de modo que irão expirar ao final de cada mês caso não sejam utilizadas.</p>

      <p> 1.8 Cancelamento pelo(a) usuário(a)</p>

      <p>O(a) usuário(a) poderá cancelar a contratação do plano contratado em quaisquer de suas modalidades mediante encaminhamento de e-mail para o endereço [=], manifestando expressamente seu interesse em cancelar a contratação de nossos serviços.</p>

      <p>Se eventualmente for apurado um débito do(a) usuário(a) para com a Bonuz, ele ficará obrigado a imediatamente liquidá-lo para efetivação de cancelamento nas modalidades acima referidas.</p>

      <p>Em qualquer hipótese de cancelamento, o usuário(a) não terá direito à devolução do valor pago pelo plano ou por eventuais serviços adicionais prestados durante a vigência do que fora contratado. APÓS A SOLICITAÇÃO DO CANCELAMENTO DO PLANO, SERÁ NECESSÁRIO O (A) USUÁRIO (A) REALIZAR O PAGAMENTO DE MAIS DOIS MESES CONSECUTIVOS COMO TAXA DE CANCELAMENTO. NESTE PERÍODO DE DOIS MESES, O (A) USUÁRIO (A) E SEUS BENEFICIÁRIOS NÃO PODERÃO MAIS SOLICITAR SERVIÇOS NA PLATAFORMA.</p>

      <p> 1.9 Hipóteses de cancelamento por iniciativa da Bonuz</p>

      <p>A Bonuz poderá cancelar a qualquer tempo o acesso do(a) usuário ao Bonuz Jurídico Pessoal: a) diante da falta de pagamento das parcelas do preço; b) por violação pelo(a) usuário(a) aos Termos de Uso ; ou c) pela prática de ato ilegal ou atentatório à legislação vigente.</p>

      <p>2. POLÍTICA DE PRIVACIDADE</p>

      <p>2.1 Quais os objetivos de nossa política de proteção de dados?</p>

      <p>Nossa política de privacidade serve para explicar ao(à) usuário(a) do Bonuz Data Privacy como e por que os dados e informações por ele(a) disponibilizados (“dados coletados”) são coletados, tratados, armazenados e manipulados pela Bonuz, de modo a satisfazer requisitos específicos da legislação de proteção de dados e regulamentação de privacidade.</p>

      <p>Nossa Política de Privacidade se aplica à Bonuz como destinatária dos dados pessoais eventualmente fornecidos pelo(a)s usuário(a)s e pelos quais a Bonuz torna-se responsável – a partir da respectiva coleta – pela utilização, armazenamento e proteção na qualidade de controladora das informações fornecidas conforme a definição legal.</p>

      <p>2.2 Qual o compromisso da Bonuz com a proteção de dados?</p>

      <p>A Bonuz considera a proteção da privacidade de dados pessoais de extrema relevância, comprometendo-se a salvaguardar nos termos da legislação em vigor a privacidade de todos aqueles que, de algum modo, a ela disponibilizam suas informações de caráter pessoal.</p>

      <p>2.3 De quem é a responsabilidade pelo tratamento dos dados coletados?</p>

      <p>A responsabilidade pelo tratamento, armazenagem e manipulação dos dados coletados é exclusiva da Bonuz.</p>

      <p>2.4 Quais são os dados coletados?</p>

      <p>Para efeito da política de privacidade da Bonuz, consideram-se dados coletados as informações disponibilizadas pelo(a) usuário(a) e de seus familiares de 1º grau no ato de seu cadastro (nome completo, data de nascimento, CPF, endereço residencial, e-mail e telefone), bem como aquelas obtidas quando do processo de pagamento online por nossos serviços.</p>

      <p>Nossos sites utilizam cookies de navegação, ou seja, pequenos arquivos de texto que ficam gravados no computador do internauta e podem ser recuperados durante a navegação. Contudo, os cookies não armazenam nenhuma informação pessoal, apenas contêm dados de rastreamento que permitem ao site reconhecer sua presença mediante o navegador do usuário, oferecendo uma experiência mais customizada de navegação e ofertas. Estes cookies são previamente autorizados pelo usuário do site e podem, a qualquer momento, serem por ele descartados ou bloqueados, conforme instruções específicas do respectivo navegador.</p>

      <p>2.5 Quando os dados são coletados?</p>

      <p>A Bonuz poderá coletar seus dados pessoais no ato de seu cadastro e pagamento para aquisição de algum dos planos, ou ainda, eventualmente, quando o(a) usuário(a) entra em contato com a Bonuz para alguma consulta ou suporte técnico.</p>

      <p>2.6 Por que os dados coletados são utilizados?</p>

      <p>Os dados coletados serão utilizados para: a) Viabilizar a comunicação referente aos serviços aos quais o(a) usuário(a) tem acesso por conta do plano contratado; b) Viabilizar o processo de pagamento (e respectiva emissão de notas-fiscais) pelo plano contratado; c) Realizar análises de marketing e perfis de clientela para fins estatísticos; d) Para eventuais campanhas promocionais e institucionais da Bonuz; e) Cumprimento de obrigações legais e regulatórias; f) Exercício de outras atividades essenciais para a regular operação da Bonuz, no âmbito de seu objeto social.</p>

      <p>2.7 Com quem os dados coletados são compartilhados e/ou transferidos?</p>

      <p>A Bonuz não vende ou aluga os dados coletados a terceiros, nem tão pouco os utiliza ou compartilha de maneira diversa da descrita nesta Política de Privacidade, sem o prévio consentimento do usuário, salvo mediante ordem judicial em sentido contrário.</p>

      <p>Os dados coletados poderão ser compartilhados com terceiros, exclusivamente, nas seguintes hipóteses: a) ao longo do processo de pagamento por cartão de crédito, os dados do titular são inseridos diretamente na plataforma de gestão de pagamento online; b) por ordem judicial ou disposição legal. c) Trabalhamos com terceiros que prestam serviços, que podem incluir a hospedagem do site, a realização da promoção, processamento de pedidos, liberação de cartão de crédito, análise de dados e outros serviços de natureza administrativa.</p>

      <p>As informações pessoais, incluindo informações financeiras, podem ser compartilhadas com terceiros para efeitos da prestação de tais serviços a você. Procuramos impor obrigações de confidencialidade sobre esses prestadores de serviços. Além disso, empresas coligadas aa Bonuz poderão utilizar suas informações pessoais sob os termos desta Política de Privacidade. Também nos reservamos o direito de divulgar informações pessoais a terceiros, quando acreditamos de boa fé que a lei o exige, para fazer valer os nossos Termos de Serviço, para proteger os direitos, propriedade, segurança ou a segurança da Bonuz ou do público, para responder a uma emergência, ou conforme indicado nesta Política de Privacidade.</p>

      <p>2.8 Como a Bonuz protege os dados coletados?</p>

      <p>A Bonuz adota medidas e controles rígidos de proteção de dados, privacidade e segurança, a fim de salvaguardar os dados coletados, incluindo dentre elas criptografia de dados em seus sites, backup de dados em locais externos.</p>

      <p>2.9 Durante quanto tempo os dados coletados são armazenados?</p>

      <p>A Bonuz armazenará os dados coletados pelo período que considerar necessário ao devido cumprimento das finalidades que determinaram sua respectiva coleta.</p>

      <p>A Bonuz respeitará o prazo legal mínimo de armazenamento de informações dos(as) usuários(as), conforme disposto na Lei n. 12.965/2014.</p>

      <p>2.10 Quais são os direitos do(a)s usuário(a)s como titulares de dados pessoais?</p>

      <p>A Bonuz reserva aos respectivo(a)s usuário(a)s a possibilidade de exercerem os direitos previstos na Lei Geral de Proteção de Dados Pessoais (“LGPD”), podendo apresentar solicitações relacionadas aos seus dados pessoais, tais como: a) Confirmação da existência de tratamento; b) Acesso aos dados pessoais; c) Correção de dados incompletos, inexatos ou desatualizados; d) Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou supostamente tratados em desconformidade com a LGPD, ressalvadas as hipóteses previstas em Lei; e) Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa pelo usuário; f) Eliminação dos dados tratados com consentimento do usuário, ressalvadas as hipóteses previstas em Lei; g) Obtenção de informações sobre as entidades com as quais compartilhou os seus dados; h) Informação sobre a possibilidade de o usuário não fornecer o consentimento, bem como de ser informado sobre as consequências em caso de negativa; i) Informações relacionadas ao tratamento, armazenagem e destinação dos dados pessoais, no âmbito da LGPD; j) Revogação do consentimento.</p>

      <p>Para exercer os direitos indicados acima, por favor, entre em contato através do e-mail dpo@bonuz.it.</p>

      <p>3. DISPOSIÇÕES FINAIS 3.1 É vedado o(a) usuário(a) ceder ou transferir a terceiros os direitos e obrigações decorrentes do presente instrumento, total ou parcialmente, salvo se com concordância prévia e expressa da Bonuz.</p>

      <p>3.2 A Bonuz poderá terceirizar parte ou a totalidade de suas obrigações assumidas nestes Termos de Uso ou sua execução sem a comunicação ou anuência prévia do(a) usuário.</p>

      <p>3.3 A declaração de nulidade de qualquer disposição destes Termos de Uso, não invalida nenhuma das demais.</p>

      <p>3.4 A tolerância de uma parte para com a outra, relativamente ao descumprimento de qualquer das obrigações assumidas nestes Termos de Uso, constitui mera liberalidade, não sendo considerada novação ou renúncia a qualquer direito, não impedindo que a parte tolerante exija da outra o seu fiel cumprimento, a qualquer tempo.</p>

      <p>3.5 Estes Termos de Uso são regidos e interpretados de acordo com as leis brasileiras.</p>

      <p>3.6 A Bonuz poderá alterar as disposições dos Termos de Uso e respectivas políticas a eles relacionadas a qualquer tempo, disponibilizando-as em seu site.</p>

      <p>3.7 Estes Termos de Uso representam o total entendimento entre a Bonuz e o(a) usuário(a) em relação à matéria aqui tratada, devendo prevalecer sobre quaisquer outros entendimentos pretéritos sobre a mesma matéria, sejam estes verbais ou escritos.</p>

      <p>3.8 Caso qualquer dispositivo destes Termos de Uso sejam considerados, por um tribunal competente, como sendo contrários à lei, referidos dispositivos deverão ser aplicados na maior extensão permitida, permanecendo os demais dispositivos em pleno vigor e eficácia.</p>

      <p>3.9 Os presentes Termos de Uso permanecerão válidos e vigentes, sem qualquer alteração, interrupção ou necessidade de aviso prévio, em caso de fusão, incorporação, cisão, aquisição, ou qualquer outro tipo de operação societária que resulte na transferência do controle societário ou do negócio da Bonuz ou Bonuz Jurídico Pessoal para entidade terceira.</p>

      <p>3.10 Para dirimir eventuais conflitos decorrentes dos Termos de Uso e respectivas políticas, inclusive Política de Privacidade, fica eleito o foro da comarca de São Paulo, SP.</p>
    </div>
  )
}

export default Terms
