import React from "react";
import './ServiceRequester.css'
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useQuery} from "@apollo/react-hooks";
import {clientsQueries} from "../../../../graphql/queries";
import {CustomSpinner} from "../../../index";

const ServiceRequester = (props) => {
    const {nextStep, requester} = props;

    const { data: dataAccountUsers, loading } = useQuery(clientsQueries.GET_ACCOUNT_USERS)

    if(loading){
      return (
        <Container id={"service-requester"}>
          <Card id={"loader"}>
            <Card.Body>
              <Row>
                <CustomSpinner />
              </Row>
            </Card.Body>
          </Card>
        </Container>
      )
    }

    return (
        <Container id={"service-requester"}>
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <h3>Quem está solicitando?<br/>(demandante)</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="subject-id"><strong>Responsável</strong></label>
                            <Form.Select id="subject-id" onChange={(e)=>requester(e.target.value)}>
                              <option disabled selected>Selecione</option>
                                <option value={null}>Eu</option>
                                {
                                  dataAccountUsers?.getAccountUsers.map((user,counter)=>{
                                    return (
                                      <option key={counter} value={user._id}>{user.name} ({user.email})</option>
                                    )
                                  })
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <div id="service-details-options-buttons">
                            <Row>
                                <Col>
                                    <Button variant="outline-primary" onClick={()=>nextStep(1)}>
                                        Voltar
                                    </Button>
                                </Col>
                                <Col>
                                    <Button variant="primary" onClick={()=>nextStep(3)}>
                                        Avançar
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default ServiceRequester
