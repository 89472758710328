import React, { useEffect } from 'react'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Spinner from 'ui-components/lib/components/Spinner'
import { useHistory, Link } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useMutation } from '@apollo/react-hooks'
import { useFormik } from 'formik'
import { ClientFirstLogIn } from '../../models'
import { ClientFirstLogInSchema } from '../../validate'
import { clientsMutations } from '../../graphql/mutations'
import useQueryString from '../../hooks/useQueryString'
import styled from 'styled-components'
import { 
  SystemIntroduction, 
  LoginForm, 
  LoginTextWrapper, 
  LoginFormWrapper, 
  LoginTitleWrapper,
  TextFieldForm, 
  ButtonForm 
} from '../../components'

const LoginWrapper = styled(Flex)`
  width: 100%;
  min-height: 0;
  display: flex;
  background: #000000;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
`

const FisrtLoginForm = (props) => {

  const history = useHistory()

  const { showMessage } = useAlertStack()

  const [autoCompleteEmail] = useQueryString('email')

  const [resetPasswordClient, { loading }] = useMutation(clientsMutations.RESET_PASSWORD_CLIENT, {
    onCompleted() {
      showMessage({
        title: 'Perfil ativado',
        message: 'Senha salva com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      history.push('/')
    },
    onError(error) {
      showMessage({
        title: 'Erro',
        message: 'Código inválido ou algum erro inesperado.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const formik = useFormik({
    initialValues: new ClientFirstLogIn(),
    validationSchema: ClientFirstLogInSchema,
    onSubmit: values => {
      resetPasswordClient({ variables: values })
    }
  })

  useEffect(() => {
    const token = (((props || {}).match || {}).params || {}).token

    if (token) {
      formik.setValues({ ...formik.values, token })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (autoCompleteEmail) {
      formik.setValues({ ...formik.values, email: autoCompleteEmail })
    }
  }, [autoCompleteEmail]) // eslint-disable-line

  return (
    <LoginWrapper>
      <SystemIntroduction width={1/2} theme={'dark'} />
      <LoginFormWrapper>
        <LoginForm onSubmit={formik.handleSubmit}>
          <Flex flexWrap={'wrap'}>
            <LoginTitleWrapper marginTop={'35px'}>Seja bem vindo à plataforma B.onuz</LoginTitleWrapper>
            <LoginTextWrapper marginTop={'15px'} marginBottom={'7.5px'}>Por favor, cadastre sua senha inicial preenchendo o formulário abaixo.</LoginTextWrapper>
            <LoginTextWrapper marginTop={'7.5px'} marginBottom={'20px'}>Por motivos de segurança, não armazenamos sua senha. Por isso, não a enviamos por e-mail.</LoginTextWrapper>
            <Box width={[1]} my={10}>
              <TextFieldForm 
                required
                id={'token'}
                name={'token'}
                label={'Código'}
                placeholder={'Código enviado no e-mail'}
                value={formik.values.token}
                onChange={formik.handleChange}
                error={formik.errors.token}
              />
            </Box>
            <Box width={[1]} my={10}>
              <TextFieldForm 
                required
                id={'email'}
                name={'email'}
                label={'E-mail'}
                placeholder={'john@doe.com'}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
              />
            </Box>
            <Box width={[1]} my={10}>
              <TextFieldForm 
                required
                id="password"
                name={'password'}
                label={'Senha'}
                placeholder={'******'}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.errors.password}
                m={2}
                inputProps={{
                  type: 'password',
                }}
              />
            </Box>
            <Box width={[1]} my={10}>
              <TextFieldForm 
                required
                id="passwordConfirmation"
                name={'passwordConfirmation'}
                label={'Confirmação de senha'}
                placeholder={'******'}
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                error={formik.errors.passwordConfirmation}
                m={2}
                inputProps={{
                  type: 'password',
                }}
              />
            </Box>
          </Flex>
          <Flex flexWrap={'wrap'} justifyContent="center" alignItems="center" mt={'25px'}>
            <ButtonForm fullWidth type={'submit'}>
              {loading ? <Spinner /> : 'Salvar'}
            </ButtonForm>
          </Flex>
          <Flex justifyContent={'center'} mt={3}>
            <Link to="/" style={{ fontFamily: 'Roboto', fontSize: '14px', color: '#F14F5C', textDecoration: 'underline' }}>Voltar</Link>
          </Flex>
        </LoginForm>
      </LoginFormWrapper>
    </LoginWrapper>
  )
}

export default FisrtLoginForm