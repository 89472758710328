import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button';

const CustomButton = styled(Button)({
  '&.MuiButton-root': {
    maxWidth: '372px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.16px',
    borderRadius: '2px',
    padding: '12px',
    color: '#FFFFFF',
    backgroundColor: 'rgba(30, 30, 30, 0.87)',
    textTransform: 'uppercase',
    border: 'none',
    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))',
    fontFamily: 'Roboto',
  },
  '&.MuiButton-root:hover': {
    backgroundColor: 'rgba(30, 30, 30, 0.77)',
    border: 'none',
    boxShadow: 'none',
  },
  '&.MuiButton-root:active': {
    backgroundColor: 'rgba(30, 30, 30, 0.77)',
    border: 'none',
    boxShadow: 'none',
  },
  '&.MuiButton-root:focus': {
    boxShadow: 'none',
  },
  '&.MuiButton-root:disabled': {
    backgroundColor: 'rgba(30, 30, 30, 0.47)',
    color: 'rgba(30, 30, 30, 0.67)',
    border: 'none',
    boxShadow: 'none',
  },
});

const ButtonForm = props => {
  return (
    <CustomButton fullWidth style={props.style} onClick={props.onClick || null} disabled={props.disabled} type={props.type || 'button'}>
      {props.children}
    </CustomButton>
  )
}

export default ButtonForm