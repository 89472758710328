import React from 'react'
import styled from 'styled-components'
import { Box } from 'reflexbox'
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  font-family: 'Roboto';
`

const CustomSelect = styled(TextField)({
  '& .MuiInput-root': {
    fontFamily: 'Roboto',
    padding: '0% 14px',
  },
  "& .MuiInputLabel-root": {
    marginLeft: '14px',
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: 'black',
  },
  "&:hover .MuiInputLabel-root": {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: 'black',
  },
  '& label.Mui-focused': {
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'rgba(0, 0, 0, 0.42)',
  },
  '& .MuiInput-placeholder': {
    fontFamily: 'Roboto',
  },
  '& .MuiFormHelperText-root': {
    fontFamily: 'Roboto',
    padding: '0% 14px',
  },
});

const SelectForm = props => {
  return (
    <Wrapper>
      <CustomSelect
        fullWidth
        id={props.id}
        name={props.name}
        value={props.value}
        label={props.label}
        required={props.required}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        InputLabelProps={{ shrink: true }}
        variant="standard"
        select
      >
        {(props.children || []).map((item) => (
          <MenuItem key={item} value={item}>{item}</MenuItem>
        ))}
      </CustomSelect>
    </Wrapper>
  )
}

export default SelectForm