const { REACT_APP_CLIENT_BASE_URL } = process.env
class AccountUser {
    constructor(data = {}) {
      this.id = data._id || null
      this.name = data.name || ''
      this.email = data.email || ''
      this.password = data.password || ''
      this.passwordConfirmation = data.passwordConfirmation || ''
      this.document = data.document || ''
      this.accountType = data.accountType || ''
      this.accountTypeId = data.accountTypeId || ''
      this.product = data.product || ''
      this.relatedness = data.relatedness || null
      this.clientURL = REACT_APP_CLIENT_BASE_URL
    }
  }
  
  export default AccountUser
