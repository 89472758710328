import React from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'
import login_aside_bg from '../../images/login_aside_bg.svg'

const AsideContainer = styled(Flex)`
  width: 482px;
  min-height: 454px;
  background-color: #F14F5C;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: url(${login_aside_bg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media only screen and (max-width: 980px) {
    display: none!important;
  }
`

const LoginContainerAside = props => {
    return (
      <AsideContainer style={props.style}>
        {props.children}
      </AsideContainer>
    )
}

export default LoginContainerAside