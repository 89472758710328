import React  from "react";
import Modal from '@mui/material/Modal';
import "./PlansPresentation.css";
import { ReactComponent as CheckedIcon } from "../../../../images/icons/checked_icon.svg";

const PlansPresentation = (props) => {

  const { handleClose, openModal, planType } = props;

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
    >
      <div className="modal-data-privacy-plans-presentation">
        {planType === 'biannual' ? (
          <>
            <h4>Plano Semestral</h4>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Oferecemos 1 <b>Treinamento Anual</b> para a sua empresa</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Tire suas duvidas de Data Privacy e cláusulas no <b>Chat Ilimitado</b></p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p><b>Adicione 2 usuários</b> à sua conta</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Tenha um responsável B.onuz (<b>Data Privacy Account Manager</b>) e obtenha atendimento prioritário</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />            
              <p>Acompanharemos todo processo de adequação da sua empresa.</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />  
              <p><b>Obtenha 20 solicitações de revisão de documentos</b></p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Tenha 4 <b>Calls Mensais</b> com um dos representantes</p>
            </div>
          </>
        ) : (
          <>
            <h4>Plano Anual</h4>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Atuação como <b>DPO</b></p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />  
              <p>Oferecemos 2 <b>Treinamentos Anuais</b> para a sua empresa</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />  
              <p>Tire suas duvidas de Data Privacy e cláusulas no <b>Chat Ilimitado</b></p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p><b>Adicione 2 usuários</b> à sua conta</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Tenha um responsável B.onuz (<b>Data Privacy Account Manager</b>) e obtenha atendimento prioritário</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Acompanharemos todo processo de adequação da sua empresa.</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Obtenha <b>ilimitada solicitações de revisão</b> de documentos</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p><b>Avaliação LGPD de 5 Clientes e/ou Fornecedores</b></p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p>Tenha <b>Calls Ilimitadas</b> com um dos representantes</p>
            </div>
            <div className="modal-data-privacy-plans-presentation-details">
              <CheckedIcon style={{marginRight: '10px'}} />
              <p><b>Relatório Mensal Completo</b></p>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default PlansPresentation;