import * as Yup from 'yup'

const PlanQuestion = Yup.object().shape({
  businessBillings: Yup.string('Este campo aceita apenas caracteres.').required('Campo obrigatório.'),
  businessCaptions: Yup.string('Este campo aceita apenas caracteres.').required('Campo obrigatório.'),
  businessEmployees: Yup.string('Este campo aceita apenas caracteres.').required('Campo obrigatório.'),
  businessRegion: Yup.string('Este campo aceita apenas caracteres.').required('Campo obrigatório.'),
  clientURL: Yup.string('Este campo aceita apenas caracteres.').nullable(),
})

export default PlanQuestion
