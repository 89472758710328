import React from 'react'
import styled from 'styled-components'

const LoginTitleDiv = styled.div`
  font-family: Roboto;
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #F14F5C;
  margin-top: ${props => props.marginTop};
`

const LoginTitleWrapper = props => {
    return (
        <LoginTitleDiv marginTop={props.marginTop}>
            {props.children}
        </LoginTitleDiv>
    )
}

export default LoginTitleWrapper