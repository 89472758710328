import React from 'react';

const IPlus = ({ cor }) => {
  const color = cor || '#FFFFFF'
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99977 0C7.21901 0 7.42926 0.0921874 7.58429 0.256282C7.73931 0.420376 7.82641 0.642936 7.82641 0.875V6.125H12.7862C13.0055 6.125 13.2157 6.21719 13.3708 6.38128C13.5258 6.54538 13.6129 6.76794 13.6129 7C13.6129 7.23206 13.5258 7.45462 13.3708 7.61872C13.2157 7.78281 13.0055 7.875 12.7862 7.875H7.82641V13.125C7.82641 13.3571 7.73931 13.5796 7.58429 13.7437C7.42926 13.9078 7.21901 14 6.99977 14C6.78053 14 6.57027 13.9078 6.41525 13.7437C6.26022 13.5796 6.17313 13.3571 6.17313 13.125V7.875H1.2133C0.994058 7.875 0.7838 7.78281 0.628775 7.61872C0.47375 7.45462 0.386658 7.23206 0.386658 7C0.386658 6.76794 0.47375 6.54538 0.628775 6.38128C0.7838 6.21719 0.994058 6.125 1.2133 6.125H6.17313V0.875C6.17313 0.642936 6.26022 0.420376 6.41525 0.256282C6.57027 0.0921874 6.78053 0 6.99977 0Z" fill=""/>
    </svg>
  )
}

export default IPlus
