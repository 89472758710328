import React from 'react'
import styled from 'styled-components'

const TextDescription = styled.h4`
  margin: 0;
  padding: 2px 0 0 0;
  color: #7A7786;
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
`

const TextFormLabel = styled.h4`
  margin: 0;
  padding: 0;
  color: #9997A0;
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
`

const TextLinkLabel = styled.h4`
  margin: 0;
  padding: 0;
  color: #4E4B59;
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
`

const TextDescriptionForm = props => {
  if(props.textType === 'description') {
    return (
      <TextDescription style={props.style}>
        {props.children}
      </TextDescription>
    )
  }
  if(props.textType === 'formLabel') {
    return (
      <TextFormLabel style={props.style}>
        {props.children}
      </TextFormLabel>
    )
  }
  if(props.textType === 'linkLabel') {
    return (
      <TextLinkLabel style={props.style}>
        {props.children}
      </TextLinkLabel>
    )
  }
}

export default TextDescriptionForm