import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from '@apollo/react-hooks'
import { chatMutations } from '../../graphql/mutations'
import { chatQueries } from '../../graphql/queries'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Spinner from "ui-components/lib/components/Spinner";
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { formatISOTimestampToDate } from "../../utils";
import { ReactComponent as SendMessageIcon } from "../../images/icons/sendMessageIcon.svg";
import { ReactComponent as CloseIcon } from "../../images/icons/close_icon.svg";
import "./ChatDemand.css";

const ChatDemand = (props) =>{
  const { showMessage } = useAlertStack()
  const [message, setMessage] = useState("")
  const [chatMessagesData, setChatMessagesData] = useState([])

  const {
    handleClose,
    openModal,
    demandId,
    demandLawyer,
    subscription
  } = props;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none !important',
    boxShadow: 24,
  };

  const { loading: chatLoading, data: chatData, refetch: chatRefetch } = useQuery(chatQueries.GET_CHAT_MESSAGES, {
    variables: {
      demandId: demandId,
      subscription: subscription
    },
  })

  // const { loading: chatLoading, data: chatData, startPolling, stopPolling, refetch: chatRefetch } = useQuery(chatQueries.GET_CHAT_MESSAGES, {
  //   variables: {
  //     demandId: demandId,
  //   },
  // })

  const [sendChatMessage, { loading: sendChatMessageLoading }] = useMutation(chatMutations.SEND_CHAT_MESSAGES, {
    variables: {
      demandId: demandId ? demandId : null,
      lawyerId: demandLawyer ? demandLawyer._id : null,
      message: message,
      subscription: subscription
    },
    onCompleted(response) {
      setMessage("")
      // if(chatMessagesData){
      //   setChatMessagesData([...chatMessagesData, response.sendChatMessages])
      // } else {
      //   setChatMessagesData([response.sendChatMessages])
      // }
      chatRefetch()
    },
    onError(error) {
      showMessage({
        title: 'Erro ao enviar a mensagem',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const handleChangeMessage = (event) => {
    setMessage(event.target.value)
  }

  const handleSendMessage = () => {
    if(message){
      sendChatMessage()
    }
  }

  const handleSendMessageByKeyPress = (event) => {
    if(event.key === 'Enter' && message){
      sendChatMessage()
    }
  }

  useEffect(() => {
    if(chatData?.getChatMessages) {
      const filteredChatMessages = chatData?.getChatMessages.find(item => item.sender === "lawyer")

      const chatMessages = chatData?.getChatMessages.map((item, index) => {
        let messageCreatedAtDate = formatISOTimestampToDate(item.createdAt).formattedDate
        let messageCreatedAtHour = formatISOTimestampToDate(item.createdAt).formattedHourMinute

        if(!messageCreatedAtDate && !messageCreatedAtHour) {
          messageCreatedAtDate = formatISOTimestampToDate(item.created_at, 'ISO').formattedDate
          messageCreatedAtHour = formatISOTimestampToDate(item.created_at, 'ISO').formattedHourMinute
        }

        return {
          _id: item._id,
          chatId: item.chatId,
          message: item.message,
          sender: item.sender,
          createdAtDate: messageCreatedAtDate,
          createdAtHour: messageCreatedAtHour
        }
      })

      if(!filteredChatMessages){
        chatMessages.push({
          message: `Olá Bem-vindo à B.onuz. Envie sua mensagem e aguarde um momento que ${subscription ? `o advogado especialista` : `um dos nossos advogados especialistas`} já irá te atender.`,
          sender: 'lawyer',
          createdAtDate: false,
          createdAtHour: false
        })
      }
      
      setChatMessagesData(chatMessages)
    }
  }, [chatData]); // eslint-disable-line 

  useEffect(() => {
    if(openModal){
      chatRefetch()
    }
  },[openModal]) // eslint-disable-line


  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      disableAutoFocus
      sx={{zIndex: '9999'}}
    >
      <Box sx={style} className="box-chat">
        <Box className="container-chat">
          <Box className="container-header-chat">
            <Box className="chat-lawyer-name">
              {demandLawyer ? (demandLawyer.displayAs) : ('Chat')}
            </Box>
            <Box className="chat-button-close">
            <button onClick={() => handleClose()}>
              <CloseIcon className="close-icon-chat" />Fechar
            </button> 
            </Box>
          </Box>
          <Box className="container-messages-chat">
            <Box className="container-messages-chat-reverse">
              {chatMessagesData ? (
                chatMessagesData.map((item, index) => (
                  <>
                    {item.createdAtDate && (
                      <Box className="message-createdAt">
                        {item.createdAtDate}
                      </Box>
                    )}
                    {item.sender === 'client' ? (
                      <Box className="message-client-container">
                        <Box className="message-client">
                          {item.message}
                        </Box>
                        {item.createdAtHour}
                      </Box>
                    ) : (
                      <Box className="message-lawyer-container">
                        {item.createdAtHour}
                        <Box className="message-lawyer">
                          {item.message}
                        </Box>
                      </Box>
                    )}
                  </>
                ))
              ) : (
                <Box className="message-lawyer">
                  Você não possui nenhuma mensagem para esta demanda.
                </Box>
              )}
            </Box>
          </Box>
          <Box className="container-footer-chat">
            <FormControl variant="outlined">
              <OutlinedInput
                sx={
                  chatLoading ? {
                    background: '#FAFAFA', 
                    borderRadius: "8px", 
                    border: "1px solid", 
                    borderColor: "#D3D2D7"
                  } : {
                    background: 'transparent', 
                    borderRadius: "8px", 
                    border: "1px solid", 
                    borderColor: "#D3D2D7"
                  }}
                id="outlined-adornment-message"
                value={message}
                onChange={handleChangeMessage}
                onKeyPress={handleSendMessageByKeyPress}
                endAdornment={
                  <InputAdornment position="end">
                    {chatLoading || sendChatMessageLoading ? ( 
                      <Spinner color="primary" /> 
                    ) : (
                      <IconButton
                        onClick={handleSendMessage}                      
                      >
                        <SendMessageIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                placeholder="Escreva uma mensagem"
                inputProps={{
                  'aria-label': 'message',
                }}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChatDemand;
