import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { clientsMutations } from '../../graphql/mutations';
import { clientsQueries } from '../../graphql/queries';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import styled from 'styled-components'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as NotificationIcon } from "../../images/icons/notification_icon.svg";
import { ReactComponent as NotificationIconSmall } from "../../images/icons/notification_icon_small.svg";
import { ReactComponent as NotificationActiveIcon } from "../../images/icons/notification_active_icon.svg";

const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  font-family: 'Roboto';
`

const BoxNotifications = styled(Box)`
  max-width: 20vw;
  padding: 16px 16px 30px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
` 

const HeaderNotifications = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #9997A0;
  border-bottom: 1px solid #D3D2D7;
  padding-bottom: 7px;
` 

const MessagesNotifications = styled(Box)`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #9997A0;
  padding-top: 16px;
` 

const Notifications = props => {
  const history = useHistory()
  const { showMessage } = useAlertStack()
  const [notificationId, setNotificationId] = useState('');
  const [notifications, setNotifications] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { loading: notificationsLoading, data: notificationsData, refetch: notificationsRefetch } = useQuery(clientsQueries.GET_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network'
  })

  const [setReadNotification, { loading: setReadNotificationLoading }] = useMutation(clientsMutations.SET_READ_NOTIFICATION, {
    variables: {
      id: notificationId,
    },
    onCompleted(response) {
      notificationsRefetch()
    },
    onError(error) {
      showMessage({
        title: 'Erro na notificação',
        message: 'Erro ao marcar notificação como lida.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationLink = (id, route) => {
    setNotificationId(id)
    history.push(route)
  };

  useEffect(() => {
    if(notificationId) {
      setReadNotification()
    }
  }, [notificationId, setReadNotification]);

  useEffect(() => {
    if(notificationsData?.getNotifications) {
      setNotifications(notificationsData?.getNotifications)
    }
  }, [notificationsData]);


  return (
    <Wrapper style={props.style}>
      <Box sx={{ display: 'flex', alignItems: 'left', textAlign: 'left' }}>
        <Button
          onClick={handleClick}
          id="notification-btn"
          startIcon={notifications.length ? 
            <NotificationActiveIcon style={{width: '28px', height: '28px'}} />
            : 
            <NotificationIcon style={{width: '24px', height: '24px'}} />
          }
        >
          {props.title}
        </Button>
      </Box>
      <Popover
        id={'notificationsPopover'}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            mt: 0.5,
            borderRadius: '8px',
            '& .MuiMenuItem-root': {
                paddingLeft: '20px',
                paddingRight: '20px',
            },
            '& .MuiMenuItem-root:hover': {
                bgcolor: '#DCDCDC',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <BoxNotifications>
          <HeaderNotifications>
            <NotificationIconSmall style={{width: '15px', height: '16px'}} />
            Notificações
          </HeaderNotifications>
          {notificationsLoading || setReadNotificationLoading ? (
            <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 20px 10px 20px'}}>
              <CircularProgress sx={{color: '#F14F5C'}} size={24} /> 
            </Box>
          ) : (
            notifications.length ? (
              notifications.map((item, index) => (
                <MessagesNotifications>
                  <b>{item.senderName}</b>{' '}{item.message}{' '}
                    {item.linkTo && (
                      <Link
                        component="button"
                        variant="body2"
                        style={{color: '#F14F5C', textDecoration: 'underline', fontWeight: '600', verticalAlign: 'unset'}}
                        onClick={() => handleNotificationLink(item._id, item.linkTo)}
                      >
                        clique aqui
                      </Link>
                    )}
                </MessagesNotifications>
              ))
            ) : ( 
              <MessagesNotifications>
                Você não possui novas notificações
              </MessagesNotifications>
            )  
          )}
        </BoxNotifications>
      </Popover>
    </Wrapper>
  )
}

export default Notifications

