import * as Yup from 'yup'
import cpf from 'node-cpf'

const NaturalPersonSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    type: Yup.string().required(),
    document: Yup.string().test('cpf-test', 'CPF inválido', (value) => cpf.validate(value)).required('Campo obrigatório'),
    personalMobileNumber: Yup.string().min(10, "Deve ser um número válido.").required('Campo obrigatório'),
    personalTelephoneNumber: Yup.string().nullable(),
    addressZipcode: Yup.string().min(8, "Deve ser um CEP válido.").required('Campo obrigatório'),
    addressUF: Yup.string().required('Campo obrigatório'),
    addressCity: Yup.string().required('Campo obrigatório'),
    addressNeighborhood: Yup.string().min(2, "Deve ser maior que 2 carácteres.").required('Campo obrigatório'),
    addressStreet: Yup.string().min(2, "Deve ser maior que 2 carácteres.").required('Campo obrigatório'),
    addressNumber: Yup.string().min(1, "Deve ser maior que 1 caráctere.").required('Campo obrigatório'),
    addressComplement: Yup.string().nullable(),
    buyAsLegalPerson: Yup.boolean().nullable(),
    businessCaptions: Yup.string().when('buyAsLegalPerson', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    businessBillings: Yup.string().when('buyAsLegalPerson', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    businessEmployees: Yup.string().when('buyAsLegalPerson', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    businessRegion: Yup.string().when('buyAsLegalPerson', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    businessDescription: Yup.string().when('buyAsLegalPerson', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    businessTarget: Yup.string().when('buyAsLegalPerson', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    bonuzContact: Yup.string().when('buyAsLegalPerson', {
      is: true,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
  })
  
  export default NaturalPersonSchema
  