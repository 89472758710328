import React from 'react'
import styled from 'styled-components'

const RegisterText = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  width: 100%;
`

const RegisterTextWrapper = props => {
  return (
    <RegisterText style={props.style}>
      {props.children}
    </RegisterText>
  )
}

export default RegisterTextWrapper