import React, { useContext, useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { walletQueries } from '../../graphql/queries'
import Spinner from 'ui-components/lib/components/Spinner'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import Button from 'ui-components/lib/components/Button'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import DashboardContext from '../../contexts/DashboardContext'

const Wrapper = styled(Flex)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: flex-start;
  font-family: 'Roboto';
`

const BoxTop = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const BoxBottom = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4vw;
`

const PackageBox = styled(Box)`
  display: flex;
  min-width: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
`

const BoxOuterContainer = styled(Box)`
  min-width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 11px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.23);
  padding: 10%;
`

const BoxInnerContainer = styled(Box)`
  display: flex;  
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5% 0;
`

const BoxLabel = styled.h4`
  margin: 0;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;  
  color: #FFFFFF;
  padding: 20px 0;
  text-align: center;
`

const BnzLabel = styled.h2`
  font-family: Roboto;
  font-size: 64px;
  font-weight: 900;
  text-align: left;
`

const ServicesTextSmall = styled.span`
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.15px;
  text-align: center;
  padding: 5% 0;
`

const TextSmall = styled.span`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin: 0;
`

const TextLarge = styled.h2`
  font-family: Roboto;
  font-size: 48px;
  font-weight: 900;
  color: #F14F5C;
  text-align: center;
`

const TextSpacer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5% 0;
`

const TextSpacerLine = styled(Box)`
  border: 1px solid;
  width: 6vw;
  height: 0px;
`

const PackagesPresentation = props => {
    const { width } = useWindowDimensions()
    const [packageId, setPackageId] = useContext(DashboardContext); // eslint-disable-line
    const [bnzPackages, setBnzPackages] = useState([{}])

    const { data: dataWalletSettings, loading: loadingWalletSettings } = useQuery(walletQueries.GET_WALLET_SETTINGS)

    useEffect(() => {
      setBnzPackages(dataWalletSettings?.walletSettings?.bnzPackages)
    }, [dataWalletSettings])

    return (
      <Wrapper>
        <BoxTop py={5}>
          <TextLarge style={{margin: 0, padding: '20px 0'}}>Conheça nossos pacotes:</TextLarge>
          <TextSmall style={{textAlign: 'center'}}>Você pode adquirir um de nossos pacotes para acessar o sistema e começar a utilizar seus BNZ's.</TextSmall>  
        </BoxTop>
        <BoxBottom>
        { loadingWalletSettings ? (
          <Spinner color="primary" />
        ) : (
          (bnzPackages || []).map((item, index) => (
            <PackageBox style={item.packageHighlighted ? ({backgroundColor: '#000000'}): ({backgroundColor: '#F14F5C'})}>
              <BoxLabel style={{textTransform: 'uppercase'}}>
                {item.packageName}
              </BoxLabel>
              <BoxOuterContainer flexWrap={'wrap'} style={item.packageHighlighted ? ({backgroundColor: '#F14F5C'}): ({backgroundColor: '#F1F1F1'})}>
                <Box flexWrap={'wrap'} display={'flex'} flexDirection={'row'} alignItems={'baseline'} justifyContent={'center'}>
                  <BnzLabel style={item.packageHighlighted ? ({margin: 0, color: '#FFFFFF'}): ({margin: 0, color: '#F14F5C'})}>
                    {item.packagePrice}
                  </BnzLabel>
                  <TextSmall style={item.packageHighlighted ? ({textAlign: 'left', color: '#FFFFFF'}): ({textAlign: 'left', color: '#F14F5C'})}>
                    BNZ's
                  </TextSmall>
                </Box>
                <BoxInnerContainer>
                  {item.packageServicesToHire && (
                    <ServicesTextSmall style={{color: '#000000', fontWeight: '700'}}>
                      Exemplos de serviços que cabem neste pacote:
                    </ServicesTextSmall>
                  )}
                  {(item.packageServicesToHire || []).map((servicesItem, servicesIndex) => (
                      <>
                        {( servicesIndex !== 0 ) && (
                          <TextSpacer style={item.packageHighlighted ? ({color: '#000000'}): ({color: '#F14F5C'})}>
                            <TextSpacerLine />
                              <TextSmall style={item.packageHighlighted ? ({color: '#000000', fontWeight: '700', padding: '0 5%'}): ({color: '#F14F5C', fontWeight: '700', padding: '0 5%'})}>OU</TextSmall>
                            <TextSpacerLine />
                          </TextSpacer >
                        )}
                        <ServicesTextSmall style={item.packageHighlighted ? ({color: '#FFFFFF', fontWeight: '400'}): ({color: 'rgba(0, 0, 0, 0.4)', fontWeight: '400'})}>
                          {servicesItem}
                        </ServicesTextSmall>
                      </>
                    ))}
                </BoxInnerContainer>
                <Button 
                  style={{
                    color: `${item.packageHighlighted ? ('#F14F5C'): ('#FFFFFF')}`,
                    backgroundColor: `${item.packageHighlighted ? ('#FFFFFF'): ('rgba(0, 0, 0, 0.87)')}`,
                    borderColor: `${item.packageHighlighted ? ('#FFFFFF'): ('rgba(0, 0, 0, 0.87)')}`,
                    maxWidth: '180px',
                    maxHeight: '36px',
                    textTransform: 'uppercase', 
                    fontFamily: 'Roboto', 
                    fontSize: '14px',
                    borderRadius: '2px',
                    fontWeight: '500',
                    letterSpacing: '0.16px',
                    filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))',
                  }}  
                  color={'default'}
                  onClick={() => setPackageId(index)}
                >
                {width >= 900 ? ('Adquirir Pacote') : ('Comprar')}
                </Button>
              </BoxOuterContainer>
            </PackageBox>
          ))
        )}    
        </BoxBottom>              
      </Wrapper>
    )
}

export default PackagesPresentation