import * as Yup from 'yup'
import cpf from 'node-cpf'

const AccountUserSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    name: Yup.string().min(2, "Nome deve ser maior que 2 carácteres.").required('Campo obrigatório'),
    email: Yup.string().email('Deve ser um e-mail válido').required('Campo obrigatório'),
    password: Yup.string().min(8, 'Deve conter no mínimo 8 caracteres').nullable(),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas não estão iguais').nullable(),
    document: Yup.string().test('cpf-test', 'CPF inválido', (value) => cpf.validate(value)).required('Campo obrigatório'),
    product: Yup.string().nullable(),
    accountTypeId: Yup.string().required('Campo obrigatório'),
    relatedness: Yup.string().nullable(),
  })
  
  export default AccountUserSchema
  