import React from 'react'
import styled from 'styled-components'

const LineSpacer = styled.div`
  width: 100%;
  height: 1px;
  background-color: #D3D2D7;
`

const TextSpacer = styled.h4`
  margin: 0;
  padding: 0 8px;
  color: #D3D2D7;
  font-family: 'Inter', Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
`

const ContentSpacerForm = props => {
    return (
      <>  
        <LineSpacer />
        <TextSpacer>OU</TextSpacer>
        <LineSpacer />        
      </>
    )
}

export default ContentSpacerForm