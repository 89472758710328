import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as MailIcon } from '../../images/icons/mail_icon.svg';
import { ReactComponent as LockIcon } from '../../images/icons/lock_icon.svg';
import { ReactComponent as CodeIcon } from '../../images/icons/code_icon.svg';
import { ReactComponent as PersonIcon } from '../../images/icons/person_icon.svg';
import { ReactComponent as TelephoneIcon } from '../../images/icons/telephone_icon.svg';
import { ReactComponent as CardIcon } from '../../images/icons/card_icon.svg';
import { ReactComponent as BuildingIcon } from '../../images/icons/building_icon.svg';
import { ReactComponent as EyeIcon } from '../../images/icons/eye_icon.svg';
import { ReactComponent as EyeHideIcon }from '../../images/icons/eye_hide_icon.svg';
import { ReactComponent as AlertIcon }from '../../images/icons/alert_icon.svg';
import { ReactComponent as SuccessIcon }from '../../images/icons/success_icon.svg';

const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: "8px"
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#D3D2D7"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#7683F6",
    boxShadow: "0px 0px 0px 2px rgba(123, 97, 255, 0.43)"    
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#F14F5C",
    boxShadow: "0px 0px 0px 2px rgba(241, 79, 92, 0.52)"    
  },
  [`& .${outlinedInputClasses.input}`]: {
    fontFamily: "'Inter', Helvetica, sans-serif",
    fontSize: 14,
    fontWeight: 400,
    paddingTop: "13px",
    paddingBottom: "13px",
    color: "#4E4B59"
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: "#4E4B59"
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]: {
    color: "#4E4B59",
  },
  [`& .${inputLabelClasses.outlined}`]: {
    color: "#D3D2D7"
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: "#D3D2D7"
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: "#D3D2D7"
  }
});

const TextFieldInput = props => {

  const [values, setValues] = useState({
    value: '',
    showValue: false,
  });
  
  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
  
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showValue: !values.showValue,
    });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let leftIcon = ''
  let rightIconEye = ''
  let rightIconEyeHide = ''
  let rightIconAlert = <SvgIcon style={{paddingTop: '8px'}} component={AlertIcon} />
  let rightIconSuccess = <SvgIcon style={{paddingTop: '8px'}} component={SuccessIcon} />

  if (props.textFieldType === 'mailField') {
    leftIcon = <SvgIcon style={{paddingTop: '10px'}} component={MailIcon} />
  }

  if (props.textFieldType === 'codeField') {
    leftIcon = <SvgIcon style={{paddingTop: '10px'}} component={CodeIcon} />
  }

  if (props.textFieldType === 'identityField') {
    leftIcon = <SvgIcon style={{paddingTop: '10px'}} component={CardIcon} />
  }

  if (props.textFieldType === 'companyField') {
    leftIcon = <SvgIcon style={{paddingTop: '10px'}} component={BuildingIcon} />
  }

  if (props.textFieldType === 'nameField') {
    leftIcon = <SvgIcon style={{paddingTop: '10px'}} component={PersonIcon} />
  }

  if (props.textFieldType === 'telephoneField') {
    leftIcon = <SvgIcon sx={{ fontSize: 28 }} style={{paddingTop: '10px'}} component={TelephoneIcon} />    
  }

  if (props.textFieldType === 'passwordField') {
    leftIcon = <SvgIcon sx={{ fontSize: 28 }} style={{paddingTop: '10px'}} component={LockIcon} />
    rightIconEye = <SvgIcon sx={{ fontSize: 28 }} style={{padding: '12px 0 0 6px'}} component={EyeIcon} />
    rightIconEyeHide = <SvgIcon sx={{ fontSize: 28 }} style={{padding: '10px 0 0 10px'}} component={EyeHideIcon} />
  }

  return (
    <StyledTextField
    fullWidth
    id={props.id}
    name={props.name}
    label={props.label}
    placeholder={props.placeholder}
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
    onKeyDown={props.onKeyDown}
    onKeyUp={props.onKeyUp}
    error={props.error}
    helperText={props.helperText}
    sx={!props.defaultStyle && !props.error && props.onTouched ? {
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            borderColor: "#44E47A",
            boxShadow: "0px 0px 0px 2px rgba(124, 234, 161, 0.58)"
          }
        }
      } : {
        "& .MuiOutlinedInput-root": {
          "& > fieldset": {
            borderColor: "#D3D2D7"
          }
        }
      }
    }
    type={
      props.type === 'password' ? (
        values.showValue ? 'text' : 'password'
      ) : (
        'text'
      )
    }
    InputProps={
      {
      startAdornment: (
        <InputAdornment position="start">{leftIcon}</InputAdornment>
      ),
      endAdornment: ([
        props.type === 'password' && (
          <InputAdornment position="end">
            <IconButton
              name={'togglePasswordVisibility'}
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {values.showValue ? rightIconEyeHide : rightIconEye}
            </IconButton>
          </InputAdornment>
        ), props.error && props.type !== 'password' && (
          <InputAdornment position="end">
            {rightIconAlert}
          </InputAdornment>          
        ), !props.defaultStyle && props.onTouched && !props.error && props.type !== 'password' && (
          <InputAdornment position="end">
            {rightIconSuccess}
          </InputAdornment>          
        )
      ])
     }
    }
    required={props.required}
    autoFocus={props.autoFocus}        
    InputLabelProps={{ shrink: true }}
    />
  )
}

export default TextFieldInput