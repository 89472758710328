import React, { useEffect, useState} from 'react'
import './FinalPrice.css'
import { useQuery } from '@apollo/react-hooks'
import { walletQueries } from '../../graphql/queries'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { CustomSpinner } from '../index'
const FinalPrice = (props) => {

  const { price, subcriptrionIdentifier } = props
  const [priceWithCreditsDiscount,setPriceWithCreditsDiscount] = useState(0);
  const [credits, setCredits] = useState(0);

  const { data: dataWallet, loading: loadingWallet } = useQuery(walletQueries.GET_MY_WALLET)

  useEffect(() => {
      if(dataWallet.wallet.creditsBonuzPlans !== undefined){
        setCredits(dataWallet.wallet.creditsBonuzPlans)
      }
  },[price,dataWallet.wallet.creditsBonuzPlans])

  useEffect(()=>{
      if(credits > 0){
        const discount = price - credits
        if(discount < 0){
          setPriceWithCreditsDiscount(0)
        }else {
          setPriceWithCreditsDiscount(credits - price)
        }
      }
  },[credits, price])


  function priceWithCreditDiscount(){
    const value = Math.abs(parseInt(priceWithCreditsDiscount));
    const priceFinal = Math.abs(parseInt(price));
    return (
      <div id="final-price-plans">
        <span id="old-price-plans">{priceFinal === 1 ? `${price} BNZ` : `${price} BNZs`}</span>
        <br/>
        {value > 0 && (
          <>
            <span id="current-price-plans">{value === 0 ? `${value} BNZ` : `${value} BNZs`}</span>
          </>
        )}
        <br/>
        <div id="hint-discount-credits">
          <Tooltip 
            title={
              <>
                <b>INCLUSO NO ESSENCIAL</b>
                <br />
                {value > 0 ? `Para solicitar o serviço faltam ${value} BNZ${value > 1 ? 's' : ''}` : '*use até 1350 BNZs/Mês'}
              </>
            }
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#7683F6',
                  '& .MuiTooltip-arrow': {
                    color: '#7683F6',
                  },
                },
              },
            }}
          >
            <IconButton>
              <InfoIcon sx={{ color: '#7683F6' }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  function priceWithoutCreditDiscount(){
    return (
      <div id="final-price-plans">
        <span>{price}</span>
      </div>
    )
  }

  return loadingWallet ?  <CustomSpinner /> : subcriptrionIdentifier === 'plano-essencial' && credits > 0 ? priceWithCreditDiscount() : priceWithoutCreditDiscount()
}

export default FinalPrice
