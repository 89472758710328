import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import theme from 'ui-components/lib/theme'
import { ThemeProvider } from 'styled-components'
import 'typeface-lato'
import GlobalStyles from './utils/GlobalStyles'
import AlertStackProvider from 'ui-components/lib/components/AlertStack/AlertStackProvider'
import AlertStack from 'ui-components/lib/components/AlertStack'

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <AlertStackProvider>
        <App />
        <AlertStack />
      </AlertStackProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
