import { documentsQueries } from '../graphql/queries';
import client from '../graphql/apolloClient'

const fileS3Exists = async (file) => {
  file = decodeURIComponent(file);
  try {
    await client.query({
      query: documentsQueries.GET_S3_EXISTS,
      variables: { file }
    });
    return true
  } catch (error) {
    return false
  }
}

export default fileS3Exists