import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'
import logo from 'ui-components/assets/bonuz_logo_colorido.svg'
import Alert from 'ui-components/lib/components/Alert'
import NaturalPersonForm from './NaturalPerson'
import LegalPersonForm from './LegalPerson'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { RegisterWrapper, RegisterLogo, AlertWrapper } from '../../components/'

const RegisterStep = () => {

  const [legalPersonForm] = useState(true)
  const [naturalPersonForm] = useState(false)

  const [AlertAnalysis] = useState(true)
  const [AlertDenied] = useState(false)

  return (
    <RegisterWrapper>
      <Box mt={5}>
        <RegisterLogo src={logo} alt={'logo Bonuz'} width={216} />
      </Box>
      <AlertWrapper my={4}>
        {AlertAnalysis && (
          <Alert
            color="black"
            icon={faExclamationTriangle}
            message="Seus dados foram registrados e enviados para análise, aguarde nosso contato para mais detalhes."
            noShadow
            title="Cadastro enviado para análise"
          />
        )}
        {AlertDenied && (
          <Alert
            color="black"
            icon={faExclamationTriangle}
            message="Não foi possível dar continuidade ao seu cadastro."
            noShadow
            title="Cadastro não foi efetivado"
          />
        )}
      </AlertWrapper>
      <Flex mb={4} mt={2} flexDirection={'column'} alignItems="center">
        {legalPersonForm && (<NaturalPersonForm/>)}
        {naturalPersonForm && (<LegalPersonForm/>)}
      </Flex>
    </RegisterWrapper>
  )
}

export default RegisterStep
