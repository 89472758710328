import React from 'react'
import styled from 'styled-components'

const WrapperContainer = styled.img`
  width: 24px;
  height: 24px;
`

const SvgWrapper = props => {
    return (
      <WrapperContainer src={props.src} alt={props.alt} style={props.style}>
        {props.children}
      </WrapperContainer>
    )
}

export default SvgWrapper