import React, { useState, useEffect } from 'react'
import { Switch, NavLink as Link, useLocation } from 'react-router-dom'
import Spinner from 'ui-components/lib/components/Spinner'
import BaseLayout from 'ui-components/lib/components/BaseLayout'
import { useFormik } from 'formik'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { demandsQueries, walletQueries, clientsQueries } from '../graphql/queries'
import { Wallet } from '../models'
import * as routes from './routes'
import DashboardContext from '../contexts/DashboardContext'
import PrivateRoute from '../components/PrivateRouter'
import NavHeader from '../components/NavHeader'
import Cart from '../components/Cart'
import RatingModal from '../components/Modal/RatingModal'
import useWindowDimensions from '../hooks/useWindowDimensions'
import logoInline from '../assets/img/logos/logo.svg'
import {ReactComponent as MenuLogoHome} from '../images/menu_logo_home.svg'
import {ReactComponent as MenuLogoRequestService} from '../images/menu_logo_request_service.svg'
import {ReactComponent as MenuPartners} from "../images/icons/partners.svg"
import shortid from 'shortid'
import SmallLogo from "../images/SmallLogo.png"
import { IoIosArrowDropleft, IoIosArrowDropright } from 'react-icons/io'
import {IoCaretDown } from "react-icons/io5";
import * as authService from '../services/auth'
import * as localStorageService from '../services/localStorageService'
import useBeforeunload from '../services/useBeforeUnload'
import './DashboardRoutes.css'
import '../assets/global.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import PrivacyLogo from "../assets/img/logos/dataPrivacy.svg"
import juridicoFamiliar from "../assets/img/logos/juridicoFamiliar.svg"
import SubtabsDropdown from '../components/SubtabDropdown'
import { FaUsers, FaFileContract } from 'react-icons/fa'
import CreateDemandModal from "../components/CreateDemandModal/CreateDemandModal";
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const DashboardSidebar = ({openSidebar, handleCloseMenu, isJp, accountPermissions, ...rest}) => {
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false)
  const handeCloseModal = () => {
    setOpenModal(!openModal)
  }

  let menuToFilter = [
    {
      text: 'Início',
      path: '/dashboard',
      icon: <MenuLogoHome className='svg' style={{width:'24px',height:'auto'}} />,
      routeTo: 'dashboard',
    },
    {
      text: 'Meus serviços',
      path: '/dashboard/serviços',
      icon: <MenuLogoRequestService className='svg' style={{width:'24px',height:'auto'}}/>,
      routeTo: 'services-list',
    },
    {
      text: 'Gestão Contratual',
      path: '/dashboard/gestao-contratual',
      icon: <FaFileContract className='svg' style={{width:'24px',height:'auto', color: 'black'}}/>,
      routeTo: 'contractual-management',
    },
    {
      text: 'Para empresas',
      icon: <FaUsers className='svg' style={{width:'24px',height:'auto', color: 'black'}}/>,
      path: '/dashboard/para-empresas',
      routeTo: 'for-copmpanies',
    },
    {
      text: 'Parceiros',
      icon: <MenuPartners className='svg' style={{width:'24px',height:'auto'}}/>,
      path: '/dashboard/parceiros',
      routeTo: 'partners',          
    },
  ]

  let menuItems = menuToFilter

  if(accountPermissions){
    menuItems = menuToFilter.map((item, index) => {
      let subItemMap

      if(item.subtabs){
        subItemMap = item.subtabs.filter(item => accountPermissions.permissions.includes(item.routeTo))
      }

      return {
        ...item,
        ...subItemMap && {subtabs: subItemMap}
      }
    })
  }

  const valdateRouteActive = (location, path) => {
    if (path === '/dashboard') {
      return location === path;
    }
    return location.startsWith(path);
  }

  return (
    <div className='sidebar'>
      {!isJp?<button onClick={()=>window.location.href = '/dashboard/fala-advogado'} className="sidebutton"><span>Fale com advogado</span></button>:""}
      {/* {!isJp?<button onClick={()=>window.location.href = '/dashboard/bonuz-sign/create'} className="sidebutton-2 signBlue "><span>Assinar contrato</span></button>:""} */}
      {!openSidebar ?
        <div className="nav-left">
          <div className="nav-logo">
            <button ><img src={SmallLogo} alt="bonuz logo, an owl" className="logo" /></button>
          </div>
          <div className="link">
              {menuItems?.map((item) => {
                  if (item.emBreve !== true && !item.subtabs) {
                      return (
                        <Link 
                          to={item.path} 
                          activeClassName="link-active"
                          exact={true}
                        >
                          <div className="nav-icon">
                              <div className="icon-container">{item.icon}</div>
                          </div>
                        </Link>
                      )
                  } else if (item.subtabs) {
                    return (
                      <div className="nav-icon" onClick={() => handleCloseMenu(!openSidebar)}>
                        <div className="icon-container">
                            {item.icon}
                        </div>
                      </div>
                    )
                  }
                  else {
                    return (
                      <div className="nav-icon" onClick={() => alert("Em breve!")}>
                        <div className="icon-container">
                            {item.icon}
                        </div>
                      </div>
                    )
                  }
              })}
          </div>

          <div className="expandir" onClick={() => handleCloseMenu(!openSidebar)}>
              <IoIosArrowDropright />
              <p>Expandir</p>
          </div>
        </div>
      : 
        <div className="nav-left open">
          <div className="nav-logo">
              <button ><img src={location.pathname === "/dashboard/para-empresas/data-privacy" ? PrivacyLogo : (location.pathname.slice(0,27) === "/dashboard/juridico-pessoal") ? juridicoFamiliar : logoInline} alt="bonuz logo, an owl" className="logo"/></button>
          </div>
          <div className="link">
            {menuItems?.map((item) => {
                  if (item.emBreve === true) {
                      return (
                          <div className="nav-icon" style={{cursor: "pointer"}} onClick={() => alert("Em breve!")}>
                          <div className="icon-container">
                              <div>{item.icon}</div>
                              <div><p>{item.text}</p></div>
                          </div>
                      </div>
                      )
                  }else if(item.subtabs) {
                    return (
                        <SubtabsDropdown className="nav-icon" >
                          <div className="icon-container">
                              <div>{item.icon}</div>
                              <div><p>{item.text}</p></div>
                              <div><IoCaretDown style={{marginLeft: "10px"}}/></div>
                          </div>
                          <div className='subtabs'>
                            {item.subtabs.map(item => {
                              if( item.openModal ) {
                                return (
                                  <Link
                                    to={item.path}
                                    activeClassName="link-active"
                                    onClick={(e)=> item.openModal(e)}
                                    exact={true}
                                    isActive={(match, location) => valdateRouteActive(location.pathname, item.path)}
                                  >
                                    <div className="nav-icon">
                                      <div className="icon-container">

                                        <div><p>{item.text}</p></div>
                                        <div>{item.icon}</div>
                                      </div>
                                    </div>
                                  </Link>
                                )
                              } else{
                                return (
                                  <Link
                                    to={item.path}
                                    activeClassName="link-active"
                                    exact={true}
                                    isActive={(match, location) => valdateRouteActive(location.pathname, item.path)}
                                  >
                                    <div className="nav-icon">
                                      <div className="icon-container">

                                        <div><p>{item.text}</p></div>
                                        <div>{item.icon}</div>
                                      </div>
                                    </div>
                                  </Link>
                                )
                              }}
                            )}
                          </div>
                        </SubtabsDropdown>
                    )
                  } else {
                      return(
                          <Link
                            to={item.path} 
                            activeClassName="link-active"
                            exact={true}
                            isActive={(match, location) => valdateRouteActive(location.pathname, item.path)}
                          >
                            <div className="nav-icon">
                                <div className="icon-container">
                                    <div>{item.icon}</div>
                                    <div><p>{item.text}</p></div>
                                </div>
                            </div>
                          </Link>
                      )
                  }
              })}
          </div>

        <div className="expandir recuar" onClick={() => handleCloseMenu(!openSidebar)}>
            <div><IoIosArrowDropleft /></div>
            <div><p>Recuar</p></div>
        </div>
      </div>
      }
      <CreateDemandModal
        openModal={openModal}
        handleClose={handeCloseModal}
      />
      <ToastContainer />
    </div>
  )
}

const DashboardRoutes = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(true)
  const [packageId, setPackageId] = useState('false')

  const [openRatingModal, setOpenRatingModal] = useState(false)
  const [demandRatingData, setDemandRatingData] = useState('')

  const [clientAccountPermissions, setClientAccountPermissions] = useState(false)

  const [clientWallet, setClientWallet] = useState('')
  const [clientSubscription, setClientSubscription] = useState(false)
  const [clientSubscriptionIdentifier,setClientSubscriptionIdentifier] = useState('')
  const [fetchMyWallet, { data: dataMyWallet }] = useLazyQuery(walletQueries.GET_MY_WALLET, {
    onCompleted(data){
      setClientWallet(dataMyWallet)
    }
  })

  const { data: dataMyName, loading: loadingMyName } = useQuery(clientsQueries.GET_MY_NAME)

  const { data: dataAccountPermissions } = useQuery(clientsQueries.GET_ACCOUNT_PERMISSIONS, {
    onCompleted(data){
      setClientAccountPermissions(dataAccountPermissions.getAccountPermissions)
    }
  })

  const { data: dataClientSubscription, loading:dataClientSubscriptionLoading} = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })

  const { data: subscriptionBonuzPlans, loading:subscriptionLoading} = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans',
    }
  })

  const { data: subscriptionDataPrivacy, loading:subscriptionLoadingDataPrivacy} = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'data-privacy',
    }
  })

  useEffect(() => {
    if(subscriptionBonuzPlans){
      if(subscriptionBonuzPlans.subscription.active && subscriptionBonuzPlans.subscription.planIdentifier){
        setClientSubscription(true)
        setClientSubscriptionIdentifier(subscriptionBonuzPlans.subscription.planIdentifier)
      }
    }
  }, [subscriptionBonuzPlans])

  useEffect(() => {
    if(subscriptionDataPrivacy){
      if(subscriptionDataPrivacy.subscription.active && subscriptionDataPrivacy.subscription.planIdentifier){
        setClientSubscription(true)
        setClientSubscriptionIdentifier(subscriptionDataPrivacy.subscription.planIdentifier)
      }
    }
  }, [subscriptionDataPrivacy])

  const { data: openEvaluationData, loading: openEvaluationLoading } = useQuery(demandsQueries.GET_OPEN_EVALUATION)

  useEffect(() => {
    const openEvaluation = localStorageService.getStorage('demand-evaluation')

    if(!openEvaluationLoading && !openEvaluation && openEvaluationData){
      setDemandRatingData({demandId: openEvaluationData?.getOpenEvaluation?.demandId, lawyerId: openEvaluationData?.getOpenEvaluation?.lawyerId})
      setOpenRatingModal(true)
    }
  }, [openEvaluationData, openEvaluationLoading])


  const { width } = useWindowDimensions()
  const largeWidth = width >= 1640 && true

  const userMenu = [
    {
      text: 'Meu Perfil',
      path: '/dashboard/painel',
      routeTo: 'client-panel',
    },
    {
      text: 'Minha Conta',
      path: '/dashboard/minha-conta',
      routeTo: 'my-account',
    },
    {
      text: 'Minha Carteira',
      path: '/dashboard/carteira',
      routeTo: 'wallet',
    },
    {
      text: 'Minha Equipe',
      path: '/dashboard/minha-equipe',
      routeTo: 'my-team',
    },
    {
      text: 'Sair',
      path: '/sair',
      routeTo: 'logout',
    },
  ]

  let menuItems = []

  if(clientAccountPermissions){
    menuItems = userMenu.filter((element) => {
      return clientAccountPermissions.permissions.find(item => item === element.routeTo)
    })
  }

  const formik = useFormik({
    initialValues: new Wallet(),
  })

  useEffect(() => {
    fetchMyWallet()
  }, [fetchMyWallet])


  useEffect(() => {
    let myWallet = (dataMyWallet || {}).wallet
    if (myWallet) {
      if(subscriptionBonuzPlans){
        if(subscriptionBonuzPlans.subscription.active && subscriptionBonuzPlans.subscription.planIdentifier && subscriptionBonuzPlans.subscription.planIdentifier === 'plano-essencial'){
          myWallet.saldoFinal = myWallet.balance;
          myWallet.balance += myWallet.creditsBonuzPlans; 
        }
      }
      formik.setValues(myWallet)
    }
  }, [dataMyWallet, subscriptionBonuzPlans]) // eslint-disable-line

  useEffect(() => {
    if(largeWidth){
      setMenuIsOpen(true)
    }
  }, [largeWidth])

  const toggleMenu = () => {
      setMenuIsOpen(!menuIsOpen)
  }

  const closeMenu = () => {
    setMenuIsOpen(false)
  }

  const decodedToken = authService.decodedToken()

  useBeforeunload((event) => {
    if(decodedToken.keepAlive && decodedToken.keepAlive === 'false'){
      authService.onLogout()
      return true
    } else {
      return true
    }
  });

  return (
    <DashboardContext.Provider value={[ packageId, setPackageId ]}>
      <BaseLayout
        id={"base-layout"}
        title={''}
        cornerContent={
          <NavHeader 
            clientName={ 
              loadingMyName ? <Spinner color="primary" /> : dataMyName?.me?.companyName || `${dataMyName?.me?.firstName} ${dataMyName?.me?.lastName}` 
            }
            bnzBalance={(subscriptionBonuzPlans?.subscription?.planIdentifier === 'plano-essencial' ? formik.values.creditsBonuzPlans : formik.values.balance) || '0'}
            isJp={dataClientSubscription !== undefined}
            jpLoading={dataClientSubscriptionLoading}
            isEssencial={subscriptionBonuzPlans?.subscription?.planIdentifier === 'plano-essencial'}
            plansLoading={subscriptionLoading}
            profileMenuItems={menuItems}
            dataBonuzPlan={subscriptionBonuzPlans}
            dataPrivacy={subscriptionDataPrivacy}
            dataPrivacyLoading={subscriptionLoadingDataPrivacy}
            dataJp={dataClientSubscription}
          />
        }
        menuIsOpen={menuIsOpen}
        handleChangeMenuIsOpen={toggleMenu}
        menuWidth={0}
        style={{background: '#FFFFFF'}}
        sidebar={<DashboardSidebar openSidebar={menuIsOpen} onClose={closeMenu} isJp={dataClientSubscription} handleCloseMenu={toggleMenu} accountPermissions={clientAccountPermissions} />}
      >
        <Switch>
          {routes.dashboard.map(({ ...props }) => { //eslint-disable-line
            let newProps = { ...props }
            if(clientAccountPermissions && !clientAccountPermissions.permissions.find(item => item === props.routeTo)){
              newProps.isAuthorized = false
            }
            return <PrivateRoute key={shortid.generate()} {...newProps} />
          }
          )}
        </Switch>
      </BaseLayout>
      <Cart menuIsOpen={menuIsOpen} wallet={clientWallet} subscription={clientSubscription} subscriptionIdentifier={clientSubscriptionIdentifier} />
      <RatingModal 
        openModal={openRatingModal}
        handleClose={() => setOpenRatingModal(false)}
        demandId={demandRatingData.demandId}
        lawyerId={demandRatingData.lawyerId}
      />
    </DashboardContext.Provider>
  )
}

export default DashboardRoutes
