const tableEssencialStart = {
  backgroundColor: '#FFFFFF',
  title: 'Tabela de serviços',
  titleColor: '#000000',
  borderColor: '#D3D2D7',
  tags: [
    {
      title: 'Essencial',
      class: 'essencial',
    },
    {
      title: 'Start',
      class: 'start',
    }
  ],
  columns: [
    {
      title: 'Serviço',
      backgroundColor: '#F14F5C',
      color: '#FFFFFF'
    },
    {
      title: 'Preço',
      backgroundColor: '#F14F5C',
      color: '#FFFFFF'
    }
  ],
  data: [
    {
      title: 'Registro de marca (acompanhamento por 18 meses incluso)',
      price: '600 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Registro de software',
      price: '1000 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Acordo de Sócios',
      price: '1000 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'AGE/AGO',
      price: '500 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Alteração de contrato social (ACS)',
      price: '250 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Abertura de Emrpesa (LTDA)',
      price: '550 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'NDA',
      price: '200 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Termos de uso e política de privacidade',
      price: '1100 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Registros/paralegal (de acordo com região)',
      price: '400 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Vesting',
      price: '550 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Revisão de Contratos',
      price: '300 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Elaboração de contrato (clinete/fornecedor/funcionário/serviços)',
      price: '500 BNZs',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Fale com Advogado (Consultoria por 30 minutos com especialista)',
      price: '500 BNZs',
      backgroundColor: '#9CA5FB',
      fontColor: '#FFFFFF'
    }
  ]
}

const tablePremium = {
  backgroundColor: '#000000',
  title: 'Tabela de serviços',
  titleColor: '#FFFFFF',
  borderColor: '#D3D2D7',
  tags: [
    {
      title: 'Premium',
      class: 'premium',
    }
  ],
  columns: [
    {
      title: 'Serviço',
      backgroundColor: '#000000',
      color: '#EBC149'
    },
    {
      title: 'Preço',
      backgroundColor: '#000000',
      color: '#EBC149'
    }
  ],
  data: [
    {
      title: 'Revisão de Contrato',
      price: 'R$100,00',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Elaboração de Contrato',
      price: 'R$250,00',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Registro de marca',
      price: 'R$500,00',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Acordo de Sócios',
      price: 'R$1.000,00',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Elaboração de AGE ou AGO',
      price: 'R$500,00',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Registro de documentos - Paralegal SP e RJ',
      price: 'R$400,00',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Demais serviços',
      price: '20% OFF',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Urgência (Entregas até 48 horas)',
      price: '50% do valor',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    },
    {
      title: 'Outro idoma (Ing/Esp)',
      price: '50% do valor',
      backgroundColor: '#565656',
      fontColor: '#FFFFFF'
    }
  ]
}

const tablePlatinum = {
  backgroundColor: '#FFFFFF',
  title: 'Tabela de serviços',
  titleColor: '#000000',
  borderColor: '#D3D2D7',
  subTitle: 'Com direito a 15 solicitações de serviços no mês',
  subTitleColor: '#000000',
  tags: [
    {
      title: 'Platinum',
      class: 'platinum',
    }
  ],
  columns: [
    {
      title: 'Serviço',
      backgroundColor: '#085C6E',
      color: '#FFFFFF'
    }
  ],
  data: [
    {
      title: 'Contratos',
      backgroundColor: '#FFFFFF',
      fontColor: '##33303E'
    },
    {
      title: 'Consultivo trabalhista',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Societário',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Propriedade intelectual com registros',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Abertura de empresas',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Contrato de investimento',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Notificação e respostas extrajudiciais',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Urgência (Entregas até 48 horas)',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    },
    {
      title: 'Outro idoma (Ing/Esp)',
      backgroundColor: '#FFFFFF',
      fontColor: '#33303E'
    }
  ]
}

export const cardsList = [
  {
    title: 'Start',
    colors: {
      border: '#F14F5C',
      title: '#F14F5C',
      line: '',
      background: '#FFFFFF',
      button: '#F14F5C'
    },
    monthlyValue: 300,
    optionsTitle: 'Perfeito para empresas em estágio inicial',
    services: tableEssencialStart,
    options: [
      {
        title: '600 BNZs para usar nas demandas do dia a dia',
        icon: 'ok',
      },
      {
        title: 'Fale com Advogado nas demandas',
       icon: 'ok',
      },
      {
        title: 'SLA conforme o prazo da plataforma',
       icon: 'ok',
      },
      {
        title: 'Gestão Contratual',
       icon: 'notok',
      },
      {
        title: 'Pacote Data Privacy + DPO as a service',
       icon: 'notok',
      },
      {
        title: 'Desconto na Compra De BNZs',
       icon: 'notok',
      },
      {
        title: 'Solicitação de serviços via WhatsApp ou E-mail',
       icon: 'notok',
      },
      {
        title: 'Legal Account Manager',
       icon: 'notok',
      }
    ]
  },
  {
    title: 'Essencial',
    colors: {
      border: '#F14F5C',
      title: '#FB6D06',
      line: '#751F91',
      background: '#FFFFFF',
      button: '#F14F5C'
    },
    monthlyValue: 600,
    optionsTitle: 'Perfeito para Startups em estágio de tração',
    services: tableEssencialStart,
    options: [
      {
        title: 'Gestão Contratual',
        icon: 'ok'
      },
      {
        title: 'Fale com Advogado nas demandas',
        icon: 'ok'
      },
      {
        title: 'SLA conforme o prazo da plataforma',
        icon: 'ok'
      },
      {
        title: '1.350 BNZs Para Uso (Mensal)',
        icon: 'ok'
      },
      {
        title: '10% Off Na Compra De BNZs',
        icon: 'ok'
      },
      {
        title: 'Pacote Data Privacy + DPO as a service',
        icon: 'notok'
      },
      {
        title: 'Solicitação de serviços via WhatsApp ou E-mail',
        icon: 'notok'
      },
      {
        title: 'Legal Account Manager',
        icon: 'notok'
      }
    ]
  },
  {
    title: 'Premium',
    colors: {
      border: '#F14F5C',
      title: '#000000',
      line: '#DBB412',
      background: '#FFFFFF',
      button: '#F14F5C'
    },
    monthlyValue: 1300,
    optionsTitle: 'Perfeito para empresas que precisam de um advogado acompanhando o negócio',
    services: tablePremium,
    options: [
      {
        title: 'Gestão Contratual',
        icon: 'ok'
      },
      {
        title: 'Fale com Advogado nas demandas',
        icon: 'ok'
      },
      {
        title: 'SLA 2 dias',
        icon: 'ok'
      },
      {
        title: 'R$500 para uso em serviços na nossa tabela Premium no mês',
        icon: 'ok'
      },
      {
        title: '20% Off na Compra De BNZs',
        icon: 'ok'
      },
      {
        title: 'Solicitação de serviços via WhatsApp ou E-mail',
        icon: 'ok'
      },
      {
        title: 'Legal Account Manager',
        icon: 'ok'
      },
      {
        title: 'Pacote Data Privacy + DPO as a service',
        icon: 'notok'
      }
    ]
  },
  {
    title: 'Platinum',
    colors: {
      border: '#F14F5C',
      title: '#0A5D6F',
      line: '#DBB412',
      background: '#FFFFFF',
      button: '#F14F5C'
    },
    monthlyValue: 3000,
    optionsTitle: 'Perfeito para empresas com alto volume de demandas',
    services: tablePlatinum,
    options: [
      {
        title: 'Gestão Contratual',
        icon: 'ok'
      },
      {
        title: 'Fale com Advogado nas demandas',
        icon: 'ok'
      },
      {
        title: 'SLA 2 dias',
        icon: 'ok'
      },
      {
        title: '15 serviços por mês já incluso',
        icon: 'ok'
      },
      {
        title: '30% Off Na Compra De BNZs',
        icon: 'ok'
      },
      {
        title: 'Solicitação de serviços via WhatsApp ou E-mail',
        icon: 'ok'
      },
      {
        title: 'Pacote Data Privacy + DPO as a service. Saiba mais',
        icon: 'ok'
      },
      {
        title: 'Legal Account Manager',
        icon: 'ok'
      }
    ]
  }
]
