import React from 'react'
import styled from 'styled-components'
import { Box } from 'reflexbox'
import logoInline from '../../images/logo_inline.png'

const LogoWrapper = styled(Box)`
  display: flex;
  align-items: center;
`

const LogoImg = styled.div`
  background: url(${logoInline});
  background-repeat: no-repeat;
  background-position: center center;
  width: 229px;
  height: 49px;
  box-sizing: border-box;
`

const Logo = () => {
  return (
    <LogoWrapper>
      <LogoImg />  
    </LogoWrapper>
  )
}

export default Logo