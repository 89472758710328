import React from 'react'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Spinner from 'ui-components/lib/components/Spinner'
import { Flex, Box } from 'reflexbox'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { clientsMutations } from '../../graphql/mutations'
import { ForgotPassword } from '../../models'
import { ForgotPasswordSchema } from '../../validate'
import { useHistory } from 'react-router-dom'
import recovery_password_aside_owl from '../../images/recovery_password_aside_owl.svg'

import {
  LoginLogo,
  LoginWrapper,
  LoginContainer,
  LoginContainerAside,
  LoginContentAside,
  LoginFormContainer,
  LoginButtonForm,
  LinkForm,
  TextTitleForm, 
  TextFieldInput,
  TextDescriptionForm,
  FieldLabelForm
} from '../../components'


const ForgotPasswordForm = () => {
  const history = useHistory()

  const { showMessage } = useAlertStack()

  const [forgotPasswordClient, { loading }] = useMutation(clientsMutations.FORGOT_PASSWORD_CLIENT, {
    onCompleted() {
      showMessage({
        title: 'Instruções enviadas',
        message: 'Foram enviadas em seu e-mail as instruções para redefinição de senha.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })

      history.push(`/redefinir?email=${formik.values.email}`)
    },
    onError(error) {
      showMessage({
        title: 'Erro',
        message: 'E-mail não encontrado ou algum erro inesperado.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const formik = useFormik({
    initialValues: new ForgotPassword(),
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      forgotPasswordClient({ variables: values });
      setSubmitting(false);      
    },
  })

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginFormContainer onSubmit={formik.handleSubmit}>
          <LoginLogo />
          <TextTitleForm>Esqueceu sua senha?</TextTitleForm>
          <TextDescriptionForm textType={'description'} style={{padding: '16px 0 0 0'}}>Por favor, insira o endereço de e-mail associado a sua conta</TextDescriptionForm>
          <Box width={[1]} mt={24}>
            <FieldLabelForm>E-mail</FieldLabelForm>
            <TextFieldInput 
              id="email"
              name={'email'}
              textFieldType={'mailField'}
              placeholder={'seunegocio@suaempresa.com'}
              helperText={formik.touched.email && formik.errors.email}              
              type={'text'}                
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleBlur}
              onTouched={formik.touched.email}                     
              error={formik.touched.email && formik.errors.email && true}
              inputProps={{
                autoFocus: true,
              }}
            />
          </Box>
          <Flex width={1} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} style={{padding: '22px 0 16px 0'}}>
            <LoginButtonForm buttonType={'primaryButton'} disabled={!formik.isValid || formik.isSubmitting} type={'submit'}>
              {loading ? <Spinner color="primary" /> : 'Enviar'}
            </LoginButtonForm>
            <LinkForm linkType={'redirect'} to="/">Voltar</LinkForm>
          </Flex>
        </LoginFormContainer>
        <LoginContainerAside style={{padding: '47px 22px 26px 31px'}}>
          <LoginContentAside src={recovery_password_aside_owl} alt={'coruja-bonuz'} style={{width: '100%', height: '100%'}}/>
        </LoginContainerAside>        
      </LoginContainer>
    </LoginWrapper>
  )
}

export default ForgotPasswordForm
