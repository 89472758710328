import * as Yup from 'yup'

export default Yup.object().shape({
  serviceId: Yup.string().nullable(),
  subserviceId: Yup.string().nullable(),
  urgent: Yup.boolean().nullable(),
  bicolumn: Yup.boolean().nullable(),
  extraLanguage: Yup.boolean().nullable(),
  answers: Yup.array().of(
    Yup.object().shape({
      questionId: Yup.string().nullable(),
      attachments: Yup.array().nullable(),
      __required: Yup.boolean().nullable(),
      answer: Yup.string().when('__required', {
        is: true,
        then: Yup.string().required('Campo obrigatório').nullable(),
        otherwise: Yup.string().nullable(),
      }),
    })
  ),
})
