import {
  NotFound,
  Dashboard,
  Wallet,
  Transactions,
  MyAccount,
  MyTeam,
  Demands,
  DemandDetails,
  Proposals,
  Partners,
  PurchaseService,
  ClientDemandsPanel,
  PurchaseDataPrivacy,
  TalkToLawer,
  DataPrivacy,
  BonuzPlans,
  PurchaseBonuzPlans,
  ContractualManagement,
  ContractualManagementForm,
  ContractualManagementAlerts,
  BonuzSign,
  PurchaseBonuzSign,
  Services,
  ForCompanies,
  ContractManagement
} from '../containers'
import Document from '../containers/ContractualManagement/Document';
import DemandDetailsV3 from "../containers/DemandDetails/V3/DemandDetailsV3";


const url_base = '/dashboard'

export const dashboard = [
  { path: `${url_base}`, component: Dashboard, exact: true, routeTo: 'dashboard', jp: true}, // VAI TER ACESSO
  { path: `${url_base}/carteira`, component: Wallet, exact: true, routeTo: 'wallet'}, // NÃO VAI TER
  { path: `${url_base}/transações`, component: Transactions, exact: true, routeTo: 'transactions', jp: false },  // NÃO VAI TER
  { path: `${url_base}/painel`, component: ClientDemandsPanel, exact: true, routeTo: 'client-panel', jp: true }, // VAI TER ACESSO
  { path: `${url_base}/minha-conta`, component: MyAccount, exact: true, routeTo: 'my-account', jp: true }, // VAI TER
  { path: `${url_base}/minha-equipe`, component: MyTeam, exact: true, routeTo: 'my-team', jp: false }, // VAI TER
  { path: `${url_base}/serviços`, component: Services, exact: true, routeTo: 'services-list', jp: true }, // VAI TER
  { path: `${url_base}/para-empresas`, component: ForCompanies, exact: true, routeTo: 'for-companies', jp: true }, // VAI TER
  { path: `${url_base}/gestao-contratual`, component: ContractManagement, exact: true, routeTo: 'contractual-management', jp: true }, // VAI TER
  { path: `${url_base}/serviços/demands`, component: Demands, exact: true, routeTo: 'demands', jp: true }, // VAI TER
  { path: `${url_base}/serviços/propostas`, component: Proposals, exact: true, routeTo: 'proposals', jp: true }, // VAI TER
  { path: `${url_base}/parceiros`, component: Partners, exact: true, routeTo: 'partners', jp: true }, // VAI TER
  { path: `${url_base}/serviços/solicitar/:subscriptionService?`, component: PurchaseService, exact: true, routeTo: 'purchase-service', jp: false }, // NÃO VAI TER
  { path: `${url_base}/serviços/detalhes/:id`, component: DemandDetailsV3, exact: true, routeTo: 'demand-details', jp: true }, // VAI TER
  { path: `${url_base}/serviços/detalhes/proposta/:id`, component: DemandDetails, exact: true, routeTo: 'demand-details-proposals', jp: true }, // VAI TER
  { path: `${url_base}/para-empresas/data-privacy/pagamento`, component: PurchaseDataPrivacy, exact: true, routeTo: 'purchase-data-privacy', jp: false }, // NÃO VAI TER
  { path: `${url_base}/para-empresas/data-privacy`, component: DataPrivacy, exact: true, routeTo: 'data-privacy', jp: false }, // NÃO VAI TER
  { path: `${url_base}/fala-advogado/:subject?`, component: TalkToLawer, exact: true, routeTo: 'talk-to-lawyer', jp: true }, // VAI TER
  { path: `${url_base}/para-empresas/planos`, component: BonuzPlans, exact: true, routeTo: 'bonuz-plans' },
  { path: `${url_base}/para-empresas/planos/pagamento`, component: PurchaseBonuzPlans, exact: true, routeTo: 'purchase-bonuz-plans' },
  { path: `${url_base}/gestao-contratual/documents`, component: ContractualManagement, exact: true, routeTo: 'contractual-management-documents' },
  { path: `${url_base}/gestao-contratual/alerts`, component: ContractualManagementAlerts, exact: true, routeTo: 'contractual-management-alerts' },
  { path: `${url_base}/gestao-contratual/document`, component: ContractualManagementForm, exact: true, routeTo: 'contractual-management-document' },
  { path: `${url_base}/gestao-contratual/document/:id?`, component: Document, exact: true, routeTo: 'contractual-management-document-update' },
  { path: `${url_base}/gestao-contratual/alerts/:id`, component: ContractualManagementAlerts, exact: true, routeTo: 'contractual-management-alert'},
  { path: `${url_base}/bonuz-sign/create`, component: BonuzSign, exact: true, routeTo: 'bonuz-sign-create'},
  { path: `${url_base}/bonuz-sign/purchase`, component: PurchaseBonuzSign, exact: true, routeTo: 'purchase-bonuz-sign'},
  { path: `*`, component: NotFound },
]
