import React, { useState } from 'react'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'
import { Flex } from 'reflexbox'
import { useHistory } from 'react-router-dom'
import './style.css'
import paperAirplane from '../../assets/icons/paperAirplane.svg'
import mailbox from '../../assets/icons/mailbox.svg'
import clipboardList from '../../assets/icons/clipboardList.svg'
import chevron from '../../assets/icons/chevron.svg'
import CreateDemandModal from '../../components/CreateDemandModal/CreateDemandModal'
import UseCountProposals from '../../hooks/UseCountProposals'

const Services = (props) => {
  const history = useHistory()
  const [openModal, setOpenModal] = useState(false)
  
  const handleOpenModal = (e) => {
    e.preventDefault()
    setOpenModal(!openModal)
  }

  const handeCloseModal = () => {
    setOpenModal(!openModal)
  }

  const navigationLinks = [{ label: 'Início', onClick: () => history.push('/dashboard') }, { label: 'Serviços' }]

  return (
    <Flex width={1} justifyContent="center" flexDirection="column" className="service">
      <Flex width={1} mb={3} className="breadCrumb">
        <BreadCrumb ml={-3} navigationLinks={navigationLinks} />
      </Flex>
      <Flex width={1} flexDirection="column">
        <div className='my-services'>
          <h4>Serviços</h4>
          <div className='items'>
            <div className='item'>
              <img src={paperAirplane} alt='' />
              <h5>Solicitar serviço</h5>
              <div className='text'>
                Solicite um Serviço Jurídico que precise. Alguns serviços já possuem um valor determinado e outros você receberá uma proposta específica para aprovação.
              </div>
              <button type='button' onClick={handleOpenModal}> Solicitar serviço <img src={chevron}  alt='' /></button>
            </div>
            <div className='item'>
              <img src={mailbox}  alt='' />
              <h5>Demandas</h5>
              <div className='text'>
                Apresentação de todos os resultados dos serviços que você solicitou recentemente.
              </div>
              <button type='button' onClick={() => history.push('/dashboard/serviços/demands')}> Ver Demandas <img src={chevron}  alt='' /></button>
            </div>
            <div className='item'>
              <UseCountProposals />
              <img src={clipboardList}  alt='' />
              <h5>Propostas</h5>
              <div className='text'>
                Solicite um serviço de Orçamento com um dos nossos AdvogadoS B.onuz. Receba propostas e defina a melhor opção para você!
              </div>
              <button type='button' onClick={() => history.push('/dashboard/serviços/propostas')}> Ver Propostas <img src={chevron}  alt='' /></button>
            </div>
          </div>
        </div>
      </Flex>
      <CreateDemandModal
        openModal={openModal}
        handleClose={handeCloseModal}
      />
    </Flex>
  )
}

export default Services
