import React from 'react'

const Terms = () => {

  return (
    <div>
     <h2>Termos de uso</h2>
    </div>
  )
}

export default Terms
