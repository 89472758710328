import React, {useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Input, Pagination, Select, Textarea } from 'ui-components'
// import SwitchInput from '../../components/SwitchInput'
import Typography from 'ui-components/lib/components/Typography'
import { Flex } from 'reflexbox'
import Table from 'ui-components/lib/components/Table'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { documentsMutations } from '../../graphql/mutations'
import { documentsQueries } from '../../graphql/queries'
import FormLoading from '../../components/HalfLoading'
import { AlertPeriod, AlertReceiver, AlertRepeatPeriod } from '../../components'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { HiPencil, HiTrash } from 'react-icons/hi'
import { FaEllipsisV } from 'react-icons/fa'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './style.css'

const ContractualManagementAlerts = (props) => {
  const history = useHistory()
  const [id,setId] = useState('');
  const [alertName,setAlarmName] = useState('');
  const [alertDescription,setAlertDescription] = useState('');
  const [alertPeriod,setAlertPeriod] = useState('');
  const [alertReceivers,setAlertReceivers] = useState('');
  const [alertRepeatPeriod,setAlertRepeatPeriod] = useState(0);
  const [isDefault, setIsDefault] = useState(false);
  const [sort, setSort] = useState('contractDueDate') //eslint-disable-line
  const [order,setOrder] = useState('desc') //eslint-disable-line
  const [currentPage, setCurrentPage] = useState(1)
  const [updateAlertOperation,setUpdateAlertOperation] = useState(false)
  const [isShow, setIsShow] = useState(true)
  const offset = 10

  const { loading: alertsLoading, data: alertsData,
    refetch: refetchAlerts //eslint-disable-line
  } = useQuery(documentsQueries.GET_ALERTS, {

    variables: {
      sort: sort,
      page: currentPage,
      order,
      offset,
      queryBy: 'status'
    },
  })

  const [createAlert, {loading:createAlertLoading}] = useMutation(documentsMutations.CREATE_ALERT,{
    variables:{
      alertName,
      alertDescription,
      alertPeriod,
      alertReceivers,
      alertRepeatPeriod,
      isDefault
    },
    onCompleted(){
      toast.success('Alerta criado com sucesso.', {
        theme: 'dark'
      })
      clearForm()
      refetchAlerts()
    },
    onError({ graphQLErrors }){
       toast.error(graphQLErrors[0].extensions.response.body || 'Erro ao criar o alerta', {
        theme: 'dark'
      })
    }
  })

  const clearForm = () => {
    setAlarmName('');
    setAlertDescription('');
    setAlertPeriod('');
    setAlertReceivers('')
    setAlertRepeatPeriod(0);
  }

  const [updateAlert, {loading:updateAlertLoading}] = useMutation(documentsMutations.UPDATE_ALERT,{
    variables:{
      id,
      alertName,
      alertDescription,
      alertPeriod,
      alertReceivers,
      alertRepeatPeriod,
      isDefault
    },
    onCompleted(){
      setUpdateAlertOperation(!updateAlertOperation)
      setId('')
      clearForm()
      toast.success('Alerta atualizado com sucesso.', {
        theme: 'dark'
      })
    },
    onError({ graphQLErrors }){
      toast.error(graphQLErrors[0].extensions.response.body || 'Erro ao atualizar o alerta', {
       theme: 'dark'
     })
   }
  })

  const [deleteAlert] = useMutation(documentsMutations.DELETE_ALERT,{
    onCompleted(){
      toast.success('Alerta deletado com sucesso.', {
        theme: 'dark'
      })
      refetchAlerts()
    },
    onError({ graphQLErrors }){
      toast.error(graphQLErrors[0].extensions.response.body || 'Erro ao excluir o alerta ', {
        theme: 'dark'
      })
    }
  })

  const handleChangeNext = () => {
    const nextPage = currentPage + 1
    if (nextPage <= alertsData?.getDocuments?.pagination?.pagesAmount) setCurrentPage(nextPage)
  }

  const handleChangePrev = () => {
    const prevPage = currentPage - 1
    if (prevPage > 0) setCurrentPage(currentPage - 1)
  }

  const handleChangeOrder = (item) => {
    if (item.key === sort) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    } else {
      setSort(item.key)
      setOrder('asc')
    }
  }

  const handleDeleteAlert = (id) => {
    deleteAlert({
      variables:{
        id:id
      }
    });
  }


  const [getAlert, { loading: alertLoading}] = useLazyQuery(documentsQueries.GET_ALERT, {
    variables: {
      id:id,
    },
    onCompleted({ getAlert }){
      setUpdateAlertOperation(true)
      setId(getAlert?._id)
      setAlarmName(getAlert?.alertName)
      setAlertDescription(getAlert?.alertDescription)
      setAlertPeriod(getAlert?.alertPeriod)
      setAlertReceivers(getAlert?.alertReceivers)
      setAlertRepeatPeriod(getAlert?.alertRepeatPeriod)
      setIsDefault(getAlert?.isDefault)
    }
  })


  const setAlertId = (id)=>{
    setId(id)
    setIsShow(false)

    if(id !== ""){
      getAlert({
        variables:{
          id:id
        }
      })
    }
  }

  const navigationLinks = [
    { label: 'Início', onClick: () => history.push('/dashboard') },
    { label: 'Gestão Contratual', onClick: () => history.push('/dashboard/gestao-contratual') },
    { label: 'Alertas' }
  ]

  return (
    <>
      <Flex width={1} justifyContent="center" flexDirection="column" className="service">
        <Flex width={1} mb={3} className="breadCrumb">
          <BreadCrumb ml={-3} navigationLinks={navigationLinks} />
        </Flex>
      </Flex>
      <div className='cm' style={{paddingLeft: '2.5%', paddingRight: '2.5%'}}>
        {!isShow && (
          <>
            <Col style={{width:'15%', marginTop:'30px',marginBottom:'20px'}}>
              <Row xs="auto" style={{flexDirection:'row',flexWrap:'nowrap',alignItems:'center'}}>
                <h3 style={{marginBottom:'0px'}}>
                  {updateAlertOperation?'Atualizar Alerta':'Novo Alerta'}
                </h3>
              </Row>
            </Col>
            <div>
              <Row
                className='doc p-4 formBorder'
              >
                <Col>
                  <h3 style={{marginBottom: '20px'}}>Informações do alerta</h3>
                </Col>
                {alertLoading || createAlertLoading || updateAlertLoading
                  ?
                  <FormLoading/>
                  :
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Nome*</Form.Label>
                            <Input type="text" placeholder="Nome do alerta" value={alertName} onChange={(e)=>{setAlarmName(e.target.value)}}/>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'20px'}}>
                        <Col>
                          <Form.Group>
                            <Form.Label>Descrição*</Form.Label>
                            <Textarea type="text" placeholder="Descrição do alerta" value={alertDescription} onChange={(e)=>{setAlertDescription(e.target.value)}}>
                            </Textarea>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{marginTop:'20px'}}>
                        <Col>
                          <Form.Group>
                            <Form.Label>Destinatários da notificação*</Form.Label>
                            <Input type="email" placeholder="exemplo@exemplo.com" value={alertReceivers} onChange={(e)=>{setAlertReceivers(e.target.value)}}/>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Prazo para o recebimento (em dias)*</Form.Label>
                            <Input type="text" placeholder="dias" value={alertPeriod}  onChange={(e)=>setAlertPeriod(e.target.value)}/>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Repetir alerta*</Form.Label>
                            <Select value={alertRepeatPeriod} onChange={(e)=> {setAlertRepeatPeriod(parseInt(e.target.value))}}>
                              <option selected value="0" disabled>Selecione um alerta</option>
                              <option value='7'>Semanalmente</option>
                              <option value='14'>A cada 2 semanas</option>
                              <option value='30'>Mensalmente</option>
                              <option value='1'>Não repetir</option>
                            </Select>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{color: '#9997A0'}}>
                          * use ; como separador
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              </Row>
            </div>
          </>
        )}
        {isShow && (
          <>
            <Row style={{marginBottom: '20px'}}>
              <Col md='10' style={{marginTop: '20px'}}>
                <h2 className='textBlack'>Documentos</h2>
              </Col>
            </Row>
            <div className='formBorder'>
              <Row>
                <Col sm='4'>
                  <h3>Alertas criados</h3>
                </Col>
                <Col sm='2' className='ms-auto'>
                  <button
                    className='btn btn-red p-2'
                    onClick={() => { setIsShow(!isShow); clearForm();}}
                  >
                    + Criar alerta
                  </button>
                </Col>
                <Col sm='3'>
                  <Select style={{height:'40px'}} onChange={(e)=> handleChangeOrder(e.target.value)}>
                    <option disabled selected>Ordenar por </option>
                    <option value='alertName'>Alerta</option>
                    <option value='alertReceivers'>Quem receberá</option>
                    <option value='alertRepeatPeriod'>Repetir alerta</option>
                    <option value='alertPeriod'>Prazo</option>
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='formAlertBorder table'>
                    <Table
                      flex={1}
                      width={[1]}
                      loading={alertsLoading}
                      refetch={refetchAlerts}
                      onChangeOrder={handleChangeOrder}
                      overflow={'auto'}
                      pagination={()=>{}}
                      className="alert-table"
                      headers={[
                        {
                          title: 'Alerta',
                          key: 'author',
                          sort: false,
                          align: 'left',
                          cellComponent: ({ row }) => <Typography>{row.alertName}</Typography>,
                        },
                        {
                          title: 'Quem receberá',
                          key: 'createdAt',
                          sort: false,
                          align: 'left',
                          cellComponent: ({ row }) => <AlertReceiver alertReceivers={row.alertReceivers}/>,
                        },
                        {
                          title: 'Repetir alerta',
                          key: 'size',
                          sort: false,
                          align: 'left',
                          cellComponent: ({ row }) => <AlertRepeatPeriod alertPeriod={parseInt(row.alertRepeatPeriod)}/>,
                        },
                        {
                          title: 'Prazo para recebimento',
                          key: 'size',
                          sort: false,
                          align: 'left',
                          cellComponent: ({ row }) => <AlertPeriod alertPeriod={parseInt(row.alertPeriod)}/>,
                        },
                        {
                          title: 'Alarme Padrão',
                          key: 'size',
                          sort: false,
                          align: 'left',
                          cellComponent: ({ row }) => <Typography>{row.isDefault?'Sim':'Não'}</Typography>,
                        },
                        {
                          title: 'Ações',
                          key: 'actions',
                          sort: false,
                          align: 'center',
                          width: '50px',
                          cellComponent({ row }) {
                            return (
                              <OverlayTrigger
                                rootClose={true}
                                trigger="click"
                                key={'up'}
                                overlay={
                                  <Popover className="submenu-document" id={`popover-positioned-up-${row._id}`}>
                                    <Popover.Body>
                                      <p style={{marginBottom: '20px'}}>
                                        <HiPencil color={'#000000'} size={'30'}/>&nbsp;<button type='button' className="contract-submenu-option" onClick={() => setAlertId(row._id)}>Editar</button>
                                      </p>
                                      <p style={{marginBottom: '20px'}}>
                                        <HiTrash color={'#000000'} size={'30'}/>&nbsp;<button type='button' className="contract-submenu-option" onClick={() => handleDeleteAlert(row._id)}>Excluir</button>
                                      </p>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <button style={{background: 'transparent', border: 'none', color: '#9997A0'}}><FaEllipsisV/></button>
                              </OverlayTrigger>
                            )
                          }
                        },
                      ]}
                      data={alertsData?.getAlerts?.data}
                    />
                  </div>
                </Col>
                <Flex justifyContent="center">
                  <Pagination
                    color="primary"
                    m={20}
                    onChangePage={(item) => setCurrentPage(item)}
                    onPrevPage={() => handleChangePrev()}
                    onNextPage={() => handleChangeNext()}
                    total={alertsData?.getAlerts?.pagination?.pagesAmount}
                    page={alertsData?.getAlerts?.pagination?.current || 1}
                  />
                </Flex>
              </Row>
            </div>
          </>
        )}
      </div>
      {!isShow && (
          <div className='form-actions'>
            <Button
              onClick={
                () => { setIsShow(!isShow); setId(''); }
              }
              variant='outline-danger'
              size='lg'
              className="p-2"
              style={{marginRight: '20px'}}
            >  
              <FontAwesomeIcon icon={faChevronLeft} /> Voltar
            </Button>
            <Button 
              onClick={
                () => (id ? updateAlert() : createAlert())
              }
              variant='danger'
              size='lg'
            >
              Continuar <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
        )}
    </>
  )
}
export default ContractualManagementAlerts
