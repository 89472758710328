import { CookieStorage } from 'cookie-storage'
import jwtDecode from 'jwt-decode'
const TOKEN = 'token'
const listeners = []

export const addAuthListener = (callback = () => {}) => {
  listeners.push(callback)

  return () => {
    const indexToRemove = listeners.length - 1

    listeners.splice(indexToRemove, 1)
  }
}

export const makeCookieStorage = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const expires = new Date(year + 200, month, day)
  return new CookieStorage({
    expires,
    path: '/'
  })
}
const cookieStorage = makeCookieStorage()

const getStorageAuthKey = (key) => key

export const getAccessToken = () => cookieStorage.getItem(getStorageAuthKey(TOKEN)) || null
export const decodedToken = () => (getAccessToken() && jwtDecode(getAccessToken())) || {}
export const onLogout = () => {
  cookieStorage.clear && cookieStorage.clear()

  listeners.forEach((callback) => callback(false))
}

export const login = ({ token }) => {
  cookieStorage.setItem(getStorageAuthKey(TOKEN), token)

  listeners.forEach((callback) => callback(true))
}

export const updateToken = (token) => {
  cookieStorage.setItem(TOKEN, token)
}

export const loginAsAdmin = (authToken) => {
  cookieStorage.clear && cookieStorage.clear()
  cookieStorage.setItem(getStorageAuthKey(TOKEN), authToken)

  listeners.forEach((callback) => callback(true))
}