import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Flex } from 'reflexbox'
import styled from 'styled-components'
import {clientsQueries, walletQueries, demandsQueries, termsQueries} from '../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import { Loading, AlertsVariants } from '../../components'
import Button from 'ui-components/lib/components/Button'
import Modal from "ui-components/lib/components/Modal";
import useModal from "ui-components/lib/hooks/useModal";
import {clientsMutations} from "../../graphql/mutations";
import useAlertStack from "ui-components/lib/hooks/useAlertStack";
import bannerIfood from '../../images/banner_ifood.svg'
import NoticeCarrousel from '../../components/NoticeCarrousel/NoticeCarrousel'
import MyBnzResume from '../../components/MyBnzResume/MyBnzResume'
import CardInfoModal from '../../components/Modal/CardInfo/CardInfo'
import CustomSpinner from '../../components/CustomSpinner'
import {formatDate} from '../../utils/formatDate'
import Carousel from 'react-bootstrap/Carousel';
import LogoDataPrivacy from "../../assets/img/logos/dataPrivacyWhite.svg"
import PlansMan from '../../assets/img/plansMan.png'
import Tooltip from '@mui/material/Tooltip'
import {ReactComponent as IInfo} from '../../assets/icons/exclamation-circle.svg'
import {ReactComponent as IDone} from '../../assets/icons/check-circle.svg'
import {ReactComponent as IPending} from '../../assets/icons/exclamation.svg'
import {ReactComponent as IInProgress} from '../../assets/icons/exclamation-violet.svg'
import {ReactComponent as ICancel} from '../../assets/icons/vector-circle.svg'
import './styles.css'

const BannerPartner = styled.img`
width: 100%;
max-height: 544px;
box-sizing: border-box;
`

const Dashboard = () => {
  const history = useHistory()

  const { showMessage } = useAlertStack()

  const [openCardInfoModal, setCardInfoModal] = useState(false);
  const [modalInfo, setmModalInfo] = useState(null);
  const [userPlan,setUserPlan] = useState(undefined)

  const [lastStatements, setLastStatements] = useState([]);
  const [countDemandsDone, setCountDemandsDone] = useState(0);
  const [countDemandsInProgress, setCountDemandsInProgress] = useState(0);
  const [countDemandsInReview, setCountDemandsInReview] = useState(0);
  const [countDemandsPending, setCountDemandsPending] = useState(0);
  
  // Termos de uso
  const [stillOpen,setStillOpen] = useState(true)
  let { isOpen: openTerms, closeModal: closeTerms } = useModal()
  
  const { data: privacyPolicyData} = useQuery(termsQueries.GET_PRIVACY_POLICY)

  const { data: termsOfUseData} = useQuery(termsQueries.GET_TERMS_OF_USE)
  
  const { data: statusData, loading: statusLoading } = useQuery(clientsQueries.GET_STATUS, {
    fetchPolicy: 'network-only'
  })
  const { data: myInfoData, loading: myInfoDataLoading } = useQuery(clientsQueries.GET_MY_INFOS, {
    fetchPolicy: 'network-only'
  })
  const { data: partnerData } = useQuery(clientsQueries.GET_PARTNER, {
    fetchPolicy: 'network-only'
  })

  const { loading: loadingClientSubscriptionBonuz } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans'
    },
    onCompleted(data){
      setUserPlan(data?.subscription?.planIdentifier)
    }
  })

  const { loading: loadingClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
    onCompleted(data){
      setUserPlan(data?.subscription?.planIdentifier)
    }
  })

  const { loading: loadingClientSubscriptionDataPrivacy } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'data-privacy'
    },
    onCompleted(data){
      setUserPlan(data?.subscription?.planIdentifier)
    }
  })
  
  const { data: walletData, loading: walletLoading } = useQuery(walletQueries.GET_MY_WALLET)


  const { loading: demandsLoadingDone, data: demandsDataDone } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'done',
    },
  })

  const { loading: demandsLoadingInProgrees, data: demandsDataInProgress } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'in_progress',
    },
  })

  const { loading: demandsLoadingInReview, data: demandsDataInReview } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'in_review',
    },
  })

  const { loading: demandsLoadingPending, data: demandsDataPending } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'pending',
    },
  })

  useEffect(() => {
    let valor = 0;
    if (demandsDataDone) {
      valor = demandsDataDone?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsDone(valor)
  }, [demandsDataDone])

  useEffect(() => {
    let valor = 0;
    if (demandsDataInProgress) {
      valor = demandsDataInProgress?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsInProgress(valor)
  }, [demandsDataInProgress])

  useEffect(() => {
    let valor = 0;
    if (demandsDataInReview) {
      valor = demandsDataInReview?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsInReview(valor)
  }, [demandsDataInReview])

  useEffect(() => {
    let valor = 0;
    if (demandsDataPending) {
      valor = demandsDataPending?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsPending(valor)
  }, [demandsDataPending])

  const { data: dataMyStatement, loading: loadingMyStatement } = useQuery(walletQueries.GET_MY_STATEMENTS, {
    variables: { page: 1, search: '', filterType: '', filterPeriod: '', sort: 'createdAt', order: 'desc', offset: 10 },
  })
  
  useEffect(() => {
    if (statusData && statusData?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [statusData]) // eslint-disable-line

  useEffect(() => {
    if (dataMyStatement) {
      setLastStatements(dataMyStatement?.statements?.data)
      if(!myInfoDataLoading && !myInfoData?.me?.notShowPlanSelection && !myInfoData?.me?.subAccount && dataMyStatement?.statements?.data?.length === 0){
        history.push('/dashboard/para-empresas/planos');
      }
    }
  }, [dataMyStatement, myInfoData, myInfoDataLoading]) // eslint-disable-line


  useEffect(() => {
    let route = null;
    const dataprivacy = [
      'data-privacy-semestral',
      'data-privacy-anual',
    ];
    if(userPlan) {
      if (dataprivacy.indexOf(userPlan) >= 0) {
        route = '/dashboard/para-empresas/data-privacy';
      }
    }
    if (route) {
      history.push(route)
    }
  })

  useEffect(() => {
    let route = null;
    const bonuzPlans = [
      'plano-essencial',
      'plano-premium',
    ];
    if(userPlan) {
      if (bonuzPlans.indexOf(userPlan) >= 0) {
        route = '/dashboard/para-empresas/planos';
      }
    }
    if (route) {
      history.push(route)
    }
  })
  
  const handleChangeCardInfo = (demand) => {
    setCardInfoModal(!openCardInfoModal)
    setmModalInfo(demand)
  }

  const [acceptTermsAndPolicy] = useMutation(clientsMutations.ACCEPT_TERMS, {
    onCompleted(response) {
      showMessage({
        title: 'Termos e politicas aceitos',
        message: 'Você aceitou os termos de uso e as politicas de privacidade',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    refetchQueries: [
      {
        query: clientsQueries.GET_MY_INFOS,
      }
    ],
    onError(error) {
      showMessage({
        title: 'Erro!',
        message: 'Houve um erro ao tentar aceitar os termos e as politicas. Tente novamente',
        color: 'error',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const confirmAcceptTermsPolicy = useCallback(() => {
    acceptTermsAndPolicy()
  }, [acceptTermsAndPolicy])



  if(myInfoData?.me?.agreedPolicy !== true || myInfoData?.me?.agreedTerms !== true){
    openTerms = true
  }

  const toRoute = (route) => history.push(route)

  if (statusLoading || walletLoading || loadingClientSubscription || loadingClientSubscriptionBonuz || loadingClientSubscriptionDataPrivacy) return <Loading />

  if (statusData?.me?.status === 'pending_package') return <AlertsVariants status={statusData?.me?.status} />
  return (
    <div style={{paddingLeft: '2.5%', paddingRight: '2.5%'}}>
      {/* <WelcomeModal
        clientName={dataMyName?.me?.firstName}
        openModal={openWelcomeModal}
        handleClose={handleChangeWelcome}
      /> */}
      <CardInfoModal
        openModal={openCardInfoModal}
        handleClose={handleChangeCardInfo}
        demand={modalInfo}
      />
      <Carousel>
      <Carousel.Item>
        <div className='data-privacy'>
          <div className="banner">
            <img src={LogoDataPrivacy} alt="Bonuz Privacy" />
              <div className="info">
                <h1 className='mb-0'>Já possui Data Privacy? </h1>
                <p>Pelo preço de um estagiário, você terceriza toda a parte de DPO/LGPD do seu negócio. </p>
                <Link to='dashboard/data-privacy'><button className='btn btn-blue px-3 py-2'>Saiba mais</button></Link >
              </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='bonuz-plan'>
        <div className='banner'>
          <div>
            {/* {clientSubscription &&  <p style={{textTransform: 'uppercase'}}>PLANO {clientSubscription.planIdentifier === 'plano-essencial' ? 'essencial' : 'premium'}</p>} */}
            <h1>Assessoria Jurídica para o seu negócio!</h1>
            <p>Conheça nossos planos de Assessoria Jurídica mensal, perfeitos para STARTUPS, pequenas e médias empresas.</p>
          </div>
          <div>
            {/* {!PlansMan && <button>Saiba mais</button>} */}
            <img alt="A man using a suit handling a tablet while picks up a call on his phone" src={PlansMan}/>
          </div>
        </div>
      </div>
      </Carousel.Item>
    </Carousel>
      <Flex alignItems={'center'} flexDirection={'column'}>
        <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3}>
          {(partnerData && partnerData?.me?.partnership === 'Ifood') && (
            <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3}>
              <BannerPartner src={bannerIfood} alt="Banner Ifood" />
            </Flex>
          )}
          <Flex width={1} alignItems={'center'} mt={3} >
            <Flex width={(!userPlan || (userPlan !== 'plano-platinum' && userPlan !== 'plano-premium')) ? 1/2 : 1} alignItems={'center'}>
              <NoticeCarrousel/>
            </Flex>
            {(!userPlan || (userPlan !== 'plano-platinum' && userPlan !== 'plano-premium')) && (
              <Flex width={1/2} alignItems={'center'}>
                <MyBnzResume balance={walletData?.wallet?.balance} reserved={walletData?.wallet?.reserved}/>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex width={1}>
          <Flex width={2.6/5} alignItems={'start'} justifyContent={'flex-start'}>
            {(demandsLoadingDone || demandsLoadingPending || demandsLoadingInProgrees || demandsLoadingInReview) && (
              <CustomSpinner />
            )}
            {(!demandsLoadingDone && !demandsLoadingPending && !demandsLoadingInProgrees && !demandsLoadingInReview) && (
              <div className='dashboard-card-demands'>
                <h2><span className='count'>{ countDemandsDone + countDemandsInProgress + countDemandsInReview + countDemandsPending  }</span> Demandas 
                  <Tooltip 
                    title={
                      <>
                        As demandas são todas as suas tarefas pendentes que você ainda precisa concluir.
                      </>
                    }
                    placement="right"
                    className='dasboard-demandas-tooltip'
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#7683F6',
                          '& .MuiTooltip-arrow': {
                            color: '#7683F6',
                          },
                        },
                      },
                    }}
                  >
                    <IInfo style={{cursor: 'pointer'}} />
                  </Tooltip>
                </h2>
                {((countDemandsDone + countDemandsPending + countDemandsInProgress + countDemandsInReview) === 0) && (
                  <div className='demand-not-found'>
                    Você ainda não possui demandas.
                  </div>
                )}
                {countDemandsDone > 0 && (
                  <div className='dashboard-demand-item'>
                    <h2>
                      <IDone className='icon-demand' />
                      <span className='count green'>{ countDemandsDone }</span> 
                      Demandas concluídas
                    </h2>
                    <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços/demands?status=done')}>Ver detalhes</button>
                  </div>
                )}
                {countDemandsPending > 0 && (
                  <div className='dashboard-demand-item'>
                    <h2>
                      <IPending className='icon-demand' />
                      <span className='count orange'>{ countDemandsPending }</span>
                      Demandas pendentes
                    </h2>
                    <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços/demands?status=pending')}>Ver detalhes</button>
                  </div>
                )}
                {countDemandsInProgress > 0 && (
                  <div className='dashboard-demand-item'>
                    <h2>
                      <IInProgress className='icon-demand' />
                      <span className='count violet'>{ countDemandsInProgress }</span>
                      Demandas em progresso
                    </h2>
                    <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços/demands?status=in_progress')}>Ver detalhes</button>
                  </div>
                )}
                {countDemandsInReview > 0 && (
                  <div className='dashboard-demand-item'>
                    <h2>
                      <ICancel className='icon-demand' />
                      <span className='count red'>{ countDemandsInProgress }</span>
                      Demandas em revisão
                    </h2>
                    <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços/demands?status=in_review')}>Ver detalhes</button>
                  </div>
                )}
              </div>
            )}
          </Flex>
          <Flex width={2.4/5} alignItems={'start'} justifyContent={'flex-start'} ml={20}>
            {loadingMyStatement && (
              <CustomSpinner />
            )}
            {!loadingMyStatement && (
              <div className='dashboard-card-demands transaction'>
                <h2> Transações 
                  <Tooltip 
                    title={
                      <>
                        Transações e compras recentes em serviços solicitados.
                      </>
                    }
                    placement="right"
                    className='dasboard-demandas-tooltip'
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#7683F6',
                          '& .MuiTooltip-arrow': {
                            color: '#7683F6',
                          },
                        },
                      },
                    }}
                  >
                    <IInfo style={{cursor: 'pointer'}} />
                  </Tooltip>
                </h2>
                {(!lastStatements || lastStatements.length === 0) && (
                  <div className='demand-not-found'>
                    Você não fez nenhuma transação recente.
                  </div>
                )}
                {lastStatements && lastStatements.map(item => (
                  <div className='deshboard-transction-item'>
                    <h2 className='amount'>{userPlan ? (`R$ ${item.amount * 2}`) : (`${item.amount} BNZ`)} - {formatDate(item.createdAt).replace(",", "")}</h2>
                    <h2 className='description'>{item.description}</h2>
                  </div>
                ))}
              </div>
            )}
          </Flex>
        </Flex>
        {!myInfoDataLoading && (
          <Modal
            open={openTerms && stillOpen}
            onClose={closeTerms}
            boxProps={{ p: 28 }}
            contentModalProps={{ width: '80vw' }}
            header={<h3>Termos de Serviço</h3>}
            id="termsModal"
          >
            <Flex flexDirection="column">
              <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: termsOfUseData?.termsOfUse?.content }} />
              <h3>Politica de privacidade</h3>
              <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: privacyPolicyData?.privacyPolicy?.content }} />
              <Flex justifyContent="flex-end">
                <Button
                  mt={3}
                  mr={3}
                  color="primary"
                  onClick={
                    () => {
                      confirmAcceptTermsPolicy()
                      setStillOpen(false)
                    }}
                >
                  Aceitar
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )}
      </Flex>
    </div>
  )
}

export default Dashboard
