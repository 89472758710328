import * as Yup from 'yup'
import cnpj from 'node-cnpj'
import cpf from 'node-cpf'

const PersonSchema = Yup.object().shape({
    _id: Yup.string().nullable(),
    documentPerson: Yup.boolean().nullable(),
    document: Yup.string().when('type', {
      is: 'natural',
      then: Yup.string().test('cpf-test', 'CPF inválido', (value) => cpf.validate(value)).required('Campo obrigatório'),
      otherwise: Yup.string().nullable(),
    }),
    cnpj: Yup.string().when('type', {
        is: 'legal',
        then: Yup.string().test('cnpj-test', 'CNPJ inválido', (value) => cnpj.validate(value)).required('Campo obrigatório'),
        otherwise: Yup.string().nullable(),
      }),
    companyName: Yup.string().nullable(),
    tradeName: Yup.string().nullable(),
    financialEmail: Yup.string().email('Deve ser um e-mail válido').nullable(),
    type: Yup.string().required('Campo obrigatório'),
  })
  
  export default PersonSchema