export default function formatNumberToBRL (price) {
  try {
    const convertedPrice = Number(price)

    const brlFormatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })

    return brlFormatter.format(convertedPrice)

  } catch (error) {
    throw new Error('Only number argument')
  }
}
