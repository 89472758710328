class Wallet {
  constructor(data = {}) {
    this.id = data._id || null
    this.clientId = data.clientId || ''
    this.balance = data.balance || ''
    this.reserved = data.reserved || ''
  }
}

export default Wallet
