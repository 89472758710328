import React from 'react'
import styled from 'styled-components'

const AsideOwl = styled.img`
  margin: 0;
`
const LoginContentAside = props => {
  return (
    <AsideOwl src={props.src} alt={props.alt} style={props.style} />
  )
}

export default LoginContentAside