const { REACT_APP_CLIENT_BASE_URL } = process.env

class LegalPersonAccount {
    constructor(data = {}) {
      this.id = data._id || null
      this.type = data._type || 'legal'
      this.addressZipcode = data.addressZipcode || ''
      this.addressUF = data.addressUF || ''
      this.addressCity = data.addressCity || ''
      this.addressStreet = data.addressStreet || ''
      this.addressNumber = data.addressNumber || ''
      this.addressComplement = data.addressComplement || ''
      this.addressNeighborhood = data.addressNeighborhood || ''
      this.businessEmail = data.businessEmail || ''
      this.businessTelephoneNumber = data.businessTelephoneNumber || ''
      this.businessDescription = data.businessDescription || ''
      this.businessTarget = data.businessTarget || ''
      this.password = data.password || null
      this.passwordConfirmation = data.passwordConfirmation || null
      this.articlesOfAssociation = data.articlesOfAssociation || ''
      this.articlesOfAssociationFileName = data.articlesOfAssociationFileName || ''
      this.clientURL = REACT_APP_CLIENT_BASE_URL
    }
  }
  
  export default LegalPersonAccount
  