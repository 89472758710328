import React from 'react'
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const CustomSpinner = props => {
  const {color} = props
  return (
    <Stack 
      sx={{ 
        color: color ? color : '#F14F5C',
        alignSelf: 'center'
      }} 
      spacing={2}
      direction="row"
    >
      <CircularProgress size={24} color="inherit" />
    </Stack>
  )
}

export default CustomSpinner