import gql from 'graphql-tag'

export const GET_MY_WALLET = gql`
  query wallet {
    wallet {
      balance
      reserved
      credit
      creditsBonuzPlans
      contractCredits
    }
  }
`

export const GET_MY_STATEMENTS = gql`
  query statements($sort: String, $order: String, $search: String, $offset: Int, $page: Int, $filterPeriod: String, $filterType: String) {
    statements(sort: $sort, order: $order, search: $search, offset: $offset, page: $page, filterPeriod: $filterPeriod, filterType: $filterType) {
      data {
        _id
        clientId
        description
        type
        amount
        balance
        createdAt
        createdAt
      }
      pagination {
        totalRecords
        pagesAmount
        order
        current
        previous
        next
      }
    }
  }
`

export const GET_CARDS = gql`
  query cards {
    cards {
      _id
      clientId
      token
      holderName
      displayNumber
      brand
      month
      year
      default
      paymentMethodId
      createdAt
      updatedAt
    }
  }
`

export const GET_MY_SUBSCRIPTION = gql`
  query subscription($subscriptionType: String, $planIdentifier: String) {
    subscription(subscriptionType: $subscriptionType, planIdentifier: $planIdentifier) {
      _id
      clientId
      active
      price
      planIdentifier
    }
  }
`

export const GET_MY_SUBSCRIPTIONS = gql`
  query subscriptions {
    subscriptions {
      standardBnzPrice
      currentBnzPrice
      subscriptions {
        id
        subscription
        price
      }
    }
  }
`

export const GET_VOUCHER = gql`
  query voucher($voucherCode: String, $subscriptionType: String) {
    voucher(voucherCode: $voucherCode, subscriptionType: $subscriptionType) {
      id
      discount
    }
  }
`

export const GET_WALLET_SETTINGS = gql`
  query walletSettings {
    walletSettings {
      minimumBnzAmount
      minimumBnzAmountFirstBuy
      price
      creditCardTransactionLimit
      creditCardMaximumInstallments
      creditCardMaximumInstallmentsFirstBuy
      bankSlipMaximumInstallments
      bankSlipMaximumInstallmentsFirstBuy
      bankSlipLateFee
      bankSlipLateFeePercentage
      bankSlipInterestPerDay
      bankSlipInterestPercentage
      bankSlipDaysForTheFirstDeadline
      bankSlipDaysBeforeDeadlineForNotification
      shouldUpdateInformation
      demandInterest
      demandInterestPercentage
      oneTimeBuyInstallments {
        nameInstallments
        maximumInstallmentsCreditCard
        maximumInstallmentsBankSlip
        fromInstallments
        toInstallments
      }
      bnzPackages {
        packageName
        packagePrice
        packageBonuses
        packageInstallments
        packageMaximumInstallmentsCreditCard
        packageMaximumInstallmentsBankSlip
      }
    }
  }
`

export const GET_CREDIT_CARD_REGISTRATION = gql`
  query getCreditCardRegistration($token_: String) {
    getCreditCardRegistration(token_: $token_) {
      _id
    }
  }
`

