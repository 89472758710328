import React, { useState } from "react"
import {ReactComponent as Wallet} from "../../assets/icons/TalkLawer/wallet.svg"
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'

export const MeetTopic = (props) => {
    let changeView = props.changeView
    let meetSubject = props.meetSubject
    let walletData = props.walletData
    let subservice = props.subservice
    const [meetSubjectM,setMeetSubjectM] = useState(props.subjectName);
    const { showMessage } = useAlertStack()

    const handleMeetSubject = (e) => {
      e.preventDefault()
      let error = false

      if (meetSubjectM === "" || meetSubjectM === null) {
        showMessage({
          title: 'Erro ao prosseguir',
          message: 'Todos os campos devem ser preenchidos',
          color: 'alert',
          position: 'bottom-left',
          time: 4000,
        })
        error = true
      }

      if (error === false){
        meetSubject(meetSubjectM)
        changeView(1)
      }
    }

    return (
        <>
          <div className="d-flex justify-content-start mt-3">
            {walletData?.wallet?.balance < subservice?.clientPrice?.amount && !props.isJp ? (
              <div className="alert alert-danger w-100" role="alert">
                <p onClick={()=>window.location.href='/dashboard/carteira'}>Você não possui bnz's suficientes para solicitar este serviço! <strong><u>Comprar BNZ's</u></strong></p>
              </div>
            ):(
              <div>
                <h4 className="mb-3">Você selecionou a opção de agendar uma reunião com um dos nossos advogados. Por favor, nos informe o assunto a ser tratato:</h4>
                <input type='text' value={meetSubjectM} disabled={props.subjectName !== ""} onChange={event => setMeetSubjectM(event.target.value)} name="topic" placeholder="Assunto da reunião"/>
                <button className="btn-position btn btn-blue" onClick={(e) => handleMeetSubject(e)}>Avançar</button>
              </div>
            )}
          </div>
        </>
    )
}

export const MeetDate = ({changeView,meeetDate,googleAgenda}) => {
    const [inputDisable, setInputDisable] = useState(true)
    const [meetDate,setMeetDate] = useState("");
    const { showMessage } = useAlertStack()

    const handleMeetDate = (e) => {
        e.preventDefault()
        let error = false

        if (meetDate === "" || meetDate === null) {
          showMessage({
            title: 'Erro ao prosseguir',
            message: 'Todos os campos devem ser preenchidos',
            color: 'alert',
            position: 'bottom-left',
            time: 4000,
          })
          error = true
        }

        if (error === false){
          meeetDate(meetDate)
          changeView(2)
        }
      }

    return (
        <>
            <h4 className="mb-3">Digite a data desejável para agendar a reunião (no mínimo 3).</h4>
            <textarea required name="date" onChange={event => setMeetDate(event.target.value)} placeholder="Digite a data e o horário desejaveis para a reunião" rows="3" className="form-control mb-5">
            </textarea>
            <div className="d-flex">
                <h4>Compartilhar minha agenda.</h4>
                <input className="form-check-input" required style={{marginLeft: '10px'}} type="checkbox" value="" onClick={() => setInputDisable(!inputDisable)}/>
            </div>
            <p>Compartilhe o link do seu Google agenda</p>
            <input type='text' name="google-meet" required onChange={event => googleAgenda(event.target.value)} placeholder="Link do google agenda" disabled={inputDisable}/>
            <div className="btn-position">
                <button onClick={() => changeView(0)} className="btn btn-out-blue" style={{marginRight: '10px'}}>voltar</button>
                <button onClick={(e) => handleMeetDate(e)} className="btn btn-blue">Avançar</button>
            </div>
        </>
    )
}


export const MeetTime = ({changeView, meetHour}) => {

    const { showMessage } = useAlertStack()

    const options = [
        { value: '8h30', label: '8h30' },
        { value: '9h00', label: '9h00' },
        { value: '9h30', label: '9h30' },
        { value: '10h00', label: '10h00' },
        { value: '10h30', label: '10h30' },
        { value: '11h00', label: '11h00' },
        { value: '11h30', label: '11h30' },
        { value: '12h00', label: '12h00' },
        { value: '12h30', label: '12h30' },
        { value: '13h00', label: '13h00' },
        { value: '13h30', label: '13h30' },
        { value: '14h00', label: '13h30' },
        { value: '14h30', label: '13h30' },
        { value: '15h00', label: '13h30' },
        { value: '15h30', label: '15h30' },
        { value: '16h00', label: '16h00' },
        { value: '16h30', label: '16h30' },
        { value: '17h00', label: '17h00' },
        { value: '17h30', label: '17h30' },
    ];

    const [suggestion1,setSuggestion1] = useState("");
    const [suggestion2,setSuggestion2] = useState("");
    const [suggestion3,setSuggestion3] = useState("");

    const handleSendAllSuggestionHours = (e) =>{
      e.preventDefault()
      let suggestionHours = [suggestion1,suggestion2,suggestion3];
      let suggestionHour = suggestionHours.join(",");
      let error = false

      if(suggestion1 === "" || suggestion2 === "" || suggestion3 === ""){
        showMessage({
          title: 'Erro ao prosseguir',
          message: 'Todos os campos devem ser preenchidos',
          color: 'alert',
          position: 'bottom-left',
          time: 4000,
        })
        error = true
      }


      if(suggestion1 === suggestion2 ||
        suggestion1 === suggestion3 ||
        suggestion2 === suggestion1 ||
        suggestion2 === suggestion3 ||
        suggestion3 === suggestion1 ||
        suggestion3 === suggestion2 ){

        showMessage({
          title: 'Erro ao prosseguir',
          message: 'Os Horários devem ser diferentes',
          color: 'danger',
          position: 'bottom-left',
          time: 4000,
        })
        error = true
      }

      if(error === false){
        meetHour(suggestionHour)
        changeView(3)
      }
    }


    return (
        <>
            <h4 className="mb-4">Escolha até três horários de preferência para marcar a reunião.</h4>
            <h6>Primeira opção</h6>
            <select className="basic-multi-select select form-control" onChange={(event)=>setSuggestion1(event.target.value)}>
              <option selected disabled>Selecione um horário</option>
              {options.map((option,counter)=>{
                return (
                  <option value={option.value}>
                    {option.label}
                  </option>
                )
              })};
            </select>
          <h6>Segunda opção</h6>
          <select className="basic-multi-select select form-control" onChange={(event)=>setSuggestion2(event.target.value)}>
            <option selected disabled>Selecione um horário</option>
            {options.map((option,counter)=>{
              return (
                <option value={option.value}>
                  {option.label}
                </option>
              )
            })};
          </select>
          <h6>Terceira opção</h6>
          <select className="basic-multi-select select form-control" onChange={(event)=>setSuggestion3(event.target.value)}>
            <option selected disabled>Selecione um horário</option>
            {options.map((option,counter)=>{
              return (
                <option key={counter} value={option.value}>
                  {option.label}
                </option>
              )
            })};
          </select>
            <div className="btn-position">
                <button onClick={() => changeView(1)} className="btn btn-out-blue" style={{marginRight: '10px'}}>voltar</button>
                <button onClick={(e) => handleSendAllSuggestionHours(e)} className="btn btn-blue">Avançar</button>
            </div>
        </>
    )
}

export const Payment = ({changeView, subservice,createDemand, isJp}) => {

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
               <div>
                    <h4>Fale com Advogado Bonuz por 30 min.</h4>
                    <p>Um dos nossos especialistas irá contatar</p>
                </div>
                <div className="total">

                  {isJp ?
                    (
                      <>
                        <h6><span><Wallet fill="#F14F5C"/></span> Total da solicitação</h6>
                        <span style={{color: '#F14F5C', fontSize: "24px"}}>1 Crédito</span>
                      </>
                    )
                    :
                    (
                      <>
                        <h6><span><Wallet fill="#F14F5C"/></span> Total da compra</h6>
                        <span style={{color: '#F14F5C', fontSize: "24px"}}>{subservice?.clientPrice?.amount} BNZ's</span>
                      </>
                    )
                  }
                </div>
            </div>
            <div >
                <button onClick={() => changeView(1)} className="btn btn-out-blue" style={{marginRight: '10px'}}>voltar</button>
                <button onClick={() => createDemand()} className="btn btn-blue">Avançar</button>
            </div>
        </>
    )
}

export const Confirm = ({changeView}) => {
    return (
        <>
            <h4>Estamos quase lá!</h4>
            <p style={{color: '#4E4B59'}}>Aguarde um dos nossos especialistas entrar em contato para marcar um horário para reunião.  </p>
            <small le={{color: '#4E4B59'}}>*Mandamos um e-mail com o link da reunião.</small>
            <div className="btn-position">
                <button onClick={() => changeView(3)} className="btn btn-out-blue" style={{marginRight: '10px'}}>voltar</button>
                <button onClick={() => window.location.href = '/dashboard/serviços'} className="btn btn-blue">Avançar</button>
            </div>
        </>
    )
}
