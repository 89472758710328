export const createStorage = (key, data) => {
  localStorage.setItem(key, data)
}

export const getStorage = (key) => {
  return localStorage.getItem(key)  
}

export const removeStorage = (key) => {
  localStorage.removeItem(key)
}

export const clearStorage = () => {
  localStorage.clear()
}