import React from 'react'
import { Typography } from '@mui/material'

const AlertRepeatPeriod = (props) => {
  const alertPeriod = props.alertPeriod;

  switch (alertPeriod){
    case 1:
      return (
        <Typography>Não repetir</Typography>
      );
    case 7:
      return (
        <Typography>Semanalmente</Typography>
      );
    case 14:
      return (
        <Typography>A cada duas semanas</Typography>
      )
    case 30:
      return (
        <Typography>Mensalmente</Typography>
      )
    default:
      return (
        <Typography>Período não especificado</Typography>
      )
  }
}

export default AlertRepeatPeriod;
