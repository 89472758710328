import gql from 'graphql-tag'

export const GET_TYPE = gql`
  query me {
    me {
      type
    }
  }
`

export const GET_MY_NAME = gql`
  query me {
    me {
      firstName
      lastName
      companyName
    }
  }
`

export const GET_STATUS = gql`
  query me {
    me {
      status
    }
  }
`

export const GET_PARTNER = gql`
  query me {
    me {
      partnership
      product
    }
  }
`

export const GET_MY_INFOS = gql`
  query me {
    me {
      email
      buyAsLegalPerson
      type
      personalMobileNumber
      personalTelephoneNumber
      addressZipcode
      addressStreet
      addressNumber
      addressNeighborhood
      addressCity
      addressUF
      addressComplement
      businessEmail
      businessTelephoneNumber
      buyAsLegalPerson
      businessTarget
      businessDescription
      bonuzContact
      agreedPolicy
      agreedTerms
      notShowPlanSelection
      subAccount
      clientCards {
        _id
        holderName
        displayNumber
        brand
        month
        year
        default
      }
    }
  }
`

export const GET_MY_WHATSAPP = gql`
  query whatsAppContactNumber {
    whatsAppContactNumber {
      content
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      _id
      lawyerId
      clientId
      userType
      senderName
      message
      checked
      linkTo
    }
  }
`

export const GET_ACCOUNT_TYPES = gql`
  query getAccountTypes {
    getAccountTypes {
      _id
      type
      permissions
      description
    }
  }
`

export const GET_ACCOUNT_USERS = gql`
  query getAccountUsers {
    getAccountUsers {
      _id
      name
      email
      document
      accountTypeId
      product
      relatedness
    }
  }
`

export const GET_AVAILABLE_LAWYERS = gql`
  query getAvailableLawyers {
    getAvailableLawyers {
      _id
      name
      email
      homologated
    }
  }
`

export const GET_ACCOUNT_USER = gql`
  query getAccountUser($id: String!) {
    getAccountUser(id: $id) {
      _id
      name
      email
      document
      accountTypeId
      product
      relatedness
    }
  }
`

export const GET_ACCOUNT_PERMISSIONS = gql`
  query getAccountPermissions {
    getAccountPermissions {
      permissions
      type
    }
  }
`
