import React, { useEffect, useState } from 'react'
import DropZoneContainer from '../../services/DropZoneContainer'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { useDropzone } from 'react-dropzone'
import Button from 'ui-components/lib/components/Button'
import { Flex, Box } from 'reflexbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { useMutation } from '@apollo/react-hooks'
import { S3_UPLOADER } from '../../graphql/mutations/demands'
import { fileExtension } from '../../utils'
import Spinner from 'ui-components/lib/components/Spinner'
import Typography from 'ui-components/lib/components/Typography'
import fileS3Exists from "../../services/fileS3Exists"

export default ({ question, questionIndex, formik, onLoad = () => {} }) => {
  const { showMessage } = useAlertStack()

  const [isFilesUploading, setUploadingFiles] = useState(false)
  useEffect(() => {
    onLoad(isFilesUploading)
  }, [onLoad, isFilesUploading])

  const addAttachments = (newAttachment = {}) => {
    const answers = formik.values.answers

    // the answer object by index
    answers[questionIndex] = {
      ...formik.values.answers[questionIndex], // re-pass questionId and other attributes
      attachments: [...formik.values.answers[questionIndex].attachments, newAttachment], // mix the new attachment object with current attahcments array
    }

    formik.setValues({
      ...formik.values,
      answers, // re insert answers
    })
  }

  const clearAttachments = () => {
    const answers = formik.values.answers

    // the answer object by index
    answers[questionIndex] = {
      ...formik.values.answers[questionIndex], // re-pass questionId and other attributes
      attachments: [], // clear attachments
    }

    formik.setValues({
      ...formik.values,
      answers,
    })
  }

  const [uploadFile] = useMutation(S3_UPLOADER)

  const onDrop = async (accepted, rejected, event) => {
    clearAttachments()

    setUploadingFiles(true)

    for await (let file of accepted) {
      const { data: { s3uploader: { signedUrl, randomFileName } } = {} } = await uploadFile({
        variables: { fileName: file.name, mimeType: file.type, extension: fileExtension.format(file.name) },
      })

      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      })

      let uploaded = false;
      if (response.ok) {
        const path = new URL(signedUrl)
        uploaded = await fileS3Exists(path.pathname)
      }

      if (uploaded) {
        addAttachments({
          name: file.name,
          randomFileName,
          attachment: null,
          mime: file.type,
          extension: fileExtension.format(file.name),
          fileSize: file.size,
        })
      } else {
        showMessage({
          title: 'Falha de upload do arquivo',
          message: 'Ocorreu um erro durante o upload de um ou mais arquivos, porfavor, tente novamente.',
          color: 'danger',
          position: 'bottom-left',
          time: 4000,
        })
        break;
      }
    }

    setUploadingFiles(false)
  }

  // eslint-disable-next-line
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf, .png, .jpeg, .jpg, .xls, .xlsx, .doc, .docx, .ppt, .pptx',
    maxSize: 15728640,
    multiple: true,
    maxFiles: 3,
    onDropRejected() {
      showMessage({
        title: 'Falha ao selecionar um ou mais arquivos',
        message: 'O arquivo foi rejeitado porque excede o tamanho máximo, ou foram selecionados mais de 3 arquivos.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  return (
    <DropZoneContainer
      className="dropzone"
      aria-required={question.required}
      onDrop={(accepted, rejected, event) => onDrop(accepted, rejected, event, questionIndex)}
      isDragActive={isDragActive && !isFilesUploading}
      mt={2}
      ml={0}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div>
        <center>
          <p>Você pode arrastar, ou clicar no botão para enviar até 3 arquivos.</p>
          <p>Os formatos suportados são .pdf, .png, .jpeg, .jpg, .xls, .xlsx, .doc, .docx, .ppt, .pptx.</p>
          <p>O limite do tamanho de cada arquivo não deve exceder 15 megabytes.</p>
          <Box>
            <Button p={[1, 2]} disabled={isFilesUploading} color="primary">
              <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                {!isFilesUploading && (
                  <>
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                    <Flex mr={2} />
                  </>
                )}
                {isFilesUploading ? <Spinner color="primary" /> : 'Selecionar arquivo'}
              </Flex>
            </Button>
          </Box>
        </center>
      </div>
      {!isFilesUploading && !!acceptedFiles.length && (
        <>
          <Flex mb={2} />
          <Typography>Arquivos:</Typography>
        </>
      )}
      {!isFilesUploading && (
        <>
          {acceptedFiles.map((file) => (
            <Flex flexDirection="row">
              <Typography style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '250px' }}>
                {file.name.substring(0, file.name.length - 4)}
              </Typography>
              <Typography>
                {file.name.substring(file.name.length - 4, file.name.length)} {'('}
                {Number(file.size / 1024 / 1024).toFixed(2)} mb{')'}
              </Typography>
            </Flex>
          ))}
        </>
      )}
    </DropZoneContainer>
  )
}
