import React, {useState, useEffect, useCallback} from "react";
import { demandsQueries, walletQueries } from '../../../../graphql/queries'
import { useQuery } from '@apollo/react-hooks'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "./DemandDetails.css";
import CustomSpinner from '../../../CustomSpinner';
import StatusChip from '../../../StatusChips';
import { ReactComponent as MessageIcon } from "../../../../images/icons/messageIcon.svg";
import {  PurchaseBNZ } from '../../../../components'

const DemandDetails = (props) => {

    const {
        handleClose,
        openModal,
        demandId,
        demandBidId,
        handleOpenChat,
        handleOpenAcceptModal,
        handleOpenRefuseModal,
        isJp
    } = props;

    const [showDetails, setShowDetails] = useState(false)
    const [content, setContent] = useState('')
    const [bonuzPlans, setBonuzPlans] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [clientSubscription, setClientSubscription] = useState('')
    const [servicePrice, setServicePrice] = useState('')
    const [planData, setPlanData] = useState('')

    const openModalPurchaseBNZ = useCallback((price) => {
      setServicePrice(price)
      setModalIsOpen(true)
    }, [])
  
    const closeModalPurchaseBNZ = useCallback(() => {
      setModalIsOpen(false)
      refetchMyWallet()
    }, []) // eslint-disable-line

    const { loading: demandProposalLoading, data: demandProposalData } = useQuery(demandsQueries.GET_DEMAND, {
      variables: {
        id: demandId,
      },
      fetchPolicy: 'cache-and-network',
    })

    const { loading: JPLoading } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
      variables: {
        subscriptionType: 'juridico-pessoal'
      },
      onCompleted(data){
        setClientSubscription(data.subscription)
      }
    })

    const { loading: planDataLoading } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
      variables: {
        subscriptionType: 'planos-bonuz',
        planIdentifier: 'bonuz-plans'
      },
      onCompleted(data){
        const planos = [
          'plano-essencial',
          'plano-start',
          'plano-premium',
          'plano-platinum'
        ];
        let exists = planos.indexOf(data?.subscription?.planIdentifier) >= 0;
        setBonuzPlans(exists)
        setPlanData(data.subscription)
      }
    })

    const { data: walletData, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)

    useEffect(() => {
      if(demandProposalData){
        setContent([demandProposalData])
      }
    }, [demandProposalData]) // eslint-disable-line

    return (
      <>
        <Modal
            open={openModal}
            onClose={handleClose}
            disableAutoFocus
            sx={{zIndex: '9999'}}
        >
            <div className="modal-demand-details-proposal">
              {!demandProposalLoading && !planDataLoading && !JPLoading && content.length > 0 ? (
                content.map((item, index) => (
                  <>
                    {/* <CloseIcon /> */}
                    <Box class="modal-demand-details-proposal-header-container modal-demand-details-proposal-text">
                      <h2>{item.demand.fullDescription}</h2>
                      <Box class="modal-demand-details-proposal-status-container modal-demand-details-proposal-text">
                        <span>Status</span>
                        <StatusChip status="waiting_estimate_approval" />
                      </Box>
                    </Box>
                    <Box className="modal-demand-details-proposal-inner-container modal-demand-details-proposal-text">





                      {item.demand.demandBids && item.demand.demandBids.length > 0 ? (
                        item.demand.demandBids.map((bidsItem, bidsIndex) => (
                          bidsItem._id === demandBidId && (
                            <>
                              <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                                <span>Data da entrega</span>
                                <p>{bidsItem.deadline} d/u</p>
                              </Box>
                              <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                                <span>Advogado responsável</span>
                                <p>{bidsItem.lawyerName ? (bidsItem.lawyerName) : ('-')}</p>
                              </Box>
                            </>
                          )
                        ))
                      ) : (
                        <>
                          <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                            <span>Data da entrega</span>
                            <p>{item.demand.finalDate ? (new Date(Number(item.demand.finalDate)).toLocaleDateString('pt-BR')) : ('-')}</p>
                          </Box>
                          <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                            <span>Advogado responsável</span>
                            <p>{item.demand.lawyer ? (item.demand.lawyer.displayAs) : ('-')}</p>
                          </Box>
                        </>
                      )}






                    </Box>
                    <Box className="modal-demand-details-proposal-middle-container modal-demand-details-proposal-text">
                      <span>Observação/Comentário</span>
                      {item.demand.demandInteractionsHistory.length > 0 && (
                        <h4>{item.demand.demandInteractionsHistory[0].comment}</h4>
                      )}
                    </Box>
                    <Box className="modal-demand-details-proposal-show-details-container modal-demand-details-proposal-text">
                      <span>Informações do serviço</span>
                      <button onClick={() => setShowDetails(!showDetails)}>
                        Detalhes do serviço {showDetails ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </button>
                      {showDetails && (
                        <Box className="modal-demand-details-proposal-show-details-inner-container">
                          {item.demand.demandAnswers.length > 0 && (
                            item.demand.demandAnswers.map((item, index) => (
                              <>
                                <p>{item.description}</p>
                                <h4>{item.answer}</h4>
                              </>
                            ))
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box className="modal-demand-details-proposal-upper-bottom-container  modal-demand-details-proposal-text">
                      <span>Histórico</span>
                      {item.demand.demandBids && item.demand.demandBids.length > 0 ? (
                        item.demand.demandBids.map((bidsItem, bidsIndex) => (
                          bidsItem._id === demandBidId && (
                            <>
                              {(isJp? (bidsItem.price ? `R$ ${bidsItem.price * 2}` : ''): (bidsItem.price ? `${bidsItem.price} BNZs ${bidsItem.isBNZAdquired || bidsItem.isBNZAdquired === null ? '(Adquirido)' : ''}` : ''))}
                            </>
                          )
                        ))
                      ) : (
                        <>
                          {(isJp? (item.demand.price ? `R$ ${item.demand.price * 2}` : ''): (item.demand.price ? `${item.demand.price} BNZs` : ''))}
                        </>
                      )}




                    </Box>
                    <Box className="modal-demand-details-proposal-lower-bottom-container">
                      <Box className="modal-demand-details-proposal-info-container">
                        <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                          <span style={{paddingBottom: '8px'}}>Número</span>
                          <p>#{item.demand.code}</p>
                        </Box>
                        {item.demand.demandInteractionsHistory.length > 0 && (
                          <>
                            <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                              <span style={{paddingBottom: '8px'}}>Data da interação</span>
                              <p>{new Date(Number(item.demand.demandInteractionsHistory[0].createdAt)).toLocaleDateString('pt-BR')}</p>
                            </Box>
                            <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                              <span style={{paddingBottom: '5px'}}>Status</span>
                              <StatusChip status={item.demand.demandInteractionsHistory[0].status} />
                            </Box>
                          </>
                        )}
                        <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text">
                          <span style={{paddingBottom: '2px'}}>Chat Advogado</span>
                          <IconButton disabled={!item.demand.lawyer}>
                            <MessageIcon onClick={() => handleOpenChat({demandId: item.demand._id, demandLawyer: item.demand.lawyer})} />
                          </IconButton>
                        </Box>
                      </Box>


                      {
                        (item.demand.isAuction && item.demand.demandBids.length > 0) &&
                        (
                          (
                            (item.demand.demandBids[0]?.isBNZAdquired === true || item.demand.demandBids[0]?.isBNZAdquired === null || planData?.planIdentifier === 'plano-premium' || planData?.planIdentifier === 'plano-platinum') && 
                              parseFloat(walletData?.wallet?.balance) >= parseFloat(item.demand.demandBids[0]?.price)
                          ) ||
                          (
                            (item.demand.demandBids[0]?.isBNZAdquired === false) &&
                              parseFloat(walletData?.wallet?.balance + walletData?.wallet?.creditsBonuzPlans) >= parseFloat(item.demand.demandBids[0]?.price)
                          ) 
                        ) ?
                        (
                          <Box className="modal-demand-details-proposal-buttons-container">
                            <Button onClick={() => handleOpenRefuseModal(demandBidId)} className="modal-demand-details-proposal-refuse-button">
                              Recusar
                            </Button>
                            <Button onClick={() => handleOpenAcceptModal(demandBidId)} className="modal-demand-details-proposal-accept-button">
                              Aceitar
                            </Button>
                          </Box>
                        )
                        : 
                        (
                          <Box className="modal-demand-details-proposal-inner-container-box modal-demand-details-proposal-text modal-demand-details-proposal-buttons-container">
                            <div>
                              <strong>Você não possui saldo {bonuzPlans ? ` de BNZs` : ``} suficiente para aprovar essa proposta.</strong>
                            </div>
                            <button className="btn" style={{backgroundColor: "#44E47A"}} onClick={() => openModalPurchaseBNZ(item.demand.demandBids[0]?.price)}>{bonuzPlans ? 'Comprar BNZ' : 'Pagamento'}</button>                    
                          </Box> 
                        )
                      }
                    </Box>
                  </>
                ))                  
              ) : (
                <CustomSpinner />
              )}                
            </div>
        </Modal>
        <Modal
          open={modalIsOpen}
          onClose={() => {
            closeModalPurchaseBNZ()
          }}
          disableAutoFocus
          sx={{zIndex: '9999'}}
        >
          <PurchaseBNZ
            closeParentModal={() => {
              closeModalPurchaseBNZ()
            }}
            isJp={clientSubscription || false}
            servicePrice={servicePrice}
          />
        </Modal>
      </>
    );
};

export default DemandDetails;
