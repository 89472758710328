import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const LittleInfoBalloon = ({info}) => {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 150, hide: 150 }}
      overlay={<Tooltip style={{backgroundColor: '#5A67DF', borderRadius: '8px'}}>{info}</Tooltip>}
    >
      <div className="info-toggle">
        <p>?</p>
      </div>
    </OverlayTrigger>
  )
}

export default LittleInfoBalloon
