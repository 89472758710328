import React from "react";
import './ServiceTypeOption.css'
import {Card, Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ServiceTypeOption = (props) => {

  const {nextStep} = props
  const history = useHistory()

  function redirectToRequestDemand(){
    history.push('/dashboard/serviços/solicitar')
  }

  return (
    <Container id={"service-type-option"}>
      <Card>
        <Card.Body>
          <Row>
            <h2>Para quem é a sua solicitação?</h2>
          </Row>
          <div id="service-type-options-buttons">
            <Row>
              <Col>
                <Button variant="secondary" onClick={()=>nextStep(1)}>
                  Minha Equipe
                </Button>
              </Col>
              <Col>
                <Button variant="success" onClick={()=>redirectToRequestDemand()}>
                  Bonuz
                </Button>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </Container>

  )
}

export default ServiceTypeOption
