import React from 'react'
import PropTypes from 'prop-types'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Alert from 'ui-components/lib/components/Alert'

const StatusVariants = ({ status }) => {
  switch (status) {
    case 'active':
      return (<></>)
    case 'pending_package':
      return(
        <Alert 
          icon={faExclamationTriangle}
          color="warning"
          title="Perfil com pendência"
          message="Para solicitar um novo serviço, você precisa de um pacote atrelado ao seu perfil. Por favor, entre em contato com a administração.">
        </Alert>
      )
    case 'pending_articles_of_association':
      return(
        <Alert 
          icon={faExclamationTriangle}
          color="warning"
          title="Perfil com pendência"
          message='Para solicitar um novo serviço, é necessário que você envie seu contrato/estatuto social. Acesse "Minha conta" para envia-lo.'>
        </Alert>
      )
    default:
      return (
        <Alert 
          icon={faExclamationTriangle}
          color="warning"
          title="Perfil com pendências"
          message="Por favor, entre em contato com a administração para resolver as pendências.">
        </Alert>
      )  
  }
}

StatusVariants.propTypes = {
  status: PropTypes.string.isRequired
}

export default StatusVariants
