import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  font-size: 21px;
  font-weight: bold;
`

const FormTitle = props => {
    return (
        <Title>
            {props.children}
        </Title>
    )
}

export default FormTitle