import IClient from '../../../assets/img/bonuzPlan/client.svg'
import IProvider from '../../../assets/img/bonuzPlan/provider.svg'
import ICollaborator from '../../../assets/img/bonuzPlan/collaborator.svg'
import INDA from '../../../assets/img/bonuzPlan/nds.svg'
import IPartnership from '../../../assets/img/bonuzPlan/partnership.svg'
import IVesting from '../../../assets/img/bonuzPlan/vesting.svg'
import IWorker from '../../../assets/img/bonuzPlan/worker.svg'

export const cards = [
  {
    title: 'Cliente',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20com%20cliente'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20com%20cliente'
      }
    ],
    icon: IClient
  },
  {
    title: 'Fornecedor',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20com%20fornecedor'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20com%20fornecedor'
      }
    ],
    icon: IProvider
  },
  {
    title: 'colaborador',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20trabalho'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20trabalho'
      }
    ],
    icon: ICollaborator
  },
  {
    title: 'funcionário (aditivo contratual)',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/aditivo%20ao%20contrato%20de%20trabalho'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/aditivo%20contratual%20de%20trabalho'
      }
    ],
    icon: IWorker
  },
  {
    title: 'funcionário (teletrabalho)',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20teletrabalho'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20teletrabalho'
      }
    ],
    icon: IWorker
  },
  {
    title: 'funcionário (contrato de trabalho)',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20trabalho'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20trabalho'
      }
    ],
    icon: IWorker
  },
  {
    title: 'funcionário (rescisão)',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/rescisão%20contrato%20de%20trabalho'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/rescisão%20contratual%20de%20trabalho'
      }
    ],
    icon: IWorker
  },
  {
    title: 'parceria',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20parcerias'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/contrato%20de%20parceria'
      }
    ],
    icon: IPartnership
  },
  {
    title: 'NDA',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/nda'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/nda'
      }
    ],
    icon: INDA
  },
  {
    title: 'vesting',
    options: [
      {
        title: 'Elaboração de Contrato',
        link: '/dashboard/serviços/solicitar/vesting'
      },
      {
        title: 'Revisão de Contrato',
        link: '/dashboard/serviços/solicitar/vesting'
      }
    ],
    icon: IVesting
  },
]
