import gql from 'graphql-tag'

export const GET_PARTNERS = gql`
  query getPartners {
    getPartners {
      partnerName
      benefits
      textHighlighted
      description
      partnerLink
      showPartner
    }
  }
`
