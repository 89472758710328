import { onError } from 'apollo-link-error'
import * as authService from '../../services/auth'

const unauthorized = () => {
  authService.onLogout();
  (window || {}).location = '/login'
}
const linkError = (...rest) => {
  return onError(({ graphQLErrors, networkError, forward, ...rest }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, ...rest }) => {
        if (window.location.pathname === '/login' && !authService.getAccessToken()) return

        if (message === '401: Unauthorized' || message === 'Unauthorized') {
          return unauthorized()
        }
      })
    }
  })
}
export default linkError
