import React from 'react'
import styled from 'styled-components'

const RegisterFormTitle = styled.div`
  color: #F14F5C;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`

const RegisterFormTitleWrapper = props => {
  return (
    <RegisterFormTitle style={props.style}>
      {props.children}
    </RegisterFormTitle>
  )
}

export default RegisterFormTitleWrapper