import React from 'react'
import styled from 'styled-components'

const Form = styled.form`
  margin-top: 4%;
  max-width: 384px;
  box-sizing: border-box;
  overflow-y: auto;
`

const LoginForm = props => {
    return (
        <Form novalidate onSubmit={props.onSubmit}>
            {props.children}
        </Form>
    )
}

export default LoginForm