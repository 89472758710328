import React from 'react'
import { Typography } from '@mui/material'

const AlertPeriod = (props) => {
  const alertPeriod = props.alertPeriod;

  return (
    <Typography>{alertPeriod} dias</Typography>
  )
}

export default AlertPeriod;
