import * as Yup from 'yup'

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Deve ser um e-mail válido').required('Campo obrigatório'),
  token: Yup.string().required('Campo obrigatório'),
  password: Yup.string().min(8, 'Deve conter no mínimo 8 caracteres').required('Campo obrigatório'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'As senhas não estão iguais').required('Campo obrigatório'),
  resetClientURL: Yup.string(),
})

export default ResetPasswordSchema