import React, { useState, useEffect, useContext, useCallback } from 'react'
import DashboardContext from '../../contexts/DashboardContext'
import InputMask from 'react-input-mask'
import * as Yup from 'yup'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { walletQueries } from '../../graphql/queries'
import { walletsMutations } from '../../graphql/mutations'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import Typography from 'ui-components/lib/components/Typography'
import Select from 'ui-components/lib/components/Select'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { formatNumberToBRL } from '../../utils'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import { Hints } from '../../components';
import CustomTextField from '../CustomTextField';
import SwitchInput from '../SwitchInput';
import CustomSpinner from '../CustomSpinner';
import { useDetectAdBlock } from '../../utils';
import { ReactComponent as CoinIcon } from "../../images/coin.svg";
import { ReactComponent as WalletIcon } from "../../images/icons/wallet_icon.svg";
import { ReactComponent as BankSlipIcon } from "../../images/icons/bank_slip_icon.svg";
import { ReactComponent as CreditCardIcon } from "../../images/icons/credit_card_icon.svg";
import { ReactComponent as ModalCloseIcon } from "../../images/icons/modal_close_icon.svg"
import { ReactComponent as CheckedIcon } from "../../images/icons/checked_icon.svg"
import "./PurchaseBNZ.css";
import RequestService from '../Modal/BonuzPlans/ResquestService'
const { REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID, REACT_APP_PAYMENT_GATEWAY_SANDBOX } = process.env



export default (props) => {
  const { isJp, servicePrice } = props

  const { showMessage } = useAlertStack()
  const [packageId] = useContext(DashboardContext)
  const [newCard, setNewCard] = useState(false)
  const [bankSlipSuccess, setBankSlipSuccess] = useState(false)
  const [cardInstallments, setCardInstallments] = useState([])
  const [cardSelectedInstallment, setCardSelectedInstallment] = useState(1)
  const [cardChecked, setCardChecked] = useState(false)
  const [creditCardChecked, setCreditCardChecked] = useState(false)
  const [bankSlipInstallments, setBankSlipInstallments] = useState([])
  const [bankSlipSelectedInstallment, setBankSlipSelectedInstallment] = useState(1)
  const [bankSlipChecked, setBankSlipChecked] = useState(false)
  const [shouldSaveCard, setShouldSaveCard] = useState(true)
  const [nonSavedCards, setNonSavedCards] = useState([])
  const [finalPrice, setFinalPrice] = useState(0)
  const [cardToken, setCardToken] = useState(null)
  const [bnzQuantity, setBnzQuantity] = useState('')
  const [bnzQuantityError, setBnzQuantityError] = useState(false)
  const [cardBrandMask, setCardBrandMask] = useState('9999 9999 9999 9999')
  const [expirationMask, setExpirationMask] = useState('99/99')
  const [clientFirstBuy, setClientFirstBuy] = useState(false)
  const [oneTimeBuyInstallments, setOneTimeBuyInstallments] = useState()
  const [bnzPackages, setBnzPackages] = useState([{}])
  const [selectedBnzPackage, setSelectedBnzPackage] = useState('')
  const [minimumBnzAmountAllowed, setMinimumBnzAmountAllowed] = useState('')
  const [enableDiscountCoupons, setEnableDiscountCoupons] = useState(true)
  const [enablePackagesBonuses, setEnablePackagesBonuses] = useState(true)
  const [clientSubscription, setClientSubscription] = useState('')
  const [demandPrice, setDemandPrice] = useState('')
  const [activeClientSubscriptions, setActiveClientSubscriptions] = useState(false)
  const [discountPercentage,setDiscountPercentage] = useState(0)
  const [discountEssencial, setDiscountEssencial] = useState(0)
  const [discountPremium, setDiscountPremium] = useState(0)
  const adBlockDetected = useDetectAdBlock();
  const [upgradeToPremium,setUpgradeToPremium] = useState(false)
  const [finalBalance, setFinalBalance] = useState(0)
  const handleOpenUpgradeToPremium = (e) => {
    e.preventDefault()
    setUpgradeToPremium(!upgradeToPremium)
  }
  
  const { data: dataClientCards, loading: loadingClientCards, refetch: cardsRefetch } = useQuery(walletQueries.GET_CARDS)
  const { data: dataWalletStatements, loading: loadingWalletStatements } = useQuery(walletQueries.GET_MY_STATEMENTS);
  const { data: dataWalletSettings, loading: loadingWalletSettings } = useQuery(walletQueries.GET_WALLET_SETTINGS);
  const { data: dataWalletSubscriptions, loading: loadingWalletSubscription  } = useQuery(walletQueries.GET_MY_SUBSCRIPTIONS);

  const [getVoucher, { data: dataVoucher, error: errorVoucher, loading: loadingVoucher }] = useLazyQuery(walletQueries.GET_VOUCHER)
  const {data:planData, loading:loadingEssencial} = useQuery(walletQueries.GET_MY_SUBSCRIPTION,{
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans'
    },
  })

  const [purchaseBNZ, { loading: loadingPurchaseBNZ }] = useMutation(walletsMutations.PURCHASE_BNZ, {
    onCompleted(response) {
      const { purchaseBNZ: dataPurchase = {} } = response
      const { invoiceId = '', invoiceUrl = '' } = dataPurchase

      setNonSavedCards([])

      if (invoiceId) {
        props.closeParentModal()

        showMessage({
          title: 'Compra feita com sucesso',
          message: 'Sua compra foi feita com sucesso.',
          color: 'success',
          position: 'bottom-left',
          time: 4000,
        })
      } else {
        setBankSlipSuccess(invoiceUrl)
      }
    },
    onError() {
      showMessage({
        title: 'Compra não realizada',
        message: 'Ocorreu um erro ao processar sua compra, tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
    update: graphqlHelpers.deleteItemsFromCache('Card'),
  })

  const [saveCard, { loading: loadingSaveCard }] = useMutation(walletsMutations.SAVE_CARD, {
    onCompleted() {
      cardsRefetch()
      setNewCard(false)
    },
    onError(error) {
      alert('Erro desconhecido. Verifique as informações.')
    },
    update: graphqlHelpers.deleteItemsFromCache('Card')
  })


  useEffect(() => {
    if(bnzPackages[packageId]){
      setSelectedBnzPackage(`${packageId}`)
      setBnzQuantity(bnzPackages[packageId].packagePrice)
      setMinimumBnzAmountAllowed(0)
    } else {
      if(!loadingWalletStatements && !loadingWalletSettings){
        if(!dataWalletStatements.statements.data.length){
          setClientFirstBuy(true)
          if(demandPrice){
            setBnzQuantity(demandPrice)
            setMinimumBnzAmountAllowed(demandPrice)
          } else {
            setBnzQuantity(dataWalletSettings.walletSettings.minimumBnzAmountFirstBuy)
            setMinimumBnzAmountAllowed(dataWalletSettings.walletSettings.minimumBnzAmountFirstBuy)
          }
        } else{
          setClientFirstBuy(false)
          if(demandPrice){
            setBnzQuantity(demandPrice)
            setMinimumBnzAmountAllowed(demandPrice)
          } else {
            setBnzQuantity(dataWalletSettings.walletSettings.minimumBnzAmount)
            setMinimumBnzAmountAllowed(dataWalletSettings.walletSettings.minimumBnzAmount)
          }
        }  
      }
    }
    setDiscountPercentage((demandPrice / finalPrice) * 100);
  }, [packageId, bnzPackages, loadingWalletStatements, loadingWalletSettings, demandPrice]) // eslint-disable-line


  useEffect(() => {
    if (finalBalance) {
      let cardInstallments = []
      let bankSlipInstallments = []
      let creditCardMaximumInstallmentsEnabled = []
      let bankSlipMaximumInstallmentsEnabled = []

      if(selectedBnzPackage && bnzPackages[selectedBnzPackage]?.packageInstallments){
        creditCardMaximumInstallmentsEnabled = bnzPackages[selectedBnzPackage].packageMaximumInstallmentsCreditCard
        bankSlipMaximumInstallmentsEnabled = bnzPackages[selectedBnzPackage].packageMaximumInstallmentsBankSlip
      } else {
        if(clientFirstBuy){
          creditCardMaximumInstallmentsEnabled = dataWalletSettings?.walletSettings?.creditCardMaximumInstallmentsFirstBuy
          bankSlipMaximumInstallmentsEnabled = dataWalletSettings?.walletSettings?.bankSlipMaximumInstallmentsFirstBuy
        } else {
          if(oneTimeBuyInstallments){
            const selectedInstallments = oneTimeBuyInstallments.find((item, index) => bnzQuantity >= item.fromInstallments && bnzQuantity <= item.toInstallments)
            if(selectedInstallments){
              creditCardMaximumInstallmentsEnabled = selectedInstallments.maximumInstallmentsCreditCard
              bankSlipMaximumInstallmentsEnabled = selectedInstallments.maximumInstallmentsBankSlip
            } else {
              creditCardMaximumInstallmentsEnabled = dataWalletSettings?.walletSettings?.creditCardMaximumInstallments
              bankSlipMaximumInstallmentsEnabled = dataWalletSettings?.walletSettings?.bankSlipMaximumInstallments
            }
          } else {
            creditCardMaximumInstallmentsEnabled = dataWalletSettings?.walletSettings?.creditCardMaximumInstallments
            bankSlipMaximumInstallmentsEnabled = dataWalletSettings?.walletSettings?.bankSlipMaximumInstallments
          }
        }
      }

      for (let i = 1; i <= creditCardMaximumInstallmentsEnabled; i++) {
        const installmentPrice = formatNumberToBRL(finalBalance / i)

        cardInstallments.push({ price: installmentPrice })
      }

      for (let i = 1; i <= bankSlipMaximumInstallmentsEnabled; i++) {
        const installmentPrice = formatNumberToBRL(finalBalance / i)

        bankSlipInstallments.push({ price: installmentPrice })
      }

      setCardInstallments(cardInstallments)  
      setBankSlipInstallments(bankSlipInstallments)
    }
  }, [finalBalance, selectedBnzPackage]) //eslint-disable-line

  useEffect(() => {
    const { Iugu } = window

    const isSandbox = REACT_APP_PAYMENT_GATEWAY_SANDBOX === 'true' ? true : false
    Iugu.setAccountID(REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID)
    Iugu.setTestMode(isSandbox)
  }, []) // eslint-disable

  useEffect(() => {
    let discount = 0;
    let essencialDiscount = 0;
    let premiumDiscount = 0;
    let balance = 0;

    if (!isNaN(finalPrice)) {
      essencialDiscount = finalPrice - (finalPrice * 0.1);
      premiumDiscount = finalPrice - (finalPrice * 0.2);

      const planIdentifier = planData?.subscription?.planIdentifier;
      if (planIdentifier === 'plano-essencial') {
        discount = 10;
      } else if (planIdentifier === 'plano-premium') {
        discount = 20;
      }

      balance = finalPrice - (finalPrice * discount / 100);
    }

    setDiscountPercentage(parseFloat(discount.toFixed(2)));
    setDiscountEssencial(parseFloat(essencialDiscount.toFixed(2)));
    setDiscountPremium(parseFloat(premiumDiscount.toFixed(2)));
    setFinalBalance(parseFloat(balance).toFixed(2));
  }, [finalPrice, bnzQuantity])  //eslint-disable-line

  const handleChangeCard = () => {
    const { Iugu } = window
    const element = document.getElementById('newPaymentCard')

    Iugu.createPaymentToken(element, (response) => {
      if (response.errors) {
        alert('Erro com o cartão. Por favor, verifique as informações.')
      } else {
        const { id: cardToken, extra_info } = response
        const { brand, display_number: displayNumber, holder_name: holderName, month, year } = extra_info

        if (shouldSaveCard) {
          saveCard({ variables: { token: cardToken, isDefault: true, brand, displayNumber, holderName, month, year } })
        } else {
          setNonSavedCards([
            ...nonSavedCards,
            {
              token: cardToken,
              brand,
              displayNumber,
            },
          ])

          setNewCard(false)
        }
      }
    })
  }

  const handleCardChecked = useCallback((event) => {
    const token = event.target.value

    setCardToken(token)

    setBankSlipChecked(false)
    setCardChecked(true)
  }, [])

  const handleCreditCardChecked = useCallback((event) => {
    setCreditCardChecked(true)
    setBankSlipChecked(false)
  }, [])

  const handleBankSlipChecked = useCallback((event) => {
    setCardChecked(false)
    setCardToken(null)
    setBankSlipChecked(true)
    setCreditCardChecked(false)
  }, [])

  const handleChangeBnzQuantity = useCallback((event) => {
    if (!event.target.value) {
      setBnzQuantity()
    } else {
      const bnzQuantityFieldValue = Number(event.target.value)

      setBnzQuantityError(false)
      setBnzQuantity(bnzQuantityFieldValue)
    }
  }, []) //eslint-disable-line

  const handleCardSelectedInstallment = useCallback((event) => {
    setCardSelectedInstallment(event.target.value)
  }, [])

  const handleBankSlipInstallmentsSelect = useCallback((event) => {
    setBankSlipSelectedInstallment(event.target.value)
  }, [])

  const handleShouldSaveCard = () => {
    setShouldSaveCard(!shouldSaveCard)
  }

  // Preço final com/sem coupon ------------------------------------
  useEffect(() => {
    let currentBnzPrice = dataWalletSubscriptions?.subscriptions?.currentBnzPrice
    
    if(dataVoucher){
      const discount = (dataWalletSubscriptions?.subscriptions?.currentBnzPrice * Number(dataVoucher.voucher.discount)) / 100
      const discountedPrice = (dataWalletSubscriptions?.subscriptions?.currentBnzPrice - discount).toFixed(2)
      currentBnzPrice = discountedPrice
    }

    if(selectedBnzPackage){
      if(dataWalletSubscriptions?.subscriptions?.currentBnzPrice > dataWalletSubscriptions?.subscriptions?.standardBnzPrice){
        currentBnzPrice = dataWalletSubscriptions?.subscriptions?.standardBnzPrice
      }
    }
    setFinalPrice(bnzQuantity * currentBnzPrice)
  }, [bnzQuantity, selectedBnzPackage, dataVoucher, errorVoucher]) //eslint-disable-line
  // ---------------------------------------------------------------

  // Coupon de desconto --------------------------------------------  
  const handleCheckVoucherCode = useCallback((event) => {
    const voucherCode = event.target.value

    if(voucherCode.length === 8){
      getVoucher({ variables: { voucherCode: voucherCode } })
    }
  }, []) //eslint-disable-line
  // ---------------------------------------------------------------

  useEffect(() => {
    if (dataWalletSettings?.walletSettings) {
      setBnzPackages(dataWalletSettings?.walletSettings?.bnzPackages)
      setOneTimeBuyInstallments(dataWalletSettings?.walletSettings?.oneTimeBuyInstallments)

      if(isJp){
        setClientSubscription('jp')
      }

      if (servicePrice){ 
        setDemandPrice(Number(servicePrice))
      }

      if(dataWalletSubscriptions?.subscriptions?.subscriptions?.length){
        setActiveClientSubscriptions(true)
        setEnableDiscountCoupons(false)
        setEnablePackagesBonuses(false)
      }

    }
  }, [isJp, servicePrice, dataWalletSettings, dataWalletSubscriptions])


  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()

      try {
        setBnzQuantityError(false)
        const minimumBnzAmount = minimumBnzAmountAllowed
        const bnzQuantityValidation = Yup.number()
          .positive()
          .moreThan(minimumBnzAmount - 1)
          .integer()
          .required()

        await bnzQuantityValidation.validate(bnzQuantity, {
          abortEarly: false,
        })

        purchaseBNZ({
          variables: {
            bnzAmount: bnzQuantity,
            finalPrice: parseFloat(finalBalance),
            installments: bankSlipChecked ? Number(bankSlipSelectedInstallment) : Number(cardSelectedInstallment),
            isBankSlip: bankSlipChecked,
            cardToken,
            bnzPackageId: selectedBnzPackage ? selectedBnzPackage : null,
            voucherId: dataVoucher?.voucher?.id || null
          },
        })
      } catch (error) {
        setBnzQuantityError(!bnzQuantityError)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataWalletSettings, bnzQuantity, bankSlipChecked, bankSlipInstallments, bankSlipSelectedInstallment, cardToken, cardSelectedInstallment]
  )

  const finalPriceCalculation = (finalPrice, minimumBnz, bnzAmount) => {
    if (!finalPrice || (minimumBnz > bnzAmount)) {
      return formatNumberToBRL(0)
    } else {
      return formatNumberToBRL(finalPrice)
    }
  }

  const handleSetBnzPackage = (index) => {
    if(index){
      setSelectedBnzPackage(index)
      setBnzQuantity(bnzPackages[index].packagePrice)
      setMinimumBnzAmountAllowed(0)
      setEnableDiscountCoupons(false)
    } else {
      setSelectedBnzPackage(index)
      setBnzQuantity(clientFirstBuy ? Number(dataWalletSettings?.walletSettings.minimumBnzAmountFirstBuy) : Number(dataWalletSettings?.walletSettings.minimumBnzAmount))
      setMinimumBnzAmountAllowed(clientFirstBuy ? Number(dataWalletSettings?.walletSettings.minimumBnzAmountFirstBuy) : Number(dataWalletSettings?.walletSettings.minimumBnzAmount))
      setEnableDiscountCoupons(true)
    }
  }

  const updateCardBrandMask = useCallback((event) => {
    const { Iugu } = window

    let brand
    const cardNumber = document.getElementById('number')

    if (cardNumber && Iugu) {
      brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber.value.replace(/\D/g, ''))
    }

    if(event.key === "Backspace"){
      setCardBrandMask("9999999999999999")
    } else {
      if (brand === 'amex') {
        setCardBrandMask('9999 9999999 9999')
      } else if (brand === 'diners') {
        setCardBrandMask('9999 999999 9999')
      } else {
        setCardBrandMask('9999 9999 9999 9999')
      }
    }
  }, [])

  const updateExpirationMask = useCallback((event) => {
    if(event.key === "Backspace"){
      setExpirationMask("9999")
    } else {
      setExpirationMask('99/99')
    }
  }, [])


  if (loadingClientCards || loadingWalletSettings || loadingWalletStatements || loadingWalletSubscription || loadingEssencial){
    return (
      <Box className="purchaseBnz-wrapper">
        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CustomSpinner />
        </Box>
      </Box>  
    )
  }

  return (
    <div>
      <Box className="purchaseBnz-wrapper">
      {bankSlipSuccess ? (
        <>
          <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <FontAwesomeIcon icon={faCheck} color={'#f94e5c'} style={{ fontSize: '36px' }} />
            <h2>Compra realizada com sucesso</h2>
            <Typography m={2}>
              Clique{' '} <a href={bankSlipSuccess} target="noopener noreferrer _blank"> aqui</a>{' '}para acessar o boleto.
            </Typography>
          </Box>
          <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <Box>
              <Button onClick={() => props.closeParentModal && props.closeParentModal()} className="purchaseBnz-main-button">
                Fechar
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <Box className="purchaseBnz-title">
            {clientSubscription !== 'jp' ? 'Comprar BNZs' : 'Pagamento'}
          </Box>
          {clientSubscription !== 'jp' ? (
            <Box className="purchaseBnz-hint-section">
              <Hints
                hintInfo={'O valor do seu BNZ é fixo! Caso compre BNZs de forma avulsa será aplicada uma taxa.'}
              />
            </Box> 
          ) : ('')}
          <Box className="purchaseBnz-header-section">
            {clientSubscription !== 'jp' && (
              <Box className="purchaseBnz-header-section-left-container">
                <CoinIcon style={{width: '62px', height: '62px'}} />
                <Box className="purchaseBnz-header-section-inner-wrapper">
                  <Box className="purchaseBnz-header-section-field-label">
                    Quantidade de BNZs
                  </Box>
                  <InputMask mask="99999" value={bnzQuantity} onChange={handleChangeBnzQuantity} maskChar={null} disabled={selectedBnzPackage || demandPrice}>
                    {(inputProps) => (
                      <CustomTextField
                        placeholder={minimumBnzAmountAllowed}
                        sx={{padding: '10px 0 15px 0'}}
                      />
                    )}
                  </InputMask>
                  <Box className="purchaseBnz-header-section-field-helper-text">
                    {selectedBnzPackage ? (
                      `Pacote ${bnzPackages[selectedBnzPackage].packageName} selecionado`
                    ) : (
                      `*${demandPrice ? `valor da demanda: ` : `mínimo de `}${minimumBnzAmountAllowed} BNZs`
                    )}  
                  </Box>
                </Box>
              </Box>
            )}
            <Box className="purchaseBnz-header-section-right-container">
              <Box className="purchaseBnz-header-section-right-container-title">
                <WalletIcon style={{paddingRight: '9px'}} /> Valor a pagar
              </Box>
              <div className="row">
                <div className="col">
                  <p>Desconto</p>
                </div>
                <div className="col">
                  <strong>{discountPercentage}%</strong>
                </div>
              </div>
              <Box className="purchaseBnz-header-section-right-container-price">
                {finalPriceCalculation(finalBalance, minimumBnzAmountAllowed, bnzQuantity)}
              </Box>
            </Box>
          </Box>
          {!planData && (
            <div className="row">
              <p className="discount-orange">Seja cliente <strong>Essencial</strong> e pague <span>{formatNumberToBRL(discountEssencial)}</span>&nbsp; <button onClick={()=>{window.location.href='/dashboard/para-empresas/planos/pagamento?selectedPlan=plano-essencial'}} className="upgrade-plan-essencial">Quero ser Essencial</button></p>
              <p className="discount-yellow">Seja cliente <strong>Premium</strong> e pague <span>{formatNumberToBRL(discountPremium)}</span>&nbsp; <button onClick={()=>{window.location.href='/dashboard/para-empresas/planos/pagamento?selectedPlan=plano-premium'}} className="upgrade-plan-premium">Quero ser Premium</button></p>
              <p className="discount-yellow">Seja cliente <strong>Platinum</strong> e pague <span>{formatNumberToBRL(discountPremium)}</span>&nbsp; <button onClick={()=>{window.location.href='/dashboard/para-empresas/planos/pagamento?selectedPlan=plano-platinum'}} className="upgrade-plan-platinum">Quero ser Platinum</button></p>
            </div>
          )}

          {
            planData?.subscription?.planIdentifier === 'plano-essencial' && (
              <div className="row">
                <p className="discount-yellow">Seja cliente <strong>Premium</strong> e pague <span>{formatNumberToBRL(discountPremium)}</span>&nbsp; <button onClick={(e)=>handleOpenUpgradeToPremium(e)} className="upgrade-plan-premium">Quero ser Premium</button></p>
              </div>
            )
          }

          {clientSubscription !== 'jp' && !demandPrice && (
            <Box className="purchaseBnz-middle-section">
              Pacotes de BNZs
              <Box className="purchaseBnz-middle-section-inner-wrapper">
                <Box className="purchaseBnz-middle-section-left-container">
                  {/* <CustomSelectInput
                    id="bnzPackages"
                    onChange={(event) => handleSetBnzPackage(event.target.value)}
                  >
                    <MenuItem value={''}>
                      Sem pacote - Compra avulsa
                    </MenuItem>
                    {(bnzPackages || []).map((item, index) => (
                      <MenuItem key={index} value={index} selected={packageId === index && true}>
                        {item.packageName}
                      </MenuItem>
                    ))}
                  </CustomSelectInput> */}
                    <Select onChange={(event) => handleSetBnzPackage(event.target.value)}>
                      <option value={''}>Sem pacote - Compra avulsa</option>
                      {(bnzPackages || []).map((item, index) => (
                        <option key={index} value={index} selected={packageId === index && true}>
                          {item.packageName}
                        </option>
                      ))}
                    </Select>
                </Box>
                {(selectedBnzPackage && bnzPackages[selectedBnzPackage].packageName !== 'Silver' && bnzPackages[selectedBnzPackage].packageName !== 'Gold') && (
                  <Box 
                    className="purchaseBnz-middle-section-right-container"
                  >                
                    <Box className="purchaseBnz-package-description-text">
                      <b>{bnzPackages[selectedBnzPackage].packageName}</b><br/>
                    </Box>
                    <Box className="purchaseBnz-package-description-text">
                      Com o pacote <b>{bnzPackages[selectedBnzPackage].packageName}</b> você compra <b>{bnzPackages[selectedBnzPackage].packagePrice} BNZs </b>
                      {enablePackagesBonuses && bnzPackages[selectedBnzPackage].packageBonuses > 0 && (
                        <>e recebe mais <b>{bnzPackages[selectedBnzPackage].packageBonuses} BNZs</b> na sua conta.</>
                      )}
                    </Box>
                  </Box>
                )}
                {(selectedBnzPackage && bnzPackages[selectedBnzPackage].packageName === 'Silver') && (
                  <Box 
                    className="purchaseBnz-middle-section-right-container"
                    style={{
                      color: '#4E4B59',
                      background: 'linear-gradient(105.08deg, #E5E3E0 -29.8%, #D6D4D2 -18.16%, #B0ADAF 4.05%, #959296 17.98%, #F8F8F7 64.76%, #EAE9E9 76.51%, #C4C2C3 99.04%, #898689 128.81%, #B1B1B0 151.18%)'
                    }}
                  >                
                    <Box className="purchaseBnz-package-description-text">
                      <b>{bnzPackages[selectedBnzPackage].packageName}</b><br/>
                    </Box>
                    <Box className="purchaseBnz-package-description-text">
                      Com o pacote <b>{bnzPackages[selectedBnzPackage].packageName}</b> você compra <b>{bnzPackages[selectedBnzPackage].packagePrice} BNZs </b>
                      {enablePackagesBonuses && bnzPackages[selectedBnzPackage].packageBonuses > 0 && (
                        <>e recebe mais <b>{bnzPackages[selectedBnzPackage].packageBonuses} BNZs</b> na sua conta.</>
                      )}
                    </Box>
                  </Box>
                )}
                {(selectedBnzPackage && bnzPackages[selectedBnzPackage].packageName === 'Gold') && (
                  <Box 
                    className="purchaseBnz-middle-section-right-container"
                    style={{
                      color: '#765D0B',
                      background: 'linear-gradient(105.08deg, #EFCA2E -28.78%, #FFFAD2 15.95%, #F8EFC1 24.97%, #E5D494 42.88%, #C8A84C 67.78%, #BB952D 77.97%, #E7D589 123.7%, #EBDB91 127.79%, #FFFAD2 151.18%)'
                    }}
                  >                
                    <Box className="purchaseBnz-package-description-text">
                      <b>{bnzPackages[selectedBnzPackage].packageName}</b><br/>
                    </Box>
                    <Box className="purchaseBnz-package-description-text">
                      Com o pacote <b>{bnzPackages[selectedBnzPackage].packageName}</b> você compra <b>{bnzPackages[selectedBnzPackage].packagePrice} BNZs </b>
                      {enablePackagesBonuses && bnzPackages[selectedBnzPackage].packageBonuses > 0 && (
                        <>e recebe mais <b>{bnzPackages[selectedBnzPackage].packageBonuses} BNZs</b> na sua conta.</>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          {adBlockDetected ? (
            <Box className="purchaseBnz-detectadblock-section">
              * Você possui um bloqueador de anúncios ativado no seu navegador. Por favor desabilite o bloqueador de 
                anúncios durante a compra pois podem ocorrer erros inesperados para adicionar novo cartão de crédito ou 
                durante o processamento das transações.
            </Box>
          ) : (
            ''
          )}
          <Box className="purchaseBnz-bottom-section">
            <Box className="purchaseBnz-bottom-section-header-container">
              <Box>
                Formas de pagamento
              </Box>
              {!bnzQuantity || bnzQuantity < minimumBnzAmountAllowed ? null : (
                <>
                  {finalPrice <= dataWalletSettings?.walletSettings?.creditCardTransactionLimit && (
                    <Box>
                      <Button onClick={() => setNewCard(!newCard)} className="purchaseBnz-secondary-button">
                        {!newCard ? 'Adicionar novo cartão de crédito' : 'Usar outro método de pagamento'}
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Box className="purchaseBnz-bottom-section-inner-wrapper">
              <Box className="purchaseBnz-bottom-section-left-container">
                <Box className="purchaseBnz-payment-type-container">
                  <Radio
                    id="paymentTypeBankSlip"
                    className="purchaseBnz-payment-type-radio-button"
                    name={'paymentType'}
                    onClick={() => handleBankSlipChecked()}
                    checked={bankSlipChecked}
                  />
                  <BankSlipIcon style={{paddingLeft: '12px'}} />
                  <Box className="purchaseBnz-payment-type-field-label">
                    Boleto bancário
                  </Box>
                </Box>
                <Box className="purchaseBnz-payment-type-container">
                  <Radio                                                          
                    id="paymentTypeCreditCard"
                    className="purchaseBnz-payment-type-radio-button"
                    name={'paymentType'}
                    onClick={() => handleCreditCardChecked()}
                    checked={creditCardChecked}
                    disabled={finalPrice <= dataWalletSettings?.walletSettings?.creditCardTransactionLimit ? false : true}
                  />
                  <CreditCardIcon style={{paddingLeft: '12px'}} />
                  <Box className="purchaseBnz-payment-type-field-label">
                    Cartão de Crédito
                  </Box>
                </Box>
              </Box>
              <Box className="purchaseBnz-bottom-section-right-container">
                {!newCard ? (
                  <Box className="purchaseBnz-bottom-section-installments">
                    {finalPrice <= dataWalletSettings?.walletSettings?.creditCardTransactionLimit ? (
                      <>
                        {creditCardChecked && (
                          <Box className="purchaseBnz-cards-section-container" style={{width: '60%'}}>
                            <Box className="purchaseBnz-cards-section-field-label">
                              Selecionar cartão
                            </Box>
                            <Select 
                              onChange={handleCardChecked}
                              disabled={!bnzQuantity || (minimumBnzAmountAllowed > bnzQuantity) ? true : false}
                            >
                              {(!!dataClientCards?.cards?.length || !!nonSavedCards.length) ? (
                                [...dataClientCards?.cards, ...nonSavedCards]?.map((card, index) => (
                                  index === 0 ? (
                                    <>
                                      <option style={{width: '100%'}} key={''} value={''} selected disabled>
                                        {'Selecione'}
                                      </option>
                                      <option key={card.paymentMethodId || card.token} value={card.paymentMethodId || card.token}>
                                        {card.brand} - {card.displayNumber}
                                      </option>
                                    </>
                                  ) : (
                                      <option key={card.paymentMethodId || card.token} value={card.paymentMethodId || card.token}>
                                        {card.brand} - {card.displayNumber}
                                      </option>
                                    )
                                ))
                              ) : (
                                <option key={''} value={''} selected disabled>
                                  Nenhum cartão cadastrado
                                </option>                          
                              )}
                            </Select>
                          </Box>
                        )}
                        {cardChecked && (
                          <Box className="purchaseBnz-cards-section-container" style={{width: '60%'}}>
                            <Box className="purchaseBnz-cards-section-field-label">
                              Parcelas
                            </Box>
                            <Select 
                              onChange={handleCardSelectedInstallment}
                              disabled={!bnzQuantity || (minimumBnzAmountAllowed > bnzQuantity) ? true : false}
                            >
                            {cardInstallments.length ? (
                                cardInstallments?.map((installment, index) => (
                                  index === 0 ? (
                                    <option key={installment.price} value={index + 1} selected>
                                      À vista {installment.price}
                                    </option>
                                  ) : (
                                      <option key={installment.price} value={index + 1}>
                                        {index + 1} vezes de {installment.price}
                                      </option>
                                    )
                                ))
                              ) : (
                                <option key={''} value={''} selected disabled>
                                  Parcelamento não disponível
                                </option>                          
                              )}
                            </Select>
                          </Box>
                        )}
                      </>
                    ) : (
                      creditCardChecked && (
                        <Box className="purchaseBnz-cards-section-container">
                          <Box className="purchaseBnz-cards-section-field-label">
                            Valor acima do limite permitido para compra com cartão de crédito
                          </Box>
                        </Box>
                      )
                    )}
                    {bankSlipChecked && (
                      <Box className="purchaseBnz-cards-section-container" style={{width: '40%'}}>
                        <Box className="purchaseBnz-cards-section-field-label">
                          Parcelas
                        </Box>
                        <Select 
                          onChange={handleBankSlipInstallmentsSelect}
                          disabled={!bnzQuantity || (minimumBnzAmountAllowed > bnzQuantity) ? true : false}
                        >
                          {bankSlipInstallments?.map((installment, index) => (
                            index === 0 ? (
                              <option key={installment.price} value={index + 1} selected>
                                À vista {installment.price}
                              </option>
                            ) : (
                                <option key={installment.price} value={index + 1}>
                                  {index + 1} vezes de {installment.price}
                                </option>
                              )
                          ))}
                        </Select>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box className="purchaseBnz-add-cards">
                    <form id="newPaymentCard">
                      <Box className="purchaseBnz-cards-section-upper-container">
                        <Box className="purchaseBnz-cards-section-container">
                          <Box className="purchaseBnz-cards-section-field-label">
                            Número do cartão
                          </Box>
                          <InputMask mask={cardBrandMask} onChange={updateCardBrandMask} onKeyDown={updateCardBrandMask} maskChar={null}>
                            {(inputCardProps) => (
                              <CustomTextField
                                id="number"
                                name="number"
                                placeholder="**** **** **** ****"
                                inputProps={{ 'data-iugu': 'number' }}
                                {...inputCardProps}
                              />
                            )}
                          </InputMask>
                        </Box>
                        <Box className="purchaseBnz-cards-section-container">
                          <Box className="purchaseBnz-cards-section-field-label">
                            Nome do cartão
                          </Box>
                          <CustomTextField
                            id="cardName"
                            name='cardName'
                            placeholder='Nome do cartão'
                            inputProps={{ 'data-iugu': 'full_name' }}
                          />
                        </Box>
                      </Box>
                      <Box className="purchaseBnz-cards-section-middle-container">
                        <Box className="purchaseBnz-cards-section-container">
                          <Box className="purchaseBnz-cards-section-field-label">
                            Vencimento
                          </Box>
                          <InputMask mask={expirationMask} maskChar={null} onChange={updateExpirationMask} onKeyDown={updateExpirationMask}>
                            {(inputCardProps) => (
                              <CustomTextField 
                                id="cardExpire"
                                name='cardExpire'
                                placeholder='xx/xx'
                                inputProps={{ 'data-iugu': 'expiration' }}
                                {...inputCardProps}
                              />
                            )}
                          </InputMask>
                        </Box>
                        <Box className="purchaseBnz-cards-section-container">
                            <Box className="purchaseBnz-cards-section-field-label">
                              Cód. segurança
                            </Box>
                            <InputMask mask="999?" formatChars={{ "9": "[0-9]", "?": "[0-9 ]" }} maskChar={null} >
                              {(inputProps) => (
                                <CustomTextField 
                                  id='safe_number' 
                                  name='safe_number'
                                  inputProps={{ 'data-iugu': 'verification_value' }} 
                                  placeholder="***"
                                />
                              )}
                            </InputMask>
                        </Box>              
                      </Box>
                      <Box className="purchaseBnz-cards-section-lower-container">
                        <Box className="purchaseBnz-cards-section-container">
                            <Box className="purchaseBnz-cards-section-field-label">
                              Salvar cartão para futuras compras
                            </Box>
                            <SwitchInput
                              id="saveCard"
                              name={'saveCard'}  
                              onChange={handleShouldSaveCard}
                              checked={shouldSaveCard}
                              sx={{marginLeft: '10px'}}
                            />
                        </Box>               
                      </Box>
                    </form>   
                  </Box>
                )}
              </Box>
            </Box>
            {(!activeClientSubscriptions && enableDiscountCoupons) && (
              <Box className="purchaseBnz-coupon-container">
                Cupom de desconto
                <Box className="purchaseBnz-coupon-field">
                  <CustomTextField
                    id="voucherCode"
                    name='voucherCode'
                    placeholder='CUPOM10%'
                    helperText={errorVoucher && (
                      (((((errorVoucher.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).error
                    )}
                    onChange={handleCheckVoucherCode}
                  />
                  {loadingVoucher && (
                    <CustomSpinner />
                  )}
                  {errorVoucher && (
                    <ModalCloseIcon width={26} height={24} style={{marginTop:'7px'}} />
                  )}
                  {dataVoucher && (
                    <CheckedIcon width={26} height={24} style={{marginTop:'7px'}} />
                  )}
                </Box>
              </Box>
            )}
            <Box className="purchaseBnz-button-container">
              <Button 
                disabled={loadingPurchaseBNZ || loadingSaveCard} 
                onClick={() => props.closeParentModal && props.closeParentModal()} 
                className="purchaseBnz-secondary-button"
              >
                Cancelar
              </Button>
              {newCard ? (
                <Button 
                  disabled={loadingSaveCard} 
                  onClick={() => handleChangeCard()} 
                  className="purchaseBnz-main-button"
                >
                  {loadingSaveCard ? <CustomSpinner color="#FFFFFF" /> : 'Adicionar'}
                </Button>
              ) : (
                <Button 
                  disabled={(!cardChecked && !bankSlipChecked) || loadingPurchaseBNZ || (!bnzQuantity || (minimumBnzAmountAllowed > bnzQuantity))}
                  className="purchaseBnz-main-button"
                  type="submit"
                >
                  {loadingPurchaseBNZ ? <CustomSpinner color="#FFFFFF" /> : 'Comprar'}
                </Button>
              )}
            </Box>
          </Box>
        </form>
      )}
    </Box>
      <RequestService
        openModal={upgradeToPremium}
        handleClose={()=>setUpgradeToPremium(!upgradeToPremium)}
        requestedService={"upgrade"}/>
    </div>
  )
}
