import React  from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import "./SuccessModal.css";
import Coruja from "../../../../images/owl-success-data-privacy.svg"
// import CloseIcon from '@mui/icons-material/Close';

const SuccessModal = (props) => {


  const { handleClose, openModal, invoiceLink } = props;

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      disableAutoFocus
    >
      <div className="modal-success-data-privacy">
        {/* <CloseIcon /> */}
        <h2>OBRIGADO PELA CONFIANÇA!</h2>
        <div><img src={Coruja} alt="Bonuz owl"/></div> 
        <p>
          Clique no botão abaixo para acessar o seu dashboard do Data Privacy{invoiceLink && <> ou <a href={invoiceLink} target="noopener noreferrer _blank">clique aqui</a> para acessar o seu boleto.</>}
        </p>
        <Box className="modal-success-data-privacy-buttons-container">
          <Button onClick={handleClose} className="modal-success-data-privacy-success-button">
            OK
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default SuccessModal;
